/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { autoUpdate, flip, limitShift, offset, shift, useFloating } from '@floating-ui/react-dom';
import isHotkey from 'is-hotkey';
import { Range as SlateRange } from 'slate';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { MuiFade } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ToolButtonFactory } from 'bundles/cml/editor/components/toolbars/constants';
import type { ToolbarProps } from 'bundles/cml/editor/components/toolbars/types';
import { getHoveringTools } from 'bundles/cml/editor/components/toolbars/utils';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { hoverMenu } from 'bundles/cml/shared/styles';
import type { HoverToolsKeys } from 'bundles/cml/shared/utils/customTools';

const styles = {
  root: css`
    ${hoverMenu()}
    border-radius: 2px;
    z-index: ${zIndex.xxl};
  `,
};

const isEscapeKey = isHotkey('escape');

const HoveringToolbar = (props: ToolbarProps) => {
  const { customTools, options } = props;
  const editor = useSlate();
  const enableIndent = hasAncestorOfType(editor, [BLOCK_TYPES.BULLET_LIST, BLOCK_TYPES.NUMBER_LIST]);

  const toolOptions = useMemo(
    () => ({
      ...options,
      indent: enableIndent,
    }),
    [options, enableIndent]
  );

  const hoveringTools = useMemo(() => getHoveringTools(customTools, toolOptions), [customTools, toolOptions]);

  const [rangeRef, setRangeRef] = useState<Range | null>(null);
  const [open, setOpen] = useState(false);
  const { focused } = useContext(FocusContext);

  const {
    x: left,
    y: top,
    refs: { setReference, setFloating },
    strategy: position,
  } = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [offset(8), flip(), shift({ limiter: limitShift() })],
    whileElementsMounted: autoUpdate,
  });

  const staticEditor = useSlateStatic();
  const selection = editor.selection;

  useEffect(() => {
    setOpen(!!selection && SlateRange.isExpanded(selection) && focused); // && !hasVoidNode(value);
  }, [selection, focused]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!open) {
        setRangeRef(null);
        return;
      }

      try {
        const domSelection = window.getSelection();
        if (domSelection) {
          setRangeRef(domSelection.getRangeAt(0));
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error('[CMLEditor] Unable to get range from selection');
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [open, selection]);

  useLayoutEffect(() => {
    setReference(rangeRef);
  }, [rangeRef, setReference]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isEscapeKey(e.nativeEvent)) {
        e.preventDefault();
        ReactEditor.focus(staticEditor);
        setOpen(false);
      }
    },
    [staticEditor]
  );

  if (!rangeRef) {
    return null;
  }

  return (
    <MuiFade in>
      <Toolbar
        style={{
          position,
          left: left ?? '',
          top: top ?? '',
        }}
        ref={setFloating}
        className="rc-HoveringToolbar"
        css={styles.root}
        onKeyDown={handleKeyDown}
      >
        <React.Fragment>
          {hoveringTools.map((tool: HoverToolsKeys) => {
            const toolbarButtonFactory = ToolButtonFactory[tool];
            if (!toolbarButtonFactory) {
              return null;
            }

            const ToolbarButton = toolbarButtonFactory();

            return <ToolbarButton {...props} tools={customTools} key={tool} pageless={false} />;
          })}
        </React.Fragment>
      </Toolbar>
    </MuiFade>
  );
};

export default HoveringToolbar;
