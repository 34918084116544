/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Editor, Transforms } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';

import { Typography2, useId } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import ResizableContainer from 'bundles/authoring/common/components/ResizableContainer';
import { isEmbedPdfInReadingEnabled } from 'bundles/authoring/featureFlags';
import type { Props as WidgetModalProps } from 'bundles/cml/editor/components/WidgetModal';
import type { Props as AssetModalProps } from 'bundles/cml/editor/components/dialogs/AssetModal';
import DeleteConfirmDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import type { Props as ImageDescriptionDialogProps } from 'bundles/cml/editor/components/elements/image/dialogs/ImageDescriptionDialog';
import { useCMLToSlate } from 'bundles/cml/editor/components/hooks/useCMLToSlate';
import { useCustomTools } from 'bundles/cml/editor/components/hooks/useCustomTools';
import { useFocusHandler } from 'bundles/cml/editor/components/hooks/useFocusHandler';
import { useKeyDownHandler } from 'bundles/cml/editor/components/hooks/useKeyDownHandler';
import {
  useRenderElement,
  useRenderPlaceholder,
  useRenderTextElement,
} from 'bundles/cml/editor/components/hooks/useRenderElement';
import { useSlateEditor } from 'bundles/cml/editor/components/hooks/useSlateEditor';
import { useSlateToCML } from 'bundles/cml/editor/components/hooks/useSlateToCML';
import Notification from 'bundles/cml/editor/components/notifications/Notifications';
import Toolbars from 'bundles/cml/editor/components/toolbars/Toolbars';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import type { AssetContextType } from 'bundles/cml/editor/context/assetContext';
import { CodeBlockContext } from 'bundles/cml/editor/context/codeBlockContext';
import type { CodeBlockContextType } from 'bundles/cml/editor/context/codeBlockContext';
import { FillableBlanksContext } from 'bundles/cml/editor/context/fillableBlanksContext';
import type { FillableBlanksContextType } from 'bundles/cml/editor/context/fillableBlanksContext';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import type { FocusContextType } from 'bundles/cml/editor/context/focusContext';
import { ImageContext } from 'bundles/cml/editor/context/imageContext';
import type { ImageContextType } from 'bundles/cml/editor/context/imageContext';
import { NotificationContext } from 'bundles/cml/editor/context/notificationContext';
import type { NotificationContextType } from 'bundles/cml/editor/context/notificationContext';
import { PersonalizationTagContext } from 'bundles/cml/editor/context/personalizationTagContext';
import type { PersonalizationTagContextType } from 'bundles/cml/editor/context/personalizationTagContext';
import type { StyleContextType } from 'bundles/cml/editor/context/styleContext';
import { StyleContext } from 'bundles/cml/editor/context/styleContext';
import { WidgetContext } from 'bundles/cml/editor/context/widgetContext';
import type { WidgetContextType } from 'bundles/cml/editor/context/widgetContext';
import type { AssetModalOptions } from 'bundles/cml/editor/types/assetTypes';
import type { Props as EditorProps } from 'bundles/cml/editor/types/cmlEditorProps';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import type { WidgetMenuOptions, WidgetModalOptions } from 'bundles/cml/editor/types/widgetTypes';
import { useVariableSubstitutions } from 'bundles/cml/editor/utils/variableSubstitutionUtils';
import { cdsToCMLStyles } from 'bundles/cml/legacy/components/cds/cdsToCMLStyles';
import { getCodeBlockIndices } from 'bundles/cml/shared/components/code/utils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';
import type { AssetManager as AssetManagerType } from 'bundles/cml/shared/types/assetManager';
import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';
import { renderWithProviders } from 'bundles/cml/shared/utils/withProviders';
import type { Provider } from 'bundles/cml/shared/utils/withProviders';

import _t from 'i18n!nls/cml';

const styles = {
  label: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  editor: (theme: Theme) => css`
    position: relative;
    outline: none;
    background-color: var(--cds-color-neutral-background-primary);

    [data-slate-editor='true'] {
      ${cdsToCMLStyles(theme)}

      // Slate does not properly handle scrolling with void nodes (images, assets, code blocks, etc...).
      // The bug is due to the internal spacer node having "absolute" positioning. This is especially a problem for void nodes
      // that have their own scrollable sections like Code blocks. CP-12378
      // https://github.com/ianstormtaylor/slate/issues/2302
      [data-slate-void='true'],
      [data-slate-spacer='true'] {
        position: relative !important;
      }
    }
  `,
  pageless: css`
    &,
    [data-testid='resizable-root'],
    [data-testid='resizable-container'],
    .data-cml-editor-scroll-container,
    .data-cml-editor-scroll-content,
    .data-cml-editor-padding-container {
      height: 100%;
    }

    [data-slate-editor='true'] {
      min-height: 100% !important;
      padding-bottom: var(--cds-spacing-300);
    }
  `,
  scrollContainer: css`
    height: 100%;
    overflow: auto;
  `,
  resizableContent: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-200);
  `,
  pagelessContent: css`
    padding: var(--cds-spacing-50);
  `,
};

const TOOLBAR_HEIGHT = 53;
const BORDER_WIDTH = 1 * 2;
const MIN_HEIGHT = 104;
const MAX_HEIGHT = 320;

export type Props = Omit<EditorProps, 'initialCML' | 'onContentChange'> & {
  cmlValue: string;
  onChange: (value: string) => void;
  onMarkdownChange?: (value: string) => void;
  AssetModal: React.ComponentType<AssetModalProps>;
  AssetManager: AssetManagerType;
  ImageDescriptionDialog: React.ComponentType<ImageDescriptionDialogProps>;
  WidgetManager?: WidgetManagerType;
  WidgetModal?: React.ComponentType<WidgetModalProps>;
  widgetMenuOptions?: WidgetMenuOptions;
  enableEmbeddedPdf?: boolean;
};

const CMLEditor: React.FC<Props> = (props) => {
  const {
    cmlValue,
    onChange,
    uploadOptions,
    onFocus,
    onBlur,
    AssetModal,
    AssetManager,
    ImageDescriptionDialog,
    codeBlockOptions,
    onPersonalizationTagClick,
    isLearnerUpload = false,
    macros,
    minHeight = MIN_HEIGHT,
    maxHeight = MAX_HEIGHT,
    usePagelessDesign: pageless = false,
    enableMonospace: monospace = true,
    scrollingContainer,
    placeholder = _t('Enter text here'),
    focusOnLoad: autoFocus,
    shouldFocus = false,
    label,
    ariaLabel = _t('Rich Text Editor, toolbar available, standard formatting hotkeys supported'),
    ariaRequired,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaInvalid,
    contentId,
    readOnly,
    children,
    toolbarPosition = 'bottom',
    fillableBlankTags = [],
    enableWidgets = false,
    enableMarkdown = false,
    WidgetManager,
    WidgetModal,
    widgetMenuOptions,
    onMarkdownChange,
    enableEmbeddedPdf = isEmbedPdfInReadingEnabled(),
    borderColor,
  } = props;

  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);
  const { tools: customTools, options } = useCustomTools({
    monospace,
    pageless,
    isLearnerUpload,
    customTools: props.customTools,
    enableWidgets,
  });
  const editor = useSlateEditor(setNotification, customTools, enableMarkdown);
  const staticEditor = useRef(editor).current;
  const id = useId();

  const ref = useRef<HTMLDivElement>(null);
  const { focused, setFocused, setFocusedOverride } = useFocusHandler(ref, onFocus, onBlur);

  const handleVariableSubstitutions = useVariableSubstitutions(macros);

  const value = useCMLToSlate(staticEditor, focused, cmlValue);
  const [assetModalOptions, setAssetModalOptions] = useState<AssetModalOptions | undefined>();
  const [widgetModalOptions, setWidgetModalOptions] = useState<WidgetModalOptions | undefined>();
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const renderElement = useRenderElement();
  const renderLeaf = useRenderTextElement();
  const renderPlaceholder = useRenderPlaceholder();
  const handleKeyDown = useKeyDownHandler(staticEditor, { setDeleteConfirm });
  const handleChange = useSlateToCML(staticEditor, cmlValue, onChange, onMarkdownChange);

  // disable scrollIntoView if the editor doesn't have focus
  // to prevent Slate from scrolling to the previous selection
  // if the container is scrolled when the editor is not focused
  const handleScrollIntoView = focused ? undefined : () => undefined;

  const assetContext = useMemo<AssetContextType>(
    () => ({
      enableEmbeddedPdf,
      isLearnerUpload,
      setAssetModalOptions,
      assetManager: AssetManager,
      pageless,
      uploadOptions,
    }),
    [enableEmbeddedPdf, isLearnerUpload, AssetManager, pageless, uploadOptions]
  );

  const widgetContext = useMemo<WidgetContextType>(
    () => ({
      widgetManager: WidgetManager,
      setWidgetModalOptions,
      widgetMenuOptions,
    }),
    [WidgetManager, widgetMenuOptions]
  );

  const focusContext = useMemo<FocusContextType>(
    () => ({ focused, setFocused: setFocusedOverride }),
    [focused, setFocusedOverride]
  );
  const imageContext = useMemo<ImageContextType>(() => ({ ImageDescriptionDialog }), [ImageDescriptionDialog]);
  const styleContext = useMemo<StyleContextType>(() => ({ pageless, editorNode: ref.current }), [pageless, ref]);
  const codeBlockContext = useMemo<CodeBlockContextType>(
    () => ({ codeBlockOptions, codeBlockIndices: getCodeBlockIndices(staticEditor.children) }),
    [codeBlockOptions, staticEditor]
  );
  const personalizationTagContext = useMemo<PersonalizationTagContextType>(
    () => ({ onPersonalizationTagClick }),
    [onPersonalizationTagClick]
  );
  const notificationContext = useMemo<NotificationContextType>(() => ({ setNotification }), [setNotification]);
  const fillableBlankContext = useMemo<FillableBlanksContextType>(() => {
    return {
      fillableBlanks: fillableBlankTags.reduce((acc: Record<string, string>, blank) => {
        acc[blank.id] = blank.label;
        return acc;
      }, {}),
    };
  }, [fillableBlankTags]);

  const handleDismissNotification = useCallback(() => setNotification(undefined), []);

  useEffect(() => {
    if (value) {
      Editor.normalize(staticEditor, { force: true });
    }
  }, [customTools, value, staticEditor]);

  const providers = [
    [AssetContext, assetContext] as Provider<AssetContextType>,
    [FocusContext, focusContext] as Provider<FocusContextType>,
    [ImageContext, imageContext] as Provider<ImageContextType>,
    [StyleContext, styleContext] as Provider<StyleContextType>,
    [CodeBlockContext, codeBlockContext] as Provider<CodeBlockContextType>,
    [PersonalizationTagContext, personalizationTagContext] as Provider<PersonalizationTagContextType>,
    [NotificationContext, notificationContext] as Provider<NotificationContextType>,
    [FillableBlanksContext, fillableBlankContext] as Provider<FillableBlanksContextType>,
    [WidgetContext, widgetContext] as Provider<WidgetContextType>,
  ];

  const focusEditor = useCallback(() => {
    if (!staticEditor.selection) {
      if (!staticEditor.children.length) {
        Transforms.insertNodes(staticEditor, { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] });
      }

      const points = Array.from(Editor.positions(staticEditor, { at: [0] }))[0];
      staticEditor.selection = points ? { anchor: points, focus: points } : null;
    }
    ReactEditor.focus(staticEditor);
  }, [staticEditor]);

  useEffect(() => {
    if (autoFocus) {
      focusEditor();
    }
  }, [staticEditor, autoFocus, focusEditor]);

  const previousShouldFocus = usePrevious(shouldFocus);
  useEffect(() => {
    if (!previousShouldFocus && shouldFocus) {
      focusEditor();
    }
  }, [previousShouldFocus, shouldFocus, focusEditor]);

  const toolbar = !readOnly && (
    <Toolbars
      customTools={customTools}
      scrollingContainer={scrollingContainer}
      pageless={pageless}
      position={toolbarPosition}
      options={options}
    />
  );

  const resizable = !readOnly && !pageless;
  const minContentHeight = resizable ? Math.max(minHeight, MIN_HEIGHT) - TOOLBAR_HEIGHT - BORDER_WIDTH : undefined;

  return (
    <React.Fragment>
      {label && (
        <Typography2 css={styles.label} variant="subtitleMedium" component="label">
          {label}
        </Typography2>
      )}
      <div
        id={id}
        ref={ref}
        tabIndex={-1}
        className="rc-CMLEditor slate-editor"
        css={[styles.editor, pageless && styles.pageless]}
      >
        {renderWithProviders(
          providers,
          <ResizableContainer
            contentSelector={`#${id} .data-cml-editor-scroll-content`}
            resizable={resizable}
            minHeight={Math.max(minHeight, MIN_HEIGHT)}
            initialHeight={maxHeight}
            borderColor={borderColor || (focused ? 'var(--cds-color-blue-700)' : undefined)}
          >
            <Slate editor={editor} value={value} onChange={handleChange}>
              <div className="data-cml-editor-scroll-container" css={styles.scrollContainer}>
                <div className="data-cml-editor-scroll-content">
                  {toolbarPosition === 'top' && toolbar}
                  <div
                    className="data-cml-editor-padding-container"
                    css={resizable ? styles.resizableContent : styles.pagelessContent}
                    style={{ minHeight: minContentHeight }}
                  >
                    <Editable
                      decorate={macros ? handleVariableSubstitutions : undefined}
                      renderElement={renderElement}
                      renderPlaceholder={renderPlaceholder}
                      renderLeaf={renderLeaf}
                      placeholder={placeholder}
                      autoFocus={autoFocus}
                      onKeyDown={handleKeyDown}
                      onFocus={setFocused}
                      scrollSelectionIntoView={handleScrollIntoView}
                      aria-label={ariaLabel ?? label}
                      aria-required={ariaRequired}
                      aria-labelledby={ariaLabelledBy}
                      aria-describedby={ariaDescribedBy}
                      readOnly={readOnly}
                      data-testid={contentId}
                      aria-invalid={ariaInvalid}
                      tabIndex={0}
                    />
                  </div>
                  {toolbarPosition === 'bottom' && toolbar}
                </div>
              </div>
            </Slate>
          </ResizableContainer>
        )}
        {deleteConfirm && <DeleteConfirmDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
        <AssetModal
          assetOptions={assetModalOptions}
          isLearnerUpload={isLearnerUpload}
          uploadOptions={uploadOptions}
          onClose={() => setAssetModalOptions(undefined)}
          onFocused={setFocusedOverride}
        />
        {WidgetModal && enableWidgets && (
          <WidgetModal widgetOptions={widgetModalOptions} onClose={() => setWidgetModalOptions(undefined)} />
        )}
        <Notification notification={notification} onDismiss={handleDismissNotification} />
        {children}
      </div>
    </React.Fragment>
  );
};

export default CMLEditor;
