/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import GoogleCertificate from 'bundles/epic/data/defaults/GoogleCertificate.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  removeLinksAboveFold: boolean;
  '2023q3-google-geo-price-test-india-param':
    | '2023q3-google-geo-price-test-india-14-control'
    | '2023q3-google-geo-price-test-india-18-variant'
    | '2023q3-google-geo-price-test-india-19.60-variant'
    | '2023q3-google-geo-price-test-india-24-variant';
  '2023q3-google-geo-price-test-excl-india-param':
    | '2023q3-google-geo-price-test-excl-india-14-control'
    | '2023q3-google-geo-price-test-excl-india-24-variant'
    | '2023q3-google-geo-price-test-excl-india-29-variant'
    | '2023q3-google-geo-price-test-excl-india-39-variant';
  blockCertInFreeTrialEnabled: boolean;
  finaidCeilingDiscountPercentage: 100 | 75;
  finaidCeilingDiscountPercentageIndia: 100 | 90;
  finaidCeilingDiscountPercentageNonUSDeveloped: 100 | 75;
  finaidCeilingVariant: 'optOut' | 'A';
  finaidCeilingVariantV2: 'optOut' | 'A';
  finaidCeilingVariantV3: 'optOut' | 'control' | 'A';
  finaidCeilingDiscountPercentageV3RegionA: 100 | 90;
  finaidCeilingDiscountPercentageV3RegionB: 100 | 75;
  routeToFinancialAidApplicationV3: boolean;
  googleS12nPriceDecreaseTestVariant: boolean;
  combineFreeTrialAndCheckout: 'control' | '1' | '2';
  googleXdpImageRollout: boolean;
  showGoogleSeptemberAICampaignBadgeAndBanner: boolean;
  showGoogleRefreshCampaign: boolean;
};

const NAMESPACE = 'GoogleCertificate';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([GoogleCertificate as $TSFixMe]);

const GoogleCertificateEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default GoogleCertificateEpicClient;
