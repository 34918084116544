import React, { useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { useResizeDetector } from 'react-resize-detector';

import { useLocalizedStringFormatter, breakpoints } from '@coursera/cds-common';
import type { BaseComponentProps } from '@coursera/cds-common';
import { ChevronNextIcon } from '@coursera/cds-icons';

import ActionOverflow from '@core/ActionOverflow';
import { IconButton } from '@core/IconButton';
import { useMediaQuery } from '@core/utils';

import type { BreadcrumbsItemElement } from './BreadcrumbsItem';
import getBreadcrumbsCss, { classes } from './getBreadcrumbsCss';
import i18nMessages from './i18n';

type FocusRef = {
  isOnHome: boolean;
  isInsideBreadcrumbsList: boolean;
  isOnNavigationMenuButton: boolean;
  isInsideNavigationMenu: boolean;
};

export type Props = BaseComponentProps<'nav'> & {
  /**
   * Individual `Breadcrumbs.Item` elements used as links.
   *
   * ⚠️The `Breadcrumbs.Item` elements **must** be direct descendants.
   */
  children: React.ReactNode;

  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;
};

/**
 * Breadcrumbs are a secondary navigation aid that displays a user’s current location. It provides a way to navigate through a list of pages and understand the hierarchy among page levels.
 *
 * See [Props](__storybookUrl__/components-navigation-breadcrumbs--default#props)
 */
const Breadcrumbs = React.forwardRef<HTMLElement, Props>(function Breadcrumbs(
  props,
  ref
) {
  const { invert = false, children, ...navProps } = props;

  const stringFormatter = useLocalizedStringFormatter(i18nMessages);

  const xs = useMediaQuery(breakpoints.down('xs'));

  const homeRef = useRef<HTMLAnchorElement>(null);
  const navigationMenuButtonRef = useRef<HTMLButtonElement>(null);
  const navigationMenuRef = useRef<HTMLOListElement>(null);

  const { width, ref: listRef } = useResizeDetector<HTMLOListElement>({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 300,
  });

  const [truncated, setTruncated] = useState(false);
  const focusRef = useRef<FocusRef | null>(null);

  const findFocus = useCallback(
    (): FocusRef => ({
      isOnHome: homeRef.current === document.activeElement,
      isInsideBreadcrumbsList:
        listRef.current?.contains?.(document.activeElement) || false,
      isOnNavigationMenuButton:
        navigationMenuButtonRef.current === document.activeElement,
      isInsideNavigationMenu:
        navigationMenuRef.current?.contains?.(document.activeElement) || false,
    }),
    [
      homeRef.current,
      listRef.current,
      navigationMenuButtonRef.current,
      navigationMenuRef.current,
    ]
  );

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    // Force show all list items to measure their width
    listRef.current.classList.add(classes.pendingOverflowCalculation);

    const overflow = listRef.current.scrollWidth > listRef.current.clientWidth;

    // Disable force showing. Computation is done.
    listRef.current.classList.remove(classes.pendingOverflowCalculation);

    if (overflow !== truncated) {
      focusRef.current = findFocus();
    }

    setTruncated(overflow);
  }, [width, listRef.current]);

  useEffect(() => {
    if (!focusRef.current) {
      return;
    }

    const focus = focusRef.current;

    if (truncated) {
      // Reset focus to navigation menu button, if breadcrumbs were trucated
      if (focus.isInsideBreadcrumbsList && !focus.isOnHome) {
        navigationMenuButtonRef.current?.focus();
      }
    } else {
      // Reset focus back home, if breadcrumbs fit
      if (focus.isInsideNavigationMenu || focus.isOnNavigationMenuButton) {
        homeRef.current?.focus();
      }
    }

    focusRef.current = null;
  }, [truncated]);

  const items = React.Children.toArray(children).filter(
    React.isValidElement
  ) as BreadcrumbsItemElement[];

  if (items.length === 0) {
    return null;
  }

  const [firstItem, ...secondToLastItems] = items;

  return (
    <nav
      ref={ref}
      aria-label={stringFormatter.format('breadcrumbs')}
      css={getBreadcrumbsCss}
      {...navProps}
    >
      <ol ref={listRef} className={classes.list}>
        <li className={classes.listItem}>
          <IconButton
            ref={homeRef}
            component="a"
            {...firstItem.props}
            className={clsx(firstItem.props.className, classes.homeIcon)}
            data-testid="home-link"
            intent="home"
            size="small"
            variant={invert ? 'ghostInvert' : 'ghost'}
          />
        </li>
        {truncated && (
          <li className={clsx(classes.listItem, classes.actionOverflowTrigger)}>
            <ChevronNextIcon
              color={invert ? 'invert' : 'default'}
              size="small"
            />
            <ActionOverflow
              ref={navigationMenuButtonRef}
              aria-label={stringFormatter.format('navigationMenuButtonTitle')}
              invert={invert}
              menuRef={navigationMenuRef}
              size="small"
            >
              {secondToLastItems.map((item, i) => (
                <ActionOverflow.Item
                  key={item.key}
                  aria-current={
                    i === secondToLastItems.length - 1 ? 'page' : undefined
                  }
                  component="a"
                  {...item.props}
                />
              ))}
            </ActionOverflow>
          </li>
        )}
        {secondToLastItems.map((item, i) => {
          const lastItem = i === secondToLastItems.length - 1;
          const itemsToShow = xs ? 1 : 2;

          return (
            <li
              key={item.key}
              className={clsx(classes.listItem, {
                [classes.hide]:
                  truncated && i < secondToLastItems.length - itemsToShow,
              })}
            >
              <ChevronNextIcon
                color={invert ? 'invert' : 'default'}
                size="small"
              />
              {React.cloneElement(item, {
                'aria-current': lastItem ? 'page' : undefined,
                className: clsx(item.props.className, classes.link, {
                  [classes.disableNowrap]: truncated && lastItem,
                }),
                invert: invert,
              })}
            </li>
          );
        })}
      </ol>
    </nav>
  );
});

export default Breadcrumbs;
