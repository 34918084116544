/* eslint-disable camelcase */

/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import { Button, Grid, InlineNotification, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';
import { css as cuicss, placeholder } from '@coursera/coursera-ui';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import EnterpriseProductCardCollection, {
  EnterpriseProductCardCollectionsPlaceholder,
} from 'bundles/enterprise-collections/components/EnterpriseProductCardCollection';
import { getAllProducts } from 'bundles/enterprise-collections/components/getCollectionItemList';
import type { EnterpriseBadgeWithCollection } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import { PRODUCT_TYPE } from 'bundles/program-common/components/ProductCard';
import type { Product, ProductType } from 'bundles/program-common/types/programCommon';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import EnterpriseBadgeEnrollConfirmationModal from 'bundles/program-home/components/modals/EnterpriseBadgeEnrollConfirmationModal';
import type { Props } from 'bundles/program-home/components/single-program/EnterpriseBadgeCollectionsView';
import { EnterpriseBadge_userState } from 'bundles/program-home/components/single-program/__generated__/EnterpriseBadgeCollectionsQuery';
import useEnterpriseBadgeEnrollMutation from 'bundles/program-home/queryHooks/useEnterpriseBadgeEnrollMutation';
import { removeCredentialFromBadgeTitle } from 'bundles/program-home/utils/EnterpriseBadgeUtils';

import _t from 'i18n!nls/program-home';

export type PropsForEnterpriseBadge = {
  enterpriseBadgeWithCollection: EnterpriseBadgeWithCollection;
} & Omit<Props, 'enterpriseBadgesWithCollection' | 'loading'>;

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const TrackedButtonLink = withSingleTracked({ type: 'BUTTON' })<ButtonProps<ReactRouterLink>>(
  // TODO(jkemp) Ask for way to type this across program-home
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

export const useStyles = () => {
  const theme = useTheme();
  return {
    badgeTitle: css`
      margin-bottom: ${theme.spacing(8)};
    `,
    collectionInfo: css`
      padding: ${theme.spacing(24)};
      margin-bottom: ${theme.spacing(24)};
      ${theme.breakpoints.down('xs')} {
        margin-left: calc(${theme.spacing(12)} * -1);
        margin-right: calc(${theme.spacing(12)} * -1);
      }

      border-top-style: solid;
      border-top-color: ${theme.palette.blue[600]};
      border-top-width: 1px;
      background-color: ${theme.palette.blue[100]};
    `,
    badgeDescription: css`
      margin-bottom: ${theme.spacing(24)};
    `,
    badgeImg: css`
      margin-left: ${theme.spacing(48)};
      ${theme.breakpoints.down('sm')} {
        margin-left: ${theme.spacing(0)};
        display: flex;
        justify-content: center;
      }
      ${theme.breakpoints.down('xs')} {
        margin-bottom: ${theme.spacing(24)};
      }
    `,
    learningObjective: css`
      line-height: 30px;
    `,
    actionButtonContainer: css`
      margin-top: ${theme.spacing(32)};
    `,
    actionButton: css`
      margin-top: ${theme.spacing(16)};
      margin-bottom: ${theme.spacing(12)};
      ${theme.breakpoints.down('xs')} {
        display: flex;
        justify-content: center;
      }
    `,
    productCollectionDescription: css`
      margin-top: ${theme.spacing(8)};
      margin-bottom: ${theme.spacing(12)};
    `,
    badgePlaceholderImg: css`
      margin-top: ${theme.spacing(32)};
      width: 185px;
      height: 185px;
      margin-bottom: 8px;
      animation-delay: -0.3s;
    `,
    collectionInfoPlaceholder: css`
      padding: ${theme.spacing(24)};
      margin-bottom: ${theme.spacing(24)};
      ${theme.breakpoints.down('xs')} {
        margin-left: calc(${theme.spacing(12)} * -1);
        margin-right: calc(${theme.spacing(12)} * -1);
      }

      background-color: ${theme.palette.blue[100]};
      border-top-style: solid;
      border-top-color: #2a73cc;
      border-top-width: 1px;
    `,
    confirmationButton: css`
      margin-right: ${theme.spacing(24)};
    `,
    discListStyle: css`
      list-style-type: disc;
      padding-inline-start: 40px;
      margin-bottom: 1rem;
    `,
  };
};

export function EnterpriseBadgeComponent({
  enterpriseBadgeWithCollection,
  userId,
  programId,
  programSlug,
  isProgramMember,
  onProductCardClick,
  refetchEnterpriseBadgeCollections,
}: PropsForEnterpriseBadge) {
  const styles = useStyles();

  const { title, description, collectionId, learningObjectives, imageUrl, productIds, userState } =
    enterpriseBadgeWithCollection.enterpriseBadge;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<boolean>(false);
  const [isEnrolling, setIsEnrolling] = React.useState(false);
  const [enrollError, setEnrollError] = React.useState(false);
  const badgeTitle = removeCredentialFromBadgeTitle(title);
  const router = useRouter();
  const enroll = useEnterpriseBadgeEnrollMutation();

  const handleNavToMyCourses = () => {
    router.push({
      pathname: `/programs/${programSlug}/my-learning`,
    });
  };

  const handleProductCardClick =
    (collectionTrackingId: string | null) => (product: Product, productType: ProductType) => {
      onProductCardClick(product, productType, collectionTrackingId, programId);
    };

  const handleEnroll = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (isEnrolling) return;

    try {
      setIsEnrolling(true);
      await enroll({
        user_id: userId,
        badge_template_id: enterpriseBadgeWithCollection.enterpriseBadge.badgeTemplateId,
        request_origin: 'REQUEST_ORIGIN_USER',
      });
      const allProducts = getAllProducts(enterpriseBadgeWithCollection.collection);
      if (allProducts.length > 0) {
        const productType = allProducts[0].isCourse ? PRODUCT_TYPE.COURSE : PRODUCT_TYPE.S12N;
        onProductCardClick(allProducts[0].product, productType, collectionId, programId);
      }
      setIsEnrolling(false);
      setIsConfirmationModalOpen(false);
      if (refetchEnterpriseBadgeCollections) refetchEnterpriseBadgeCollections();
      document.location.hash = '';
    } catch (error) {
      setIsEnrolling(false);
      setIsConfirmationModalOpen(false);
      setEnrollError(true);
    }
  };

  return (
    <div>
      <div
        id={`badge-${enterpriseBadgeWithCollection.enterpriseBadge.badgeTemplateId}`}
        data-testid={`badge-${enterpriseBadgeWithCollection.enterpriseBadge.badgeTemplateId}`}
        className="EnterpriseBadgeInfo"
        css={styles.collectionInfo}
      >
        <Heading className="EnterpriseBadgeInfo-title" defaultLevel={3} variant="h2semibold" css={styles.badgeTitle}>
          {badgeTitle}
        </Heading>
        <Section initialLevel={4}>
          <Typography2 component="p" variant="bodyPrimary" css={styles.badgeDescription}>
            {description}
          </Typography2>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={4} md={3}>
                <div className="EnterpriseBadgeInfo-image" css={styles.badgeImg}>
                  <img src={imageUrl} alt="" aria-hidden="true" width="185" height="185" />
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Typography2 variant="subtitleMedium" component="div">
                  {_t('Things you will learn')}
                </Typography2>
                <ul css={styles.discListStyle}>
                  {learningObjectives.map((learningObjective) => {
                    return (
                      <li className="EnterpriseBadgeInfo-learning-objective">
                        <Typography2 component="p" variant="bodyPrimary" css={styles.learningObjective}>
                          {learningObjective}
                        </Typography2>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {enrollError && (
              <InlineNotification severity="error">
                <div>
                  <Typography2 variant="subtitleMedium" component="div">
                    <FormattedMessage message={_t('Enrollment in ‘{badgeName}’ has failed')} badgeName={badgeTitle} />
                  </Typography2>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t('There has a been a problem enrolling you, please try again later.')}
                  </Typography2>
                </div>
              </InlineNotification>
            )}
            {isProgramMember && (
              <div>
                {(userState === EnterpriseBadge_userState.NEVER_ENROLLED ||
                  userState === EnterpriseBadge_userState.UNENROLLED) && (
                  <div className="badge-action-container-enroll" css={styles.actionButtonContainer}>
                    <Typography2 component="p" variant="bodyPrimary">
                      {_t('Enroll in this learning path to earn the digital badge.')}
                    </Typography2>
                    <div css={styles.actionButton}>
                      <TrackedButton
                        {...Button.defaultProps}
                        variant="primary"
                        onClick={() => setIsConfirmationModalOpen(true)}
                        trackingName="enterprise_badge_enroll_button"
                        trackingData={{ programSlug }}
                      >
                        {_t('Enroll')}
                      </TrackedButton>
                    </div>
                  </div>
                )}
                {userState === EnterpriseBadge_userState.ENROLLED && (
                  <div
                    className="badge-action-container-view-progress-in-my-courses"
                    css={styles.actionButtonContainer}
                  >
                    <Typography2 component="div" variant="subtitleMedium">
                      {_t('You are currently enrolled in this learning path')}
                    </Typography2>
                    <div css={styles.actionButton}>
                      <TrackedButtonLink
                        {...Button.defaultProps}
                        css={styles.actionButton}
                        component={ReactRouterLink}
                        variant="secondary"
                        trackingData={{ programSlug }}
                        trackingName="enterprise_badge_view_progress_button"
                        onClick={handleNavToMyCourses}
                      >
                        {_t('View progress in My Courses')}
                      </TrackedButtonLink>
                    </div>
                  </div>
                )}
                {userState === EnterpriseBadge_userState.ISSUED && (
                  <div className="badge-action-container-view-in-my-courses" css={styles.actionButtonContainer}>
                    <Typography2 component="div" variant="subtitleMedium">
                      {_t('You have completed this learning path')}
                    </Typography2>
                    <div css={styles.actionButton}>
                      <TrackedButtonLink
                        {...Button.defaultProps}
                        css={styles.actionButton}
                        component={ReactRouterLink}
                        variant="secondary"
                        trackingData={{ programSlug }}
                        trackingName="enterprise_badge_view_in_my_courses_button"
                        onClick={handleNavToMyCourses}
                      >
                        {_t('View in My Courses')}
                      </TrackedButtonLink>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Section>
      </div>
      <Typography variant="h2semibold" component="h4">
        <FormattedMessage
          message={_t(
            'Complete {numCourses, plural, =1 {the course} other {all # courses}} to gain this digital badge'
          )}
          numCourses={productIds.length}
        />
      </Typography>
      <Typography2 component="p" variant="bodyPrimary" css={styles.productCollectionDescription}>
        {_t('Start learning with the first course in this learning path')}
      </Typography2>
      <EnterpriseProductCardCollection
        collection={enterpriseBadgeWithCollection.collection}
        onProductCardClick={handleProductCardClick(collectionId)}
      />
      <EnterpriseBadgeEnrollConfirmationModal
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <Button
          css={styles.confirmationButton}
          variant="primary"
          onClick={handleEnroll}
          iconPosition="before"
          icon={isEnrolling ? <SpinnerIcon size="medium" /> : undefined}
        >
          {_t('Enroll and go to course')}
        </Button>
        <Button variant="secondary" onClick={() => setIsConfirmationModalOpen(false)}>
          {_t('Cancel')}
        </Button>
      </EnterpriseBadgeEnrollConfirmationModal>
    </div>
  );
}

export function EnterpriseBadgeCollectionsViewPlaceholder() {
  const styles = useStyles();
  return (
    <div>
      <div css={styles.collectionInfoPlaceholder}>
        <Typography
          variant="h2semibold"
          component="div"
          {...cuicss(placeholder.styles.shimmer)}
          color="invertBody"
          aria-hidden
        >
          &nbsp;
        </Typography>
        <Section initialLevel={4}>
          <Typography
            variant="body1"
            component="div"
            {...cuicss(placeholder.styles.shimmer)}
            color="invertBody"
            aria-hidden
          >
            &nbsp;
          </Typography>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <div css={styles.badgeImg}>
                  <div css={styles.badgePlaceholderImg} {...cuicss(placeholder.styles.shimmer)} />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Typography2 component="div" variant="subtitleMedium">
                  {_t('Things you will learn')}
                </Typography2>
                <ul>
                  {[0, 1, 2, 3].map((id) => {
                    return (
                      <li key={id}>
                        <Typography
                          variant="body1"
                          component="div"
                          {...cuicss(placeholder.styles.shimmer)}
                          color="invertBody"
                          aria-hidden
                        >
                          &nbsp;
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
            <Grid item>
              <div css={styles.actionButtonContainer}>
                <div>
                  <Typography2 component="p" variant="bodyPrimary">
                    {_t('Enroll in this learning path to earn the digital badge.')}
                  </Typography2>
                  <div css={styles.actionButton}>
                    <Button aria-hidden variant="ghost" disabled={true}>
                      {_t('Enroll')}
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Section>
      </div>
      <Typography
        variant="h2semibold"
        component="div"
        {...cuicss(placeholder.styles.shimmer)}
        color="invertBody"
        aria-hidden
      >
        &nbsp;
      </Typography>
      <Typography
        variant="body1"
        component="div"
        {...cuicss(placeholder.styles.shimmer)}
        color="invertBody"
        aria-hidden
      >
        &nbsp;
      </Typography>
      <EnterpriseProductCardCollectionsPlaceholder />
    </div>
  );
}
