import type {
  FeedbackCategoryState,
  FeedbackComments,
  FeedbackContext,
  FeedbackDebugInfo,
  FeedbackRating,
} from 'bundles/authoring/course-content-feedback/types/courseContentFeedback';

/**
 * Feedback model
 */

type FeedbackProps = {
  rating: FeedbackRating;
  comments: FeedbackComments;
  timestamp?: number;
  context?: FeedbackContext;
  debugInfo?: FeedbackDebugInfo;
  categoryStates?: FeedbackCategoryState;
  id?: string;
};

class Feedback {
  categoryStates: FeedbackProps['categoryStates'];

  comments: FeedbackProps['comments'];

  context: FeedbackProps['context'];

  debugInfo: FeedbackProps['debugInfo'];

  id: FeedbackProps['id'];

  rating: FeedbackProps['rating'];

  timestamp: FeedbackProps['timestamp'];

  constructor(props: FeedbackProps) {
    const { rating, comments, timestamp, context, debugInfo, categoryStates, id } = props;
    this.rating = rating;
    this.comments = comments;
    this.timestamp = timestamp;
    this.context = context;
    this.debugInfo = debugInfo;
    this.categoryStates = categoryStates;
    this.id = id;
  }

  get value() {
    return this.rating.value;
  }

  get isActive() {
    return this.rating.active;
  }

  get subItemId() {
    return this.context && this.context.definition.subItemId;
  }

  get subItemTitle() {
    return this.context && this.context.definition.subItemTitle;
  }

  toObject() {
    return {
      rating: this.rating,
      comments: this.comments,
    };
  }
}

export default Feedback;
