import gql from 'graphql-tag';

import type { EnterpriseNoticeAcceptanceLogsV1GetByUserIdAndOrganizationIdQuery } from 'bundles/naptimejs/resources/__generated__/EnterpriseNoticeAcceptanceLogsV1';
import type { ProgramMembershipsV2GetQuery } from 'bundles/naptimejs/resources/__generated__/ProgramMembershipsV2';

export type ProgramHomeLoggedInQueryVariables = {
  programMembershipId: string;
  userId: string;
  thirdPartyOrganizationId: string;
  requirePreJoinCheck: boolean;
};

export type ProgramHomeLoggedInQuery = ProgramMembershipsV2GetQuery &
  EnterpriseNoticeAcceptanceLogsV1GetByUserIdAndOrganizationIdQuery;

const ProgramHomeLoggedInQueryText = gql`
  query ProgramHomeLoggedInQuery(
    $programMembershipId: String!
    $userId: String!
    $thirdPartyOrganizationId: String!
    $requirePreJoinCheck: Boolean!
  ) {
    ProgramMembershipsV2 @naptime {
      get(id: $programMembershipId) {
        elements {
          id
          membershipState
          externalUserData {
            ... on NaptimeProgramMembershipsV2_externalUserData_genericExternalUserData {
              typeName
              definition {
                fullName
                email
              }
            }
          }
        }
      }
    }

    EnterpriseNoticeAcceptanceLogsV1 @naptime @include(if: $requirePreJoinCheck) {
      getByUserIdAndOrganizationId(userId: $userId, thirdPartyOrganizationId: $thirdPartyOrganizationId) {
        elements {
          id
        }
      }
    }
  }
`;

export default ProgramHomeLoggedInQueryText;
