import * as React from 'react';

import { useSlateStatic } from 'slate-react';

import { PdfPageSelectModal } from 'bundles/cml/editor/components/elements/asset/PdfPageSelectModal';
import { updateAssetEmbedState } from 'bundles/cml/editor/components/elements/asset/assetUtils';
import DeleteAssetButton from 'bundles/cml/editor/components/elements/asset/buttons/DeleteAssetButton';
import DownloadAssetButton from 'bundles/cml/editor/components/elements/asset/buttons/DownloadAssetButton';
import EmbedAssetButtons from 'bundles/cml/editor/components/elements/asset/buttons/EmbedAssetButtons';
import OpenInLibraryButton from 'bundles/cml/editor/components/elements/asset/buttons/OpenInLibraryButton';
import SwapAssetButton from 'bundles/cml/editor/components/elements/asset/buttons/SwapAssetButton';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { useAssetContext } from 'bundles/cml/editor/context/assetContext';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

type Props = {
  anchorEl: HTMLElement | null;
  element: AssetElement;
  asset?: Asset;
  pageless: boolean;
  onClose: () => void;
};

const AssetMenu: React.FC<Props> = ({ anchorEl, pageless, asset, element, onClose }) => {
  const { enableEmbeddedPdf } = useAssetContext();
  const staticEditor = useSlateStatic();
  const [embedModalOpen, setEmbedModalOpen] = React.useState(false);

  if (!asset) {
    return (
      <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
        <Toolbar role="menubar" className="rc-AssetMenu" autoFocus={true}>
          <DeleteAssetButton />
          <SwapAssetButton element={element} onClick={onClose} />
        </Toolbar>
      </RestoreFocus>
    );
  }

  const onOpenModal = () => setEmbedModalOpen(true);

  const onCloseModal = () => setEmbedModalOpen(false);

  const onEmbedChange = () => {
    updateAssetEmbedState(staticEditor, element, !element.embedEnabled);
  };

  const onPageSelect = (pageStart?: number, pageEnd?: number) => {
    updateAssetEmbedState(staticEditor, element, true, pageStart, pageEnd);
    setEmbedModalOpen(false);
  };

  if (embedModalOpen) {
    return (
      <PdfPageSelectModal
        embedStartPage={element.embedStartPage}
        embedEndPage={element.embedEndPage}
        url={asset.url.url}
        assetId={asset.id}
        onClose={onCloseModal}
        onPageSelect={onPageSelect}
      />
    );
  }

  return (
    <FloatingMenu anchorEl={anchorEl} pageless={pageless} onClose={onClose}>
      <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
        <Toolbar role="menubar" className="rc-AssetMenu">
          {enableEmbeddedPdf && element.extension === 'pdf' ? (
            <EmbedAssetButtons
              element={element}
              onEmbedButtonClick={onEmbedChange}
              onPageSelectButtonClick={onOpenModal}
            />
          ) : (
            <React.Fragment />
          )}
          <OpenInLibraryButton asset={asset} />
          <DeleteAssetButton />
          <SwapAssetButton element={element} onClick={onClose} />
          <DownloadAssetButton asset={asset} />
        </Toolbar>
      </RestoreFocus>
    </FloatingMenu>
  );
};

export default AssetMenu;
