import { useCallback, useEffect, useMemo, useState } from 'react';

function useInfiniteScrollPageObserver(
  cardRef: React.MutableRefObject<HTMLDivElement | null>,
  page: number | undefined,
  setScrollPage: ((page: number) => void) | undefined
) {
  const [windowIsSafe, setWindowIsSafe] = useState(false);

  const handleVisibilityOnInfiniteScroll = useCallback(
    ([trigger]) => {
      const shouldCallback = trigger?.isIntersecting && page;
      if (shouldCallback) {
        setScrollPage?.(page);
      }
    },
    [setScrollPage, page]
  );

  const cardPositionOnInfiniteScrollObserver = useMemo(() => {
    return windowIsSafe
      ? new IntersectionObserver(handleVisibilityOnInfiniteScroll, {
          threshold: 0.5,
        })
      : false;
  }, [handleVisibilityOnInfiniteScroll, windowIsSafe]);

  useEffect(() => {
    if (page && setScrollPage && cardPositionOnInfiniteScrollObserver && cardRef.current) {
      cardPositionOnInfiniteScrollObserver.observe(cardRef.current);
    }
    return () => {
      if (cardPositionOnInfiniteScrollObserver) cardPositionOnInfiniteScrollObserver.disconnect();
    };
  }, [cardRef, cardPositionOnInfiniteScrollObserver, page, setScrollPage]);

  useEffect(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      setWindowIsSafe(true);
    }
  }, []);
}

export default useInfiniteScrollPageObserver;
