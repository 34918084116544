import * as React from 'react';

import useRouter from 'js/lib/useRouter';
import user from 'js/lib/user';

import { Button } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { EnterpriseBannerRenderer } from 'bundles/program-home/components/enterprise-home/EnterpriseBannerRenderer';
import ProgramSwitcher from 'bundles/program-home/components/multiprogram/ProgramSwitcher';
import useHasMultipleProgramProps from 'bundles/program-home/components/multiprogram/useHasMultipleProgramProps';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/EnterpriseBanner';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

export type ThirdPartyOrganization = {
  id: string;
  primaryColor?: string | null;
  landingPageBanner?: string | null;
  name: string;
  slug: string;
};

export type Program = {
  metadata: {
    slug: string;
    name: string;
    tagline?: string | null;
    landingPageBanner?: string | null;
    primaryColor?: string | null;
  };
};

type PropsFromCaller = {
  thirdPartyOrganization: ThirdPartyOrganization;
  program?: Program | null;
  isMultiProgram: boolean;
  isProgramDetailsView: boolean;
  shouldShowJoinButton: boolean;
  isUpswell: boolean;
  isC4cv: boolean;
  disableCTA?: boolean;
};

export type Props = PropsFromCaller;

type ButtonViewProps = {
  userId?: number;
  thirdPartyOrganization: ThirdPartyOrganization;
  program?: Program | null;
};

type JoinButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

export function ButtonView({ userId, thirdPartyOrganization, program }: ButtonViewProps) {
  if (!userId) {
    return null;
  }

  return (
    <ProgramSwitcher
      userId={userId}
      thirdPartyOrganizationId={thirdPartyOrganization.id}
      thirdPartyOrganizationSlug={thirdPartyOrganization.slug}
      programSlug={program?.metadata.slug}
    />
  );
}

export function JoinButton({ onClick, children }: JoinButtonProps) {
  return (
    <TrackedButton
      {...Button.defaultProps}
      className="join-button"
      variant="secondary"
      onClick={onClick}
      trackingName="banner_join_for_free"
      data-e2e="banner-join-button"
      data-testid="banner-join-button"
    >
      {children}
    </TrackedButton>
  );
}

export function EnterpriseBanner({
  thirdPartyOrganization,
  program,
  isMultiProgram,
  isProgramDetailsView,
  shouldShowJoinButton,
  isUpswell,
  isC4cv,
  disableCTA,
}: Props) {
  const router = useRouter();
  const userId = user.get().id;
  const { hasMultipleProgramsInOrg } = useHasMultipleProgramProps({
    userId,
    thirdPartyOrganizationId: thirdPartyOrganization.id,
  });

  const onJoinClicked = () => {
    if (userId) {
      // Given the current architecture, it's the easiest to reload the page to bring up the appropriate modal
      // instead of dealing with ProgramHomeModalManager directly. We might come back and revisit this.
      window.location.reload();
    } else {
      router.push({
        pathname: router.location.pathname,
        params: router.params,
        query: { ...router.location.query, authMode: 'login' },
      });
    }
  };

  const showButtonView = !disableCTA && (isMultiProgram || hasMultipleProgramsInOrg);

  return (
    <EnterpriseBannerRenderer
      thirdPartyOrganization={thirdPartyOrganization}
      program={program}
      shouldShowJoinButton={shouldShowJoinButton}
      joinMessage={_t('Join')}
      showButtonView={showButtonView}
      userId={userId}
      isProgramDetailsView={isProgramDetailsView}
      onJoinClicked={onJoinClicked}
      isUpswell={isUpswell}
      isC4cv={isC4cv}
    />
  );
}

export default EnterpriseBanner;
