import * as React from 'react';

const CoursesSvg = () => {
  return (
    <svg
      width="104"
      height="103"
      viewBox="0 0 104 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <rect x="0.5" y="0.5" width="61" height="70" rx="1.5" fill="white" stroke="#929599" />
      <path d="M1 2C1 1.44771 1.44772 1 2 1H60C60.5523 1 61 1.44772 61 2V28H1V2Z" fill="#EBF3FF" />
      <rect x="9.91992" y="44.8281" width="31.6551" height="3.55" fill="#AAC0E1" />
      <rect x="9.91992" y="51.8877" width="39.68" height="3.55001" fill="#AAC0E1" />
      <rect x="9.91992" y="58.9482" width="27.1329" height="3.55" fill="#AAC0E1" />
      <rect x="9.91992" y="14.2002" width="22.32" height="21.3" fill="#AAC0E1" />
      <rect x="16.5" y="16.5" width="61" height="70" rx="1.5" fill="white" stroke="#929599" />
      <path d="M17 18C17 17.4477 17.4477 17 18 17H76C76.5523 17 77 17.4477 77 18V44H17V18Z" fill="#EBF3FF" />
      <rect x="25.9199" y="60.8281" width="31.6551" height="3.55" fill="#AAC0E1" />
      <rect x="25.9199" y="67.8877" width="39.68" height="3.55001" fill="#AAC0E1" />
      <rect x="25.9199" y="74.9482" width="27.1329" height="3.55" fill="#AAC0E1" />
      <rect x="25.9199" y="30.2002" width="22.32" height="21.3" fill="#AAC0E1" />
      <rect x="32.5" y="32.5" width="61" height="70" rx="1.5" fill="white" stroke="#929599" />
      <path d="M33 34C33 33.4477 33.4477 33 34 33H92C92.5523 33 93 33.4477 93 34V60H33V34Z" fill="#EBF3FF" />
      <rect x="41.9199" y="76.8281" width="31.6551" height="3.55" fill="#AAC0E1" />
      <rect x="41.9199" y="83.8877" width="39.68" height="3.55001" fill="#AAC0E1" />
      <rect x="41.9199" y="90.9482" width="27.1329" height="3.55" fill="#AAC0E1" />
      <rect x="41.9199" y="46.2002" width="22.32" height="21.3" fill="#AAC0E1" />
      <circle cx="94" cy="68" r="10" fill="#00255D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2686 69.7474L90.4636 67.1362L89.6523 68.0102L93.2686 71.5616L98.806 65.4642L97.9542 64.4746L93.2686 69.7474Z"
        fill="white"
      />
    </svg>
  );
};

export default CoursesSvg;
