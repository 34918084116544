/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose, withProps } from 'recompose';

import { Button, Typography, Typography2, useTheme } from '@coursera/cds-core';

import Naptime from 'bundles/naptimejs';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enterprise-admin-coursera-for-campus-basic';

type PropsFromCaller = {
  onCloseModal: () => void;
  courseId: string;
};

type PropsFromNaptime = {
  course: CoursesV1;
};

type PropsFromWithProps = {
  courseSlug: string;
};

export type Props = PropsFromCaller & PropsFromWithProps;

export const LearnerReenrollPopupModal = ({ onCloseModal, courseSlug }: Props) => {
  const theme = useTheme();

  return (
    <Modal modalName="LearnerPopupModal" handleClose={onCloseModal}>
      <Typography variant="h1semibold" css={{ marginBottom: theme.spacing(32) }}>
        {_t("You're successfully re-enrolled!")}
      </Typography>
      <Typography2 component="p" css={{ marginBottom: theme.spacing(32) }}>
        {_t('Keep up the momentum and you’ll be done in no time! Continue exactly where you left off.')}
      </Typography2>
      <Button
        variant="primary"
        css={{ marginRight: theme.spacing(24) }}
        component="a"
        href={`/learn/${courseSlug}/home/welcome`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {_t('View Course')}
      </Button>
      <Button variant="secondary" onClick={onCloseModal}>
        {_t('Done')}
      </Button>
    </Modal>
  );
};

const LearnerReenrollPopupModalWithData = compose<Props, PropsFromCaller>(
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ courseId }) => ({
    course: CoursesV1.get(courseId, {
      fields: ['slug'],
      params: { showHidden: true },
    }),
  })),
  withProps<PropsFromWithProps, PropsFromNaptime>(({ course }) => {
    return {
      courseSlug: course.slug,
    };
  })
)(LearnerReenrollPopupModal);

export default LearnerReenrollPopupModalWithData;
