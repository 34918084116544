/* eslint-disable camelcase */

/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';
import { compose, withProps } from 'recompose';

import { Button, InlineNotification, Typography2, useTheme } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon, SpinnerIcon } from '@coursera/cds-icons';

import ProductList from 'bundles/course-cards/components/course-card/enterprise/ProductList';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { PropsFromWithSkillSetNameAndSlugById } from 'bundles/program-home/components/available/SaveSkillSetBanner';
import { withSkillSetNameAndSlugById } from 'bundles/program-home/components/available/SaveSkillSetBanner';
import BadgeCard from 'bundles/program-home/components/badgeEnrolledCard/BadgeCard';
import EnterpriseBadgeEnrollConfirmationModal from 'bundles/program-home/components/modals/EnterpriseBadgeEnrollConfirmationModal';
import { EnterpriseBadge_userState } from 'bundles/program-home/components/single-program/__generated__/EnterpriseBadgeCollectionsQuery';
import useEnterpriseBadgeEnrollMutation from 'bundles/program-home/queryHooks/useEnterpriseBadgeEnrollMutation';
import type { CourseStub, ProductStub } from 'bundles/program-home/types/Products';
import {
  getProductsFromUnenrolledBadges,
  removeCredentialFromBadgeTitle,
} from 'bundles/program-home/utils/EnterpriseBadgeUtils';

import _t from 'i18n!nls/program-home';

export const INITIAL_SHOWN_PRODUCTS = 3;

type PropsFromCaller = {
  products: Array<ProductStub>;
  enterpriseBadgeCollections?: Array<EnterpriseBadge>;
  programId: string;
  userId: number;
  showSaveSkillSetBanner?: boolean;
  productCount?: number;
  targetSkillProfileId?: string;
  programSlug: string;
  viewMoreLessContext: string;
  thirdPartyOrganizationId: string;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
} & PropsFromSaveSkillSetToggle;

export type Props = PropsFromCaller;

export const ProductListWithExpandToggle: React.FC<Props> = ({
  products,
  enterpriseBadgeCollections,
  programId,
  userId,
  showSaveSkillSetBanner,
  productCount,
  targetSkillProfileId,
  programSlug,
  viewMoreLessContext,
  onSkillSetSaveToggle,
  thirdPartyOrganizationId,
  refetchEnterpriseBadgeCollections,
}) => {
  const theme = useTheme();
  const styles = {
    container: css`
      margin-bottom: ${theme.spacing(32)};
    `,
    btnContainer: css`
      display: flex;
      flex-direction: row-reverse;
      margin-top: ${theme.spacing(16)};
    `,
    firstViewMoreLessBtn: css`
      margin-bottom: ${theme.spacing(8)};
    `,
    confirmationButton: css`
      margin-right: ${theme.spacing(16)};
    `,
    successMessage: css`
      margin-bottom: ${theme.spacing(24)};
    `,
  };

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isEnrolling, setIsEnrolling] = React.useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<boolean>(false);
  const [selectedBadge, setSelectedBadge] = React.useState<EnterpriseBadge | undefined>();
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);

  const enroll = useEnterpriseBadgeEnrollMutation();

  const initalProducts = products.slice(0, INITIAL_SHOWN_PRODUCTS);
  const expandedProducts = products.slice(INITIAL_SHOWN_PRODUCTS);
  const onClickExpand = () => setIsExpanded(!isExpanded);

  const viewMoreLessLabel = isExpanded ? _t('View less') : _t('View more');
  const viewMoreLessAriaLabel = `${viewMoreLessLabel}: ${viewMoreLessContext}`;

  const { recommendedProductIds, badgeProductMapping } = getProductsFromUnenrolledBadges(
    enterpriseBadgeCollections ?? []
  );

  const handleEnrollInCredential = (badge: EnterpriseBadge) => {
    setSelectedBadge(badge);
    setIsConfirmationModalOpen(true);
  };

  const handleEnrollInCredentialCancel = () => {
    setSelectedBadge(undefined);
    setIsConfirmationModalOpen(false);
  };

  const handleEnroll = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (isEnrolling) return;

    if (!selectedBadge) return;

    try {
      setIsEnrolling(true);
      await enroll({
        user_id: userId,
        badge_template_id: selectedBadge?.badgeTemplateId,
        request_origin: 'REQUEST_ORIGIN_USER',
      });
      if (refetchEnterpriseBadgeCollections) {
        await refetchEnterpriseBadgeCollections();
      }
      setShowSuccessMessage(true);
    } finally {
      setIsEnrolling(false);
      setIsConfirmationModalOpen(false);
    }
  };

  const handleSuccessDismiss = () => {
    setShowSuccessMessage(false);
    setSelectedBadge(undefined);
  };

  const enrolledBadges: Array<EnterpriseBadge> =
    enterpriseBadgeCollections?.filter(
      (badge) =>
        badge.userState === EnterpriseBadge_userState.ENROLLED || badge.userState === EnterpriseBadge_userState.ISSUED
    ) || [];
  const recommendBadgeCourseId: CourseStub | undefined =
    (products
      .filter((product) => product.isCourse)
      .find((product) => product.isCourse && recommendedProductIds.has(product.courseId)) as CourseStub) || undefined;

  return (
    <div css={styles.container}>
      {enrolledBadges?.map((badge) => (
        <React.Fragment key={badge.badgeTemplateId}>
          {showSuccessMessage && badge.badgeTemplateId === selectedBadge?.badgeTemplateId && (
            <InlineNotification severity="success" onDismiss={handleSuccessDismiss} css={styles.successMessage}>
              <Typography2 component="p">
                {_t('You have successfully enrolled in the #{badgeTitle}', {
                  badgeTitle: removeCredentialFromBadgeTitle(selectedBadge?.title),
                })}
              </Typography2>
            </InlineNotification>
          )}
          <BadgeCard
            programId={programId}
            userId={userId}
            enterpriseBadge={badge}
            refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
          />
        </React.Fragment>
      ))}
      <ProductList
        products={initalProducts}
        programId={programId}
        programSlug={programSlug}
        userId={userId}
        onSkillSetSaveToggle={onSkillSetSaveToggle}
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        showSaveSkillSetBanner={showSaveSkillSetBanner}
        productCount={productCount}
        targetSkillProfileId={targetSkillProfileId}
        recommendBadgeCourseId={recommendBadgeCourseId}
        recommendedProductIds={recommendedProductIds}
        badgeProductMapping={badgeProductMapping}
        selectedBadge={selectedBadge}
        handleEnrollInCredential={handleEnrollInCredential}
      />
      <div css={styles.btnContainer}>
        {products.length > INITIAL_SHOWN_PRODUCTS && (
          <Button
            variant="ghost"
            icon={isExpanded ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />}
            onClick={onClickExpand}
            iconPosition="after"
            size="small"
            css={styles.firstViewMoreLessBtn}
            aria-label={viewMoreLessAriaLabel}
          >
            {viewMoreLessLabel}
          </Button>
        )}
      </div>
      {isExpanded && (
        <React.Fragment>
          <ProductList
            products={expandedProducts}
            programId={programId}
            programSlug={programSlug}
            userId={userId}
            onSkillSetSaveToggle={onSkillSetSaveToggle}
            thirdPartyOrganizationId={thirdPartyOrganizationId}
            showSaveSkillSetBanner={showSaveSkillSetBanner}
            productCount={productCount}
            targetSkillProfileId={targetSkillProfileId}
            recommendBadgeCourseId={recommendBadgeCourseId}
            recommendedProductIds={recommendedProductIds}
            badgeProductMapping={badgeProductMapping}
            selectedBadge={selectedBadge}
            handleEnrollInCredential={handleEnrollInCredential}
          />
          <div css={styles.btnContainer}>
            {products.length > INITIAL_SHOWN_PRODUCTS && (
              <Button
                variant="ghost"
                icon={isExpanded ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />}
                onClick={onClickExpand}
                iconPosition="after"
                size="small"
                aria-label={viewMoreLessAriaLabel}
              >
                {viewMoreLessLabel}
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
      <EnterpriseBadgeEnrollConfirmationModal open={isConfirmationModalOpen} onClose={handleEnrollInCredentialCancel}>
        <Button
          css={styles.confirmationButton}
          variant="primary"
          onClick={handleEnroll}
          iconPosition="before"
          icon={isEnrolling ? <SpinnerIcon size="medium" /> : undefined}
        >
          {_t('Enroll')}
        </Button>
        <Button variant="secondary" onClick={handleEnrollInCredentialCancel}>
          {_t('Cancel')}
        </Button>
      </EnterpriseBadgeEnrollConfirmationModal>
    </div>
  );
};

const ProductListWithExpandToggleHookProxy = (props: Props) => <ProductListWithExpandToggle {...props} />;

export const ProductListWithExpandToggleWithSkillSetViewMoreLessContext = compose<Props, PropsFromCaller>(
  withSkillSetNameAndSlugById,
  withProps<
    Pick<Props, 'viewMoreLessContext'>,
    PropsFromWithSkillSetNameAndSlugById & Pick<PropsFromCaller, 'viewMoreLessContext'>
  >(({ tspTitle, viewMoreLessContext }) => ({
    viewMoreLessContext: tspTitle ? `${viewMoreLessContext}: ${tspTitle}` : viewMoreLessContext,
  }))
)(ProductListWithExpandToggleHookProxy);

export default ProductListWithExpandToggle;
