/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import type { Theme } from '@coursera/cds-core';
import { Grid, Radio, Typography2 } from '@coursera/cds-core';

import type { AuthoringWidgetSummariesV1 } from 'bundles/widget-admin/types/WidgetTypes';

import { DATE_COLUMN_WIDTH, NAME_COLUMN_WIDTH } from './WidgetSelectionListHeader';

const styles = {
  root: () =>
    css({
      display: 'flex',
      '.cds-checkboxAndRadio-labelText': {
        display: 'inline-block !important', // We override value to enable truncation.
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    }),
  radio: () =>
    css({
      width: NAME_COLUMN_WIDTH,
    }),
  date: (theme: Theme) =>
    css({
      width: DATE_COLUMN_WIDTH,
      padding: theme.spacing(8, 0),
      paddingLeft: theme.spacing(32),
    }),
};

type Props = {
  widget: AuthoringWidgetSummariesV1;
  onWidgetSelected: (id: string) => void;
};

const WidgetSelectionListItem: React.FC<Props> = ({ widget, onWidgetSelected }) => {
  return (
    <Grid css={styles.root} onClick={() => onWidgetSelected(widget.id)}>
      <Radio label={widget.name} value={widget.id} css={styles.radio} />
      <Typography2 component="p" variant="bodyPrimary" css={styles.date}>
        {formatDateTimeDisplay(widget.updatedAt, MED_DATE_ONLY_DISPLAY)}
      </Typography2>
    </Grid>
  );
};

export default WidgetSelectionListItem;
