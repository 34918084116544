/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import { Typography2, VisuallyHidden } from '@coursera/cds-core';
import { StarFilledColoredIcon } from '@coursera/cds-icons';

import { getNumberOfReviews } from 'bundles/product-card/utils/productCardUtils';

import _t from 'i18n!nls/product-card';

const styles = {
  reviews: css`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,
  reviewItem: css`
    margin-right: 4px;
  `,
};

const ProductCardReviews: React.FC<{ avgProductRating?: number; numProductRatings?: number }> = ({
  avgProductRating,
  numProductRatings,
}) => {
  if (!avgProductRating || (numProductRatings && numProductRatings <= 5)) {
    return null;
  }

  const numOfReviews = getNumberOfReviews(numProductRatings);

  return (
    <div css={styles.reviews} className="product-reviews">
      <StarFilledColoredIcon size="small" css={styles.reviewItem} />
      {avgProductRating && (
        <>
          <Typography2 aria-hidden="true" variant="subtitleMedium" component="p" css={styles.reviewItem}>
            {avgProductRating.toFixed(1)}
          </Typography2>
          <VisuallyHidden component="p">
            {_t('#{ratings} stars', {
              ratings: avgProductRating.toFixed(1),
            })}
          </VisuallyHidden>
        </>
      )}
      {numProductRatings && (
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {_t('(#{numOfReviews} reviews)', {
            numOfReviews,
          })}
        </Typography2>
      )}
    </div>
  );
};

export default memo(ProductCardReviews);
