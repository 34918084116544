import * as React from 'react';

import { compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

import { Typography2 } from '@coursera/cds-core';

import Naptime from 'bundles/naptimejs';
import LearnerCourseSchedulesV1 from 'bundles/naptimejs/resources/learnerCourseSchedules.v1';

import _t from 'i18n!nls/program-home';

type PropsFromCaller = {
  courseId: string;
  startDateString?: string;
  upcomingSessionStartDateString?: string;
  plannedLaunchDate?: string;
  sessionsV2Enabled?: boolean;
};

type PropsFromNaptime = {
  sessionsV2Enabled: boolean;
};

type Props = PropsFromCaller & PropsFromNaptime;

const CourseSessionInfoString = ({
  startDateString,
  upcomingSessionStartDateString,
  plannedLaunchDate,
  sessionsV2Enabled,
}: Props): JSX.Element => {
  const msg1 = startDateString && !sessionsV2Enabled && (
    <FormattedMessage
      message={_t("You're enrolled. Next session starts on {startDateString}")}
      startDateString={startDateString}
    />
  );
  const msg2 = upcomingSessionStartDateString && !sessionsV2Enabled && (
    <FormattedMessage
      message={_t('The current session has ended. Next session starts on {upcomingSessionStartDateString}')}
      upcomingSessionStartDateString={upcomingSessionStartDateString}
    />
  );
  const msg3 = plannedLaunchDate && (
    <FormattedMessage
      message={_t('This is a pre-enroll course. Planned launch date: {plannedLaunchDate}')}
      plannedLaunchDate={plannedLaunchDate}
    />
  );
  return (
    <Typography2 component="span" variant="subtitleMedium" className="rc-CourseSessionInfoString session-enroll-info">
      {msg1}
      {msg2}
      {msg3}
    </Typography2>
  );
};

export default compose<Props, PropsFromCaller>(
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>((props) => ({
    sessionsV2Enabled: LearnerCourseSchedulesV1.getSessionsV2Enabled(user.get().id, props.courseId),
  }))
)(CourseSessionInfoString);
