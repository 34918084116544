export const COURSE_PROGRESS_THRESHOLD = 15; // Percent
export const STORAGE_KEY = 'program-home-hideForceSSOAlternativeEmailModal';
export const ALTERNATIVE_EMAIL_COURSE_PROGRESS_CACHE_DAYS = 1 * 86400000; // 7 days
export const ALTERNATIVE_EMAIL_CACHE_DAYS = 14 * 86400000; // 14 days
export const ALTERNATIVE_EMAIL_NEVER_EXPIRY = 9999 * 86400000; // 9999 days
export const PROGRAM_AUTOJOIN_NOTIF_EXPIRY = 60 * 1000; // 1 minute

export const STEPS = {
  ADD_EMAIL: 'ADD_EMAIL',
  CONFIRM_ADD: 'CONFIRM_ADD',
} as const;
