import * as React from 'react';

import PropTypes from 'prop-types';
import { compose, withStateHandlers } from 'recompose';

import Retracked from 'js/app/retracked';
import { isRightToLeft } from 'js/lib/language';

import { PaginationControls } from '@coursera/coursera-ui';

import { ALL_BRANCHES } from 'bundles/author-branches/constants';
import CourseReview from 'bundles/content-feedback/components/rating-dashboard/CourseReview';
import type { LearnerAudience } from 'bundles/content-feedback/constants/LearnerAudiences';
import getLearnerAudiences from 'bundles/content-feedback/constants/LearnerAudiences';
import type { RatingValue } from 'bundles/content-feedback/constants/RatingValues';
import getRatingValues from 'bundles/content-feedback/constants/RatingValues';
import RatingFeedback from 'bundles/content-feedback/models/RatingFeedback';
import CourseRatingAPIUtils from 'bundles/content-feedback/utils/CourseRatingAPIUtils';
import BounceLoader from 'bundles/teach-course/components/BounceLoader';

import _t from 'i18n!nls/coursera-ui';

import 'css!./__styles__/CourseReviewPaginatedList';

type ReviewsObject = {
  feedbacks: Array<RatingFeedback>;
  next?: number;
  totalCount: number;
};

type PropsWithDefaults = {
  branchId: string;
  ratingValue: RatingValue;
  learnerAudience: LearnerAudience;
  pageSize: number;
};

type InputProps = {
  courseId: string;
} & Partial<PropsWithDefaults>;

type Props = InputProps &
  PropsWithDefaults & {
    onReviewsLoaded: (x0: {}) => void;
    onReviewsLoading: (x0: boolean) => void;
    isLoadingReviews: boolean;
    reviews?: ReviewsObject;
  };

class CourseReviewPaginatedList extends React.Component<Props> {
  static defaultProps = {
    branchId: ALL_BRANCHES,
    get ratingValue() {
      // Display all reviews
      return getRatingValues()[0];
    },
    learnerAudience: getLearnerAudiences().AllLearners.id,
    pageSize: 3,
  };

  static contextTypes = {
    _eventData: PropTypes.object,
  };

  componentDidMount() {
    this.getReviewsForPage(1);
  }

  getReviewsForPage = (pageNumber: number) => {
    const { courseId, branchId, ratingValue, learnerAudience, pageSize, onReviewsLoading, onReviewsLoaded } =
      this.props;
    const { _eventData } = this.context;
    Retracked.trackComponent(_eventData, { courseId, pageNumber }, 'social_proof_reviews_page_number', 'click');
    onReviewsLoading(true);
    CourseRatingAPIUtils.getReviews(
      courseId,
      branchId,
      learnerAudience,
      pageNumber - 1, // pageNumber is 1-based
      ratingValue.value,
      pageSize
    )
      .then((response: { feedbacks: Array<any>; next?: string; totalCount: number }) => onReviewsLoaded(response))
      .catch(() => {
        onReviewsLoading(false);
      })
      .done();
  };

  render() {
    const { reviews, pageSize, isLoadingReviews } = this.props;
    const pageCount = reviews ? Math.ceil(reviews.totalCount / pageSize) : 0;
    const currentPage = reviews && reviews.next ? reviews.next / pageSize : pageCount;
    const isRtl = isRightToLeft(_t.getLocale());

    return (
      <div className="rc-CourseReviewPaginatedList">
        {reviews && reviews.feedbacks.map((feedback) => <CourseReview key={feedback.id} ratingFeedback={feedback} />)}
        {isLoadingReviews && <BounceLoader />}
        {reviews && reviews.totalCount > pageSize && (
          <div className="m-t-2">
            <PaginationControls
              currentPage={currentPage}
              pageCount={pageCount}
              onClickHandler={this.getReviewsForPage}
              dir={isRtl ? 'rtl' : undefined}
            />
          </div>
        )}
      </div>
    );
  }
}

const enhanced = compose<Props, InputProps>(
  withStateHandlers(
    () => ({
      isLoadingReviews: true,
    }),
    {
      onReviewsLoaded: () => (newReviews: { feedbacks: Array<any>; next?: string; totalCount: number }) => {
        const feedbacks = newReviews.feedbacks.map((feedback) => {
          const { rating, timestamp, id } = feedback;
          return new RatingFeedback({
            value: rating.value,
            active: rating.active,
            comment: feedback.comments.generic,
            timestamp,
            id,
          });
        });
        return {
          reviews: {
            feedbacks,
            // @ts-expect-error TSMIGRATION
            next: parseInt(newReviews.next, 10),
            totalCount: newReviews.totalCount,
          },
          isLoadingReviews: false,
        };
      },
      onReviewsLoading: () => (loading: boolean) => {
        return {
          isLoadingReviews: loading,
        };
      },
    }
  )
);

// @ts-expect-error TSMIGRATION
export default enhanced(CourseReviewPaginatedList);
