/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import TestimonyCard from 'bundles/enterprise-admin-grow-with-google/components/learner/TestimonyCard';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

const bottomContainer = (theme: Theme) =>
  css({
    maxWidth: '1224px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 12),
    marginBottom: theme.spacing(112),
    marginTop: theme.spacing(32),
  });

const bottomBody = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
});

const videoContainer = (theme: Theme) =>
  css({
    backgroundImage:
      'url(https://google-it.mystagingwebsite.com/wp-content/uploads/2020/01/Google-IT-Video-scaled.jpg)',
    backgroundRepeat: 'no-repeat',
    width: '45%',
    height: '300px',
    backgroundSize: 'cover',
    padding: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(64),
    },
  });

const testimonyCardsContainer = (theme: Theme) =>
  css({
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  });

const testimonyCardContainer = (theme: Theme) =>
  css({
    marginBottom: theme.spacing(64),
  });

const highlightedText = css({
  '&&': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
});

const LearnerProgramHomeBottom = () => {
  const theme = useTheme();
  return (
    <div css={bottomContainer(theme)}>
      <Typography variant="h1semibold" css={{ marginBottom: theme.spacing(32) }} component="h2">
        {_t('What others are saying')}
      </Typography>
      <div css={bottomBody}>
        <div className="video" css={videoContainer(theme)}>
          <Typography2 component="p" color="invertBody">
            {_t(
              'When I discovered the Google IT Support Professional Certificate I knew: this is my gateway. This is my path. This is how I can get into IT." -Tristen A., Technology Analyst'
            )}
          </Typography2>
          <div>
            <Button
              data-video="https://www.youtube.com/embed/DPdPNsat-vs"
              variant="ghostInvert"
              icon={
                <img
                  src="https://google-it.mystagingwebsite.com/wp-content/themes/coursera-theme/images/play-icon.svg"
                  alt="play"
                  height="50px"
                  width="50px"
                />
              }
              iconPosition="before"
              onClick={() => window.open('https://www.youtube.com/embed/DPdPNsat-vs', '_blank')}
            >
              {_t('Watch the video')}
            </Button>
          </div>
        </div>
        <div css={testimonyCardsContainer(theme)}>
          <div css={testimonyCardContainer(theme)}>
            <TestimonyCard
              testimony={
                <FormattedMessage
                  message={_t(
                    "The Google IT Support Professional Certificate {highlightedText} to apply for IT roles. I don't have the traditional instructional background in the field but my hands-on learning and what I've gained from the Google program propelled me forward."
                  )}
                  highlightedText={
                    <Typography2 component="span" color="highlightBlue" css={highlightedText}>
                      {_t('helped give me the confidence')}
                    </Typography2>
                  }
                />
              }
              personName="Stevenson B."
              personTitle={_t('IT SUPPORT SPECIALIST')}
              personProfile="https://google-it.mystagingwebsite.com/wp-content/uploads/2020/01/StevensonB_landingpage.png"
            />
          </div>
          <div>
            <TestimonyCard
              testimony={
                <FormattedMessage
                  message={_t(
                    'For anyone starting out as a data analyst, this is a great introduction and is very inspiring. The content was {highlightedText} to people just starting out. I liked the variety of the assignments present in the program.'
                  )}
                  highlightedText={
                    <Typography2 component="span" color="highlightBlue" css={highlightedText}>
                      {_t('well paced and was accessible')}
                    </Typography2>
                  }
                />
              }
              personName="Rachel L."
              personTitle={_t('DATA ANALYTICS')}
              personProfile="https://google-it.mystagingwebsite.com/wp-content/uploads/2021/03/rachel.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerProgramHomeBottom;
