/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { WarningOutlineIcon } from '@coursera/cds-icons';

import {
  ASSET_MANAGER_TRANSITION_MS,
  PROGRESS_SIZE_PERCENTAGE,
  UPPY_PROGRESS_PERCENTAGE,
  UPPY_TRANSITION_MS,
} from 'bundles/cml/editor/components/elements/imageUploader/constants';

const styles = {
  progress: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  circle: css`
    @property --upload-progress {
      syntax: '<angle>';
      inherits: false;
      initial-value: 0deg;
    }

    background: conic-gradient(transparent var(--upload-progress), rgb(0 136 255 / 30%) var(--upload-progress));
    border-radius: 50%;
    will-change: contents;
  `,
  uppyProgress: css`
    transition: --upload-progress ${UPPY_TRANSITION_MS}ms linear;
  `,
  assetProgress: css`
    transition: --upload-progress ${ASSET_MANAGER_TRANSITION_MS}ms linear;
  `,
  error: (theme: Theme) => css`
    color: ${theme.palette.yellow[700]};
  `,
};

type Props = {
  progress: number;
  height: number;
  width: number;
  error: boolean;
  className?: string;
};

const ImageUploaderProgress: React.FC<Props> = ({ progress, height, width, error, className }) => {
  const percent = progress / 100;
  const progressDegrees = `${percent * 360}deg`;
  const size = Math.min(height, width) * PROGRESS_SIZE_PERCENTAGE;
  const errorSize = size / 2;
  const transition = percent <= UPPY_PROGRESS_PERCENTAGE ? styles.uppyProgress : styles.assetProgress;

  if (!width || !height) {
    return null;
  }

  return (
    <React.Fragment>
      <div css={styles.progress} style={{ height, width }} className={className}>
        <div
          style={{ height: size, width: size }}
          css={[
            styles.circle,
            transition,
            css`
              --upload-progress: ${progressDegrees};
            `,
          ]}
        />
      </div>
      {error && (
        <div css={styles.progress} style={{ height, width }} className={className}>
          <WarningOutlineIcon style={{ height: errorSize, width: errorSize }} css={styles.error} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageUploaderProgress;
