/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';

import { Button, Dialog, LoadingSection, Typography2, useTheme } from '@coursera/cds-core';
import type { ButtonProps, Theme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import SubscriptionTiersCourseraPlusEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersCourseraPlusEnrollButton';
import AllPlansModal from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/AllPlansModal';
import { getHeroCopy } from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/HeroAndCompareAllPlansCopy';
import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus/contentfulData/LandingPageDataProvider';
import { isSubscriptionTiersDesignV3Variant5 } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

import _t from 'i18n!nls/coursera-plus';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const { HeadingGroup, Content, Actions } = Dialog;

const styles = {
  dialog: css`
    .cds-Dialog-dialog {
      max-width: 689px !important;
    }
  `,
  row: (theme: Theme) => css`
    display: flex;
    margin-bottom: ${theme.spacing(12)};
    gap: ${theme.spacing(8)};
  `,
  button: (theme: Theme) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 100%;
    }
  `,
  primaryValueProp: css`
    font-size: 16px;
  `,
  actions: css`
    padding-top: 24px;
  `,
};

export const CourseraPlusHeroModalComponent = ({
  open,
  handleClose,
  isSpecialization,
  courseName,
  s12nName,
  s12nType,
  courseraPlusProductPrice,
  hasFreeTrialForLite,
  hasFreeTrialForPlus,
  courseraLiteProductPrice,
  isVariant3,
  isVariant5,
}: {
  open: boolean;
  handleClose: () => void;
  isSpecialization: boolean;
  courseName: string;
  s12nName?: string;
  s12nType: S12nProductVariant;
  courseraPlusProductPrice: SubscriptionTiersProductPrice;
  courseraLiteProductPrice: SubscriptionTiersProductPrice;
  hasFreeTrialForLite: boolean;
  hasFreeTrialForPlus: boolean;
  isVariant3?: boolean;
  isVariant5?: boolean;
}) => {
  const theme = useTheme();
  const [step, setStep] = useState<1 | 2>(1);
  const { getEnrollModalTrackingRef, trackEnrollModalSecondaryCta, trackEnrollModalClose } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();

  const onCloseClick = () => {
    handleClose();
    trackEnrollModalClose();
  };

  const showViewAllPlans = () => {
    setStep(2);
    trackEnrollModalSecondaryCta('view_all_plans');
  };

  const copy = getHeroCopy({
    hasFreeTrialForLite,
    hasFreeTrialForPlus,
    productName: isSpecialization ? s12nName : courseName,
    s12nType,
    isSpecialization,
    courseraPlusProductPrice,
    courseraLiteProductPrice,
    isVariant3,
    isVariant5,
  });

  if (step === 2) {
    return <AllPlansModal onClose={() => setStep(1)} />;
  }

  return (
    <Dialog
      aria-describedby="dialog-content"
      open={open}
      variant="standard"
      onClose={onCloseClick}
      sticky="actions"
      width="large"
      css={styles.dialog}
      ref={visibilityTrackingRef}
    >
      <HeadingGroup
        css={css`
          padding-bottom: ${theme.spacing(4)};
        `}
      >
        {copy.headline as string}
      </HeadingGroup>
      <Content>
        <Typography2
          component="p"
          css={css`
            margin-bottom: ${theme.spacing(24)};
          `}
        >
          {copy.subHeadline}
        </Typography2>
        {copy.checks.map(({ primary, secondary }) => (
          <div css={styles.row}>
            <CheckIcon
              color="interactive"
              size="small"
              css={css`
                margin-top: ${theme.spacing(4)};
                min-width: 16px;
                min-height: 16px;
              `}
            />
            <div>
              <Typography2 component="h4" css={styles.primaryValueProp} variant="subtitleMedium">
                {primary}
              </Typography2>
              <Typography2 component="p">{secondary}</Typography2>
            </div>
          </div>
        ))}
      </Content>
      {isVariant3 && (
        <Actions css={styles.actions}>
          <SubscriptionTiersCourseraPlusEnrollButton
            trackingName="coursera_plus_hero_modal_subscribe_plus_cta"
            css={styles.button}
            size="medium"
            variant="primary"
          >
            {copy.primaryCTA}
          </SubscriptionTiersCourseraPlusEnrollButton>
          <TrackedButton
            trackingName="coursera_plus_hero_modal_view_all_plans"
            css={[
              styles.button,
              css`
                ${theme.breakpoints.up('sm')} {
                  margin-left: ${theme.spacing(24)};
                }
                ${theme.breakpoints.down('xs')} {
                  margin-top: ${theme.spacing(8)};
                }
              `,
            ]}
            size="medium"
            variant="secondary"
            onClick={showViewAllPlans}
          >
            {copy.secondaryCTA}
          </TrackedButton>
        </Actions>
      )}
      {isVariant5 && (
        <Actions css={styles.actions}>
          <TrackedButton
            trackingName="coursera_plus_hero_modal_view_all_plans"
            size="medium"
            variant="primary"
            onClick={showViewAllPlans}
            css={styles.button}
          >
            {copy.primaryCTA}
          </TrackedButton>
        </Actions>
      )}
    </Dialog>
  );
};

type Props = {
  isTiersVariant3: boolean;
  handleClose: () => void;
};

const CourseraPlusHeroModalContainer: React.FC<Props> = ({ handleClose, isTiersVariant3 }) => {
  const data = usePageData();
  const hasFreeTrialForLite = data.enrollmentAvailableChoices.canSubscribeToCourseraLiteFreeTrial;
  const hasFreeTrialForPlus = data.enrollmentAvailableChoices.canSubscribeToCourseraPlusFreeTrial;
  const productVariant = data.s12n?.productVariant;
  const isSpecialization = data.isSpecialization || Number(data.course?.s12nIds?.length) > 0;
  if (data.courseraPlusProductPrice && data.courseraLiteProductPrice && (productVariant || !isSpecialization)) {
    return (
      <CourseraPlusHeroModalComponent
        open
        handleClose={handleClose}
        isSpecialization={Boolean(isSpecialization)}
        courseName={data.course.name}
        s12nName={data.s12n?.name}
        s12nType={productVariant as S12nProductVariant}
        courseraPlusProductPrice={data.courseraPlusProductPrice}
        courseraLiteProductPrice={data.courseraLiteProductPrice}
        hasFreeTrialForLite={hasFreeTrialForLite}
        hasFreeTrialForPlus={hasFreeTrialForPlus}
        isVariant3={isTiersVariant3}
        isVariant5={isSubscriptionTiersDesignV3Variant5()}
      />
    );
  }
  return <LoadingSection label={_t('Fetching data...')} />;
};

export default CourseraPlusHeroModalContainer;
