/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Grid, Typography, useTheme } from '@coursera/cds-core';

import ReviewsOverview from 'bundles/enterprise-legacy-xdp/components/reviews/cds/ReviewsOverview';
import TopReviewsList from 'bundles/enterprise-legacy-xdp/components/reviews/cds/TopReviewsList';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { RatingsObject, ReviewHighlight } from 'bundles/program-common/types/xdpTypes';
import type { TopReview } from 'bundles/reviews-common/types/reviewsSharedTypes';

import _t from 'i18n!nls/program-common';

const styles = {
  reviewsModule: (theme: Theme) => css`
    padding-top: ${theme.spacing(64)};
    padding-bottom: ${theme.spacing(64)};
    padding-left: 10px;
    max-width: 1440px;
    margin: auto;
    ${theme.breakpoints.down('sm')} {
      padding-top: ${theme.spacing(48)};
      padding-bottom: ${theme.spacing(48)};
    }
  `,
};

const NUM_REVIEWS_TO_DISPLAY = 4;

type Props = {
  productName: string;
  reviews: Array<TopReview>;
  ratings: RatingsObject;
  reviewHighlights?: Array<ReviewHighlight>;
  isSDP?: boolean;
};

const ReviewsModule = ({ reviews, reviewHighlights, ratings, productName, isSDP }: Props) => {
  const theme = useTheme();
  if (!reviews?.length) {
    return null;
  }

  return (
    <TrackedDiv trackingName="updated_reviews_module" trackClicks={false} withVisibilityTracking requireFullyVisible>
      <Grid container css={styles.reviewsModule} data-e2e="reviews-section" id="reviews">
        <Grid item xs={12}>
          <Typography
            variant="h1semibold"
            component="h2"
            css={css`
              margin-bottom: ${theme.spacing(24)};
            `}
          >
            {_t('Reviews')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5} md={4}>
          <ReviewsOverview reviewHighlights={reviewHighlights} ratings={ratings} isSDP={isSDP} />
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          <TopReviewsList
            reviews={reviews}
            numReviewsToDisplay={NUM_REVIEWS_TO_DISPLAY}
            productName={productName}
            isSDP={isSDP}
          />
        </Grid>
      </Grid>
    </TrackedDiv>
  );
};

export default ReviewsModule;
