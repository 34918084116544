export type CurriculumProduct = {
  isEnrolled?: boolean;
  isSelected?: boolean;
  canManage?: boolean;
  canEnroll?: boolean;
  canUnenroll?: boolean;
  canSelect?: boolean;
  canUnselect?: boolean;
  hasNoOpenSessions?: boolean;
  programId?: string;
};

// Adapter to let us use the ProgramActionButton machinery for multi-program case.
export class MultiprogramCurriculumProduct implements CurriculumProduct {
  _curriculumProducts: CurriculumProduct[];

  constructor(curriculumProducts: CurriculumProduct[]) {
    this._curriculumProducts = curriculumProducts;
  }

  get isEnrolled() {
    return this._curriculumProducts.some((x) => x.isEnrolled);
  }

  get isSelected() {
    return false;
  }

  get canManage() {
    return false;
  }

  get canEnroll() {
    return this._curriculumProducts.some((x) => x.canEnroll);
  }

  get enrollThroughProgramIds() {
    return (
      this._curriculumProducts
        .filter((x) => x.canEnroll && x.programId)
        // See above filter()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((x) => x.programId!)
    );
  }

  get canUnenroll() {
    return this._curriculumProducts.some((x) => x.canUnenroll);
  }

  get unenrollFromProgramId() {
    return this._curriculumProducts.find((x) => x.canUnenroll)?.programId;
  }

  get canSelect() {
    return false;
  }

  get canUnselect() {
    return false;
  }

  get hasNoOpenSessions() {
    return false;
  }

  get programId(): string {
    throw new Error('multi-program does not have single program id');
  }
}
