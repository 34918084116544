import { escape } from 'lodash';

import { getCmlAttribute } from 'bundles/cml/editor/utils/cmlUtils';
import { sanitizeURL } from 'bundles/cml/editor/utils/sanitizeURL';
import { MATH_REGEX } from 'bundles/cml/legacy/models/CMLParser';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type {
  FillableBlankElement,
  InlineElement,
  LinkElement,
  MathInlineElement,
  PersonalizationTagElement,
  Text,
} from 'bundles/cml/shared/types/elementTypes';

const MARKS_TO_CML = {
  bold: 'strong',
  italic: 'em',
  underline: 'u',
  monospace: 'var',
  superscript: 'sup',
  subscript: 'sub',
} as const;

const MARKS = Object.keys(MARKS_TO_CML) as (keyof typeof MARKS_TO_CML)[];

const isText = (node: InlineElement | Text): node is Text => {
  return (node as Text).text !== undefined;
};

const getHasMath = (nodes: { text: string; hasMath: boolean }[]) => {
  return nodes.some((child) => child.hasMath);
};

const getText = (nodes: { text: string; hasMath: boolean }[]) => {
  return nodes.map((child) => child.text).join('');
};

const getTextContent = (node: Text | undefined) => {
  let result = escape(node?.text || '');
  MARKS.forEach((mark: keyof typeof MARKS_TO_CML) => {
    if (node?.[mark] === true) {
      const tag = MARKS_TO_CML[mark];
      result = `<${tag}>${result}</${tag}>`;
    }
  });

  return result;
};

const textNodeToCml = (node: Text) => {
  const textElement = node as Text;
  const text = getTextContent(textElement);
  const hasMath = !!textElement?.text?.match(MATH_REGEX);

  return {
    text,
    hasMath,
  };
};

const inlineMathToCml = (node: InlineElement) => {
  const mathInlineElement = node as MathInlineElement;
  const { formula } = mathInlineElement;
  return {
    text: formula ? escape(formula) : '',
    hasMath: formula ? !!formula.match(MATH_REGEX) : false,
  };
};

const linkToCml = (node: InlineElement) => {
  const linkElement = node as LinkElement;
  const href = sanitizeURL(linkElement.href);
  const linkTitle = escape(linkElement.title) || '';

  const children = linkElement.children.flatMap((child: Text | MathInlineElement) => {
    if (isText(child)) {
      return [textNodeToCml(child as Text)];
    }

    if (child.type === BLOCK_TYPES.MATH_INLINE) {
      return [inlineMathToCml(child)];
    }

    return [];
  });

  const text = getText(children);
  const hasMath = getHasMath(children);

  return {
    text: `<a href="${href}" title="${linkTitle}">${text}</a>`,
    hasMath,
  };
};

const personalizationTagToCml = (node: InlineElement) => {
  const personalizationElement = node as PersonalizationTagElement;
  const { tagValue } = personalizationElement;
  return {
    text: tagValue ? `<ptag>${tagValue}</ptag>` : '',
    hasMath: false,
  };
};

const fillableBlankToCml = (node: InlineElement) => {
  const fillableBlankElement = node as FillableBlankElement;
  const { uuid, responseType, innerText } = fillableBlankElement;
  const cmlAttributes = [
    ...getCmlAttribute(['uuid', uuid], !!uuid),
    ...getCmlAttribute(['type', responseType], !!responseType),
  ].join(' ');

  return {
    text: `<fillable-blank ${cmlAttributes}>${innerText}</fillable-blank>`,
    hasMath: false,
  };
};

const TRANSFORMERS = {
  [BLOCK_TYPES.LINK]: linkToCml,
  [BLOCK_TYPES.PERSONALIZATION_TAG]: personalizationTagToCml,
  [BLOCK_TYPES.FILLABLE_BLANK]: fillableBlankToCml,
  [BLOCK_TYPES.MATH_INLINE]: inlineMathToCml,
};

export const textToCML = (node: { children: (InlineElement | Text)[] }) => {
  const children =
    node?.children.map((childNode) => {
      const tranformer = TRANSFORMERS[(childNode as InlineElement).type] ?? textNodeToCml;
      return tranformer(childNode as InlineElement);
    }) ?? [];

  const text = getText(children);
  const hasMath = getHasMath(children);

  return { text, hasMath };
};
