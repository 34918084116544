/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { compose } from 'recompose';

import type { executeAction as Action } from 'js/lib/executeActionType';

import withFluxibleContextAsProps from 'bundles/author-common/components/withFluxibleContextAsProps';
import { ConfigurationEditorView } from 'bundles/author-widget/components/ConfigurationEditorView';
import { useHandleConfigurationChange } from 'bundles/author-widget/components/useHandleConfigurationChange';
import type { AuthoringWidgetSessionProvidersV1 } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';

type PropsFromCaller = {
  widgetSessionProviderDraft: AuthoringWidgetSessionProvidersV1;
  catchInvalidJsonError: (value: boolean) => void;
  readOnly: boolean;
  hasCustomLabel?: boolean;
  className?: string;
  // Custom onChange provided for cml embedded plugins.
  onChange?: (value: AuthoringWidgetSessionProvidersV1['draft']['configuration']) => void;
  courseId: string;
};

type PropsFromFluxible = {
  executeAction: Action;
};

type Props = PropsFromCaller & PropsFromFluxible;

const ConfigurationEditor: React.FC<Props> = ({
  widgetSessionProviderDraft,
  catchInvalidJsonError,
  readOnly = false,
  executeAction,
  hasCustomLabel,
  className,
  onChange,
  courseId,
}) => {
  const handleChange = useHandleConfigurationChange({
    widgetSessionProviderDraft,
    catchInvalidJsonError,
    executeAction,
    courseId,
    onChange,
  });

  return (
    <ConfigurationEditorView
      readOnly={readOnly}
      hasCustomLabel={hasCustomLabel}
      className={className}
      handleChange={handleChange}
      widgetConfig={widgetSessionProviderDraft?.draft.configuration}
    />
  );
};

export default compose<Props, PropsFromCaller>(withFluxibleContextAsProps())(ConfigurationEditor);
