/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import GoogleFullLogoSVG from 'bundles/enterprise-admin-grow-with-google/components/svgs/GoogleFullLogoSVG';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

const googleBannerContainer = (theme: Theme) =>
  css({
    backgroundColor: theme.palette.blue[100],
    paddingTop: theme.spacing(32),
    paddingBottom: theme.spacing(32),
  });

const googleBannerDescription = (theme: Theme) =>
  css({
    '&&': {
      fontSize: '24px',
      color: theme.palette.blue[800],
    },
  });

const googleBannerBody = (theme: Theme) =>
  css({
    maxWidth: '1224px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 12),
    display: 'flex',
  });

const topInstructorStyle = (theme: Theme) =>
  css({
    color: 'var(--cds-color-white-0)',
    backgroundColor: theme.palette.blue[900],
    border: `1px solid ${theme.palette.blue[900]}`,
    borderRadius: '4px',
    display: 'inline-block',
    padding: theme.spacing(0, 8),
    '&&': {
      fontWeight: 'bold',
      fontSize: '12px',
    },
    maxHeight: '20px',
    marginBottom: theme.spacing(16),
    whiteSpace: 'nowrap',
  });

const googleLogoSection = (theme: Theme) =>
  css({
    marginRight: theme.spacing(24),
  });

const GoogleBanner = () => {
  const theme = useTheme();
  return (
    <div css={googleBannerContainer(theme)}>
      <div css={googleBannerBody(theme)}>
        <div css={googleLogoSection(theme)}>
          <span css={topInstructorStyle(theme)}>{_t('TOP INSTRUCTOR')}</span>
          <GoogleFullLogoSVG />
        </div>
        <div>
          <Typography css={googleBannerDescription(theme)} variant="h1semibold" component="p">
            {_t(
              '75% of Google Career Certificate Graduates in the United States report an improvement in their career trajectory (e.g. new job or career, promotion or raise) within 6 months of certificate completion*.'
            )}
          </Typography>
          <Typography>{_t('*Based on program graduate survey, United States 2022.')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default GoogleBanner;
