/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { color } from '@coursera/coursera-ui';
import { SvgErrorOutline } from '@coursera/coursera-ui/svg';

import _t from 'i18n!nls/program-home';

const style = css`
  padding-top: 14px;
  margin-top: 30px;
  border-top: 1px solid rgb(234 234 234);
  display: flex;
  align-items: flex-start;
  gap: 12px;
  font-size: 14px;

  > svg {
    flex: 0 0 20px;
  }
`;

type Props = {
  endsAt: string;
};

const PrivateSessionEndedCard: React.FC<Props> = ({ endsAt }) => {
  return (
    <div css={style}>
      <SvgErrorOutline size={20} color={color.danger} suppressTitle />
      <div>
        <div>
          <strong>
            {_t('This course ended on #{endsAt}', { endsAt: formatDateTimeDisplay(endsAt, MED_DATE_ONLY_DISPLAY) })}
          </strong>
        </div>
        {_t(
          "You can still access the course material. Assignments can no longer be submitted to receive a certificate. Contact your institution's administrator for more information."
        )}
      </div>
    </div>
  );
};

export default PrivateSessionEndedCard;
