/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import type { ApolloQueryResult } from 'apollo-client';
import { compose, getDisplayName, setDisplayName, withProps } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Grid, Typography, Typography2, useTheme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';
import { css as cuicss, placeholder } from '@coursera/coursera-ui';

import type { CollectionType } from 'bundles/enterprise-collections/components/CollectionItem';
import EnterpriseBadgeCollectionsQuery from 'bundles/enterprise-learner-onboarding/queries/EnterpriseBadgeCollectionsQuery.graphql';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type {
  EnterpriseBadge,
  EnterpriseBadgeWithCollection,
} from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import ProductCoursesQuery from 'bundles/program-common/queries/ProductCoursesQuery.graphql';
import type {
  ProductCardCourseFragmentFragment as Course,
  ProductCoursesQuery as ProductCoursesQueryType,
  ProductCoursesQueryVariables,
} from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import type { Item, Product, ProductList } from 'bundles/program-common/types/programCommon';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import CatalogSection from 'bundles/program-home/components/single-program/CatalogSection';
import EnterpriseBadgeCollectionsView from 'bundles/program-home/components/single-program/EnterpriseBadgeCollectionsView';
import { EnterpriseBadgesCoursesSessionDatesQuery } from 'bundles/program-home/components/single-program/SingleProgramGraphqlQueries';
import type {
  EnterpriseBadgesCoursesSessionDatesQuery as EnterpriseBadgesCoursesSessionDatesQueryData,
  EnterpriseBadgesCoursesSessionDatesQueryVariables,
  EnterpriseBadgesCoursesSessionDatesQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements_session as Session,
} from 'bundles/program-home/components/single-program/__generated__/EnterpriseBadgesCoursesSessionDatesQuery';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

export type PropsFromCaller = {
  userId: number;
  programId: string;
  thirdPartyOrganizationName: string;
  programSlug: string;
  isProgramMember: boolean;
  onProductCardClick: OnProductCardClick;
};

type PropsFromCourses = {
  coursesMap: Map<string, Course>;
};

type PropsFromSessions = {
  sessions: Array<Session>;
};

type PropsFromEnterpriseBadges = {
  enterpriseBadgeCollections: Array<EnterpriseBadge> | undefined;
  courseIds: Array<string>;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

type PropsForSessionsGraphql = Pick<PropsFromEnterpriseBadges, 'courseIds'> & Pick<PropsFromCaller, 'programId'>;

type PropsFromWithProps = {
  enterpriseBadgesWithCollection: Array<EnterpriseBadgeWithCollection>;
};

type PropsForComponent = PropsFromCaller &
  PropsFromWithProps &
  Pick<PropsFromEnterpriseBadges, 'refetchEnterpriseBadgeCollections'>;

export type Props = PropsFromEnterpriseBadges &
  PropsFromCaller &
  PropsFromCourses &
  PropsFromSessions &
  PropsFromWithProps;

const useStyles = () => {
  const theme = useTheme();
  return {
    badgeCollectionsHeaderContainer: css`
      margin-bottom: ${theme.spacing(64)};
      ${theme.breakpoints.down('xs')} {
        margin-left: ${theme.spacing(12)};
      }

      /* stylelint-disable-next-line no-duplicate-selectors */
      ${theme.breakpoints.down('xs')} {
        margin-left: ${theme.spacing(8)};
      }
    `,
    badgeCollectionsInfoList: css`
      margin-top: ${theme.spacing(32)};
      margin-left: calc(${theme.spacing(24)} * -1);
      ${theme.breakpoints.down('md')} {
        margin-left: calc(${theme.spacing(0)} * -1);
      }
      ${theme.breakpoints.down('xs')} {
        order: 1;
        margin-left: calc(${theme.spacing(12)} * -1);
      }
    `,
    badgeCollectionsInfoListItem: css`
      margin-bottom: ${theme.spacing(12)};
    `,
    badgeCollectionsInfoListCheck: css`
      display: flex;
      margin-top: ${theme.spacing(4)};
    `,
    badgeCollectionsImgItem: css`
      display: flex;
      justify-content: center;
      ${theme.breakpoints.down('xs')} {
        order: 0;
      }
    `,
    badgeCollectionsImg: css`
      margin-top: ${theme.spacing(32)};
    `,
    collectionsPlaceholderImg: css`
      margin-top: ${theme.spacing(32)};
      width: 185px;
      height: 185px;
      margin-bottom: 8px;
      animation-delay: -0.3s;
    `,
  };
};

export function EnterpriseBadgeCollections({
  enterpriseBadgesWithCollection,
  thirdPartyOrganizationName,
  programId,
  programSlug,
  userId,
  isProgramMember,
  onProductCardClick,
  refetchEnterpriseBadgeCollections,
}: PropsForComponent): JSX.Element {
  const styles = useStyles();
  const enterpriseBadgesInfo = [
    _t('#{thirdPartyOrganizationName} recognized digital badge', { thirdPartyOrganizationName }),
    _t('Sharable course certificates'),
    _t('Self-paced learning option'),
    _t('Course videos & readings'),
    _t('Graded essays, quizzes and programming assignments with peer feedback'),
  ];
  const loading = !enterpriseBadgesWithCollection?.length;

  const badgeImage = loading ? (
    <div css={styles.collectionsPlaceholderImg} {...cuicss(placeholder.styles.shimmer)} />
  ) : (
    <img
      src={enterpriseBadgesWithCollection[enterpriseBadgesWithCollection.length - 1].enterpriseBadge.imageUrl}
      css={styles.badgeCollectionsImg}
      alt=""
      aria-hidden="true"
      width="185"
      height="185"
    />
  );

  return (
    <CatalogSection>
      <div css={styles.badgeCollectionsHeaderContainer}>
        <Heading defaultLevel={2} variant="h1semibold">
          <FormattedMessage
            message={_t('Earn a digital badge endorsed by {thirdPartyOrganizationName}')}
            thirdPartyOrganizationName={thirdPartyOrganizationName}
          />
        </Heading>
        <Section initialLevel={3}>
          <Typography2 component="p" variant="bodyPrimary">
            {_t('Complete the courses, gain job ready skills and earn an endorsed digital badge.')}
          </Typography2>
          <Grid container>
            <Grid item xs={12} sm={7} css={styles.badgeCollectionsInfoList}>
              <Grid container spacing={12}>
                {enterpriseBadgesInfo.map((info) => {
                  return (
                    <React.Fragment>
                      <Grid item xs={1} css={styles.badgeCollectionsInfoListCheck} justifyContent="flex-end">
                        <CheckIcon size="medium" color="interactive" />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="h2" component="span">
                          {info}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5} css={styles.badgeCollectionsImgItem}>
              {badgeImage}
            </Grid>
          </Grid>
        </Section>
      </div>
      <EnterpriseBadgeCollectionsView
        enterpriseBadgesWithCollection={enterpriseBadgesWithCollection}
        userId={userId}
        programId={programId}
        programSlug={programSlug}
        loading={loading}
        onProductCardClick={onProductCardClick}
        refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
        isProgramMember={isProgramMember}
      />
    </CatalogSection>
  );
}

function courseToItem(id: string): Item {
  return {
    productId: tupleToStringKey(['VerifiedCertificate', id]),
  };
}

export function enterpriseBadgeCollectionToCollectionType(
  enterpriseBadge: EnterpriseBadge,
  coursesMap: Map<string, Course>,
  sessions: Array<Session>
): CollectionType {
  const courses: ProductList = { elements: [] };
  const s12ns: ProductList = { elements: [] };
  const items: Array<Item> = [];

  enterpriseBadge.productIds.forEach((productId) => {
    if (coursesMap.get(productId.id)) {
      items.push(courseToItem(productId.id));
      const productFragment = coursesMap.get(productId.id);
      courses.elements.push({
        ...productFragment,
        courseTypeMetadata: {
          courseTypeMetadata: {
            __typename: productFragment?.courseTypeMetadata.metadata.__typename || '',
          },
        },
        partners: { elements: productFragment?.partners },
      } as Product);
    }
  });
  return {
    id: enterpriseBadge.collectionId,
    title: enterpriseBadge.title,
    description: {
      cml: undefined,
    },
    courses,
    s12ns,
    items,
    associatedSessions: {
      elements: sessions,
    },
  };
}

export const enhance = compose<Props, PropsFromCaller>(
  setDisplayName(getDisplayName(EnterpriseBadgeCollections)),
  graphql<
    PropsFromCaller,
    EnterpriseBadgeCollectionsQueryData,
    EnterpriseBadgeCollectionsQueryVariables,
    PropsFromEnterpriseBadges
  >(EnterpriseBadgeCollectionsQuery, {
    options: ({ userId, programId }) => {
      return {
        ssr: false,
        variables: {
          userId: String(userId),
          programId,
        },
        context: { clientName: 'gatewayGql' },
      };
    },
    props: ({ data }) => {
      const courseIds: Array<string> = [];
      data?.EnterpriseBadge?.findByUserAndProgram.badgeCollectionsWithUserState.forEach((enterpriseBadge) => {
        enterpriseBadge.productIds.forEach((productId) => {
          courseIds.push(productId.id);
        });
      });
      return {
        enterpriseBadgeCollections: data?.EnterpriseBadge?.findByUserAndProgram.badgeCollectionsWithUserState,
        courseIds,
        refetchEnterpriseBadgeCollections: data?.refetch,
      };
    },
  }),
  graphql<PropsFromEnterpriseBadges, ProductCoursesQueryType, ProductCoursesQueryVariables, PropsFromCourses>(
    ProductCoursesQuery,
    {
      options: ({ courseIds }) => ({
        ssr: false,
        variables: {
          courseIds,
        },
        context: {
          clientName: 'gatewayGql',
        },
      }),
      props: ({ data }) => {
        const coursesMap = new Map<string, Course>();
        filterExistsOrDefault(data?.Course?.queryByIds).forEach((course) => {
          coursesMap.set(course.id, course);
        });
        return {
          coursesMap,
        };
      },
    }
  ),
  graphql<
    PropsForSessionsGraphql,
    EnterpriseBadgesCoursesSessionDatesQueryData,
    EnterpriseBadgesCoursesSessionDatesQueryVariables,
    PropsFromSessions
  >(EnterpriseBadgesCoursesSessionDatesQuery, {
    options: ({ courseIds, programId }) => ({
      ssr: false,
      variables: {
        courseIds,
        programId,
      },
    }),
    props: ({ data }) => {
      const sessions = filterExistsOrDefault(
        data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramAndCourses?.elements
      );
      const sessionDates = filterExistsOrDefault(sessions.map((session) => session.session));
      return {
        sessions: sessionDates,
      };
    },
  }),
  withProps<PropsFromWithProps, Props>(({ enterpriseBadgeCollections, coursesMap, sessions }) => {
    const enterpriseBadges: Array<EnterpriseBadgeWithCollection> = filterExistsOrDefault(
      enterpriseBadgeCollections
    ).map((enterpriseBadge) => ({
      id: enterpriseBadge.badgeTemplateId,
      enterpriseBadge,
      collection: enterpriseBadgeCollectionToCollectionType(enterpriseBadge, coursesMap, sessions),
    }));
    return { enterpriseBadgesWithCollection: enterpriseBadges };
  })
);

export default enhance(EnterpriseBadgeCollections);
