import type { Editor, Node, NodeEntry } from 'slate';

import logger from 'js/app/loggerSingleton';

import { normalizeBody } from 'bundles/cml/editor/normalize/normalizeBody';
import { normalizeHeadingMarkdown } from 'bundles/cml/editor/normalize/normalizeHeadingMarkdown';
import { normalizeHeadings } from 'bundles/cml/editor/normalize/normalizeHeadings';
import { normalizeLastNode } from 'bundles/cml/editor/normalize/normalizeLastNode';
import { normalizeLinkMarkdown } from 'bundles/cml/editor/normalize/normalizeLinkMarkdown';
import { normalizeLinks } from 'bundles/cml/editor/normalize/normalizeLinks';
import { normalizeListMarkdown } from 'bundles/cml/editor/normalize/normalizeListMarkdown';
import { normalizeLists } from 'bundles/cml/editor/normalize/normalizeLists';
import { normalizeMarks } from 'bundles/cml/editor/normalize/normalizeMarks';
import { normalizeMarksMarkdown } from 'bundles/cml/editor/normalize/normalizeMarksMarkdown';
import { normalizeMath } from 'bundles/cml/editor/normalize/normalizeMath';
import { normalizeTableMarkdown } from 'bundles/cml/editor/normalize/normalizeTableMarkdown';
import { normalizeTables } from 'bundles/cml/editor/normalize/normalizeTables';
import { normalizeTopLevelElements } from 'bundles/cml/editor/normalize/normalizeTopLevelElements';
import { normalizeVoids } from 'bundles/cml/editor/normalize/normalizeVoids';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const NORMALIZERS = [
  normalizeMarks,
  normalizeHeadings,
  normalizeMath,
  normalizeVoids,
  normalizeTopLevelElements,
  normalizeLinks,
  normalizeTables,
  normalizeLastNode,
  normalizeLists,
  normalizeBody,
];

const MARKDOWN_NORMALIZERS = [
  normalizeListMarkdown,
  normalizeTableMarkdown,
  normalizeHeadingMarkdown,
  normalizeLinkMarkdown,
  normalizeMarksMarkdown,
];

type Normalizer = (editor: Editor, tools: Set<ToolsKeys>, entry: NodeEntry<Node>) => boolean;

const handleNormalize = (
  editor: Editor,
  tools: Set<ToolsKeys>,
  normalizers: Normalizer[],
  normalizeFallback: (node: NodeEntry<Node>) => void
) => {
  return (entry: NodeEntry<Node>) => {
    if (normalizers.some((normalizer) => normalizer(editor, tools, entry))) {
      return;
    }

    try {
      normalizeFallback(entry);
    } catch (e) {
      logger.warn('[CMLEditor]: normalization failed', e);
    }
  };
};

export const normalize = (
  editor: Editor,
  tools: Set<ToolsKeys>,
  normalizeFallback: (node: NodeEntry<Node>) => void
) => {
  return handleNormalize(editor, tools, NORMALIZERS, normalizeFallback);
};

export const normalizeWithMarkdown = (
  editor: Editor,
  tools: Set<ToolsKeys>,
  normalizeFallback: (node: NodeEntry<Node>) => void
) => {
  const normalizers = [...NORMALIZERS, ...MARKDOWN_NORMALIZERS];
  return handleNormalize(editor, tools, normalizers, normalizeFallback);
};
