import * as React from 'react';
import { useCallback, useMemo } from 'react';

import CMLEditor from 'bundles/cml/editor/components/CMLEditor';
import WidgetModalComponent from 'bundles/cml/editor/components/WidgetModal';
import AssetModal from 'bundles/cml/editor/components/dialogs/AssetModal';
import ImageDescriptionDialogComponent from 'bundles/cml/editor/components/elements/image/dialogs/ImageDescriptionDialog';
import { withAssetAdminStore } from 'bundles/cml/editor/components/elements/image/utils/withAssetAdminStore';
import { withFluxibleContext } from 'bundles/cml/editor/components/elements/image/utils/withFluxibleContext';
import { withEventingContext } from 'bundles/cml/editor/context/eventingContext';
import type { Props } from 'bundles/cml/editor/types/cmlEditorProps';
import { createWidgetMenuOptions } from 'bundles/cml/editor/utils/widgetApiUtils';
import type { CmlContent as NaptimeCmlContent } from 'bundles/cml/legacy/types/Content';
import { getDtdId, getValue } from 'bundles/cml/legacy/utils/CMLUtils';
import AssetManager from 'bundles/cml/shared/utils/AssetManager';
import { createWidgetManager } from 'bundles/cml/shared/utils/WidgetManager';

const ImageDescriptionDialog = withAssetAdminStore(ImageDescriptionDialogComponent);
const WidgetModal = withFluxibleContext(WidgetModalComponent);

export type CMLEditorNaptimeProps = Props<NaptimeCmlContent>;

const CMLEditorNaptime: React.FC<CMLEditorNaptimeProps> = ({ initialCML, onContentChange, ...props }) => {
  const widgetManager = useMemo(() => createWidgetManager(), []);
  const widgetMenuOptions = useMemo(() => createWidgetMenuOptions(), []);
  const cmlValue = useMemo(() => getValue(initialCML), [initialCML]);
  const dtdId = useMemo(() => getDtdId(initialCML), [initialCML]);

  const handleChange = useCallback(
    (value: string) => {
      onContentChange({
        typeName: 'cml',
        definition: { dtdId, value },
      });
    },
    [onContentChange, dtdId]
  );

  return (
    <CMLEditor
      {...props}
      cmlValue={cmlValue}
      onChange={handleChange}
      AssetModal={AssetModal}
      AssetManager={AssetManager}
      ImageDescriptionDialog={ImageDescriptionDialog}
      WidgetManager={widgetManager}
      WidgetModal={WidgetModal}
      widgetMenuOptions={widgetMenuOptions}
    />
  );
};

export default React.memo(withEventingContext(CMLEditorNaptime));
