/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Grid } from '@coursera/cds-core';

type Props = {
  header: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const styles = {
  container: (theme: Theme) => css`
    margin-bottom: 32px;
    box-shadow: 1px 2px 3px 0 ${theme.palette.gray[300]};
    border: 1px solid ${theme.palette.gray[300]};
  `,
  title: (theme: Theme) => css`
    padding: 14px 18px 10px 18px;
    background-color: ${theme.palette.gray[100]};
    border-bottom: 1px solid ${theme.palette.gray[300]};
  `,
  children: css`
    padding: 18px;
  `,
};

export const CardWithHeader: React.FC<Props> = ({ header, children, ...props }) => {
  return (
    <Grid container css={styles.container} {...props}>
      <Grid item xs={12} css={styles.title}>
        {header}
      </Grid>
      <Grid item xs={12} css={styles.children}>
        {children}
      </Grid>
    </Grid>
  );
};
