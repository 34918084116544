/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useState } from 'react';

import AddTableColumnButton from 'bundles/cml/editor/components/elements/table/buttons/AddTableColumnButton';
import AddTableRowButton from 'bundles/cml/editor/components/elements/table/buttons/AddTableRowButton';
import MoreTableOptionsButton from 'bundles/cml/editor/components/elements/table/buttons/MoreTableOptionsButton';
import RemoveTableColumnButton from 'bundles/cml/editor/components/elements/table/buttons/RemoveTableColumnButton';
import RemoveTableRowButton from 'bundles/cml/editor/components/elements/table/buttons/RemoveTableRowButton';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { StyleContext } from 'bundles/cml/editor/context/styleContext';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  hidden: css`
    visibility: hidden;
    pointer-events: none;
  `,
};

type Props = {
  table: TableElement;
  anchorEl: HTMLElement | null;
};

const TableMenu: React.FC<Props> = ({ anchorEl, table }) => {
  const [hidden, setHidden] = useState(false);

  const { pageless } = useContext(StyleContext);

  return (
    <FloatingMenu
      anchorEl={anchorEl}
      placement="top-end"
      strategy="absolute"
      onClose={() => undefined}
      onHide={setHidden}
      pageless={pageless}
      offset={0}
      enableShift={false}
      enableFlip
      css={hidden && styles.hidden}
    >
      <RestoreFocus anchorEl={anchorEl} onClose={() => setHidden(true)}>
        <Toolbar role="menubar" autoFocus={true}>
          <AddTableRowButton table={table} />
          <AddTableColumnButton table={table} />
          <RemoveTableRowButton table={table} />
          <RemoveTableColumnButton table={table} />
          <MoreTableOptionsButton table={table} />
        </Toolbar>
      </RestoreFocus>
    </FloatingMenu>
  );
};

export default TableMenu;
