import * as React from 'react';
import { useEffect, useState } from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import { useS12nEnrollModalData } from 'bundles/enroll/data/usePageData';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import PaymentChoiceFree from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoiceFree';
import PaymentChoiceFull from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoiceFull';
import PaymentChoiceGroup from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoiceGroup';
import PaymentChoiceProgram from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoiceProgram';
import PaymentChoiceSingle from 'bundles/s12n-enroll/components/bulk-pay/PaymentChoiceSingle';
import { PaymentChoiceTypes } from 'bundles/s12n-enroll/constants';
import type { PaymentChoiceType } from 'bundles/s12n-enroll/constants';

import _t from 'i18n!nls/s12n-enroll';

import 'css!./__styles__/PaymentChoices';

type PropsFromCaller = {
  s12nId: string;
  courseId: string;
  title: string;
  onFreeEnroll: () => void;
  onEnrollSuccess?: () => void;
  onEnrollFailure?: () => void;
  onSdp?: boolean;
};

const PaymentChoices: React.FC<PropsFromCaller> = ({ s12nId, courseId, title, onSdp }) => {
  const [enrolling, setIsEnrolling] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentSelectedType, setCurrentSelectedType] = useState<PaymentChoiceType | undefined>();

  const { enrollmentAvailableChoices, s12n, productOwnerships, program, group, thirdPartyOrganization } =
    useS12nEnrollModalData();

  const showFreeOption =
    enrollmentAvailableChoices.hasFreeEnrollOptionIntoCourse || enrollmentAvailableChoices.canAuditCourse;
  const showBulkPay = enrollmentAvailableChoices.canBulkPaySpecialization;
  const showEnrollThroughProgram = enrollmentAvailableChoices.canEnrollThroughProgram;
  const showEnrollThroughGroup = enrollmentAvailableChoices.canEnrollThroughGroup;

  useEffect(() => {
    const getInitialSelectedType = (): PaymentChoiceType => {
      if (showEnrollThroughProgram) {
        return PaymentChoiceTypes.PROGRAM;
      } else if (showEnrollThroughGroup) {
        return PaymentChoiceTypes.GROUP;
      } else if (showBulkPay) {
        return PaymentChoiceTypes.FULL;
      } else {
        return PaymentChoiceTypes.SINGLE;
      }
    };

    setCurrentSelectedType(getInitialSelectedType());
  }, [showEnrollThroughGroup, showEnrollThroughProgram, showBulkPay]);

  const handleClickEnroll = (ev: $TSFixMe) => {
    ev.stopPropagation();

    const choiceTypeMap = {
      free: EnrollmentChoiceTypes.ENROLL_COURSE,
      single: EnrollmentChoiceTypes.PURCHASE_SINGLE_COURSE,
      full: EnrollmentChoiceTypes.BULKPAY_FULL_SPECIALIZATION,
      program: EnrollmentChoiceTypes.ENROLL_THROUGH_PROGRAM,
      group: EnrollmentChoiceTypes.ENROLL_THROUGH_GROUP,
      subscription: undefined,
    };

    const choiceType = currentSelectedType && choiceTypeMap[currentSelectedType];
    const handleSubmitPromise = choiceType && choiceTypeToHandleSubmitPromise[choiceType];

    if (handleSubmitPromise) {
      setIsEnrolling(true);
      setShowErrorMessage(false);

      const options = {
        courseId,
        s12nId,
        data: {
          s12nId,
          ...(program ? { programId: program?.id } : {}),
          ...(group ? { groupId: group?.id } : {}),
        },
      };
      submitEnrollmentPromise({ handleSubmitPromise, options }).catch(() => {
        setShowErrorMessage(true);
      });
    } else {
      setShowErrorMessage(true);
    }
  };

  const setSelectedType = (newType: PaymentChoiceType): void => {
    setCurrentSelectedType(newType);
  };

  const renderChoices = () => {
    if (!currentSelectedType) {
      return null;
    }

    const { unownedCourses } = productOwnerships;
    const doesOwnCourse = !unownedCourses.includes(courseId);

    return (
      <TrackedDiv
        trackingName="s12n_enroll_modal_payment_choices"
        data={{ showEnrollThroughProgram, showEnrollThroughGroup, showBulkPay, showFreeOption }}
        withVisibilityTracking
      >
        {showEnrollThroughProgram && (
          <PaymentChoiceProgram s12n={s12n} program={program} thirdPartyOrganization={thirdPartyOrganization} />
        )}
        {showEnrollThroughGroup && group && <PaymentChoiceGroup s12n={s12n} group={group} />}
        {showBulkPay && !showEnrollThroughProgram && (
          <PaymentChoiceFull currentType={currentSelectedType} onClick={setSelectedType} onSdp={onSdp} />
        )}
        {!(showEnrollThroughProgram || showEnrollThroughGroup) && !doesOwnCourse && (
          <PaymentChoiceSingle
            onSdp={onSdp}
            courseId={courseId}
            currentType={currentSelectedType}
            onClick={setSelectedType}
          />
        )}
        {showFreeOption && !showEnrollThroughProgram && (
          <PaymentChoiceFree s12n={s12n} currentType={currentSelectedType} onClick={setSelectedType} />
        )}
      </TrackedDiv>
    );
  };

  const enrollButtonProps: { disabled?: boolean } = {};
  if (enrolling) {
    enrollButtonProps.disabled = true;
  }

  return (
    <div className="rc-PaymentChoices styleguide">
      <div className="title container">
        <h3 className="bt3-modal-title center">{title || _t('Pick a Payment Option')}</h3>
        {!onSdp && (
          <div className="sub-title bt3-text-center">
            <FormattedMessage
              className="center"
              message={_t('Part of a {s12nCourseCount}-course series, {s12nTitle}')}
              s12nCourseCount={s12n.courseCountWithCapstones}
              s12nTitle={s12n.name}
            />
          </div>
        )}
      </div>
      <div className="content container">
        {renderChoices()}
        <div className="horizontal-box align-items-absolute-center enroll-button">
          <TrackedButton
            trackingName="enroll_button"
            data={{ selectType: currentSelectedType }}
            className="primary"
            onClick={handleClickEnroll}
            {...enrollButtonProps}
          >
            {enrolling ? <Icon name="spinner" spin /> : _t('Enroll')}
          </TrackedButton>
        </div>
        {showErrorMessage && (
          <div className="error-message align-horizontal-center body-1-text color-warn-dark">
            {_t('Please choose a payment option')}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentChoices;
