import * as React from 'react';

import classNames from 'classnames';

import CMLCodeEditorEvaluatorConfigModal from 'bundles/cml/editor/components/elements/code/CMLCodeEditorEvaluatorConfigModal';
import CMLCodeEditorEvaluatorDropdown from 'bundles/cml/editor/components/elements/code/CMLCodeEditorEvaluatorDropdown';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';

type Props = {
  courseId?: string;
  branchId?: string;
  itemId?: string;
  evaluatorId?: string;
  value: string;
  language: LanguageType;
  onEvaluatorChange: (evaluatorId: string, value: string) => void;
  toggleEcbConfigModal?: (ecbModalComponent: React.ReactElement | null) => void;
  codeBlockLabel: string;
};

class CMLCodeEditorEvaluatorConfig extends React.Component<Props> {
  handleClose = () => {
    const { toggleEcbConfigModal } = this.props;
    toggleEcbConfigModal?.(null);
  };

  handleSave = (evaluatorId: string, value: string) => {
    this.props.onEvaluatorChange(evaluatorId, value);
    this.handleClose();
  };

  handleOpen = () => {
    const { evaluatorId, value, language, toggleEcbConfigModal } = this.props;
    if (!evaluatorId) {
      return;
    }

    // pass up the modal component to render to CMLEditor, don't render inline
    const modalComponent = (
      <CMLCodeEditorEvaluatorConfigModal
        isOpen={true}
        value={value}
        language={language}
        evaluatorId={evaluatorId}
        onSave={this.handleSave}
        onRequestClose={this.handleClose}
      />
    );

    toggleEcbConfigModal?.(modalComponent);
  };

  render() {
    const { evaluatorId, value, language, courseId, branchId, itemId, toggleEcbConfigModal, codeBlockLabel } =
      this.props;

    const configClasses = classNames('rc-CMLCodeEditorEvaluatorConfig', {
      interactive: !!evaluatorId,
    });

    return (
      <div className={configClasses}>
        <CMLCodeEditorEvaluatorDropdown
          language={language}
          evaluatorId={evaluatorId}
          branchId={branchId as string}
          itemId={itemId as string}
          courseId={courseId as string}
          onSave={this.handleSave}
          value={value}
          handleOpen={this.handleOpen}
          toggleEcbConfigModal={toggleEcbConfigModal}
          codeBlockLabel={codeBlockLabel}
        />
      </div>
    );
  }
}

export default CMLCodeEditorEvaluatorConfig;
