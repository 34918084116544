/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import moment from 'moment';

import { Button, InlineNotification, Typography2, useTheme } from '@coursera/cds-core';

import { Heading } from 'bundles/program-home/components/AutoHeading';
import DatePicker from 'bundles/program-home/components/age-verification/DatePicker';
import { setAgeVerification } from 'bundles/program-home/utils/ProgramHomeUtils';

import _t from 'i18n!nls/program-home';

export const AGE_VERIFICATION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_ANSWERED: 'NOT_ANSWERED',
} as const;

export type ageVerificationStatusValues = (typeof AGE_VERIFICATION_STATUS)[keyof typeof AGE_VERIFICATION_STATUS];

type PropsFromCaller = {
  programId: string;
  isAuthenticatedUser: boolean;
  onContinueClick: (val: ageVerificationStatusValues) => void;
};

const MIN_AGE = 13;

const useStyles = () => {
  const theme = useTheme();
  return {
    error: css`
      margin-top: ${theme.spacing(24)};
    `,
    text: css`
      margin: ${theme.spacing(24, 0)};
      ${theme.breakpoints.up('sm')} {
        text-align: center;
      }
    `,
    form: css`
      margin-top: ${theme.spacing(48)};
      ${theme.breakpoints.down('xs')} {
        margin-top: ${theme.spacing(24)};
      }
    `,
    picker: css`
      max-width: ${theme.breakpoints.values.sm}px;
      margin-left: auto;
      margin-right: auto;
    `,
    submit: css`
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: ${theme.spacing(64)};
      ${theme.breakpoints.down('xs')} {
        margin-top: ${theme.spacing(24)};
      }
    `,
  };
};

export default function AgePrompt({ programId, onContinueClick, isAuthenticatedUser }: PropsFromCaller): JSX.Element {
  const styles = useStyles();
  const [day, setDay] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>();
  const [year, setYear] = useState<number | undefined>();
  const [isInvalidDate, setIsInvalidDate] = useState(false);

  function onButtonClick(event: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    if (!year || (!month && month !== 0) || !day) {
      setIsInvalidDate(true);
      return;
    }
    setIsInvalidDate(false);
    const dob = moment([year, month, day]);
    if (!dob.isValid()) {
      setIsInvalidDate(true);
      return;
    }
    const today = moment();
    const age = today.diff(dob, 'years');
    if (age < MIN_AGE) {
      setAgeVerification(programId, isAuthenticatedUser, false);
      onContinueClick(AGE_VERIFICATION_STATUS.FAIL);
    } else {
      setAgeVerification(programId, isAuthenticatedUser, true);
      onContinueClick(AGE_VERIFICATION_STATUS.SUCCESS);
    }
  }
  return (
    <div>
      <Heading defaultLevel={1} css={styles.text}>
        {_t('Please enter your birth date')}
      </Heading>
      <Typography2 component="p" css={styles.text}>
        {_t('Coursera is required to verify your age in order to join this learning program')}
      </Typography2>
      {isInvalidDate && (
        <InlineNotification id="AgePrompt-Error" role="alert" severity="error" css={styles.error}>
          {_t('The entered date is invalid.')}
        </InlineNotification>
      )}
      <form onSubmit={onButtonClick} css={styles.form}>
        <DatePicker
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          renderDayValidationError={Boolean(isInvalidDate)}
          css={styles.picker}
        />
        <Button variant="primary" type="submit" aria-describedby="AgePrompt-Error" css={styles.submit}>
          {_t('Continue')}
        </Button>
      </form>
    </div>
  );
}
