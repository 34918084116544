/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';
import { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { Theme } from '@coursera/cds-core';
import { Typography2, useTheme } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/program-personalized-tab';

export type Props = {
  slug?: string | null;
  title?: string | null;
  description?: string | null;
  skills?: Array<string | null> | null;
  roleSlug?: string | null;
  imgUrl?: string | null;
  onClick?: (roleSlug: string) => void;
};

const roleCardModule = css({
  borderRadius: '16px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '&:hover,&:focus': {
    cursor: 'pointer',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.16)',
    transform: 'translateY(-4px)',
    transition: 'all 0.3s cubic-bezier(0, 0, 0.5, 1)',
  },
});

const bottomSection = (theme: Theme) =>
  css({
    padding: '16px 24px',
    background: theme.palette.gray[200],
    height: '180px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xl')]: {
      height: '200px',
    },
    [theme.breakpoints.down('md')]: {
      height: '170px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '160px',
    },
  });

const imgStyles = css`
  height: 160px;
  align-self: center;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  min-width: -webkit-fill-available;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  min-width: -moz-fill-available;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  min-width: -fill-available;
`;

export const RoleCardModule: React.FC<Props> = ({ title, description, skills, imgUrl, roleSlug, onClick }) => {
  const theme = useTheme();

  return (
    <TrackedDiv
      css={roleCardModule}
      trackClicks
      trackingName="personalized_role_card_module"
      trackingData={{ title }}
      role="button"
      onClick={() => onClick?.(roleSlug ?? '')}
      data-testid="personalized_role_card_module"
    >
      <Imgix src={imgUrl ?? ''} alt={title ?? ''} imgParams={{ fit: 'crop' }} css={imgStyles} />
      <section css={bottomSection(theme)}>
        <Typography2 variant="subtitleMedium" component="p">
          {title}
        </Typography2>
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {description}
        </Typography2>
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          <FormattedMessage
            message={_t('{prefix} {skills}')}
            prefix={<strong>{_t('If you like:')}</strong>}
            skills={skills?.join(', ')}
          />
        </Typography2>
      </section>
    </TrackedDiv>
  );
};
