/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link } from 'react-router';

import { Button, Typography2, useTheme } from '@coursera/cds-core';

import _t from 'i18n!nls/program-home';

type PropsFromCaller = {
  href: string;
  tspTitle?: string;
};

type Props = PropsFromCaller;

const SkillSetCourseCompletionBanner: React.FC<Props> = ({ href, tspTitle }) => {
  const theme = useTheme();
  const styles = {
    container: css`
      margin-bottom: ${theme.spacing(16)};
      background-color: var(--cds-color-white-0);
      padding: ${theme.spacing(16)};
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid ${theme.palette.gray[300]};
      border-radius: 4px;
    `,
  };
  return (
    <div css={styles.container}>
      <Typography2 component="p">
        {_t('Keep making progress in this SkillSet with these course recommendations.')}
      </Typography2>
      <Button
        variant="secondary"
        size="small"
        component={Link}
        to={href}
        aria-label={
          tspTitle ? _t('View recommended courses for #{tspTitle}', { tspTitle }) : _t('View recommended courses')
        }
      >
        {_t('View recommendations')}
      </Button>
    </div>
  );
};

export default SkillSetCourseCompletionBanner;
