import * as React from 'react';
import { useState } from 'react';

import classNames from 'classnames';

import { Button, VisuallyHidden } from '@coursera/cds-core';

import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollInS12nSubscription } from 'bundles/enroll/utils/useEnrollInS12nSubscription';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { getTrackingData } from 'bundles/promotions/utils/productDiscountPromoUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionEnrollButton';

type Props = {
  isFromS12nSelection: boolean;
  onSdp?: boolean;
  onError: (error: ApiError) => void;
};

const SubscriptionEnrollButton: React.FunctionComponent<Props> = ({ isFromS12nSelection, onSdp, onError }) => {
  const [enrolling, setEnrolling] = useState(false);
  const { enrollInSubscription, canEnrollThroughS12nSubscriptionFreeTrial: isFreeTrial } =
    useEnrollInS12nSubscription(isFromS12nSelection);
  const additionalParams = !onSdp ? { fromCoursePage: 'true' } : undefined;

  const handleError = (error: ApiError) => {
    setEnrolling(false);
    onError(error);
  };

  const handleClick = async () => {
    setEnrolling(true);
    enrollInSubscription(additionalParams).catch(handleError);
  };

  const renderButtonContent = () => {
    if (enrolling) {
      // return
      return '';
    } else {
      return isFreeTrial ? _t('Start Free Trial') : _t('Subscribe');
    }
  };

  const trackingName = isFreeTrial ? 'free_trial_start_button' : 'enroll_subscribe_monthly';

  const buttonClassName = classNames('subscribe-button', {
    'primary cozy': !enrolling,
  });

  const buttonIcon = enrolling ? <Icon name="spinner" spin /> : undefined;

  return (
    <div className="rc-SubscriptionEnrollButton" role="status" aria-live="polite">
      <Button
        component={TrackedButton}
        data-e2e="subscription_enroll_button"
        trackingName={trackingName}
        data={getTrackingData()}
        className={buttonClassName}
        onClick={handleClick}
        disabled={enrolling}
        withVisibilityTracking={false}
        requireFullyVisible={false}
        icon={buttonIcon}
        aria-live="polite"
      >
        {renderButtonContent()}
        {enrolling && (
          <VisuallyHidden component="span" className="rc-A11yScreenReaderOnly">
            {_t('Loading')}
          </VisuallyHidden>
        )}
      </Button>
    </div>
  );
};

export default SubscriptionEnrollButton;
