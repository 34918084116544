import * as React from 'react';
import { Query } from 'react-apollo';

import gql from 'graphql-tag';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';

import type {
  CourseScheduleExperienceConfigurationV1ByBranchFromEarliestOpenScheduleQuery,
  CourseScheduleExperienceConfigurationV1ByBranchFromEarliestOpenScheduleQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/CourseScheduleExperienceConfigurationV1';

type InputProps = Record<string, any>;

/* eslint-disable graphql/template-strings */
export const courseScheduleExperienceConfigurationQuery = gql`
  query courseScheduleExperienceConfigurationQuery($id: String!) {
    CourseScheduleExperienceConfigurationV1 @naptime {
      byBranchFromEarliestOpenSchedule(id: $id) {
        elements {
          experienceType {
            typeName
          }
        }
      }
    }
  }
`;

const withSessionsV2EnrollmentEnabled =
  (getCourseIdFromProps: (props: InputProps) => string, loadingComponent?: React.ReactNode | React.FC) =>
  (BaseComponent: React.ComponentType<any>) => {
    const componentName = BaseComponent.displayName || BaseComponent.name;

    const HOC: React.FC<InputProps> = ({ ...props }) => (
      <Query<
        CourseScheduleExperienceConfigurationV1ByBranchFromEarliestOpenScheduleQuery,
        CourseScheduleExperienceConfigurationV1ByBranchFromEarliestOpenScheduleQueryVariables
      >
        query={courseScheduleExperienceConfigurationQuery}
        variables={{ id: getCourseIdFromProps(props) }}
      >
        {({ error, data, loading }) => {
          if (error) {
            return null;
          }

          if (loading && loadingComponent) {
            if (typeof loadingComponent === 'function') {
              return loadingComponent(props);
            } else {
              return loadingComponent;
            }
          }
          let sessionsV2EnrollmentEnabled = false;

          if (
            data &&
            data?.CourseScheduleExperienceConfigurationV1 &&
            data?.CourseScheduleExperienceConfigurationV1?.byBranchFromEarliestOpenSchedule &&
            data?.CourseScheduleExperienceConfigurationV1?.byBranchFromEarliestOpenSchedule?.elements
          ) {
            sessionsV2EnrollmentEnabled =
              data.CourseScheduleExperienceConfigurationV1.byBranchFromEarliestOpenSchedule.elements[0].experienceType
                .typeName === 'alwaysAvailable';
          }

          return <BaseComponent sessionsV2EnrollmentEnabled={sessionsV2EnrollmentEnabled} {...props} />;
        }}
      </Query>
    );

    HOC.displayName = `withSessionsV2EnrollmentEnabled(${componentName})`;

    hoistNonReactStatics(HOC, BaseComponent);

    return HOC;
  };

export default withSessionsV2EnrollmentEnabled;
