/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Theme } from '@coursera/cds-core';
import { Typography2 } from '@coursera/cds-core';
import { Col, Row } from '@coursera/coursera-ui';

import ExpertiseTooltip from 'bundles/enterprise-legacy-xdp/components/ExpertiseTooltip';
import Instructor from 'bundles/enterprise-legacy-xdp/components/instructors/cds/Instructor';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import { XDP_EXPERTISE_ICONS } from 'bundles/program-common/constants/xdpConstants';
import type { InstructorType } from 'bundles/program-common/types/xdpTypes';

import _t from 'i18n!nls/program-common';

const styles = {
  images: (theme: Theme) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(4)};
  `,

  instructorList: (theme: Theme) => css`
    a {
      &:focus {
        text-decoration: none;
        color: inherit;
      }

      &:hover {
        text-decoration: none;
        color: inherit;

        h2 {
          text-decoration: underline;
          color: ${theme.palette.blue[600]};
        }

        #instructor-title {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  `,
  instructorRating: (theme: Theme) => css`
    margin-right: ${theme.spacing(8)};
  `,
};

export type Props = {
  id: string;
  instructors: Array<InstructorType>;
  noLink?: boolean;
  averageInstructorRating?: number;
  instructorRatingCount?: number;
};

export const getTitle = (instructors: Array<InstructorType>): React.ReactElement<typeof FormattedMessage> => (
  <FormattedMessage
    message={_t('{instructorsCount, plural, =1 {Instructor} other {Instructors}}')}
    instructorsCount={instructors.length}
  />
);

export const shouldShowAvgInstructorRating = ({
  averageInstructorRating,
  instructorRatingCount,
}: Pick<Props, 'averageInstructorRating' | 'instructorRatingCount'>): boolean => {
  return !!averageInstructorRating && !!instructorRatingCount;
};

const InstructorList = ({ id, instructors, noLink, averageInstructorRating, instructorRatingCount }: Props) => {
  const showAvgInstructorRating = shouldShowAvgInstructorRating({
    averageInstructorRating,
    instructorRatingCount,
  });

  return (
    <TrackedDiv
      trackingName="social_proof_instructors"
      data={{ id }}
      requireFullyVisible={true}
      withVisibilityTracking={true}
      css={styles.instructorList}
    >
      {showAvgInstructorRating && (
        <Row>
          <Col>
            <TrackedDiv withVisibilityTracking trackClicks trackMouseEnters trackingName="instructor_rating_tooltip">
              <ExpertiseTooltip
                id="instructor-rating-tooltip"
                text={_t(
                  'We asked all learners to give feedback on our instructors based on the quality of their teaching style.'
                )}
              >
                <span>
                  <Typography2
                    color="supportText"
                    variant="subtitleMedium"
                    component="span"
                    css={styles.instructorRating}
                  >
                    {_t('Instructor rating')}
                  </Typography2>
                  <div css={styles.images}>
                    <LazyImgix src={XDP_EXPERTISE_ICONS.instructor} alt="" maxWidth={14} maxHeight={14} />
                  </div>{' '}
                  <Typography2
                    component="span"
                    color="supportText"
                    variant="subtitleMedium"
                    className="avg-instructor-rating__total"
                  >
                    <FormattedMessage
                      message={_t('{avgInstructorRating, number}/5')}
                      avgInstructorRating={averageInstructorRating}
                    />
                  </Typography2>
                  &nbsp;
                  <Typography2
                    component="span"
                    color="supportText"
                    variant="subtitleMedium"
                    className="avg-instructor-rating__ratings-count"
                  >
                    <FormattedMessage
                      message={_t('({numInstructorRatings, number} Ratings)')}
                      numInstructorRatings={instructorRatingCount}
                    />
                  </Typography2>
                </span>
              </ExpertiseTooltip>
            </TrackedDiv>
          </Col>
        </Row>
      )}
      <Row>
        {instructors.map(
          ({
            id: instructorId,
            title,
            fullName,
            photo,
            shortName,
            department,
            learnersReached,
            coursesTaught,
            isTopInstructor,
          }) => (
            <Instructor
              key={instructorId}
              id={instructorId}
              title={title}
              fullName={fullName}
              photo={photo}
              shortName={shortName}
              department={department}
              learnersReached={learnersReached}
              coursesTaught={coursesTaught}
              isTopInstructor={isTopInstructor}
              noLink={noLink}
            />
          )
        )}
      </Row>
    </TrackedDiv>
  );
};

export default InstructorList;
