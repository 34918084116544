/** @jsx jsx */
import { jsx } from '@emotion/react';

import { FormattedMessage } from 'react-intl';

import { compose, getDisplayName, setDisplayName } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';

import type { Theme } from '@coursera/cds-core';
import { Button, Typography, Typography2, useTheme } from '@coursera/cds-core';

import type { ApiState as ApiStatus } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import { useRouterParams } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import type { NaptimeError } from 'bundles/naptimejs';
import type ProgramMembershipsV2Type from 'bundles/naptimejs/resources/programMemberships.v2';
import { EnrollmentChoiceModalBody } from 'bundles/program-common/components/EnrollmentChoiceModal';
import EnrollmentChoiceModalProductDescription from 'bundles/program-common/components/EnrollmentChoiceModalProductDescription';

import _t from 'i18n!nls/program-home';

const styles = {
  container: (theme: Theme) => ({
    margin: theme.spacing(32, 48, 0, 48),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(24, 16, 8, 16),
    },
  }),
  bodyText: (theme: Theme) => ({
    marginTop: theme.spacing(24),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(16),
    },
  }),
  strong: {
    '&&': { fontWeight: 'bold' as const },
  },
  buttonContainer: (theme: Theme) => ({
    margin: -8,
    [theme.breakpoints.down('xs')]: {
      display: 'flex' as const,
      flexFlow: 'column nowrap' as const,
    },
  }),
  button: (theme: Theme) => ({
    margin: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      '&&': { maxWidth: 'initial' as const },
    },
  }),
};

type PropsFromCaller = {
  onClose: () => void;
  programName: string;
  onResendEmail: () => void;
  onCheckVerified: () => void;
  apiStatus?: ApiStatus;
  error?: NaptimeError | null;
  programMembership: ProgramMembershipsV2Type;
};

type PropsFromRouter = {
  hasProductContext?: boolean;
};

type Props = PropsFromCaller & PropsFromRouter;

export const ProgramEmailVerifyModal = ({
  programName,
  onResendEmail,
  onCheckVerified,
  apiStatus,
  error,
  onClose,
  programMembership,
  hasProductContext,
}: Props) => {
  const theme = useTheme();
  const emailAddress = programMembership.externalUserData.definition.email;
  const busy = apiStatus === 'API_IN_PROGRESS' || apiStatus === 'API_BEFORE_SEND';
  return (
    <div className="ProgramEmailVerifyModal">
      <EnrollmentChoiceModalBody
        headerTitle={_t('Verify your email address')}
        button={
          <div css={styles.buttonContainer(theme)}>
            <Button variant="primary" css={styles.button(theme)} onClick={onResendEmail} disabled={busy}>
              {_t('Resend verification email')}
            </Button>
            <Button variant="secondary" css={styles.button(theme)} onClick={onCheckVerified} disabled={busy}>
              {_t("Yes, I'm verified")}
            </Button>
            <Button variant="ghost" css={styles.button(theme)} onClick={onClose}>
              {_t('Dismiss')}
            </Button>
          </div>
        }
      >
        <EnrollmentChoiceModalProductDescription />
        <div css={styles.container(theme)}>
          <Typography variant="h2" component="h3" css={styles.bodyText(theme)}>
            {_t('Verify email to join the learning program')}
          </Typography>
          <Typography2 component="p" css={styles.bodyText(theme)}>
            <FormattedMessage
              message={
                hasProductContext
                  ? _t(
                      'You must join {programName} to enroll through your organization. Please verify your email address first.'
                    )
                  : _t('You must verify your email address before you can join {programName}.')
              }
              programName={
                <Typography2 component="strong" css={styles.strong}>
                  {programName}
                </Typography2>
              }
            />
          </Typography2>
          <Typography2 component="p" css={styles.bodyText(theme)}>
            <FormattedMessage
              message={_t(
                'A verification email has been sent to {emailAddress}. After verification, please refresh or revisit this link to join the program.'
              )}
              emailAddress={
                <Typography2 component="strong" css={styles.strong}>
                  {emailAddress}
                </Typography2>
              }
            />
          </Typography2>
          {error && (
            <Typography2 component="p" color="error" css={styles.bodyText(theme)}>
              {_t('An error occurred, please try again later.')}
            </Typography2>
          )}
        </div>
      </EnrollmentChoiceModalBody>
    </div>
  );
};

const enhance = compose<Props, PropsFromCaller>(
  setDisplayName(getDisplayName(ProgramEmailVerifyModal)),
  connectToRouter((router) => ({ hasProductContext: useRouterParams(router) != null }))
);

export default enhance(ProgramEmailVerifyModal);
