import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { StyleSheet, breakPoint, css } from '@coursera/coursera-ui';

import withMiniModal from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import type { PropsForInjectedComponent } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const CONFIG = {
  imgParams: {
    fit: 'clamp',
    auto: 'format',
    dpr: 1,
  },
  imageSize: 100,
};

const styles = StyleSheet.create({
  CourseS12nPhoto: {
    width: 100,
    height: 100,
  },
  hiddenMdDown: {
    [`@media (max-width: ${breakPoint.md}px)`]: {
      display: 'none',
    },
  },
});

type Props = {
  slug: string;
  id?: string;
  imageSrc?: string;
  imgixClassName?: string;
  isS12n?: boolean;
  title: string | undefined;
} & Partial<PropsForInjectedComponent>;

const CourseS12nPhoto = ({
  id,
  imageSrc,
  imgixClassName = 'course-logo',
  title = Imgix.DECORATIVE,
  // Pass optional withMiniModal inline to avoid double interactive divs which confuse the screen reader.
  onClick,
  onKeyDown,
  role = 'presentation',
  tabIndex,
}: Props): JSX.Element => {
  return (
    <TrackedDiv
      trackingName="course_thumbnail"
      data={{ courseId: id }}
      trackClicks={true}
      {...css('m-r-1', styles.CourseS12nPhoto, styles.hiddenMdDown)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      <Imgix
        className={imgixClassName}
        maxWidth={CONFIG.imageSize}
        maxHeight={CONFIG.imageSize}
        imgParams={CONFIG.imgParams}
        src={imageSrc || 'images/teach-s12n/empty_image_placeholder.png'}
        alt={title}
      />
    </TrackedDiv>
  );
};

export default CourseS12nPhoto;
export const CourseS12nPhotoWithModal = withMiniModal<Props>()(CourseS12nPhoto);
