import * as React from 'react';

import cx from 'classnames';

import { isRenderingRightToLeft } from 'js/lib/language';

import { ChevronNextIcon, ChevronPreviousIcon } from '@coursera/cds-icons';

import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/Scroller';

type PropsForScroller = {
  className: string;
  trackingName: string;
  children: React.ReactNode;
};

type StateForScroller = {
  canScrollLeft: boolean;
  canScrollRight: boolean;
  scrollLeftX: number;
  scrollRightX: number;
};

export class Scroller extends React.Component<PropsForScroller, StateForScroller> {
  container: HTMLDivElement | null;

  scrollRightButton: HTMLButtonElement | null;

  scrollLeftButton: HTMLButtonElement | null;

  rightLastItem: HTMLLIElement | null;

  rightLastFocus: HTMLLinkElement | HTMLButtonElement | null;

  rightNextItem: HTMLLIElement | null;

  rightNextFocus: HTMLLinkElement | HTMLButtonElement | null;

  leftLastItem: HTMLLIElement | null;

  leftLastFocus: HTMLLinkElement | HTMLButtonElement | null;

  leftNextItem: HTMLLIElement | null;

  leftNextFocus: HTMLLinkElement | HTMLButtonElement | null;

  updateFrame: number;

  isRTL: boolean;

  state = {
    canScrollLeft: false,
    canScrollRight: false,
    scrollLeftX: 0,
    scrollRightX: 0,
  };

  constructor(props: PropsForScroller) {
    super(props);
    this.container = null;
    this.updateFrame = 0;
    this.isRTL = isRenderingRightToLeft();
    this.scrollRightButton = null;
    this.scrollLeftButton = null;
    this.rightLastItem = null;
    this.rightNextItem = null;
    this.leftLastItem = null;
    this.leftNextItem = null;
    this.rightLastFocus = null;
    this.leftLastFocus = null;
    this.rightNextFocus = null;
    this.leftNextFocus = null;
  }

  componentDidMount() {
    this.requestScrollUpdate();
    window.addEventListener('resize', this.handleScroll);
    this.getListItems();
    this.leftLastItem?.addEventListener('keydown', (event: KeyboardEvent) => this.leftKeyboardNavigate(event));
    this.rightLastItem?.addEventListener('keydown', (event: KeyboardEvent) => this.rightKeyboardNavigate(event));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScroll);
    this.leftLastItem?.removeEventListener('keydown', (event: KeyboardEvent) => this.leftKeyboardNavigate(event));
    this.rightLastItem?.removeEventListener('keydown', (event: KeyboardEvent) => this.rightKeyboardNavigate(event));
    this.cancelScrollUpdate();
  }

  getListItems() {
    let total: number = 0;
    if (this.container) {
      const { clientWidth } = this.container;

      const listItems = this.container.querySelectorAll('.HorizontalDomainsMenu-DomainListItem');
      for (let i: number = 0; i < listItems.length - 1; i += 1) {
        if (total + listItems[i].clientWidth > clientWidth) {
          this.rightLastItem = listItems[i - 1] as HTMLLIElement;
          this.leftLastItem = listItems[listItems.length - i] as HTMLLIElement;
          this.rightNextItem = listItems[i] as HTMLLIElement;
          this.leftNextItem = listItems[listItems.length - i - 1] as HTMLLIElement;

          // This prevent double focus when navigating with the tab key
          this.getFocusItem(this.rightLastItem, 'rightLast');
          this.getFocusItem(this.leftLastItem, 'leftLast');
          this.getFocusItem(this.rightNextItem, 'rightNext');
          this.getFocusItem(this.leftNextItem, 'leftNext');
        } else {
          total += listItems[i].clientWidth;
        }
      }
    }
  }

  getFocusItem(listItem: HTMLLIElement, focusKey: string) {
    const firstChild = listItem.firstChild as HTMLElement;
    if (firstChild.tagName.toLocaleLowerCase() === 'a') {
      switch (focusKey) {
        case 'rightLast':
          this.rightLastFocus = firstChild as HTMLLinkElement;
          break;
        case 'leftLast':
          this.leftLastFocus = firstChild as HTMLLinkElement;
          break;
        case 'leftNext':
          this.leftNextFocus = firstChild as HTMLLinkElement;
          break;

        case 'rightNext':
          this.rightNextFocus = firstChild as HTMLLinkElement;
          break;

        default:
          break;
      }
    } else {
      switch (focusKey) {
        case 'rightLast':
          this.rightLastFocus = firstChild.firstChild as HTMLButtonElement;
          break;
        case 'leftLast':
          this.leftLastFocus = firstChild.firstChild as HTMLButtonElement;
          break;
        case 'leftNext':
          this.leftNextFocus = firstChild.firstChild as HTMLButtonElement;
          break;

        case 'rightNext':
          this.rightNextFocus = firstChild.firstChild as HTMLButtonElement;
          break;

        default:
          break;
      }
    }
  }

  leftKeyboardNavigate(event: KeyboardEvent) {
    if (event.shiftKey && event.key === 'Tab' && this.state.canScrollLeft) {
      event.preventDefault();
      this.scrollLeftButton?.focus();
    }
  }

  rightKeyboardNavigate(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.state.canScrollRight && !event.shiftKey) {
      event.preventDefault();
      this.scrollRightButton?.focus();
    }
  }

  requestScrollUpdate() {
    if (!this.updateFrame) {
      this.updateFrame = requestAnimationFrame(this.scrollUpdate);
    }
  }

  scrollUpdate = () => {
    this.cancelScrollUpdate();

    if (!this.container) {
      return;
    }

    // Precision issues require that we have some tolerance around the "end of scroll" dead zone.
    const ε = 5;
    // In RTL, scrolling is reversed, and scrollLeft is reflected about 0.
    const dir = this.isRTL ? -1 : +1;
    // We don't want to scroll a full screen because you lose visual context.
    const amount = 4 / 5;
    const { scrollLeft, scrollWidth, clientWidth } = this.container;
    this.setState({
      canScrollLeft: scrollLeft * dir > ε,
      canScrollRight: scrollWidth - clientWidth - scrollLeft * dir > ε,
      scrollLeftX: Math.trunc(-clientWidth * dir * amount),
      scrollRightX: Math.trunc(+clientWidth * dir * amount),
    });
  };

  cancelScrollUpdate() {
    if (this.updateFrame) {
      cancelAnimationFrame(this.updateFrame);
      this.updateFrame = 0;
    }
  }

  handleRef = (ref: HTMLDivElement | null) => {
    this.container = ref;
  };

  handleScroll = () => {
    this.requestScrollUpdate();
  };

  scrollBy(left: number) {
    if (this.container == null) {
      return;
    }

    if ('scrollBehavior' in document.documentElement.style) {
      this.container.scrollBy({ left, behavior: 'smooth' });
    } else if ('scrollBy' in Element.prototype) {
      // Safari does not support the ScrollToOptions parameter.
      this.container.scrollBy(left, 0);
    } else {
      // IE and Edge do not support Element#scrollBy
      this.container.scrollLeft += left;
    }
  }

  handleScrollLeft = () => {
    this.scrollBy(this.state.scrollLeftX);
  };

  handleScrollLeftKeyboard = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      this.handleScrollLeft();
      event.preventDefault();
      if (this.leftNextFocus) this.leftNextFocus.tabIndex = 0;
      this.leftNextFocus?.focus();
    } else if (!event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      if (this.leftLastFocus) this.leftLastFocus.tabIndex = 0;
      this.leftLastFocus?.focus();
    }
  };

  handleScrollRight = () => {
    this.scrollBy(this.state.scrollRightX);
  };

  handleScrollRightKeyboard = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      this.handleScrollRight();
      event.preventDefault();
      if (this.rightNextFocus) this.rightNextFocus.tabIndex = 0;
      this.rightNextFocus?.focus();
    } else if (event.shiftKey && event.key === 'Tab' && this.state.canScrollRight) {
      event.preventDefault();
      if (this.rightLastFocus) this.rightLastFocus.tabIndex = 0;
      this.rightLastFocus?.focus();
    }
  };

  render() {
    const { className, children, trackingName } = this.props;
    const ScrollLeftIcon = this.isRTL ? ChevronNextIcon : ChevronPreviousIcon;
    const ScrollRightIcon = this.isRTL ? ChevronPreviousIcon : ChevronNextIcon;
    return (
      <div className={cx('Scroller', className)}>
        {this.state.canScrollLeft && <div className="Scroller-ShimLeft" role="presentation" />}
        {this.state.canScrollLeft && (
          <TrackedButton
            className="Scroller-ScrollLeft"
            title={_t('Scroll left')}
            aria-label={_t('Scroll left')}
            tabIndex={0}
            onClick={this.handleScrollLeft}
            trackingName={`${trackingName}_scroll_left_button`}
            onSetRef={(buttonRef) => {
              this.scrollLeftButton = buttonRef;
            }}
            onKeyDown={this.handleScrollLeftKeyboard}
          >
            <ScrollLeftIcon size="small" title={_t('Scroll left')} />
          </TrackedButton>
        )}
        <div className="Scroller-Container" ref={this.handleRef} onScroll={this.handleScroll}>
          {children}
        </div>
        {this.state.canScrollRight && <div className="Scroller-ShimRight" role="presentation" />}
        {this.state.canScrollRight && (
          <TrackedButton
            className="Scroller-ScrollRight"
            title={_t('Scroll right')}
            aria-label={_t('Scroll right')}
            tabIndex={0}
            onClick={this.handleScrollRight}
            trackingName={`${trackingName}_scroll_right_button`}
            onSetRef={(buttonRef) => {
              this.scrollRightButton = buttonRef;
            }}
            onKeyDown={this.handleScrollRightKeyboard}
          >
            <ScrollRightIcon size="small" title={_t('Scroll right')} />
          </TrackedButton>
        )}
      </div>
    );
  }
}
