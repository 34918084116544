/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router';

import type { ApolloQueryResult } from 'apollo-client';

import { Link, Typography2, useTheme } from '@coursera/cds-core';
import type { LinkProps } from '@coursera/cds-core';
import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import { toProgramHomeTab } from 'bundles/program-common/links';
import ProgramEULABanner from 'bundles/program-home/components/ProgramEULABanner';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import ProgramSSOBanner from 'bundles/program-home/components/ProgramSSOBanner';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import MergedEnrolledProductsList from 'bundles/program-home/components/available/MergedEnrolledProductsList';
import type { OutputProps as PropsFromWithProgramsStartingLater } from 'bundles/program-home/components/available/withProgramsStartingLater';
import SavedCoursesContainer from 'bundles/program-home/components/enterprise-home/SavedCoursesContainer';
import { withMyCoursesContainer } from 'bundles/program-home/components/enterprise-home/withMyCoursesContainer';
import SingleProgramBrowseCTA from 'bundles/program-home/components/single-program/SingleProgramBrowseCTA';
import { TabName } from 'bundles/program-home/components/single-program/SingleProgramTabs';
import { SingleProgramMyCoursesWarningBanner } from 'bundles/program-home/components/single-program/SingleProgramWarningBanners';
import type { OnProductCardClick, ProductStub } from 'bundles/program-home/types/Products';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/MyCoursesContainer';

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Link
);

export type PropsFromCaller = PropsFromSaveSkillSetToggle & {
  programId: string;
  programSlug: string;
  programName: string;
  userId: number;
  onProductCardClick: OnProductCardClick;
  userSkillProfileStates?: UserSkillProfileStateType[];
  userSkillProfileStatesLoading?: boolean;
  toggleCatalogTab: () => void;
  userEmails: UserEmail[] | undefined;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  shouldShowEulaBanner: boolean;
  isSummaryView: boolean;
  enableCurriculumBuilder?: boolean;
  isCareerAcademyLimitedCatalogProgram?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  enableSkillsDashboard?: boolean;
  shouldShowSavedCourses?: boolean;
};

export type PropsFromNaptime = PropsFromCaller & {
  products: Array<ProductStub>;
  unfilteredOrderedPresentProducts: Array<ProductStub>;
  unfilteredOrderedFutureProducts: Array<ProductStub>;
  completedProducts: Array<ProductStub>;
  inProgressEnrolledProducts: Array<ProductStub>;
  loadingCourses?: boolean;
  loadingS12ns?: boolean;
};

export type PropsFromEnterpriseBadges = {
  enterpriseBadgeCollections: Array<EnterpriseBadge> | undefined;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
  enrolledBadgeCollections: Array<EnterpriseBadge> | undefined;
};

export type Props = PropsFromCaller &
  PropsFromWithProgramsStartingLater &
  PropsFromNaptime & {
    hasNoEnrolledItems: boolean;
  } & PropsFromEnterpriseBadges;

export function MyCoursesContainer({
  programId,
  programSlug,
  programName,
  userId,
  onProductCardClick,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  onSkillSetSaveToggle,
  userEmails,
  hasNoEnrolledItems,
  toggleCatalogTab,
  thirdPartyOrganization,
  shouldShowEulaBanner,
  unfilteredOrderedPresentProducts = [],
  unfilteredOrderedFutureProducts = [],
  isSummaryView,
  enableCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  isCareerAcademyLimitedCatalogProgram,
  enterpriseBadgeCollections,
  refetchEnterpriseBadgeCollections,
  shouldShowSavedCourses = true,
}: Props) {
  const theme = useTheme();
  const styles = {
    banner: css`
      margin-bottom: ${theme.spacing(24)};
    `,
    cta: css`
      margin-top: ${theme.spacing(32)};
      margin-bottom: ${theme.spacing(32)};
    `,
  };

  const productList = (
    <MergedEnrolledProductsList
      enrolledProducts={unfilteredOrderedPresentProducts}
      enrolledFutureProducts={unfilteredOrderedFutureProducts}
      enterpriseBadgeCollections={enterpriseBadgeCollections}
      programId={programId}
      programSlug={programSlug}
      programName={programName}
      userId={userId}
      userSkillProfileStates={userSkillProfileStates}
      userSkillProfileStatesLoading={userSkillProfileStatesLoading}
      onSkillSetSaveToggle={onSkillSetSaveToggle}
      thirdPartyOrganizationId={thirdPartyOrganization.id}
      refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
    />
  );

  if (isSummaryView) {
    return (
      <div className="rc-MyCoursesContainer">
        {hasNoEnrolledItems ? (
          <Typography2 component="p" variant="bodyPrimary">
            {_t('There is no course activity in this learning program.')}{' '}
            <TrackedLink
              variant="quiet"
              component={ReactRouterLink}
              trackingName="my_courses_summary_no_content_browse_link"
              to={toProgramHomeTab(programSlug, TabName.Catalog)}
            >
              {_t('Browse program catalog.')}
            </TrackedLink>
          </Typography2>
        ) : (
          productList
        )}
        {shouldShowSavedCourses && (
          <SavedCoursesContainer programId={programId} userId={userId} onProductCardClick={onProductCardClick} />
        )}
      </div>
    );
  }

  return (
    <div className="rc-MyCoursesContainer">
      {shouldShowEulaBanner && (
        <ProgramEULABanner
          thirdPartyOrganization={thirdPartyOrganization}
          programId={programId}
          userId={userId}
          css={styles.banner}
        />
      )}
      {userEmails && (
        <ProgramSSOBanner
          userEmails={userEmails}
          thirdPartyOrganizationId={thirdPartyOrganization.id}
          css={styles.banner}
        />
      )}
      {hasNoEnrolledItems && !isCareerAcademyLimitedCatalogProgram ? (
        <SingleProgramBrowseCTA css={styles.cta} toggleCatalogTab={toggleCatalogTab} />
      ) : (
        productList
      )}
      {shouldShowSavedCourses && (
        <SavedCoursesContainer
          programId={programId}
          userId={userId}
          onProductCardClick={onProductCardClick}
          enableCurriculumBuilder={enableCurriculumBuilder}
          allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
        />
      )}
      {Boolean(userId) && (
        <SingleProgramMyCoursesWarningBanner
          userId={userId}
          thirdPartyOrganizationId={thirdPartyOrganization.id}
          thirdPartyOrgSlug={thirdPartyOrganization.slug}
        />
      )}
    </div>
  );
}

export default withMyCoursesContainer(MyCoursesContainer);
