/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';

import Imgix from 'js/components/Imgix';

import { Hidden, LoadingSection, Typography2, typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { CheckIcon } from '@coursera/cds-icons';

import { getFeatureTableCopy } from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/HeroAndCompareAllPlansCopy';
import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus/contentfulData/LandingPageDataProvider';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import usePageData from 'bundles/enroll/data/usePageData';

import _t from 'i18n!nls/coursera-plus';

export type ComparisonProduct = 'LITE' | 'PLUS' | 'PLUS_ANNUAL';

const styles = {
  cell: (theme: Theme) => css`
    border: 1px solid lightgray;
    min-height: 60px;
    height: 60px;
    ${theme.breakpoints.down('sm')} {
      min-height: none;
      height: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      border: none;
      padding: ${theme.spacing(0, 8)};
    }

    label {
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(8, 32)};
      }
      ${theme.breakpoints.only('sm')} {
        padding: ${theme.spacing(8, 24)};
        flex-direction: column;
      }
      ${theme.breakpoints.down('xs')} {
        padding: ${theme.spacing(8, 0)};
        flex-direction: column;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 0;
    }
  `,
  bodyCell: (theme: Theme) => css`
    label {
      font-weight: normal !important;
    }
    ${theme.breakpoints.down('xs')} {
      ${typography2.bodySecondary};
      text-align: center;
    }
  `,
  feature: (theme: Theme) => css`
    ${typography2.subtitleMedium};
    text-align: left;

    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(8, 32)};
    }
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(8, 0)};
    }
  `,
  activeColumn: (theme: Theme, colIdx: number) => css`
    thead {
      td:nth-child(${colIdx}) {
        border-top: 1px solid ${theme.palette.blue[600]};
      }
    }

    tbody tr:last-child {
      td:nth-child(${colIdx}) {
        border-bottom: 1px solid ${theme.palette.blue[600]};
      }
    }
    td:nth-child(${colIdx}) {
      border-left: 1px solid ${theme.palette.blue[600]};
      border-right: 1px solid ${theme.palette.blue[600]};
      background-color: ${theme.palette.blue[100]};
    }

    td:nth-child(${colIdx - 1}) {
      border-right: 1px solid ${theme.palette.blue[600]};
    }
  `,
  table: (theme: Theme, selected: ComparisonProduct, isFullWidth: boolean) => css`
    border-collapse: collapse;
    height: 100%;
    ${isFullWidth && 'width: 100%;'}

    ${theme.breakpoints.down('xs')} {
      margin: auto;
    }

    ${selected === 'LITE' && styles.activeColumn(theme, 2)}
    ${selected === 'PLUS' && styles.activeColumn(theme, 3)}
    ${selected === 'PLUS_ANNUAL' && styles.activeColumn(theme, 4)}

    label {
      cursor: pointer;
    }
  `,
  header: (theme: Theme) => css`
    position: sticky;
    top: 0;
    background: white;

    td {
      height: 90px;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: middle;
      ${theme.breakpoints.down('xs')} {
        flex-direction: column;
      }
    }

    ${theme.breakpoints.down('xs')} {
      border-bottom: 1px solid ${theme.palette.gray[500]};
    }
  `,
  radio: (theme: Theme) => css`
    width: 24px;
    height: 24px;
    cursor: pointer;
    accent-color: ${theme.palette.blue[600]};
  `,
  titleWithTag: (theme: Theme) => css`
    display: flex;
    align-items: center;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    display: flex;
    font-size: 20px;
    font-weight: 600;

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `,
  headerLabel: (theme: Theme) => css`
    ${theme.breakpoints.up('md')} {
      margin-left: ${theme.spacing(12)};
    }
    ${theme.breakpoints.only('sm')} {
      text-align: center;
    }
  `,
  accessLabel: (theme: Theme) => css`
    ${theme.breakpoints.up('md')} {
      padding-left: 12px;
    }
  `,
  pill: (theme: Theme) => css`
    ${theme.breakpoints.up('sm')} {
      margin-left: ${theme.spacing(4)};
    }

    ${theme.breakpoints.down('xs')} {
      margin-top: ${theme.spacing(12)};
    }

    border-radius: 2px;
  `,
};

const LiteCell = ({ children }: { children: React.ReactNode }) => (
  <Typography2 component="td" css={[styles.cell, styles.bodyCell]} variant="bodyPrimary">
    <label htmlFor="lite-option">{children}</label>
  </Typography2>
);

const PlusCell = ({ children }: { children: React.ReactNode }) => (
  <Typography2 component="td" css={[styles.cell, styles.bodyCell]} variant="bodyPrimary">
    <label htmlFor="plus-option">{children}</label>
  </Typography2>
);

const PlusAnnualCell = ({ children }: { children: React.ReactNode }) => (
  <Typography2 component="td" css={[styles.cell, styles.bodyCell]} variant="bodyPrimary">
    <label htmlFor="plus-annual-option">{children}</label>
  </Typography2>
);

type PropsFromCaller = {
  onSelect: (comparisonProduct: ComparisonProduct) => void;
  isFullWidth?: boolean;
  defaultSelectedProduct?: ComparisonProduct;
};

type PropsFromContext = {
  courseraLiteProductPrice: SubscriptionTiersProductPrice;
  courseraPlusProductPrice: SubscriptionTiersProductPrice;
  courseraPlusAnnualProductPrice: SubscriptionTiersProductPrice;
};

type PropsToComponent = PropsFromCaller & PropsFromContext;

export const ComparisonTableComponent = ({
  courseraLiteProductPrice,
  courseraPlusProductPrice,
  courseraPlusAnnualProductPrice,
  defaultSelectedProduct = 'PLUS',
  onSelect,
  isFullWidth = false,
}: PropsToComponent) => {
  const [selected, setSelected] = useState<ComparisonProduct>(defaultSelectedProduct);
  const theme = useTheme();

  const onChange = (comparisonProduct: ComparisonProduct) => {
    setSelected(comparisonProduct);
    onSelect(comparisonProduct);
  };

  useEffect(() => {
    if (selected !== defaultSelectedProduct) {
      setSelected(defaultSelectedProduct);
    } else {
      onSelect(selected);
    }
  }, [defaultSelectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  const featureTableCopy = getFeatureTableCopy({
    courseraLiteProductPrice,
    courseraPlusAnnualProductPrice,
    courseraPlusProductPrice,
  });

  return (
    <table css={styles.table(theme, selected, isFullWidth)}>
      <thead css={styles.header}>
        <tr>
          <td>&nbsp;</td>
          <LiteCell>
            <input
              css={styles.radio}
              type="radio"
              id="lite-option"
              data-testid="lite-option"
              key="lite-option"
              name="fav_language"
              value="LITE"
              checked={selected === 'LITE'}
              onChange={() => onChange('LITE')}
            />
            <div css={styles.headerLabel}>
              <div css={styles.titleWithTag}>
                <Hidden xsDown>
                  <Imgix src={logo.LITE_FULL_BLACK_BLUE} maxHeight={25} alt={_t('Coursera Lite')} />
                </Hidden>
                <Hidden smUp>
                  <Imgix
                    css={styles.pill}
                    src={logo.LITE_PILL_BLUE}
                    maxWidth={37}
                    maxHeight={16}
                    alt={_t('Coursera Lite')}
                  />
                </Hidden>
              </div>
              <Typography2 component="p" variant="bodySecondary" css={styles.accessLabel}>
                {featureTableCopy.monthlyAccess}
              </Typography2>
            </div>
          </LiteCell>

          <PlusCell>
            <input
              css={styles.radio}
              type="radio"
              id="plus-option"
              data-testid="plus-option"
              key="plus-option"
              name="fav_language"
              value="PLUS"
              checked={selected === 'PLUS'}
              onChange={() => onChange('PLUS')}
            />
            <div css={styles.headerLabel}>
              <div css={styles.titleWithTag}>
                <Hidden xsDown>
                  <Imgix src={logo.PLUS_FULL_BLACK_BLUE} maxHeight={25} alt={_t('Coursera Plus')} />
                </Hidden>
                <Hidden smUp>
                  <Imgix
                    css={styles.pill}
                    src={logo.PLUS_PILL_BLUE}
                    maxWidth={42}
                    maxHeight={16}
                    alt={_t('Coursera Plus')}
                  />
                </Hidden>
              </div>
              <Typography2 component="p" variant="bodySecondary" css={styles.accessLabel}>
                {featureTableCopy.monthlyAccess}
              </Typography2>
            </div>
          </PlusCell>
          <PlusAnnualCell>
            <input
              css={styles.radio}
              type="radio"
              id="plus-annual-option"
              key="plus-annual-option"
              name="fav_language"
              value="PLUS_ANNUAL"
              checked={selected === 'PLUS_ANNUAL'}
              onChange={() => onChange('PLUS_ANNUAL')}
            />
            <div css={styles.headerLabel}>
              <div css={styles.titleWithTag}>
                <Hidden xsDown>
                  <Imgix src={logo.PLUS_FULL_BLACK_BLUE} maxHeight={25} alt={_t('Coursera Plus')} />
                </Hidden>
                <Hidden smUp>
                  <Imgix
                    css={styles.pill}
                    src={logo.PLUS_PILL_BLUE}
                    maxWidth={42}
                    maxHeight={16}
                    alt={_t('Coursera Plus')}
                  />
                </Hidden>
              </div>
              <Typography2 component="p" variant="bodySecondary" css={styles.accessLabel}>
                {featureTableCopy.oneYearAccess}
              </Typography2>
            </div>
          </PlusAnnualCell>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
            <div>{featureTableCopy.rowHeader1}</div>
          </Typography2>
          <LiteCell>
            <CheckIcon color="success" size="large" />
          </LiteCell>
          <PlusCell>
            <CheckIcon color="success" size="large" />
          </PlusCell>
          <PlusAnnualCell>
            <CheckIcon color="success" size="large" />
          </PlusAnnualCell>
        </tr>
        <tr>
          <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
            <div>{featureTableCopy.rowHeader2}</div>
          </Typography2>
          <LiteCell>—</LiteCell>
          <PlusCell>
            <CheckIcon color="success" size="large" />
          </PlusCell>
          <PlusAnnualCell>
            <CheckIcon color="success" size="large" />
          </PlusAnnualCell>
        </tr>
        <tr>
          <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
            <div>{featureTableCopy.rowHeader3}</div>
          </Typography2>
          <LiteCell>—</LiteCell>
          <PlusCell>
            <CheckIcon color="success" size="large" />
          </PlusCell>
          <PlusAnnualCell>
            <CheckIcon color="success" size="large" />
          </PlusAnnualCell>
        </tr>
        <tr>
          <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
            <div>{featureTableCopy.rowHeader4}</div>
          </Typography2>
          <LiteCell>—</LiteCell>
          <PlusCell>
            <CheckIcon color="success" size="large" />
          </PlusCell>
          <PlusAnnualCell>
            <CheckIcon color="success" size="large" />
          </PlusAnnualCell>
        </tr>
        {courseraLiteProductPrice && courseraPlusProductPrice && courseraPlusAnnualProductPrice && (
          <tr>
            <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
              <div>{featureTableCopy.rowHeader5}</div>
            </Typography2>
            <LiteCell>{featureTableCopy.row5Lite}</LiteCell>
            <PlusCell>{featureTableCopy.row5Plus}</PlusCell>
            <PlusAnnualCell>{featureTableCopy.row5Annually}</PlusAnnualCell>
          </tr>
        )}
        <tr>
          <Typography2 variant="subtitleMedium" component="td" css={[styles.cell, styles.feature]}>
            <div>{featureTableCopy.rowHeader6}</div>
          </Typography2>
          <LiteCell>{featureTableCopy.row6Monthly}</LiteCell>
          <PlusCell>{featureTableCopy.row6Monthly}</PlusCell>
          <PlusAnnualCell>{featureTableCopy.row6Annually}</PlusAnnualCell>
        </tr>
      </tbody>
    </table>
  );
};

const ComparisonTableContainer = ({ onSelect }: PropsFromCaller) => {
  const { courseraLiteProductPrice, courseraPlusProductPrice, courseraPlusAnnualProductPrice } = usePageData();
  if (courseraLiteProductPrice && courseraPlusProductPrice && courseraPlusAnnualProductPrice) {
    return (
      <ComparisonTableComponent
        onSelect={onSelect}
        courseraLiteProductPrice={courseraLiteProductPrice}
        courseraPlusProductPrice={courseraPlusProductPrice}
        courseraPlusAnnualProductPrice={courseraPlusAnnualProductPrice}
      />
    );
  }
  return <LoadingSection label={_t('Fetching data...')} />;
};

export default ComparisonTableContainer;
