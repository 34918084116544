import { filterUndefined } from 'bundles/common/utils/commonUtils';
import type { SearchProductHit, SearchResult } from 'bundles/search-common/providers/searchTypes';
import type { AutoCompleteHits, Hits, SuggestionHits } from 'bundles/search-common/types/autocomplete';
import { getEnterpriseAutocompleteSectionTitle } from 'bundles/search-common/utils/utils';

// helper type
type AutocompleteSection = Hits & { sectionTitle?: string | boolean; hits?: AutoCompleteHits };
type ReturnValue = AutocompleteSection[];
type SearchProductHitWithTracking = SearchProductHit & { indexName?: string | null };

type HitWithTracking = SearchProductHitWithTracking[];

const mapSearchSuggestionHits = (hits: HitWithTracking, indexPosition = 0): AutoCompleteHits => {
  return hits?.map(
    (hit, hitPosition: number): SuggestionHits => ({
      ...hit,
      objectID: hit.id || undefined,
      name: hit.name || undefined,
      partners: hit?.partners || undefined,
      hitPosition,
      indexName: hit.indexName || '',
      indexPosition,
      tagline: hit.tagline || '',
      objectUrl: hit?.url || '',
      trackingName: 'autocomplete_item',
      entityType: (hit.productType as string) || undefined,
      durationMs: hit.duration,
      // @ts-expect-error URL: string
      imageUrl: hit?.imageUrl,
    })
  );
};

const mapSearchSuggestionSection = (hits: HitWithTracking, indexPosition = 0): AutocompleteSection | undefined => {
  const rawHits = hits[0];
  if (!rawHits) return undefined;
  const suggestionHits = mapSearchSuggestionHits(hits, indexPosition);

  return {
    // the item below is just to comply with the weird typing of react-autosuggest
    ...suggestionHits[0],
    index: '',
    name: '',
    objectUrl: '',
    trackingName: '',
    indexName: '',
    image: { size: 16, ...suggestionHits[0].image },
    partners: suggestionHits[0].partners || [],
    tagline: suggestionHits[0].tagline || '',
    objectID: suggestionHits[0].objectID || '',
    hitPosition: suggestionHits[0].hitPosition || 0,
    indexPosition: suggestionHits[0].indexPosition || 0,
    // until this line
    sectionTitle: getEnterpriseAutocompleteSectionTitle(hits[0]?.productType as string),
    // If it's index used for direct match, we just show one result on this section
    hits: suggestionHits,
  };
};

const getSearchSuggestions = (
  courseS12nHits: SearchProductHitWithTracking[] = [],
  clipHits: SearchProductHitWithTracking[] = []
): ReturnValue => {
  // For each index, take the first n hits according to the config
  // Product spec is to show direct match if user typed more than 3 characters
  const clipSection = mapSearchSuggestionSection(clipHits, 0);
  const courseS12nSection = mapSearchSuggestionSection(courseS12nHits, 1);

  return [clipSection, courseS12nSection].filter(filterUndefined);
};

export function getEnterpriseAutoCompleteSections(
  clipsData?: SearchResult,
  courseS12nData?: SearchResult
): ReturnValue {
  const { elements, source } = courseS12nData || {};
  const indexName = source?.indexName;
  const courseS12nHits = (elements as SearchProductHit[])?.map((hit: SearchProductHit) => ({ ...hit, indexName }));

  const { elements: clipElements, source: clipsSource } = clipsData || {};
  const clipsIndexName = clipsSource?.indexName;
  const clipHits = (clipElements as SearchProductHit[])?.map((hit: SearchProductHit) => ({
    ...hit,
    indexName: clipsIndexName,
  }));

  return getSearchSuggestions(courseS12nHits, clipHits);
}
