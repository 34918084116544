import { processImage } from 'js/lib/imgix';

import type { ProductType } from '@coursera/event-pulse-types';

import { PRODUCT_CARD_IMAGE_DEFAULT_VALUES } from 'bundles/collections-carousel/constants';
import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import type { ProductCardImageFitProps } from 'bundles/product-card/components/legacy/ProductCardImage';
import type { ProductCardPartner } from 'bundles/product-card/components/types/ProductCardV2';
import { PRODUCT_CARD_V2_IMAGE_DEFAULT_VALUES } from 'bundles/product-card/constants';
import { LEARNING_PRODUCTS, getTranslatedProductName } from 'bundles/search-common/constants';

export const getPartnerNamesAndLogos = (
  partners: { id?: string; logo?: string; name?: string }[],
  cobrandingEnabled?: boolean,
  partnerLogos?: string[]
): ProductCardPartner[] => {
  const newPartnersArray = partners.map((partner, index) => {
    let logoUrl = partnerLogos ? partnerLogos[index] : '';
    if (GrowthDiscoveryEpicClient.get('productCardPartnerImgix')) {
      logoUrl = processImage(partnerLogos ? partnerLogos[index] : '', { width: 24, height: 24, dpr: 3 });
    }
    return { id: partner.id || '', name: partner.name || '', logoUrl };
  });
  return cobrandingEnabled ? newPartnersArray : newPartnersArray.splice(0, 1);
};

/* Set string to all lowercase and capitalize each word in string */
export const capitalizeEachWordInEntityType = (
  entityType?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]
) => {
  const modifiedEntityType = entityType
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

  return modifiedEntityType;
};

export const capitalizeAndTranslateEachWordInEntityType = (
  entityType?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]
) => getTranslatedProductName(capitalizeEachWordInEntityType(entityType));

export const extractEventId = (id: string | undefined | null, type?: ProductType, isSearch?: boolean) => {
  if (typeof id !== 'string') {
    return 'undefined';
  }
  if (type === 'clip' && isSearch) {
    // Clips in search are split with -
    const lastDash = id?.lastIndexOf('-');
    if (lastDash !== -1) {
      return id?.substring(lastDash + 1);
    }
  } else if (type === 'clip') {
    // clips id in collections are seperated by ~
    const lastTilde = id?.lastIndexOf('~');
    if (lastTilde !== -1) {
      return id?.substring(lastTilde + 1);
    }
  }
  const splitId = id.split('~');
  if (splitId.length > 1) {
    return splitId[1];
  }
  return id;
};

export const eventingV3ProductType = (type?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]) => {
  switch (type) {
    case LEARNING_PRODUCTS.Course:
    case LEARNING_PRODUCTS.GuidedProject:
    case LEARNING_PRODUCTS.Project:
      return 'course';
    case LEARNING_PRODUCTS.Video:
    case LEARNING_PRODUCTS.Lesson:
      return 'clip';
    case LEARNING_PRODUCTS.Specialization:
    case LEARNING_PRODUCTS.ProfessionalCertificate:
      return 's12n';
    case LEARNING_PRODUCTS.Degree:
    case LEARNING_PRODUCTS.PostgraduateDiploma:
      return 'degree';
    case LEARNING_PRODUCTS.UniversityCertificate:
    case LEARNING_PRODUCTS.Mastertrack:
    case LEARNING_PRODUCTS.GraduateCertificate:
      return 'premium_certificate';
    default:
      return undefined; // or some other default value as needed
  }
};

export type PartnerToImageFitMap = Record<
  string,
  Record<string, ProductCardImageFitProps> & { default: ProductCardImageFitProps }
>;
const PARTNER_IMAGE_FIT_MAP: PartnerToImageFitMap = {
  Google: {
    default: { ...PRODUCT_CARD_V2_IMAGE_DEFAULT_VALUES, crop: 'faces' },
    'GwG-DA-Cert-Image.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    '1200x600_certsResize_3-1-.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    '1200x600_certsResize_1.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    '1200x600_certsResize_1-1-.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    'Andrew-WA-Thompson-699-1-.jpg': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    'GwG-PM-Cert-Image.png': { ...PRODUCT_CARD_V2_IMAGE_DEFAULT_VALUES, crop: 'entropy' },
    '082421_Karrim0066-1.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
    '3_Kenefra.png': { ...PRODUCT_CARD_IMAGE_DEFAULT_VALUES },
  },
};

/**
 *
 * For some cases, for now, we need to modify the image props based on the partners and image name.
 *
 * This functionality is considered a "quick fix" for a longer standing issue that the Authoring Team will
 * address in Q3.
 */
export const getImagePropOverrides = (
  partners: ProductCardPartner[],
  imageUrl: string,
  imageFitMap = PARTNER_IMAGE_FIT_MAP
): ProductCardImageFitProps | undefined => {
  const relevantPartnerName = partners?.find((partner) => Object.keys(imageFitMap).includes(partner.name))?.name;
  if (!relevantPartnerName) return undefined;
  const fileName = imageUrl.split('/').pop() ?? '';
  return imageFitMap[relevantPartnerName][fileName] ?? imageFitMap[relevantPartnerName].default;
};
