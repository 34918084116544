import type constants from 'bundles/payments/common/constants';

import NaptimeResource from './NaptimeResource';

class PaymentWallets extends NaptimeResource {
  static RESOURCE_NAME = 'paymentWallets.v1';

  // These properties are always included.
  id!: number;

  userId!: number;

  // These properties must be requested.
  billingCountry?: string;

  billingZipcode?: string;

  creditCardExpiration?: number;

  creditCardLastFourDigits?: string;

  creditCardType?: string;

  email?: string;

  isValid?: boolean;

  paymentProcessorId?: keyof typeof constants.paymentMethods;

  static me(fields: Array<string> = []) {
    return this.finder('me', {
      params: { getLatest: true },
      fields,
    });
  }
}

export default PaymentWallets;
