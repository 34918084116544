import { createContext } from 'react';

export type StyleContextType = {
  pageless: boolean;
  editorNode: HTMLElement | null;
};

export const StyleContext = createContext<StyleContextType>({
  pageless: false,
  editorNode: null,
});
