/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import { Grid, Typography2, useTheme } from '@coursera/cds-core';
import type { IconProps } from '@coursera/cds-core';
import {
  BarChartOneFillIcon,
  BarChartThreeFillIcon,
  BarChartTwoFillIcon,
  CalendarIcon,
  CertificateIcon,
  ChevronNextIcon,
  ClockOutlineIcon,
  CommentDotsIcon,
  GlobeIcon,
  GradedAssignmentIcon,
  GraduationCapIcon,
  HonorsAssignmentIcon,
  InstitutionIcon,
  LayerIcon,
  MoneyIcon,
  StacksIcon,
} from '@coursera/cds-icons';

import type { S12nGlanceItem } from 'bundles/program-common/types/xdpTypes';

// Icons supported by this component
const ALL_ICONS: { [key: string]: React.ComponentType<IconProps> } = {
  BarChartOneFillIcon,
  BarChartTwoFillIcon,
  BarChartThreeFillIcon,
  CertificateIcon,
  GlobeIcon,
  MoneyIcon,
  ClockOutlineIcon,
  LayerIcon,
  InstitutionIcon,
  GradedAssignmentIcon,
  CommentDotsIcon,
  ChevronNextIcon,
  HonorsAssignmentIcon,
  CalendarIcon,
  StacksIcon,
  GraduationCapIcon,
};

const DEFAULT_ICON = ChevronNextIcon;

export type ProductGlanceList = Array<S12nGlanceItem>;

export type ProductGlanceProps = {
  dataList: ProductGlanceList;
  iconColor?: string;
  rootClassName?: string;
};

const GlanceIcon: React.SFC<{
  className?: string;
  iconColor?: string;
  Svg: React.ComponentType<IconProps>;
}> = ({ Svg }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.gray[500]};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      `}
    >
      <Svg size="medium" />
    </div>
  );
};

export default function ProductGlance({ dataList = [], rootClassName }: ProductGlanceProps) {
  const theme = useTheme();
  if (!dataList.length) {
    return null;
  }

  const dataListWithIcons = dataList.map((item) => ({
    ...item,
    IconTag: ALL_ICONS[item.icon] || DEFAULT_ICON,
  }));

  return (
    <div className={classNames('ProductGlance', rootClassName)}>
      {dataListWithIcons.map(({ title, subtitle, IconTag, hasBottomBorder }, index) => (
        <Grid
          container
          key={typeof title === 'string' ? title : index}
          css={
            hasBottomBorder &&
            css`
              border-bottom: solid 1px ${theme.palette.gray[300]};
            `
          }
        >
          <Grid item>
            <GlanceIcon
              Svg={IconTag}
              css={css`
                margin: ${theme.spacing(0, 16, 8, 8)};
              `}
            />
          </Grid>
          <Grid item>
            <Typography2
              component="div"
              variant="subtitleMedium"
              css={css`
                margin-bottom: 0;
                margin-top: ${!subtitle && theme.spacing(8)};
              `}
            >
              {title}
            </Typography2>
            {subtitle && (
              <Typography2 component="p" variant="bodySecondary" color="supportText">
                {subtitle}
              </Typography2>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
