import { useCallback, useMemo } from 'react';

import { debounce } from 'lodash';
import type { Descendant, Editor } from 'slate';

import logger from 'js/app/loggerSingleton';

import { isValidOperation } from 'bundles/cml/editor/utils/operationUtils';
import { slateToCml } from 'bundles/cml/editor/utils/slateToCML';
import { slateToMarkdown } from 'bundles/cml/editor/utils/slateToMarkdown';
import type { BlockElement } from 'bundles/cml/shared/types/elementTypes';

const useChangeHandler = (onChange: (value: string) => void, onMarkdownChange?: (value: string) => void) =>
  useMemo(
    () =>
      debounce((value: Descendant[]) => {
        const cmlValue = `<co-content>${slateToCml(value as BlockElement[])}</co-content>`;

        logger.info('[CMLEditor] final cml: ', cmlValue);

        if (onMarkdownChange) {
          const markdown = value.flatMap((node: Descendant, index: number) => {
            return slateToMarkdown([node as BlockElement, [index]]);
          });
          onMarkdownChange(markdown.join('\n'));
        }

        onChange(cmlValue);
      }, 500),
    [onChange, onMarkdownChange]
  );

export const useSlateToCML = (
  editor: Editor,
  initialCMLValue: string | undefined,
  onChange: (value: string) => void,
  onMarkdownChange?: (value: string) => void
) => {
  const handleChange = useChangeHandler(onChange, onMarkdownChange);

  return useCallback(
    (value: Descendant[]) => {
      const isValueChangeEvent = editor.operations.some(isValidOperation);
      if (isValueChangeEvent) {
        handleChange(value);
      }
    },
    [editor, handleChange]
  );
};
