/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { MuiSlide } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import MathEditorDialog from 'bundles/cml/editor/components/elements/math/MathEditorDialog';
import type { Props } from 'bundles/cml/editor/components/elements/math/MathEditorDialog';

const styles = {
  root: css`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: ${zIndex.modal};
  `,
};

const MathEditorSmall = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <MuiSlide direction="up" in>
      <div className="rc-CMLEditorMenu" css={styles.root}>
        <MathEditorDialog ref={ref} {...props} />
      </div>
    </MuiSlide>
  );
});

export default MathEditorSmall;
