import { graphql } from 'react-apollo';

import _ from 'lodash';
import { compose } from 'recompose';

import type {
  UserProductStateRecordsV1ResourceQuery as UserProductStateRecordsV1ResourceQueryData,
  UserProductStateRecordsV1ResourceQueryVariables,
} from 'bundles/campus-basic-contract-renewal/utils/__generated__/UserProductStateRecordsV1ResourceQuery';
import { org_coursera_program_enrollment_UserProductState as ProductState } from 'bundles/campus-basic-contract-renewal/utils/__generated__/globalTypes';
import {
  ELEVEN_MONTHS_MILLISECONDS,
  OCT_1_2020_MILLISECONDS,
  ONE_YEAR_MILLISECONDS,
  isAllFreemiumContracts,
} from 'bundles/campus-basic-contract-renewal/utils/helpers';
import { UserProductStateRecordsV1ResourceQuery } from 'bundles/campus-basic-contract-renewal/utils/queries';
import C4CExperiments from 'bundles/epic/clients/C4C';
import type { EnterpriseContractTagsV1 as EnterpriseContractTagsV1Type } from 'bundles/naptimejs/resources/__generated__/EnterpriseContractTagsV1';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import type { PropsFromWithState2 } from 'bundles/program-home/components/ProgramHomeApp';

type PropsForWithFreemiumCourseReenrollData = {
  userCredits?: { isLimited: boolean; coursesRemaining?: number };
  programContractTags?: EnterpriseContractTagsV1Type[] | null;
  userId: number;
  programId: string;
};

type Props = PropsForWithFreemiumCourseReenrollData & PropsFromWithState2;

export type PropsFromWithFreemiumCourseReenrollData = {
  freemiumEnrollmentFilteredCourses: CourseInfo[];
};

export type CourseInfo = {
  productItemId: string;
  programId: string;
  collectionId?: string;
};

const withFreemiumCourseReenrollData = compose<Props, PropsFromWithFreemiumCourseReenrollData>(
  graphql<
    Props,
    UserProductStateRecordsV1ResourceQueryData,
    UserProductStateRecordsV1ResourceQueryVariables,
    PropsFromWithFreemiumCourseReenrollData
  >(UserProductStateRecordsV1ResourceQuery, {
    skip: ({ userId, userCredits, programContractTags }) =>
      !C4CExperiments.get('enableContractRenewalLearnerModal') ||
      !userId ||
      !userCredits ||
      !userCredits.isLimited ||
      !userCredits.coursesRemaining ||
      userCredits.coursesRemaining > 1 ||
      !programContractTags ||
      !isAllFreemiumContracts(programContractTags),
    options: ({ userId, programId }) => ({
      variables: {
        userId: userId.toString(),
        excludeGloballyEnrolledCourses: true,
        programId,
      },
    }),
    props: ({ data }) => {
      const freemiumEnrollmentFilteredCourses: CourseInfo[] = [];

      const courses = filterExistsOrDefault(
        data?.UserProductStateRecordsV1Resource?.latestCourseDowngradesByUserAndProgram?.elements
      );

      const currentTime = Date.now();
      _.forEach(courses, (c) => {
        if (
          c.state !== ProductState.DOWNGRADED ||
          c.completionTimestamp ||
          c.stateTimestamp < currentTime - ELEVEN_MONTHS_MILLISECONDS ||
          c.stateTimestamp < OCT_1_2020_MILLISECONDS + ONE_YEAR_MILLISECONDS - 86400000 * 5
        ) {
          return;
        }

        freemiumEnrollmentFilteredCourses.push({
          productItemId: c.productItemId,
          programId: c.programId,
          collectionId: c.collectionId ? c.collectionId : undefined,
        });
      });

      return {
        freemiumEnrollmentFilteredCourses,
      };
    },
  })
);

export default withFreemiumCourseReenrollData;
