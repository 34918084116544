import { Editor, Element, Transforms } from 'slate';
import type { Node, NodeEntry, Path } from 'slate';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const getLinkText = (editor: Editor, path: Path) => {
  try {
    return Editor.string(editor, path);
  } catch {
    return '';
  }
};

const normalizeLinkTool = (editor: Editor, tools: Set<ToolsKeys>, [node, path]: NodeEntry<LinkElement>) => {
  if (tools.has(Tools.LINK)) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: path, match: (el) => Element.isElement(el) && el.type === node.type });
  return true;
};

const normalizeLinkText = (editor: Editor, [node, path]: NodeEntry<LinkElement>) => {
  const text = getLinkText(editor, path);
  if (text) {
    return false;
  }

  const { href } = node;
  if (!href) {
    Transforms.delete(editor, { at: path });
    return true;
  }

  if (node.children.length) {
    Transforms.insertText(editor, href, { at: path });
    return true;
  }

  Transforms.insertNodes(editor, { text: href }, { at: [...path, 0] });
  return true;
};

export const normalizeLinks = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  const [node] = nodeEntry;
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LINK) {
    return false;
  }

  const linkNodeEntry = nodeEntry as NodeEntry<LinkElement>;
  return normalizeLinkTool(editor, tools, linkNodeEntry) || normalizeLinkText(editor, linkNodeEntry);
};
