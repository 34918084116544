import * as React from 'react';
import { hashHistory } from 'react-router';

import PropTypes from 'prop-types';

import hoistNonReactStatics from 'js/lib/hoistNonReactStatics';
import user from 'js/lib/user';

import Button from 'bundles/coursera-ui/components/basic/Button';

/**
 * A simple HOC to add LoginModal if user is not already logged in
 */
const withLogin = (Component: $TSFixMe): $TSFixMe => {
  const componentName = Component.displayName || Component.name;

  class HOC extends React.Component {
    static displayName = `withLogin(${componentName})`;

    static propTypes = {
      loggedOutBtnAttributes: PropTypes.object.isRequired,
      // Can add additional params if needed, e.g. authProvider
      loginRouterParams: PropTypes.object,
      loginHashHistoryReplacement: PropTypes.string,
      // By default, we'll always render the component
      renderNothingIfLoggedOut: PropTypes.bool,
    };

    static contextTypes = {
      router: PropTypes.object.isRequired,
    };

    static defaultProps = {
      loggedOutBtnAttributes: {
        type: 'primary',
      },
      loginRouterParams: {},
    };

    handleClick = () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loginRouterParams' does not exist on typ... Remove this comment to see the full error message
      const { loginRouterParams, loginHashHistoryReplacement } = this.props;

      const router = this.context.router;
      const newQueryParams = {
        ...router.location.query,
        authMode: 'login',
        ...loginRouterParams,
      };
      router.push({
        pathname: router.location.pathname,
        params: router.params,
        query: newQueryParams,
      });
      if (loginHashHistoryReplacement) {
        hashHistory.replace(loginHashHistoryReplacement);
      }
    };

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'loggedOutBtnAttributes' does not exist o... Remove this comment to see the full error message
      const { loggedOutBtnAttributes, renderNothingIfLoggedOut, ...rest } = this.props;
      const isAuthenticated = user.get().authenticated;
      if (!isAuthenticated) {
        return renderNothingIfLoggedOut ? null : <Button {...loggedOutBtnAttributes} onClick={this.handleClick} />;
      }

      return <Component {...rest} />;
    }
  }

  hoistNonReactStatics(HOC, Component);
  return HOC;
};

export default withLogin;
