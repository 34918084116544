import { DescriptionPage_MarketingSource as MarketingSource } from '__generated__/graphql-types';

import routeConfig from 'bundles/unified-description-page-common/constants/routeConfig';
import getXDPPageLink from 'bundles/unified-description-page-common/utils/getXDPPageLink';

function getCourseLink({
  courseSlug,
  specializationSlug,
  programSlug,
  marketingSource,
  localizedLanguageRegionCode,
}: {
  courseSlug: string;
  specializationSlug?: string;
  programSlug?: string;
  marketingSource?: MarketingSource;
  localizedLanguageRegionCode?: string;
}): string {
  let path;
  if (programSlug) {
    path = `/programs/${programSlug}`;
  }

  path = `${path ?? ''}/${routeConfig.COURSE.pathname}/${courseSlug}`;

  if (specializationSlug && marketingSource === MarketingSource.PaidMedia) {
    path = `/enroll${path}/paidmedia?specialization=${specializationSlug}`;
  } else if (specializationSlug) {
    path = `${path}?specialization=${specializationSlug}`;
  }

  return getXDPPageLink({ productPageUrlPath: path, subfolderLocaleCode: localizedLanguageRegionCode });
}
export default getCourseLink;
