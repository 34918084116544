/* eslint-disable camelcase */
import { Specialization_ProductVariant } from '__generated__/graphql-types';

import cookie from 'js/lib/cookie';

import { isSubscriptionTiersOptedOut } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import googleCertificateExperiments from 'bundles/epic/clients/GoogleCertificate';
import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import {
  ExternalCertificateS12n,
  GoogleCertificateS12n,
  NormalS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';

import _t from 'i18n!nls/payments';

const COOKIE_KEY = 'payments.cartForCombineFreeTrialAndCheckout';

export const CHECKOUT_PARAM = 'combinedCheckoutPage';

export const generateCombineFreeTrialAndCheckoutImpression = () => {
  googleCertificateExperiments.get('combineFreeTrialAndCheckout');
};

export const isCombineFreeTrialAndCheckoutEligible = (enrollmentAvailableChoices?: EnrollmentChoices) => {
  return enrollmentAvailableChoices?.canEnrollThroughS12nSubscriptionFreeTrial && isSubscriptionTiersOptedOut();
};

export const isCombineFreeTrialAndCheckoutEnabled = () => {
  return googleCertificateExperiments.preview('combineFreeTrialAndCheckout') !== 'control';
};

export const setCombineFreeTrialAndCheckoutCartId = (cart: CartsV2) => {
  cookie.set(COOKIE_KEY, cart.id);
};

const getCombineFreeTrialAndCheckoutCartId = (): number | undefined => {
  const cartId = cookie.get(COOKIE_KEY);
  if (!cartId) {
    return undefined;
  }
  return Number(cartId);
};

const isValidCart = (cart: CartsV2, query: Record<string, string>) =>
  cart.isSpecializationSubscription &&
  cart.hasFreeTrial &&
  (getCombineFreeTrialAndCheckoutCartId() === cart.id || query[CHECKOUT_PARAM] === 'true');

export const isCombinedCheckoutPageShown = (cart: CartsV2, query: Record<string, string>) => {
  if (!isValidCart(cart, query)) {
    return false;
  }

  return isCombineFreeTrialAndCheckoutEnabled();
};

export const isSocialProofShown = () => {
  return googleCertificateExperiments.preview('combineFreeTrialAndCheckout') === '2';
};

export const formatS12nProductVariant = (variant?: Specialization_ProductVariant): S12nProductVariant | undefined => {
  switch (variant) {
    case Specialization_ProductVariant.ExternalCertificateS12N:
      return ExternalCertificateS12n;
    case Specialization_ProductVariant.GoogleCertificateS12N:
      return GoogleCertificateS12n;
    case Specialization_ProductVariant.NormalS12N:
      return NormalS12n;
    case Specialization_ProductVariant.ProfessionalCertificateS12N:
      return ProfessionalCertificateS12n;
    default:
      return undefined;
  }
};

export const getFreeTrialValueProps = ({
  s12nProductVariant,
  showBlockCertInFreeTrialCopy,
}: {
  s12nProductVariant?: S12nProductVariant;
  showBlockCertInFreeTrialCopy?: boolean;
}) => {
  let UNLIMITED_BULLET_HEADER;
  switch (s12nProductVariant) {
    case ProfessionalCertificateS12n:
      UNLIMITED_BULLET_HEADER = _t('Unlimited access to all courses in this Professional Certificate');
      break;
    case NormalS12n:
    default:
      UNLIMITED_BULLET_HEADER = _t('Unlimited access to all courses in this Specialization');
      break;
  }
  const UNLIMITED_BULLET = {
    header: UNLIMITED_BULLET_HEADER,
    subheader: _t('Watch lectures, try assignments, participate in discussion forums, and more.'),
  };

  const CANCEL_ANYTIME_BULLET = {
    header: _t('Cancel anytime.'),
    subheader: _t("No penalties - simply cancel before the trial ends if it's not right for you."),
  };

  const CERTIFICATE_BULLET = {
    header: showBlockCertInFreeTrialCopy
      ? _t('Certificate when you complete after your trial ends')
      : _t('Certificate when you complete.'),
    subheader: _t('Share on your resume, LinkedIn, and CV.'),
  };

  const FREE_TRIAL_VALUE_PROPS = [UNLIMITED_BULLET, CANCEL_ANYTIME_BULLET, CERTIFICATE_BULLET];

  return {
    FREE_TRIAL_VALUE_PROPS,
  };
};

export const cardFormStyleRules = {
  '.AccordionItem': {
    border: '1px solid #929599',
    borderRadius: '4px',
    fontFamily: '"Source Sans Pro", Arial, sans-serif',
    fontSize: '16px',
    fontWeight: '700',
    color: '#1f1f1f',
  },
  '.AccordionItem--selected': {
    color: '#2a73cc',
  },
  '.Input': { border: '1px solid rgba(0,0,0,0.255)', color: '#1f8354', borderRadius: '4px' },
  '.Label': {
    color: '#1f8354',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'capitalize',
    letterSpacing: '-0.048px',
    lineHeight: '20px',
  },
  '.Label--empty': { color: '#373a3c' },
  '.Label--invalid': { color: '#d30001' },
};
