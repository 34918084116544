/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';
import ImgixWithPrioritizedLoading from 'js/components/ImgixWithPrioritizedLoading';
import { isRightToLeft } from 'js/lib/language';

import {
  CardSection,
  CardText,
  CardV2,
  PartnerLogo,
  StyleSheet,
  breakPoint,
  color,
  css as cuiCss,
  gradient as gradientCUI,
  placeholder,
  transition,
} from '@coursera/coursera-ui';
import { CARD_SECTION_TYPE } from '@coursera/coursera-ui/lib/components/basic/Card/CardSection';
import { CARD_TEXT_TYPE } from '@coursera/coursera-ui/lib/components/basic/Card/CardText';
import { SvgVideo } from '@coursera/coursera-ui/svg';

import getBadgeConstants from 'bundles/common/constants/ProjectBadgeTitle';
import ProjectBadge from 'bundles/program-common/components/ProjectBadge';

import _t from 'i18n!nls/xdp';

type GradientProp = { start: string; end: string; deg: number };

export const PRODUCT_CARD_TYPE = {
  COURSE: 'course',
  DEGREE: 'degree',
  S12N: 's12n',
};

export const PRODUCT_CARD_SIZE = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
};

const LINE_HEIGHT = 24;
const CONTENT_CURATION_IMG_HEIGHT = 104;
const CONTENT_CURATION_LOGO_TOP_SPACING = 60;

type ConfigData = {
  titleSectionTopMargin: {
    withGradient: number;
    withoutGradient: number;
  };
  titleFontSize: number;
  partnerLogoTopSpacing: number;
  partnerLogoLeftSpacing: number;
  partnerLogoDimension: number;
  partnerLogoPadding: number;
  tagTopSpacing: number;
  cardActionsBottomSpacing: number;
  cardHeight: number;
  minWidth: number;
  imageHeight: {
    withGradient: number;
    withoutGradient: number;
  };
  gradientTop: number;
  gradientDeg: number;
  ribbonDeg: number;
  titleMaxLines: number;
  subtitleMaxLines: number;
  titleStyle: {
    marginBottom: number;
    fontSize?: number;
    lineHeight?: string;
    fontFamily?: string;
  };
  subtitleStyle: {
    lineHeight: string;
    display: string;
    fontSize?: number;
  };
};

const TALL_CONFIG: ConfigData = {
  titleSectionTopMargin: {
    withGradient: 0,
    withoutGradient: 32,
  },
  titleFontSize: 20,
  partnerLogoTopSpacing: 84,
  partnerLogoLeftSpacing: 24,
  partnerLogoDimension: 72,
  partnerLogoPadding: 8,
  tagTopSpacing: 120,
  cardActionsBottomSpacing: 4,
  cardHeight: 498,
  minWidth: 240,
  imageHeight: {
    withGradient: 160,
    withoutGradient: 128,
  },
  gradientTop: 128,
  gradientDeg: -10,
  ribbonDeg: -250,
  titleMaxLines: 3,
  subtitleMaxLines: 2,
  titleStyle: {
    marginBottom: 6,
  },
  subtitleStyle: {
    lineHeight: '18px',
    display: 'inline-block',
  },
};

const LG_CONFIG: ConfigData = {
  titleSectionTopMargin: {
    withGradient: 0,
    withoutGradient: 32,
  },
  titleFontSize: 20,
  partnerLogoTopSpacing: 84,
  partnerLogoLeftSpacing: 24,
  partnerLogoDimension: 72,
  partnerLogoPadding: 8,
  tagTopSpacing: 120,
  cardActionsBottomSpacing: 4,
  cardHeight: 384,
  minWidth: 240,
  imageHeight: {
    withGradient: 160,
    withoutGradient: 128,
  },
  gradientTop: 128,
  gradientDeg: -10,
  ribbonDeg: -250,
  titleMaxLines: 3,
  subtitleMaxLines: 2,
  titleStyle: {
    marginBottom: 6,
  },
  subtitleStyle: {
    lineHeight: '18px',
    display: 'inline-block',
  },
};

const MD_CONFIG: ConfigData = {
  titleSectionTopMargin: {
    withGradient: 0,
    withoutGradient: 14,
  },
  titleFontSize: 24,
  partnerLogoTopSpacing: 48,
  partnerLogoLeftSpacing: 24,
  partnerLogoDimension: 72,
  partnerLogoPadding: 8,
  tagTopSpacing: 72,
  cardActionsBottomSpacing: 4,
  cardHeight: 336,
  minWidth: 240,
  imageHeight: {
    withGradient: 120,
    withoutGradient: 108,
  },
  gradientTop: 84,
  gradientDeg: -10,
  ribbonDeg: -250,
  titleMaxLines: 3,
  subtitleMaxLines: 2,
  titleStyle: {
    marginBottom: 6,
  },
  subtitleStyle: {
    lineHeight: '20px',
    display: 'inline-block',
  },
};

const SM_CONFIG: ConfigData = {
  titleSectionTopMargin: {
    withGradient: 0,
    withoutGradient: 8,
  },
  titleFontSize: 18,
  partnerLogoTopSpacing: 28,
  partnerLogoLeftSpacing: 24,
  partnerLogoDimension: 60,
  partnerLogoPadding: 4,
  tagTopSpacing: 46,
  cardActionsBottomSpacing: 4,
  cardHeight: 252,
  minWidth: 216,
  imageHeight: {
    withGradient: 80,
    withoutGradient: 72,
  },
  gradientTop: 60,
  gradientDeg: -6,
  ribbonDeg: -250,
  titleMaxLines: 3,
  subtitleMaxLines: 2,
  titleStyle: {
    marginBottom: 4,
    fontSize: 18,
  },
  subtitleStyle: {
    fontSize: 12,
    lineHeight: '18px', // somehow this cannot be just a numeric value
    display: 'inline-block',
  },
};

const XS_CONFIG: ConfigData = {
  titleSectionTopMargin: {
    withGradient: 2,
    withoutGradient: 6,
  },
  titleFontSize: 18,
  partnerLogoTopSpacing: 18,
  partnerLogoLeftSpacing: 24,
  partnerLogoDimension: 60,
  partnerLogoPadding: 4,
  tagTopSpacing: 24,
  cardActionsBottomSpacing: 4,
  cardHeight: 186,
  minWidth: 216,
  imageHeight: {
    withGradient: 72,
    withoutGradient: 66,
  },
  gradientTop: 60,
  gradientDeg: 0, // the card is too short to have a meaningful slanted shape
  ribbonDeg: -250,
  titleMaxLines: 3,
  subtitleMaxLines: 2,
  titleStyle: {
    marginBottom: 6,
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: "'OpenSans-Semibold', Arial, sans-serif",
  },
  subtitleStyle: {
    fontSize: 12,
    lineHeight: '18px', // somehow this cannot be just a numeric value
    display: 'inline-block',
  },
};

const cardConfigMap = {
  xs: XS_CONFIG,
  sm: SM_CONFIG,
  md: MD_CONFIG,
  lg: LG_CONFIG,
  tall: TALL_CONFIG,
};

const placeholderStyles = StyleSheet.create({
  cardImage: {
    width: '100%',
    height: '100%',
    backgroundColor: color.darkGray,
  },
  titlePlaceholder: {
    maxWidth: 250,
    height: LINE_HEIGHT,
    backgroundColor: color.gray,
    animationDelay: `${placeholder.animationDuration / 4}s`,
  },
  subtitlePlaceholder: {
    maxWidth: 150,
    height: LINE_HEIGHT,
    backgroundColor: color.gray,
    animationDelay: `${placeholder.animationDuration / 4}s`,
  },
  numberOfCoursesPlaceholder: {
    width: 100,
    height: LINE_HEIGHT,
    backgroundColor: color.gray,
    animationDelay: `${placeholder.animationDuration / 4}s`,
  },
});

const styles = {
  badgeStyle: css`
    height: 19px;
    padding: 1px 7px;
  `,
  containerStyle: css`
    vertical-align: top;
    display: inline-block;
    margin-right: 0;
  `,
  containerStyleRtl: css`
    vertical-align: top;
    display: inline-block;
    margin-right: 9px;
  `,
  textStyle: css`
    font-size: 12px;
    line-height: 17px;
  `,
  clipIconWrapper: css`
    position: absolute;
    background-color: rgba(0 0 0 40%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

const CdsLabelText = {
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: 0,
};

function getImageHeight(configData: ConfigData, showGradient: boolean, isInContentCuration?: boolean) {
  if (showGradient) {
    return configData.imageHeight.withGradient;
  } else if (isInContentCuration) {
    return CONTENT_CURATION_IMG_HEIGHT;
  } else {
    return configData.imageHeight.withoutGradient;
  }
}

function getCardStyles(
  imageSrc: string,
  isThemeDark: boolean,
  showGradient: boolean,
  configData: ConfigData,
  isInContentCuration?: boolean
) {
  const isRtl = isRightToLeft(_t.getLocale());
  return StyleSheet.create({
    ProductCard: {
      minHeight: configData.cardHeight,
      backgroundColor: isThemeDark ? color.primary : color.white,
      transition: transition.easeOut(),
      [`@media (max-width: ${breakPoint.xs}px)`]: {
        minWidth: configData.minWidth,
      },
    },
    cardActions: {
      width: '100%',
      position: 'absolute',
      bottom: configData.cardActionsBottomSpacing,
    },
    cardImageContainer: {
      height: getImageHeight(configData, showGradient, isInContentCuration),
      position: 'relative',
    },
    cardImage: {
      width: '100%',
      height: '100%',
      backgroundColor: color.lightPrimary,
      objectFit: 'cover',
    },
    labelContainer: {
      textAlign: isRtl ? 'right' : 'left',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#BABABA',
      display: 'flex',
    },
    titleSection: {
      position: 'relative',
      marginTop: showGradient
        ? configData.titleSectionTopMargin.withGradient
        : configData.titleSectionTopMargin.withoutGradient,
      zIndex: 1,
      textAlign: isRtl ? 'right' : 'left',
    },
    tagTopHalf: {
      position: 'absolute',
      top: configData.tagTopSpacing,
      left: isRtl ? 0 : undefined,
      right: isRtl ? undefined : 0,
      height: 24,
      width: '36%',
      transform: 'skewX(45deg)',
      backgroundColor: color.dividerLight,
    },
    tagBottomHalf: {
      position: 'absolute',
      top: configData.tagTopSpacing,
      left: isRtl ? 0 : undefined,
      right: isRtl ? undefined : 0,
      height: 24,
      width: '36%',
      opacity: 0.6,
      transform: 'skewX(-45deg)',
      backgroundColor: color.primaryTextThemeDark,
    },
    tag: {
      position: 'absolute',
      top: configData.tagTopSpacing,
      left: isRtl ? 0 : undefined,
      right: isRtl ? undefined : 0,
      height: 18,
      padding: '0px 12px',
      textAlign: 'right',
      color: color.darkPrimary,
      fontSize: 12,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      backgroundColor: color.dividerLight,
      ':before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: isRtl ? '0 0 9px 9px' : '0 9px 9px 0',
        left: isRtl ? undefined : -9,
        right: isRtl ? -9 : undefined,
        borderColor: isRtl
          ? `transparent transparent transparent ${color.dividerLight}`
          : `transparent ${color.dividerLight} transparent transparent`,
      },
      ':after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        opacity: 0.6,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderColor: `transparent transparent ${color.primaryTextThemeDark} transparent`,
        top: 9,
        left: isRtl ? undefined : -9,
        right: isRtl ? -9 : undefined,
        borderWidth: isRtl ? '0 9px 9px 0' : '0 0 9px 9px',
      },
    },
  });
}

function getGradientStyle(gradient: GradientProp, configData: ConfigData) {
  return StyleSheet.create({
    gradient: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: configData.gradientTop,
      backgroundImage: `linear-gradient(${gradient.deg}deg, ${gradient.start}, ${gradient.end})`,
      transform: `skewY(${configData.gradientDeg}deg)`,
    },
  });
}

function getPartnerLogoStyle(configData: ConfigData, isInContentCuration?: boolean) {
  const isRtl = isRightToLeft(_t.getLocale());

  return {
    width: configData.partnerLogoDimension,
    height: configData.partnerLogoDimension,
    padding: configData.partnerLogoPadding,
    top: isInContentCuration ? CONTENT_CURATION_LOGO_TOP_SPACING : configData.partnerLogoTopSpacing,
    left: isRtl ? undefined : configData.partnerLogoLeftSpacing,
    right: isRtl ? configData.partnerLogoLeftSpacing : undefined,
  };
}

export type ProductCardType = 'course' | 'degree' | 's12n' | 'clip';
export type ProductCardSize = 'lg' | 'md' | 'sm' | 'xs' | 'tall';

type ProductCardProps = {
  gradient?: GradientProp;
  imageSrc: string;
  logoSrc?: string;
  title: string;
  subtitle: string;
  label?: string | JSX.Element;
  tag?: string;
  shouldShowNewBadge?: boolean;
  tagClassNames?: string;
  actionsElement?: React.ReactNode;
  htmlAttributes?: { [htmlAttr: string]: string | number | React.MutableRefObject<HTMLDivElement | null> };
  isInteractive: boolean;
  isThemeDark?: boolean;
  onClick?: () => void;
  showGradient?: boolean;
  style?: { [styleAttr: string]: string | number };
  type: ProductCardType;
  size: ProductCardSize;
  children: React.ReactNode;
  display?: boolean;
  isInContentCuration?: boolean;
  prioritizeImageLoad?: boolean;
  showClipStyleIfClip?: boolean;
};

type ProductCardPlaceHolderProps = {
  hidePartnerLogo?: boolean;
  hideLabel?: boolean;
  size?: ProductCardSize;
};

export default function ProductCard({
  gradient,
  prioritizeImageLoad = false,
  imageSrc,
  logoSrc,
  subtitle,
  title,
  label,
  tag,
  shouldShowNewBadge,
  actionsElement,
  htmlAttributes,
  isInteractive,
  isThemeDark,
  onClick,
  showGradient,
  style,
  type,
  size,
  tagClassNames,
  children,
  isInContentCuration,
  showClipStyleIfClip,
}: ProductCardProps) {
  const shouldShowGradient = showGradient === undefined ? type === PRODUCT_CARD_TYPE.S12N : showGradient;
  const configData = cardConfigMap[size];
  const cardStyles = getCardStyles(imageSrc, isThemeDark || false, shouldShowGradient, configData, isInContentCuration);
  const gradientStyle = gradient && getGradientStyle(gradient, configData);
  const partnerLogoStyle = getPartnerLogoStyle(configData, isInContentCuration);

  const onClickHandler = (event: React.MouseEvent<unknown>) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  const onClickProp = onClick ? { onClick: onClickHandler } : {};
  const badgeConstants = getBadgeConstants();
  const imgProps = {
    src: imageSrc,
    alt: '', // images are aria-hidden so no need for alt
    ...cuiCss(cardStyles.cardImage),
  };
  return (
    <CardV2
      rootClassName={cardStyles.ProductCard}
      style={style}
      isInteractive={!!onClick || isInteractive}
      isThemeDark={isThemeDark || shouldShowGradient}
      htmlAttributes={{ ...onClickProp, ...htmlAttributes }}
    >
      <CardSection
        rootClassName={cardStyles.cardImageContainer}
        type={CARD_SECTION_TYPE.IMAGE}
        htmlAttributes={{ 'aria-hidden': true }}
      >
        {prioritizeImageLoad ? <ImgixWithPrioritizedLoading {...imgProps} /> : <Imgix {...imgProps} />}
        {showClipStyleIfClip && (
          <div css={styles.clipIconWrapper}>
            <SvgVideo size={40} color="#fff" hoverColor="#fff" />
          </div>
        )}
      </CardSection>
      {shouldShowGradient && <div {...cuiCss(gradientStyle?.gradient)} />}
      {logoSrc && (
        // don't have the partner name, can't add alt to the img from cui.
        <span aria-hidden>
          <PartnerLogo logoSrc={logoSrc} showGradient={shouldShowGradient} style={partnerLogoStyle} />
        </span>
      )}
      {tag && <div {...cuiCss(tagClassNames, cardStyles.tag)}>{tag}</div>}
      <CardSection rootClassName={cardStyles.titleSection}>
        <CardText
          style={configData.titleStyle}
          type={CARD_TEXT_TYPE.TITLE}
          text={title}
          htmlAttributes={{ title, 'aria-label': title }}
          maxLines={configData.titleMaxLines}
          shouldTruncate
          shouldRenderDiv
          isThemeDark={isThemeDark}
        />
        <div {...cuiCss(cardStyles.subtitleSection)}>
          <CardText
            style={configData.subtitleStyle || {}}
            type={CARD_TEXT_TYPE.SUBTITLE}
            text={subtitle}
            htmlAttributes={{ title: subtitle, 'data-e2e': 'product-card-subtitle', 'aria-label': subtitle }}
            maxLines={configData.subtitleMaxLines}
            shouldTruncate
          />
          {shouldShowNewBadge && (
            <ProjectBadge
              text={badgeConstants.new}
              badgeStyle={styles.badgeStyle}
              containerStyle={isRightToLeft(_t.getLocale()) ? styles.containerStyleRtl : styles.containerStyle}
              textStyle={styles.textStyle}
            />
          )}
        </div>
      </CardSection>
      {children}
      {(label || actionsElement) && (
        <CardSection rootClassName={cardStyles.cardActions}>
          <div {...cuiCss(cardStyles.labelContainer)}>
            <CardText style={CdsLabelText} type={CARD_TEXT_TYPE.LABEL} text={label} />
            {actionsElement}
          </div>
        </CardSection>
      )}
    </CardV2>
  );
}

export const ProductCardPlaceholder = ({ hidePartnerLogo, hideLabel, size }: ProductCardPlaceHolderProps) => {
  const configData = cardConfigMap[size || 'lg'];
  const cardStyles = getCardStyles('', false, false, configData);
  const partnerLogoStyle = getPartnerLogoStyle(configData);

  return (
    <CardV2 rootClassName={cardStyles.ProductCard}>
      <CardSection rootClassName={cardStyles.cardImageContainer} type={CARD_SECTION_TYPE.IMAGE}>
        <span {...cuiCss(placeholder.styles.shimmer, placeholderStyles.cardImage)} />
      </CardSection>
      {!hidePartnerLogo && <PartnerLogo style={partnerLogoStyle} />}
      <CardSection rootClassName={cardStyles.titleSection}>
        <div
          {...cuiCss(placeholder.styles.shimmer, placeholderStyles.titlePlaceholder)}
          style={configData.titleStyle}
        />
        <div {...cuiCss(placeholder.styles.shimmer, placeholderStyles.subtitlePlaceholder)} />
      </CardSection>
      {!hideLabel && (
        <CardSection rootClassName={cardStyles.cardActions}>
          <div {...cuiCss(placeholder.styles.shimmer, placeholderStyles.numberOfCoursesPlaceholder)} />
        </CardSection>
      )}
    </CardV2>
  );
};

ProductCard.defaultProps = {
  gradient: gradientCUI.productCardSpecialization,
  type: PRODUCT_CARD_TYPE.COURSE,
  size: PRODUCT_CARD_SIZE.LG,
};
