/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FullscreenDialog, LoadingSection, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import { getErrorStateModalCopy } from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

const { Content } = FullscreenDialog;

const getStyles = (theme: Theme) => {
  return {
    content: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      width: 100%;
    `,
    loadingIconWrapper: css`
      display: flex;
      flex-direction: column;
    `,
    title: css`
      margin-top: ${theme.spacing(32)};
      margin-bottom: ${theme.spacing(16)};
      font-size: 28px;
    `,
    trackedDiv: css`
      height: 100vh;
      width: 100vw;
    `,
  };
};

type Props = {
  courseId?: string;
  s12nId?: string;
};

const UpgradeFailureStateModal: React.FC<Props> = ({ courseId, s12nId }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const copy = getErrorStateModalCopy({ courseId, s12nId });
  const trackingData = {
    courseId,
    s12nId,
  };

  return (
    <FullscreenDialog open={true} aria-describedby="dialogContent" sticky="none">
      <TrackedDiv
        trackingName="ideal_upgrade_failure_modal"
        data-testId="ideal-upgrade-failure-modal"
        trackClicks={false}
        withVisibilityTracking={true}
        data={trackingData}
        css={styles.trackedDiv}
      >
        <Content id="dialogContent" css={styles.content}>
          <div css={styles.loadingIconWrapper}>
            <LoadingSection label={copy.loadingText} />
          </div>
          <div>
            <Typography css={styles.title} variant="h2semibold">
              {copy.title}
            </Typography>
            <Typography2 component="h3" variant="subtitleMedium">
              {copy.subText}
            </Typography2>
          </div>
        </Content>
      </TrackedDiv>
    </FullscreenDialog>
  );
};

export default UpgradeFailureStateModal;
