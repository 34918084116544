import uniq from 'lodash/uniq';

import user from 'js/lib/user';

import { EnterpriseAdminRoleType } from 'bundles/admin-dashboard/types';

const ENTERPRISE = 'ENTERPRISE';

type EnterpriseAdminRole = {
  role: string;
  managedLevelScope: string;
  thirdPartyOrganizationId?: string | null;
  programId?: string | null;
};

export class EnterpriseAdminUser {
  roles: EnterpriseAdminRole[] = [];

  contractType?: string;

  isFreemiumOrg = false;

  isC4COrg = false;

  isC4CBOrg = false;

  isGwGOrg = false;

  isGwGFreemiumOrg = false;

  isGwGPaidOrg = false;

  outsourcingPermissions: string[] = [];

  setRoles(roles: EnterpriseAdminRole[] = [], privilegedAuth?: { outsourcingPermissions: string[] }): void {
    this.roles = roles;
    this.outsourcingPermissions = privilegedAuth?.outsourcingPermissions ?? [];
  }

  setContractType(contractType?: string): void {
    this.contractType = contractType;
  }

  isStandardContractUser(): boolean {
    return !this.contractType || this.contractType === 'STANDARD';
  }

  setIsFreemiumOrg(isFreemiumOrg: boolean): void {
    this.isFreemiumOrg = isFreemiumOrg;
  }

  setIsC4CBOrg(isC4CBOrg: boolean): void {
    this.isC4CBOrg = isC4CBOrg;
  }

  setIsC4COrg(isC4COrg: boolean): void {
    this.isC4COrg = isC4COrg;
  }

  setIsGwGOrg(isGwGOrg: boolean): void {
    this.isGwGOrg = isGwGOrg;
  }

  setIsGwGFreemiumOrg(isGwGFreemiumOrg: boolean): void {
    this.isGwGFreemiumOrg = isGwGFreemiumOrg;
  }

  setIsGwGPaidOrg(isGwGPaidOrg: boolean): void {
    this.isGwGPaidOrg = isGwGPaidOrg;
  }

  canEditProgram(programId: string): boolean {
    if (!user.isAuthenticatedUser()) {
      return false;
    } else if (this.canManageOrganization()) {
      return true;
    } else {
      const isProgramAdmin = this.roles.some(
        (role) =>
          role.managedLevelScope === `program~${programId}` && role.role === EnterpriseAdminRoleType.PROGRAM_FULL
      );
      return isProgramAdmin;
    }
  }

  canManageOrganization(): boolean {
    const organizationAdminRole = this.roles.find(({ role }) => role === EnterpriseAdminRoleType.ORGANIZATION_FULL);
    return !!(organizationAdminRole || this.isSuperUserOrEnterpriseOutsourcing());
  }

  canOnlyManagePrograms(): boolean {
    return (
      !this.canManageOrganization() && this.roles.some(({ role }) => role === EnterpriseAdminRoleType.PROGRAM_FULL)
    );
  }

  getProgramIdsToManage(): string[] {
    return uniq(
      this.roles
        .filter((_) => _.role === EnterpriseAdminRoleType.PROGRAM_FULL)
        .map((_) => _.programId)
        .reduce((all, _) => (_ ? [...all, _] : all), [] as string[])
    );
  }

  hasEnterpriseOutsourcingPermission(): boolean {
    return this.outsourcingPermissions.some((perm) => perm === ENTERPRISE);
  }

  isSuperUserOrEnterpriseOutsourcing = (): boolean => {
    return this.hasEnterpriseOutsourcingPermission() || user.isSuperuser();
  };
}
