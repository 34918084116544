/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { FunctionComponent, ReactElement } from 'react';
import * as React from 'react';
import { Component } from 'react';

import PropTypes from 'prop-types';

import Retracked from 'js/lib/retracked';

import type { Theme } from '@coursera/cds-core';
import { ChevronNextIcon } from '@coursera/cds-icons';

const styles = {
  container: (theme: Theme) =>
    css({
      display: 'flex',
      flexFlow: 'row nowrap',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        svg: {
          maxWidth: '70vw',
        },
      },
    }),
  tabList: (theme: Theme) =>
    css({
      flex: '1 0 33%',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        flex: '1 0 100%',
      },
    }),

  tabPanel: (theme: Theme) =>
    css({
      flex: '1 1 66%',
      margin: '0 8px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
      },
    }),
  tab: (theme: Theme) =>
    css({
      borderTop: `solid 1px ${theme.palette.gray[400]}`,
      color: theme.palette.blue[600],
      cursor: 'default',
      fontSize: '14px',
      lineHeight: '24px',
      padding: '10px 16px 10px 10px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 16px 8px 0px',
      },
      position: 'relative',
      userSelect: 'none',
      marginBottom: '1px',
      ':focus': {
        color: theme.palette.blue[800],
      },
      '&[aria-selected="true"]': {
        color: theme.palette.blue[800],
        fontWeight: 600,
      },
      '&:first-child': {
        borderTop: 'none',
      },
    }),
};

export type TabProps = {
  label: string;
  children: React.ReactNode | React.ReactNode[];
};

/**
 * Once we are fully using React 16, change div to Fragment.
 */
export const Tab: FunctionComponent<TabProps> = (props) => <div>{props.children}</div>;

export type TabListProps = {
  initialSelectedIndex?: number;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  trackingName?: string; // TODO: make required after eventing Admin side
  trackingData?: object;
};

type Props = {
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  forwardedRef?: (element: HTMLElement | null) => void;
  onTabClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onTabKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  selectedIndex: number;
  uniqueId: string;
};

export const TabListFC: FunctionComponent<Props> = ({
  children,
  forwardedRef,
  onTabClick,
  onTabKeyDown,
  selectedIndex,
  uniqueId,
}) => {
  const itemElements = React.Children.map<ReactElement, ReactElement<TabProps>>(children, (tab, index) => {
    const selected = index === selectedIndex;

    return (
      <li
        key={`Tab-${index}`}
        id={`${uniqueId}-${index}`}
        className="TabListCardLayout-Tab"
        css={styles.tab}
        role="tab"
        aria-selected={selected}
        tabIndex={selected ? 0 : -1}
        onClick={onTabClick}
        onKeyDown={onTabKeyDown}
        data-index={index}
      >
        {tab.props.label}
        {selected && (
          <ChevronNextIcon
            color="interactive"
            size="small"
            css={{ position: 'absolute', right: '4px', top: 'calc(50% - 8px)' }}
          />
        )}
      </li>
    );
  });

  const panelElements = React.Children.map(children, (tab, index) => {
    const selected = index === selectedIndex;

    return (
      <div
        key={`TabPanel-${index}`}
        className="TabListCardLayout-TabPanel"
        css={styles.tabPanel}
        role="tabpanel"
        tabIndex={-1}
        aria-labelledby={`${uniqueId}-${selectedIndex}`}
        hidden={!selected}
      >
        {tab}
      </div>
    );
  });

  return (
    <div className="TabListCardLayout" css={styles.container} ref={forwardedRef}>
      <ol className="TabListCardLayout-TabList" css={styles.tabList} role="tablist">
        {itemElements}
      </ol>
      {panelElements}
    </div>
  );
};

export type TabListState = {
  selectedIndex: number;
};

export class TabList extends Component<TabListProps, TabListState> {
  state = { selectedIndex: this.props.initialSelectedIndex ?? 0 };

  static contextTypes = {
    _eventData: PropTypes.object,
  };

  static _uniqueIdCounter = 0;

  _uniqueId = `TabListCardLayout-${(TabList._uniqueIdCounter += 1)}`;

  _tabListRef: HTMLElement | null = null;

  _handleTabListRef = (tabList: HTMLElement | null) => {
    this._tabListRef = tabList;
  };

  _onTabClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this._activateTargetTab(event.currentTarget, 'click');
  };

  _onTabKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case ' ':
      case 'Enter':
        event.preventDefault();
        this._activateTargetTab(event.currentTarget, 'a11y');
        break;

      case 'ArrowRight':
      case 'ArrowDown':
        event.preventDefault();
        this._activateNextTab();
        break;

      case 'ArrowLeft':
      case 'ArrowUp':
        event.preventDefault();
        this._activatePrevTab();
        break;

      default:
        break;
    }
  };

  _activateTargetTab(target: HTMLElement, action: 'click' | 'a11y') {
    const selectedIndex = Number(target.dataset.index);
    if (
      !Number.isNaN(selectedIndex) &&
      selectedIndex >= 0 &&
      selectedIndex < React.Children.count(this.props.children)
    ) {
      Retracked.trackComponent(
        this.context._eventData,
        { ...this.props.trackingData, selectedIndex },
        `${this.props.trackingName}_tab`,
        action
      );
      this.setState({ selectedIndex }, this._focusActiveTab);
    }
  }

  _activateNextTab() {
    this.setState((state, props) => {
      const tabCount = React.Children.count(props.children);
      const selectedIndex = (state.selectedIndex + 1) % tabCount;
      Retracked.trackComponent(
        this.context._eventData,
        { ...this.props.trackingData, selectedIndex },
        `${this.props.trackingName}_tab_next`,
        'a11y'
      );
      return { selectedIndex };
    }, this._focusActiveTab);
  }

  _activatePrevTab() {
    this.setState((state, props) => {
      const selectedIndex =
        state.selectedIndex <= 0 ? React.Children.count(props.children) - 1 : state.selectedIndex - 1;
      Retracked.trackComponent(
        this.context._eventData,
        { ...this.props.trackingData, selectedIndex },
        `${this.props.trackingName}_tab_prev`,
        'a11y'
      );
      return { selectedIndex };
    }, this._focusActiveTab);
  }

  _focusActiveTab = () => {
    const tabSelector = `.TabListCardLayout-Tab[data-index="${this.state.selectedIndex}"`;
    const tabElement = this._tabListRef?.querySelector(tabSelector) as HTMLElement;
    tabElement?.focus();
  };

  render() {
    const { children } = this.props;
    const { selectedIndex } = this.state;
    return (
      <TabListFC
        onTabClick={this._onTabClick}
        onTabKeyDown={this._onTabKeyDown}
        selectedIndex={selectedIndex}
        uniqueId={this._uniqueId}
        forwardedRef={this._handleTabListRef}
      >
        {children}
      </TabListFC>
    );
  }
}
