import * as React from 'react';

import { compose, getDisplayName, mapProps, setDisplayName } from 'recompose';

import { isUserRightToLeft } from 'js/lib/language';

import { Typography2 } from '@coursera/cds-core';
import { CalendarIcon } from '@coursera/cds-icons';
import { StyleSheet, color, css, font } from '@coursera/coursera-ui';

import { SCREEN_TABLET } from 'bundles/browse/constants';
import ResetDeadlinesButton from 'bundles/course-sessions/components/cds/ResetDeadlinesButton';
import CourseScheduleSuggestion from 'bundles/course-sessions/models/CourseScheduleSuggestion';
import type { CourseScheduleSuggestion as CourseScheduleSuggestionType } from 'bundles/course-sessions/types/LearnerCourseScheduleSuggestion';
import Naptime from 'bundles/naptimejs';
import LearnerCourseScheduleSuggestionsV1 from 'bundles/naptimejs/resources/learnerCourseScheduleSuggestions.v1';

import _t from 'i18n!nls/program-home';

const styles = StyleSheet.create({
  SessionSwitchCardV2: {
    paddingTop: 14,
    margin: '20px 0 -18px 115px',
    borderTop: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'flex-start',
    [`@media (max-width: ${SCREEN_TABLET}px)`]: {
      marginLeft: 0,
    },
  },
  SessionSwitchCardV2RTL: {
    paddingTop: 14,
    margin: '20px 115px -18px 0',
    borderTop: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'flex-start',
    [`@media (max-width: ${SCREEN_TABLET}px)`]: {
      marginRight: 0,
    },
  },
  CdsHack: {
    marginBottom: 0,
  },
  message: {
    margin: '0 auto',
    fontSize: font.md,
    color: color.secondaryText,
    marginTop: 30,
  },
  progressTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5,
  },
  progressText: {
    fontSize: 14,
  },
  svgContainer: {
    marginRight: 10,
  },
  svgContainerRTL: {
    marginLeft: 10,
  },
  buttonContainer: {
    position: 'relative',
    top: 4,
    marginLeft: 10,
  },
  buttonContainerRTL: {
    position: 'relative',
    top: 4,
    marginRight: 10,
  },
});

type PropsFromCaller = {
  courseId: string;
  userId: number;
  courseName?: string;
};

type PropsFromNaptime = {
  courseScheduleSuggestions: LearnerCourseScheduleSuggestionsV1;
};

type PropsFromMapProps = PropsFromCaller & {
  suggestions: Array<CourseScheduleSuggestionType>;
};

type Props = PropsFromMapProps;

const SessionSwitchCardV2 = (props: Props) => {
  const { courseId, suggestions, courseName } = props;

  if (!suggestions?.length) {
    return null;
  }

  const courseScheduleSuggestion = new CourseScheduleSuggestion(suggestions[0]);
  const { progressPercentage } = courseScheduleSuggestion;
  const hasZeroProgress = progressPercentage === 0;
  const hasSignificantProgress =
    progressPercentage !== null && progressPercentage !== undefined && progressPercentage > 50;

  if (courseScheduleSuggestion.isNoSuggestion) {
    return null;
  }

  let progressTitle: string;
  if (hasZeroProgress) {
    progressTitle = _t('Ready to get started?');
  } else if (hasSignificantProgress) {
    progressTitle = _t('You can finish this time');
  } else {
    progressTitle = _t('Pick up where you left off');
  }

  let progressText: string;
  if (hasZeroProgress) {
    progressText = _t("It looks like you didn't have a chance to start yet and missed some assignment deadlines.");
  } else if (hasSignificantProgress) {
    progressText = _t(
      "You've already completed #{progressPercentage}% of your course! Reset your deadlines so you can finish the rest!",
      // See above definition of hasSignificantProgress
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { progressPercentage: Math.round(progressPercentage!) }
    );
  } else {
    progressText = _t(
      "Don't let the great things you learned fade away! Reset your deadlines and complete your assignments every week."
    );
  }

  return (
    <div
      {...css(
        'rc-SessionSwitchCardV2',
        isUserRightToLeft() ? styles.SessionSwitchCardV2RTL : styles.SessionSwitchCardV2,
        styles.CdsHack
      )}
    >
      <div {...css(isUserRightToLeft() ? styles.svgContainerRTL : styles.svgContainer)}>
        <CalendarIcon size="large" />
      </div>
      <div className="flex-1">
        <Typography2 component="p" variant="subtitleMedium">
          {progressTitle}
        </Typography2>
        <Typography2 component="p" variant="bodySecondary">
          {progressText}
        </Typography2>
      </div>
      <div {...css(isUserRightToLeft() ? styles.buttonContainerRTL : styles.buttonContainer)}>
        <ResetDeadlinesButton
          size="small"
          variant="secondary"
          trackingName="sessions_v2_reset_deadlines_enterprise"
          trackingData={{ progressPercentage }}
          courseId={courseId}
          courseScheduleSuggestion={courseScheduleSuggestion}
          courseName={courseName}
        />
      </div>
    </div>
  );
};

export { SessionSwitchCardV2 };

const SessionSwitchCardV2HookProxy = (props: Props) => <SessionSwitchCardV2 {...props} />;

export default compose<Props, PropsFromCaller>(
  setDisplayName(getDisplayName(SessionSwitchCardV2)),
  Naptime.createContainer<PropsFromCaller>(({ courseId, userId }) => {
    return {
      courseScheduleSuggestions: LearnerCourseScheduleSuggestionsV1.getByUserIdAndCourseId(userId, courseId, [
        'suggestions',
      ]),
    };
  }),
  mapProps<PropsFromMapProps, PropsFromCaller & PropsFromNaptime>(({ courseScheduleSuggestions, ...rest }) => {
    return {
      ...rest,
      // @ts-expect-error LearnerCourseScheduleSuggestionsV1 is typed incorrectly.
      suggestions: courseScheduleSuggestions?.suggestions,
    };
  })
)(SessionSwitchCardV2HookProxy);
