/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Children, useRef } from 'react';

import { useReadOnly, useSelected } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import TableMenu from 'bundles/cml/editor/components/elements/table/TableMenu';
import TableRenderer from 'bundles/cml/shared/components/table/Table';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  root: css`
    position: relative;
  `,
};

const splitHeader = ({ headless }: TableElement, children: React.ReactChildren) => {
  const rows = Children.toArray(children);
  if (headless || rows.length <= 1) {
    return { header: null, rows };
  }

  return {
    header: rows[0],
    rows: rows.slice(1),
  };
};

const Table: React.FC<RenderElementProps> = (props) => {
  const tableElement = props.element as TableElement;
  const selected = useSelected();
  const readonly = useReadOnly();
  const ref = useRef<HTMLTableElement>(null);

  const { header, rows } = splitHeader(tableElement, props.children);

  return (
    <TableRenderer ref={ref} {...props} header={header} rows={rows} css={styles.root}>
      {selected && !readonly && <TableMenu anchorEl={ref.current} table={tableElement} />}
    </TableRenderer>
  );
};

export default Table;
