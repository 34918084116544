/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Button, useTheme } from '@coursera/cds-core';
import { CloseIcon } from '@coursera/cds-icons';

import type { TrackingData } from 'bundles/common/components/withSingleTracked';

import _t from 'i18n!nls/program-common';

export type InformationCardProps = {
  id?: string;

  /** The info card is open and should be rendered */
  isOpen?: boolean;

  /** Event for when the InfoCard is requesting to be closed (i.e. use clicked the X). */
  onRequestClose?: () => void;

  rootClassName?: string;

  trackingName?: string; // TODO: make required after eventing Admin side
  trackingData?: TrackingData;
};

const InformationCard: React.FC<InformationCardProps> = ({
  id,
  isOpen,
  onRequestClose,
  trackingName,
  trackingData,
  children,
}) => {
  const theme = useTheme();
  const styles = {
    root: css`
      overflow: hidden;
      box-sizing: border-box;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      position: relative;
      box-shadow: rgb(31 31 31 / 12%) 0 1px 6px, rgb(31 31 31 / 12%) 0 1px 4px;
      color: ${theme.palette.gray[900]};
      background-color: var(--cds-color-white-0);
    `,

    closeButtonWrapper: css`
      z-index: 1000;
      position: absolute;
      top: 0;
      right: 0;

      button {
        padding: ${theme.spacing(24)};
      }
    `,
  };

  const track = useRetracked();
  const handleClose = () => {
    track({
      trackingData: trackingData ?? {},
      trackingName: `${trackingName}_dismiss`,
      action: 'click',
    });
    onRequestClose?.();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div id={id} css={styles.root}>
      {onRequestClose && (
        <div css={styles.closeButtonWrapper}>
          <Button variant="ghost" onClick={handleClose} aria-label={_t('Close')} aria-controls={id}>
            <CloseIcon size="medium" />
          </Button>
        </div>
      )}
      {children}
    </div>
  );
};

export default InformationCard;
