/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, Typography2, useTheme } from '@coursera/cds-core';

import SessionSwitchModal from 'bundles/ondemand/components/sessions/SessionSwitchModal';

import _t from 'i18n!nls/program-home';

type Props = {
  courseId: string;
  courseName?: string;
  onShowModal?: () => void;
  onCloseModal?: () => void;
};

const SessionSwitchCard = ({ courseId, courseName, onShowModal, onCloseModal }: Props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const handleShowModal = React.useCallback(() => {
    setIsModalVisible(true);
    onShowModal?.();
  }, [onShowModal]);
  const handleCloseModal = React.useCallback(() => {
    setIsModalVisible(false);
    onCloseModal?.();
  }, [onCloseModal]);
  const theme = useTheme();
  const styles = {
    SessionSwitchCard: css`
      background-color: ${theme.palette.gray[100]};

      /* This spacing corresponds to the padding in coursera-ui's CardBody element. */
      margin: 1.125rem -1.125rem -1.125rem -1.125rem;
      padding: 1.125rem;
    `,
  };
  return (
    <div className="rc-SessionSwitchCard align-items-vertical-center horizontal-box" css={styles.SessionSwitchCard}>
      <div className="flex-1">
        <Typography2 variant="subtitleMedium" component="p">
          {_t("You're falling a little behind")}
        </Typography2>
        <Typography2 component="p" variant="bodySecondary">
          {_t("Don't worry, you can switch to another session and not lose your progress")}
        </Typography2>
      </div>
      <Button size="small" variant="secondary" onClick={handleShowModal} data-e2e={`session-switch-button~${courseId}`}>
        {_t('Switch Sessions')}
        {courseName && <span className="sr-only">: {courseName}</span>}
      </Button>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClose: () => void; courseId: string; }' ... Remove this comment to see the full error message */}
      {isModalVisible && <SessionSwitchModal onClose={handleCloseModal} courseId={courseId} />}
    </div>
  );
};

export default SessionSwitchCard;
