import * as React from 'react';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionFooter';

type PropsFromCaller = {
  removeStyles?: boolean;
};

const SubscriptionFooter: React.FC<PropsFromCaller> = ({ removeStyles }) => {
  const { trackEnrollModalContinue } = useEnrollModalEventing();
  const router = useRouter();
  const { course, enrollmentAvailableChoices } = usePageData();
  const { canAuditCourse } = enrollmentAvailableChoices;

  if (!canAuditCourse) {
    return null;
  }

  const showOnboardingModal = () => {
    router.push({
      ...router.location,
      params: router.params,
      query: Object.assign({}, router.location.query, {
        showOnboardingModal: 'checkAndRedirect',
        courseSlug: course?.slug,
      }),
    });
  };

  const onClickAuditCourse = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const enrollmentChoiceType = EnrollmentChoiceTypes.AUDIT_COURSE;
    trackEnrollModalContinue(enrollmentChoiceType);
    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    const options = { courseId: course.id };

    return submitEnrollmentPromise({ handleSubmitPromise, options }).then(showOnboardingModal);
  };

  const footerClasses = classNames('rc-SubscriptionFooter', {
    subscriptionFooter: !removeStyles,
  });

  return (
    <div className={footerClasses} data-testid="subscription-footer">
      <p className="body-1-text">
        <FormattedMessage
          // eslint-disable-next-line no-restricted-syntax
          message={_t('{audit} the course')}
          audit={
            <TrackedButton className="button-link" trackingName="enroll_subscribe_audit" onClick={onClickAuditCourse}>
              {_t('Audit')}
            </TrackedButton>
          }
        />
      </p>
    </div>
  );
};

export default SubscriptionFooter;
