import * as React from 'react';

import styled from '@emotion/styled';

import { breakpoints } from '@coursera/cds-core';
import { color as cuiColor } from '@coursera/coursera-ui';

import { Spacer } from 'bundles/enterprise-ui/components/Spacer';

export const FunnelStage: React.VFC<
  {
    color: string;
    from: number;
    to: number;
  } & React.HTMLAttributes<SVGElement>
> = ({ color, from, to, ...props }) => {
  const x0 = 0;
  const x1 = 100;
  const x2 = 100;
  const y = 50;
  const y0 = from / 2;
  const y1 = to / 2;
  return (
    <svg viewBox="0 0 100 100" height="120" width="100%" preserveAspectRatio="none" {...props}>
      <path
        d={`M${x0} ${50 - y0} L${x1} ${y - y1} L${x2} ${y - y1} L${x2} ${y + y1} L${x1} ${y + y1} L${x0} ${y + y0} Z`}
        fill={color}
      />
    </svg>
  );
};

export const Metric = styled.div`
  ${breakpoints.up('sm')} {
    flex-grow: 1;
    flex-basis: 0;

    section {
      padding-right: 24px;
    }

    :not(:first-child) {
      border-left: 1px solid ${cuiColor.divider};

      section {
        padding: 0 24px;
      }
    }
  }
`;

export const MetricContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;

  :first-child {
    section {
      padding-right: 24px;
    }
  }

  :not(:first-child) {
    border-left: 1px solid ${cuiColor.divider};

    section {
      padding: 0 24px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const PrimaryMetric = styled.div`
  color: ${cuiColor.primaryText};
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: auto;
  margin-bottom: 24px;
`;

export const SecondaryMetric = styled.div`
  color: ${cuiColor.primaryText};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const MetricDescription = styled.div`
  color: ${cuiColor.secondaryText};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
`;

const Box = styled.div`
  display: flex;
`;

export type FunnelProps = {
  funnelStages: [number, number, number];
};
export const Funnel: React.FC<FunnelProps> = ({ funnelStages: [one, two, three] }) => {
  const minWidth = 4;
  const topOfFunnel = 100;
  const middleOfFunnel = Math.max(one === 0 ? 0 : (topOfFunnel / one) * two, minWidth);
  const bottomOfFunnel = Math.max(one === 0 ? 0 : (topOfFunnel / one) * three, minWidth);
  return (
    <Box>
      <Metric>
        <FunnelStage color="#5B74DD" from={topOfFunnel} to={middleOfFunnel} />
        <Spacer size={32} />
      </Metric>
      <Metric>
        <FunnelStage color="#859CFA" from={middleOfFunnel} to={bottomOfFunnel} />
        <Spacer size={32} />
      </Metric>
      <Metric>
        <FunnelStage color="#B4C2FD" from={bottomOfFunnel} to={bottomOfFunnel} />
        <Spacer size={32} />
      </Metric>
    </Box>
  );
};
