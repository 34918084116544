/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Grid, Typography, Typography2, useTheme } from '@coursera/cds-core';

import type { InformationCardProps } from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCard from 'bundles/program-common/components/InformationCard/InformationCard';
import InformationCardBody from 'bundles/program-common/components/InformationCard/InformationCardBody';
import SkillSetInfo1Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo1Svg';
import SkillSetInfo2Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo2Svg';
import SkillSetInfo3Svg from 'bundles/program-home/components/multiprogram/assets/svg/SkillSetInfo3Svg';

import _t from 'i18n!nls/program-home';

type SkillSetInformationCarddProps = Omit<InformationCardProps, 'trackingName' | 'trackingData'>;

export type SkillSetInfoSvgProps = React.SVGProps<SVGSVGElement>;

type SkillSetInfoProp = {
  SvgElement: React.ComponentType<SkillSetInfoSvgProps>;
  heading: string;
  description: string;
};

const styles = {
  skillSetInformationContainer: (theme: Theme) => css`
    ${theme.breakpoints.down('xl')} {
      padding: ${theme.spacing(32)} ${theme.spacing(48)};
    }
    ${theme.breakpoints.down('md')} {
      padding: ${theme.spacing(32)};
    }

    background-color: ${theme.palette.purple['50']};
    border: 1px solid ${theme.palette.gray['700']};
    border-radius: 4px;
  `,
  descriptionHeading: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(24)};
  `,
  imageContainer: (theme: Theme) => css`
    width: 100%;
    ${theme.breakpoints.down('xl')} {
      height: 164px;
    }
    ${theme.breakpoints.down('sm')} {
      height: 140px;
    }
    ${theme.breakpoints.down('xs')} {
      height: 234px;
    }

    background: var(--cds-color-white-0);
    margin-bottom: ${theme.spacing(24)};
    display: flex;
  `,
  infoImage: (theme: Theme) => css`
    ${theme.breakpoints.down('xl')} {
      height: 110px;
    }
    ${theme.breakpoints.down('sm')} {
      height: 65%;
      width: 80%;
    }
    ${theme.breakpoints.down('xs')} {
      height: 120px;
    }

    margin: auto;
  `,
  infoHeading: (theme: Theme) => css`
    margin-bottom: ${theme.spacing(12)};
  `,
};

const SkillSetInfoSection = ({ SvgElement, heading, description }: SkillSetInfoProp) => {
  const theme = useTheme();

  return (
    <div>
      <div css={styles.imageContainer(theme)}>
        <SvgElement css={styles.infoImage(theme)} />
      </div>
      <Typography2 css={styles.infoHeading(theme)} component="div" variant="subtitleMedium">
        {heading}
      </Typography2>
      <Typography2 component="p" variant="bodySecondary">
        {description}
      </Typography2>
    </div>
  );
};

const SkillSetInformationCard: React.FC<SkillSetInformationCarddProps> = (props) => {
  const theme = useTheme();

  return (
    <InformationCard {...props} trackingName="skillset_discovery_information_card">
      <InformationCardBody isFullBleed>
        <div css={styles.skillSetInformationContainer(theme)}>
          <Typography css={styles.descriptionHeading(theme)} variant="h2semibold" component="div">
            {_t('Fast track your professional growth with SkillSets')}
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo1Svg}
                heading={_t('Get current, in-demand skills you need')}
                description={_t(
                  'Learn the latest  skills that matter for your job and to your organization—all powered by world-class content from top universities and companies'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo2Svg}
                heading={_t('Grow with personalized recommendations')}
                description={_t(
                  'Enroll in personalized recommendations to increase your proficiency level—the recommendations will keep updating to match your level'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SkillSetInfoSection
                SvgElement={SkillSetInfo3Svg}
                heading={_t('Showcase your competencies as you progress')}
                description={_t(
                  'Increase your skill score dynamically as you complete coursework—track progress towards your organization’s targets and get noticed'
                )}
              />
            </Grid>
          </Grid>
        </div>
      </InformationCardBody>
    </InformationCard>
  );
};

export default SkillSetInformationCard;
