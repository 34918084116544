import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

export const AI_CAMPAIGN_CERTIFICATE_SLUGS = [
  'google-data-analytics',
  'google-digital-marketing-ecommerce',
  'google-it-support',
  'google-project-management',
  'google-ux-design',
  'google-cybersecurity'
];

export const shouldShowSeptemberAiCampaignPageBanner = (slug: string) => {
  return (
    AI_CAMPAIGN_CERTIFICATE_SLUGS.includes(slug) &&
    GrowthDiscoveryEpicClient.get('showSeptemberAICampaignBadgeAndBanner') === true
  );
};

export default shouldShowSeptemberAiCampaignPageBanner;
