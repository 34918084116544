import { find, pick } from 'lodash';
import BaseStore from 'vendor/cnpm/fluxible.v0-4/addons/BaseStore';

import type { UploadStatusType } from 'bundles/asset-admin/constants/UploadStatus';
import UploadStatus from 'bundles/asset-admin/constants/UploadStatus';
import type { Asset, AssetMap } from 'bundles/asset-admin/types/assets';

const SERIALIZED_PROPS: (keyof AssetAdminStore$DehydratedState)[] = ['assets', 'assetMap', 'assetsListReady'];

type AssetAdminStore$DehydratedState = {
  assets: Array<Asset>;
  assetMap: AssetMap;
  assetsListReady: boolean;
};

type initialData = {
  noticeDismissed: boolean;
};

export default class AssetAdminStore extends BaseStore implements AssetAdminStore$DehydratedState {
  static storeName = 'AssetAdminStore';

  uploadStatus: UploadStatusType = UploadStatus.IDLE;

  pendingAttemptIds: Set<string> = new Set();

  successfulAttemptIds: Set<string> = new Set();

  uploadedAssets: Array<Asset> = [];

  assets: Array<Asset> = [];

  assetsByPage: Array<Array<Asset>> = [];

  assetMap: AssetMap = {};

  assetsListReady = false;

  noticeMessageDismissed = false;

  nextPageIndex: number | null = null;

  totalAssets: number | null = null;

  keyword: string | null = null;

  updateDataFailed = false;

  updatingData = false;

  emitChange!: () => void;

  dehydrate() {
    return pick(this, ...SERIALIZED_PROPS);
  }

  rehydrate(state: AssetAdminStore$DehydratedState) {
    Object.assign(this, pick(state, ...SERIALIZED_PROPS));
  }

  static handlers = {
    INITIALIZED: 'onInitialized',
    RECEIVED_ASSETS_LIST: 'onReceivedAssetsList',
    NOTICE_MESSAGE_DISMISSED: 'onNoticeMessageDismissed',
    RESET_ASSETS: 'onResetAssets',
    SET_NEXT_PAGE_INDEX: 'onSetNextPageIndex',
    UPDATE_ASSET_DATA_START: 'onUpdateAssetDataStart',
    UPDATE_ASSET_DATA: 'onUpdateAssetData',
    UPDATE_ASSET_FAIL: 'onUpdateAssetFail',
    RESET_UPDATE_ASSET_DESCRIPTION: 'onResetUpdateAssetDescription',
    UPDATE_TEMPLATE_ID: 'onUpdateTemplateId',
    ADD_SUCCESSFUL_ATTEMPTS: 'onAddSuccessfulAttempts',
    ADD_UPLOADED_FILES: 'onAddUploadedFiles',
    HANDLE_FAILED_UPLOAD: 'onHandleFailedUpload',
    UPLOAD_STATUS_UPDATED: 'onUploadStatusUpdated',
  };

  onInitialized(data: initialData) {
    this.noticeMessageDismissed = data?.noticeDismissed;
    this.resetUploadedAssets();
    this.emitChange();
  }

  onReceivedAssetsList({
    assets,
    assetMap,
    nextPageIndex,
    totalAssets,
    pageSize,
    keyword,
  }: {
    assets: Array<Asset>;
    assetMap: AssetMap;
    nextPageIndex?: number;
    totalAssets: number;
    pageSize?: number;
    keyword: string | null;
  }) {
    this.assets = this.assets.concat(assets);

    if (assetMap) {
      Object.assign(this.assetMap, assetMap);
    }

    // if we are making a paging request, save the paging info
    if (pageSize != null && totalAssets != null) {
      const pageIndex = Math.ceil((nextPageIndex ?? totalAssets) / pageSize);
      this.assetsByPage[pageIndex] = assets;
      // if we are reloading data for an earlier page, clear out later pages because they are likely stale
      this.assetsByPage = this.assetsByPage.slice(0, pageIndex + 1);
      this.assetsListReady = true;
      this.nextPageIndex = nextPageIndex ?? null;
      this.totalAssets = totalAssets;
      this.keyword = keyword;
    }

    this.emitChange();
  }

  onSetNextPageIndex(nextPageIndex: number) {
    this.nextPageIndex = nextPageIndex;
    this.emitChange();
  }

  onNoticeMessageDismissed() {
    this.noticeMessageDismissed = true;
    this.emitChange();
  }

  onResetAssets() {
    this.resetUploadedAssets();
    this.emitChange();
  }

  onUpdateAssetDataStart() {
    this.updatingData = true;
    this.emitChange();
  }

  onUpdateAssetData({
    assetId,
    name,
    description,
    longDescription,
  }: {
    assetId: string;
    name: string;
    description: string;
    longDescription: string;
  }) {
    this.updateDataFailed = false;
    this.updatingData = false;

    const asset = this.getAssetById(assetId);

    if (asset) {
      const newDescriptionTag = { name: 'description', value: description };
      const newLongDescriptionTag = { name: 'longDescription', value: longDescription };
      const oldDescriptionValue = (asset.tags && asset.tags.find((tag) => tag.name === 'description')?.value) || '';
      const oldLongDescriptionValue =
        (asset.tags && asset.tags.find((tag) => tag.name === 'longDescription')?.value) || '';

      const nonDescriptionTags = asset.tags.filter(
        (tag) => tag.name !== 'description' && tag.name !== 'longDescription'
      );

      if (newDescriptionTag.value !== oldDescriptionValue || newLongDescriptionTag.value !== oldLongDescriptionValue) {
        asset.isDescriptionAutoGenerated = false;
      }

      asset.tags = [...nonDescriptionTags, newDescriptionTag, newLongDescriptionTag];
      asset.name = name;
    }

    this.emitChange();
  }

  onUpdateAssetFail() {
    this.updateDataFailed = true;
    this.updatingData = false;

    this.emitChange();
  }

  onResetUpdateAssetDescription() {
    this.updateDataFailed = false;
    this.updatingData = false;

    this.emitChange();
  }

  onAddSuccessfulAttempts(successfulAttemptIds: Array<string>) {
    // assetIds are identical to their old attemptId
    successfulAttemptIds.forEach((successfulAttemptId) => {
      this.pendingAttemptIds.delete(successfulAttemptId);
      this.successfulAttemptIds.add(successfulAttemptId);
    });

    if (this.pendingAttemptIds.size === 0) {
      this.uploadStatus = UploadStatus.ASSEMBLIES_COMPLETED;
    }

    this.emitChange();
  }

  onAddUploadedFiles(assets: Array<Asset>) {
    // assetIds are identical to their old attemptId
    assets.forEach((asset) => this.successfulAttemptIds.delete(asset.id));
    this.uploadedAssets = [...this.uploadedAssets, ...assets];
    this.assets = [...this.assets, ...assets];

    this.uploadStatus = UploadStatus.SUCCESS;

    this.emitChange();
  }

  onHandleFailedUpload(attemptId: string) {
    this.pendingAttemptIds.delete(attemptId);
    this.uploadStatus = UploadStatus.FAILED;

    this.emitChange();
  }

  onUploadStatusUpdated(status: UploadStatusType) {
    this.uploadStatus = status;
    this.emitChange();
  }

  getAssets() {
    return this.assets;
  }

  getAssetById(assetId: string) {
    return find(this.assets, (asset) => asset.id === assetId);
  }

  getAssetsByPage() {
    return this.assetsByPage;
  }

  getNextPageIndex() {
    return this.nextPageIndex;
  }

  getTotalAssets() {
    return this.totalAssets;
  }

  getKeyword() {
    return this.keyword;
  }

  getUploadedAssets() {
    return this.uploadedAssets;
  }

  getSuccessFullAttemptIds() {
    return Array.from(this.successfulAttemptIds);
  }

  getUploadStatus() {
    return this.uploadStatus;
  }

  getAssetMap() {
    return this.assetMap;
  }

  isAssetsListReady() {
    return this.assetsListReady;
  }

  isNoticeMessageDismissed() {
    return this.noticeMessageDismissed;
  }

  hasUpdateFailed() {
    return this.updateDataFailed;
  }

  isUpdatingData() {
    return this.updatingData;
  }

  resetUploadedAssets() {
    this.uploadedAssets = [];
    this.uploadStatus = UploadStatus.IDLE;
  }
}
