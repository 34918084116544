import * as React from 'react';

import _ from 'lodash';

import { Typography2 } from '@coursera/cds-core';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseCardAutoenrollmentDisclaimer from 'bundles/course-cards/components/course-card/enterprise/CourseCardAutoenrollmentDisclaimer';
import CourseProgressBox from 'bundles/course-cards/components/course-card/enterprise/CourseProgressBox';
import CourseProgressInfo from 'bundles/course-cards/components/course-card/enterprise/CourseProgressInfo';
import CourseWithProgressLinkButton from 'bundles/course-cards/components/course-card/enterprise/CourseWithProgressLinkButton';
import SessionEndInfo from 'bundles/course-cards/components/course-card/enterprise/SessionEndInfo';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerMaterialWeeksV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterialWeeks.v1';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';
import { getProgressByLearnerMaterial } from 'bundles/program-home/utils/CourseUtils';

import _t from 'i18n!nls/program-home';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  learnerSession?: OnDemandLearnerSessionsV1;
  learnerMaterials?: OnDemandLearnerMaterialsV1;
  learnerMaterialWeeks?: OnDemandLearnerMaterialWeeksV1;
  isAutoenrolled?: boolean;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

const CourseEnrolledRunningPublicSessionCard: React.FC<Props> = ({
  course: { id, name, homeLink, partnerIds },
  cardDropdownWidget,
  learnerMaterialWeeks,
  learnerMaterials,
  learnerSession,
  isAutoenrolled,
  isPartOfS12n,
  ...rest
}) => {
  // Add defaults
  const numberOfAssignments = (learnerMaterialWeeks && learnerMaterialWeeks.totalPassablesCount) || 0;
  let currentWeek = -1;
  let duration = -1;
  let isProgressCritical = false;

  if (learnerSession) {
    currentWeek = learnerSession.currentWeek;
  }

  if (learnerMaterials) {
    duration = _.size(learnerMaterials.weekNumbers) || duration;
    if (currentWeek > duration) {
      // currentWeek is a calculated value based on startAt and endAt,
      // there might be discrepencies between that and the number of weeks returned by API
      // we don't want to have a currentWeek that's beyong the number of weeks
      currentWeek = duration;
    }
    isProgressCritical = !!learnerMaterials.isProgressCritical;
  }

  currentWeek = (learnerMaterialWeeks && learnerMaterialWeeks.weekNumber) || currentWeek;
  const summaryCoreProgressPercentage = getProgressByLearnerMaterial(learnerMaterials);

  // If now it's week 6 and the session is 5 weeks in total, the learnerMaterialWeeks will be undefined
  return (
    <div className="rc-CourseEnrolledRunningPublicSessionCard row flex-1">
      <div className="col-xs-12 col-sm-8 col-md-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          {...rest}
        />
        {cardDropdownWidget}
        {isPartOfS12n && (
          <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
            {_t('Started')}
          </Typography2>
        )}
        <CourseProgressBox>
          <CourseProgressInfo
            summaryCoreProgressPercentage={summaryCoreProgressPercentage}
            isProgressCritical={isProgressCritical}
            numberOfAssignments={numberOfAssignments}
            courseName={name}
          />
          {learnerSession && <SessionEndInfo endsAt={learnerSession.endsAt} isEnded={learnerSession.isEnded} />}
        </CourseProgressBox>
        {isAutoenrolled && <CourseCardAutoenrollmentDisclaimer />}
      </div>
      <CourseActionBox>
        <div className="vertical-box align-items-absolute-center">
          {duration !== -1 && currentWeek !== -1 && (
            <Typography2 component="span" variant="subtitleMedium" className="week-info week-info-current">
              {_t('Week #{current} of #{total}', { current: currentWeek, total: duration })}
            </Typography2>
          )}
          <CourseWithProgressLinkButton
            link={homeLink}
            progress={summaryCoreProgressPercentage}
            trackingNamePrefix="enrolled_running_public"
            courseName={name}
          />
        </div>
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledRunningPublicSessionCard;
