import * as React from 'react';

import URI from 'jsuri';
import { branch, compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';

import { Box, Button, Divider } from '@coursera/coursera-ui';

import { TrackedButton } from 'bundles/common/components/TrackedCui';
import API from 'bundles/phoenix/lib/apiWrapper';
import withThirdPartyOrgNameData from 'bundles/user-account/components/hoc/withThirdPartyOrgNameData';

import _t from 'i18n!nls/user-account';

function getTranslations() {
  return {
    OR: _t('or'),
  };
}

type Organizations = {
  id: string;
  name: string;
}[];

type Organization = {
  id: string;
  name: string;
};

type PropsFromCaller = {
  provider: string;
  showFooter?: boolean;
  signupMode?: boolean;
  className?: string;
  organizations?: Organizations;
  useGeneralSSOLoginText?: boolean;
};

type PropsFromNaptime = {
  organizations?: Organizations;
};

type Props = PropsFromCaller & PropsFromNaptime;

type State = {
  loginUrl?: string;
};

class SsoButton extends React.Component<Props, State> {
  state: State = {
    loginUrl: undefined,
  };

  _isMounted?: boolean;

  componentDidMount() {
    this._isMounted = true;

    const { organizations } = this.props;
    const organization = organizations?.[0];
    if (organization) {
      const searchParams = new URLSearchParams();
      searchParams.append('action', 'initiateLoginFlow');
      searchParams.append('id', organization.id);
      searchParams.append('returnTo', redirect.getPathname() + redirect.getQueryParams());

      // todo(dguo): switch to naptime mutations when available
      API('/api/thirdPartyOrganizations.v1')
        .post(`?${searchParams.toString()}`)
        .then((res: string) => {
          if (this._isMounted) {
            this.setState({ loginUrl: res });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initiateFlow = () => {
    const { loginUrl } = this.state;
    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  renderButton(organization: Organization) {
    const { className, signupMode, useGeneralSSOLoginText } = this.props;
    const { loginUrl } = this.state;

    if (loginUrl) {
      if (signupMode) {
        return (
          <TrackedButton
            trackingName="sso_btn_click"
            type="primary"
            rootClassName={className}
            onClick={this.initiateFlow}
          >
            <FormattedMessage message={_t('Sign up with {organizationName}')} organizationName={organization.name} />
          </TrackedButton>
        );
      } else {
        // TODO: remove this once our SSO initiateLoginFlow is flexible to support both GET/POST
        // currently this is only for slug=honeywell-test, honeywell
        const FORM_POST_IDS = ['nHKjZ-lRSFmIkfg4BsFSTw', 'gaczNyNBRxqP1TBtQom3Eg'];
        if (FORM_POST_IDS.includes(organization.id)) {
          return this.renderPostForm(organization);
        }

        return (
          <TrackedButton
            trackingName="sso_btn_click"
            type="primary"
            rootClassName={className}
            onClick={this.initiateFlow}
          >
            {useGeneralSSOLoginText ? (
              _t('Log in with SSO')
            ) : (
              <FormattedMessage message={_t('Log in with {organizationName}')} organizationName={organization.name} />
            )}
          </TrackedButton>
        );
      }
    } else if (!organization) {
      return (
        <TrackedButton
          trackingName="sso_btn_click"
          type="primary"
          rootClassName={className}
          onClick={this.initiateFlow}
          disabled={true}
        >
          {_t('SSO Provider Not Found')}
        </TrackedButton>
      );
    } else {
      return (
        <Button type="primary" rootClassName={className} onClick={this.initiateFlow} disabled={true}>
          {_t('Loading...')}
        </Button>
      );
    }
  }

  renderPostForm(organization: Organization) {
    const { className } = this.props;
    const { loginUrl } = this.state;
    const uri = new URI(loginUrl);
    const samlRequest = uri.getQueryParamValue('SAMLRequest');
    const relayState = uri.getQueryParamValue('target');
    const partnerSpId = uri.getQueryParamValue('PartnerSpId');

    uri
      .deleteQueryParam('SAMLRequest')
      .deleteQueryParam('SigAlg')
      .deleteQueryParam('proiderId')
      .deleteQueryParam('shire')
      .deleteQueryParam('target')
      .deleteQueryParam('signature')
      .addQueryParam('PartnerSpId', partnerSpId);

    const formattedUrl = uri.toString();

    return (
      <form method="POST" action={formattedUrl} className="nostyle">
        <input type="hidden" name="SAMLRequest" value={samlRequest} />
        <input type="hidden" name="RelayState" value={relayState} />
        <TrackedButton trackingName="sso_btn_click" type="primary" rootClassName={className} style={{ width: '100%' }}>
          <FormattedMessage message={_t('Log in with {organizationName}')} organizationName={organization.name} />
        </TrackedButton>
      </form>
    );
  }

  render() {
    const { organizations, showFooter } = this.props;
    const organization = organizations?.[0];
    const _T = getTranslations();

    if (organization) {
      return (
        <Box rootClassName="c-SsoButton w-100" flexDirection="column">
          {this.renderButton(organization)}
          {showFooter && (
            <Divider>
              <span className="m-y-1 text-muted text-sm"> {_T.OR}</span>
            </Divider>
          )}
        </Box>
      );
    }
    return null;
  }
}

export default compose<Props, PropsFromCaller>(
  // In some places where this is used, we already have organizations data. If not, fetch it.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '<T extends Record<string, unknow... Remove this comment to see the full error message
  branch(({ organizations }: PropsFromCaller) => !organizations, withThirdPartyOrgNameData())
)(SsoButton);
