/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';
import { mapProps } from 'recompose';

import { Grid, Typography2, typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import type { CmlContent } from 'bundles/cml';
import { CML, CMLUtils } from 'bundles/cml';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import Screenshots from 'bundles/enterprise-legacy-xdp/components/miniPDP/Screenshots';
import type { TaskList_courseTypeMetadata_XdpV1_rhymeProjectMember_rhymeProject_tasks as Task } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/TaskList';
import withFragments from 'bundles/graphql/components/withFragments';

import _t from 'i18n!nls/program-common';

const sectionStyles = (theme: Theme) => css`
  .pdp-section-content {
    padding: ${theme.spacing(24, 12, 0)};
  }

  .pdp-section-header {
    margin-bottom: ${theme.spacing(16)};
  }
`;

const styles = {
  textContent: (theme: Theme) => css`
    order: 0;
    padding-right: ${theme.spacing(24)};

    ${theme.breakpoints.down('xs')} {
      padding-right: 0;
    }
  `,
  screenshots: (theme: Theme) => css`
    order: 1;
    position: relative;
    z-index: 2;

    ${theme.breakpoints.down('xs')} {
      order: -1;
    }
  `,
  orderedList: (theme: Theme) => css`
    ${typography2.bodyPrimary}
    counter-reset: step-counter;
    list-style: none;
    margin-top: ${theme.spacing(12)};
    padding-left: ${theme.spacing(24)};
  `,
  listItem: (theme: Theme) => css`
    counter-increment: step-counter;
    position: relative;

    &::before {
      content: counter(step-counter);
      color: ${theme.palette.blue[500]};
      font-weight: bold;
      left: -24px;
      position: absolute;
    }
  `,
};

type PropsFromCaller = {
  tasks: Task[];
  screenshotUrls: string[];
  canExpand?: boolean;
};

export type PropsToComponent = Omit<PropsFromCaller, 'tasks'> & {
  steps: Array<CmlContent>;
};

export const TaskList: React.FunctionComponent<PropsToComponent> = ({ steps, screenshotUrls, canExpand = true }) => {
  const theme = useTheme();
  if (steps.length === 0 || screenshotUrls.length === 0) {
    return null;
  }
  return (
    <PdpSection title={_t('Learn step-by-step')} css={sectionStyles(theme)}>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={5} css={styles.screenshots(theme)}>
          <Screenshots screenshotUrls={screenshotUrls} canExpand={canExpand} />
        </Grid>
        <Grid item xs={12} sm={7} css={styles.textContent(theme)}>
          <Typography2 component="p" variant="bodyPrimary">
            {_t(
              'In a video that plays in a split-screen with your work area, your instructor will walk you through these steps:'
            )}
          </Typography2>
          <ol css={styles.orderedList(theme)}>
            {steps.map((step, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={'task-list-step-' + index} css={styles.listItem(theme)}>
                <CML isCdsEnabled={true} cml={step} />
              </li>
            ))}
          </ol>
        </Grid>
      </Grid>
    </PdpSection>
  );
};

const TaskListWithGQLData: React.ComponentType<PropsFromCaller> = mapProps<PropsToComponent, PropsFromCaller>(
  ({ tasks, screenshotUrls, canExpand }) => ({
    steps: tasks ? tasks.map(({ cml }) => CMLUtils.create(cml.value, cml.dtdId)) : [],
    screenshotUrls,
    canExpand,
  })
)(TaskList);

export default withFragments({
  TaskList: gql`
    fragment TaskList on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      courseTypeMetadata {
        ... on XdpV1_rhymeProjectMember {
          rhymeProject {
            tasks {
              ... on XdpV1_cmlMember {
                cml {
                  dtdId
                  value
                }
              }
            }
          }
        }
      }
    }
  `,
})(TaskListWithGQLData);
