import * as React from 'react';

import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import Retracked from 'js/app/retracked';
import { isAuthenticatedUser } from 'js/lib/user';

import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import type { Stores } from 'bundles/unified-career-academy/appEnv';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import PageNavigation from 'bundles/unified-home-common/components/consumer/PageNavigation';

import 'css!bundles/unified-career-academy/index';

type Props = {
  requestCountryCode?: string | null;
  csrfToken?: string | null;
};

const UnifiedCareerAcademyApp: React.FC<Props> = ({ children }) => {
  const pageNavigation = isAuthenticatedUser() ? <PageNavigation /> : null;

  return (
    <div className="rc-UnifiedCareerAcademyApp">
      <UnifiedAppCheckProvider>
        <PageHeader showLanguagesDropdown subNavigationLinks={pageNavigation} hideMetaNav={isAuthenticatedUser()} />
        <main>{children}</main>
        <PageFooter />
      </UnifiedAppCheckProvider>
    </div>
  );
};

export default compose(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'unified_career_academy',
      page: 'consumer_career_academy',
    },
  })),
  connectToStores<Props, {}, Stores>(['ApplicationStore'], ({ ApplicationStore }, props) => ({
    ...props,
    csrfToken: ApplicationStore.getState().csrfToken,
    requestCountryCode: ApplicationStore.getState().requestCountryCode,
  })),
  withGDPR,
  withCCPA
)(UnifiedCareerAcademyApp);
