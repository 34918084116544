import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import NaptimeResource from './NaptimeResource';

export type S12nCourseOwnerships = {
  owns: boolean;
  courseId: string;
};

class ProductOwnerships extends NaptimeResource {
  static RESOURCE_NAME = 'productOwnerships.v1';

  owns!: boolean;

  productId!: string;

  productType!: string;

  expiredOwns!: boolean;

  // Only always returned if the product is a s12n
  s12nCourseOwnerships!: Array<S12nCourseOwnerships> | null;

  @requireFields('s12nCourseOwnerships')
  get unownedCourses(): Array<string> {
    const s12nCourseOwnerships = this.s12nCourseOwnerships ?? [];
    return s12nCourseOwnerships.filter(({ owns }) => !owns).map(({ courseId }) => courseId);
  }

  static byUser(userId: string | number, data = {}) {
    return this.finder('getByUser', Object.assign({ params: { id: userId } }, data));
  }
}

export default ProductOwnerships;
