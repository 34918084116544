import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { getButtonTextByCourseProgress } from 'bundles/program-home/utils/CourseUtils';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'a'>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);
type Props = {
  trackingNamePrefix: string;
  link: string;
  progress?: number;
  buttonTextOverride?: string;
  courseName: string;
};

const CourseWithProgressLinkButton = (props: Props) => {
  const { buttonTextOverride, progress = 0, link, trackingNamePrefix, courseName } = props;
  const buttonText = buttonTextOverride || getButtonTextByCourseProgress(progress);
  const trackingName = `${trackingNamePrefix}_${buttonText}_button`;

  return (
    <TrackedButton
      {...Button.defaultProps}
      variant="primary"
      component="a"
      size="small"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      trackingName={trackingName}
      aria-label={`${buttonText}: ${courseName}`}
    >
      {buttonText}
    </TrackedButton>
  );
};

export default CourseWithProgressLinkButton;
