import { useMemo } from 'react';

import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import { getDefaultTools } from 'bundles/cml/editor/components/toolbars/utils';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Options = ToolOptions & {
  customTools?: ToolsKeys[];
};

export const useCustomTools = ({ monospace, pageless, isLearnerUpload, customTools, enableWidgets }: Options) => {
  const options: ToolOptions = useMemo(
    () => ({
      monospace,
      pageless,
      isLearnerUpload,
      enableWidgets,
    }),
    [monospace, pageless, isLearnerUpload, enableWidgets]
  );

  const tools = useMemo(() => customTools || getDefaultTools(options), [options, customTools]);
  return { tools, options };
};
