import type * as React from 'react';

import { compose, withProps } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import type { ActionContext } from 'js/lib/ActionContext';

import { updateAssetData } from 'bundles/asset-admin/actions/AssetAdminActions';
import { withFluxibleContext } from 'bundles/cml/editor/components/elements/image/utils/withFluxibleContext';
import type { UpdateAssetDataPayload } from 'bundles/cml/editor/types/assetTypes';

type PropsFromStore = {
  updateDataFailed: boolean;
};

type PropsFromFluxible = {
  context: ActionContext;
};

type PropsWithAction = {
  updateAssetData: (payload: UpdateAssetDataPayload) => void;
};

export type PropsToComponent = PropsFromStore & PropsWithAction;

export const withAssetAdminStore = <PropsFromCaller extends {}>(
  Component: React.ComponentType<PropsFromCaller & PropsToComponent>
) => {
  const Composed = compose<PropsFromCaller & PropsToComponent, PropsFromCaller>(
    connectToStores(['AssetAdminStore'], ({ AssetAdminStore }) => {
      return {
        updateDataFailed: AssetAdminStore.hasUpdateFailed(),
      };
    }),
    withProps<PropsWithAction, PropsFromFluxible>(({ context }) => {
      return {
        updateAssetData: (payload: UpdateAssetDataPayload) => context?.executeAction(updateAssetData, payload),
      };
    })
  )(Component);

  return withFluxibleContext(Composed);
};
