import type { CourseType } from 'bundles/program-common/utils/courseTypeMetadataUtils';

import _t from 'i18n!nls/program-common';

class HowItWorksViewController {
  title: string;

  leftCaption: string;

  rightCaption: string;

  constructor(courseType: CourseType) {
    switch (courseType) {
      case 'GuidedProject':
        this.title = _t('How Guided Projects work');
        this.leftCaption = _t('Your workspace is a cloud desktop right in your browser, no download required');
        this.rightCaption = _t('In a split-screen video, your instructor guides you step-by-step');
        break;
      case 'Project':
        this.title = _t('How Projects work');
        this.leftCaption = _t('Learn a new tool or skill in an interactive, hands-on environment');
        this.rightCaption = _t("You'll gain access to software and tools in a cloud workspace - no download required");
        break;
      case 'StandardCourse':
      default:
        this.title = '';
        this.leftCaption = '';
        this.rightCaption = '';
    }
  }
}

export default HowItWorksViewController;
