/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Tag } from '@coursera/cds-core';

import { getTranslatedProductName } from 'bundles/browse/utils';

import _t from 'i18n!nls/program-common';

type Size = 'sm' | 'lg';

type Props = {
  className?: string;
  'data-e2e'?: string;
  size?: Size;
  productType?: string;
  'aria-label'?: string;
};

const getStyle = (fontColor: string, backgroundColor: string, size: Size, borderColor?: string) => {
  return css({
    color: fontColor,
    backgroundColor,
    padding: '0 6px',
    textTransform: 'uppercase',
    fontSize: size === 'sm' ? '12px' : '14px',
    lineHeight: size === 'sm' ? '16px' : '24px',
    fontWeight: 'bold',
    border: `1px solid ${borderColor || backgroundColor}`,
    borderRadius: '4px',
    display: 'inline-block',
    maxHeight: size === 'sm' ? '18px' : '24px',
  });
};

export const CreditBadge: React.FC<Props> = (props) => {
  return (
    <Tag
      className={props.className}
      data-e2e={props['data-e2e']}
      aria-label={props['aria-label']}
      variant="category"
      color="highlight1"
      priority="primary"
    >
      {_t('Credit')}
    </Tag>
  );
};

export const RecommendedForCreditBadge: React.FC<Props> = (props) => {
  return (
    <Tag
      className={props.className}
      variant="category"
      data-e2e={props['data-e2e']}
      color="highlight1"
      priority="secondary"
    >
      {_t('Recommended for credit')}
    </Tag>
  );
};

export const ExclusiveBadge: React.FC<Props> = (props) => {
  return (
    <Tag
      className={props.className}
      data-e2e={props['data-e2e']}
      variant="category"
      color="highlight2"
      priority="primary"
    >
      {_t('Exclusive')}
    </Tag>
  );
};

export const PrivateBadge: React.FC<Props> = (props) => {
  return (
    <Tag
      className={props.className}
      data-e2e={props['data-e2e']}
      variant="category"
      color="highlight2"
      priority="primary"
    >
      {_t('Private')}
    </Tag>
  );
};

export const CourseBadge: React.FC<Props> = (props) => {
  return (
    <Tag className={props.className} data-e2e={props['data-e2e']} variant="category" color="default" priority="primary">
      {_t('Course')}
    </Tag>
  );
};

export const BetaBadge: React.FC<Props> = (props) => {
  return (
    <span
      className={props.className}
      data-e2e={props['data-e2e']}
      css={getStyle('#EEEEEE', '#000000', props.size || 'sm')}
    >
      {_t('Beta')}
    </span>
  );
};

export const ProductTypeBadge: React.FC<Props> = (props) => {
  const productName = getTranslatedProductName(props.productType);
  return productName ? (
    <Tag className={props.className} data-e2e={props['data-e2e']} variant="category" color="default" priority="primary">
      {productName}
    </Tag>
  ) : null;
};

export const AutoEnrollBadge: React.FC<Props> = (props) => {
  return (
    <Tag className={props.className} data-e2e={props['data-e2e']} variant="status" priority="primary" color="default">
      {_t('Auto-Enroll')}
    </Tag>
  );
};
