/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Theme } from '@coursera/cds-core';
import { Button, Typography, Typography2, typography2, useTheme } from '@coursera/cds-core';
import { Radio, RadioGroup } from '@coursera/coursera-ui';

import CenteredLoadingSpinner from 'bundles/assess-common/components/CenteredLoadingSpinner';
import { CourseInfo } from 'bundles/payments/components/finaid-2021/CourseInfo';
import FinancialAidApplicationsCountNotification from 'bundles/payments/components/finaid-2021/FinancialAidApplicationsCountNotification';
import { FindAidState as APPLICATION_STATUS } from 'bundles/payments/components/finaid-2021/enhancers/types';
import withCoursesAndFinancialAidApplications from 'bundles/payments/components/finaid-2021/enhancers/withCoursesAndFinancialAidApplications';
import type { Props } from 'bundles/payments/components/finaid-2021/enhancers/withCoursesAndFinancialAidApplications';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/finaid';

const useStyles = (theme: Theme) => ({
  header: css({
    color: 'var(--cds-color-white-0)',
    backgroundColor: theme.palette.blue[900],
    padding: theme.spacing(48),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(32),
    },
  }),
  body: css({
    paddingTop: theme.spacing(32),
    paddingLeft: theme.spacing(48),
    paddingRight: theme.spacing(48),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(32),
      paddingRight: theme.spacing(32),
    },
  }),
  radio: css({
    padding: theme.spacing(16, 0),
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    label: {
      display: 'flex',
      alignItems: 'flex-start',
      whiteSpace: 'pre-wrap',
      span: {
        outline: 'none !important',
      },
    },
  }),
  radioGroup: css({
    legend: {
      display: 'none',
    },
    width: '100%',
  }),
  buttonContainer: css({
    padding: theme.spacing(48),
    position: 'sticky',
    bottom: 0,
    zIndex: 5000, // ensures that this container is positioned above CDS Radio elements
    backgroundColor: 'var(--cds-color-white-0)',
    boxShadow: `2px -2px ${theme.palette.gray[300]}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(32),
    },
  }),
  courseChoiceModal: css({
    'div.rc-Modal': {
      'div.c-modal-content': {
        padding: theme.spacing(0),

        'div.c-modal-x-out': {
          top: '28px !important',
          right: ' 28px !important',
          [theme.breakpoints.down('xs')]: {
            top: `${theme.spacing(16)} !important`,
            right: `${theme.spacing(16)} !important`,
          },
        },
      },
    },
  }),
});

export const CourseChoiceModal: React.FC<Props> = ({
  handleClose,
  loading,
  enhancedCourses,
  partnerName,
  productVariant,
  handleSubmit,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const defaultCourse = useMemo(
    () =>
      enhancedCourses?.find(
        (course) =>
          (course.applicationState === APPLICATION_STATUS.Approved && !course.ownsProduct) ||
          (course.applicationState === APPLICATION_STATUS.ApprovedPaymentSuccessful && !course.ownsProduct) ||
          course.applicationState === APPLICATION_STATUS.PaymentExpired ||
          course.applicationState === 'NONE'
      ),
    [enhancedCourses]
  );

  const [courseId, setCourseId] = useState<string | undefined>(defaultCourse?.courseId);
  const [course, setCourse] = useState(defaultCourse);

  useEffect(() => {
    if (!loading && (!courseId || !course) && enhancedCourses) {
      setCourseId(defaultCourse?.courseId);
      setCourse(defaultCourse);
    }
  }, [loading, courseId, defaultCourse, course, enhancedCourses]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCourseId(e.target.value);
    setCourse(enhancedCourses?.find((enhancedCourse) => enhancedCourse.courseId === e.target.value));
  };

  const submitChoice = () => {
    handleSubmit(courseId);
  };

  const courseRadios = useMemo(
    () => {
      return enhancedCourses?.map((enhancedCourse, index) => {
        const shouldDisable =
          (enhancedCourse.applicationState === APPLICATION_STATUS.Approved && enhancedCourse.ownsProduct) ||
          (enhancedCourse.applicationState === APPLICATION_STATUS.ApprovedPaymentSuccessful &&
            enhancedCourse.ownsProduct) ||
          enhancedCourse.applicationState === APPLICATION_STATUS.PaymentPending ||
          enhancedCourse.applicationState === APPLICATION_STATUS.Pending;

        return (
          <div css={[{ ...typography2.bodyPrimary }, styles.radio]} key={enhancedCourse.courseId}>
            <Radio value={enhancedCourse.courseId} size={28} disabled={shouldDisable}>
              <Typography2 component="p" variant="bodyPrimary" color="inherit">
                <FormattedMessage
                  message={_t('{courseNumber} {courseName}')}
                  courseNumber={<strong>{_t('Course #{courseNumber}: ', { courseNumber: index + 1 })}</strong>}
                  courseName={enhancedCourse.courseName}
                />
              </Typography2>

              {shouldDisable &&
                (enhancedCourse.applicationState === APPLICATION_STATUS.Approved ||
                  enhancedCourse.applicationState === APPLICATION_STATUS.ApprovedPaymentSuccessful) && (
                  <Typography2 component="p" variant="bodyPrimary" css={{ color: theme.palette.green[600] }}>
                    {_t('Your financial aid application for this course has already been approved')}
                  </Typography2>
                )}

              {shouldDisable && enhancedCourse.applicationState === APPLICATION_STATUS.PaymentPending && (
                <Typography2 component="p" variant="bodyPrimary" css={{ color: theme.palette.green[600] }}>
                  {_t('Your financial aid application for this course has already been approved and pending payment')}
                </Typography2>
              )}
              {shouldDisable && enhancedCourse.applicationState === APPLICATION_STATUS.Pending && (
                <Typography2 component="p" variant="bodyPrimary" css={{ color: theme.palette.green[600] }}>
                  {_t('You already have a pending financial aid application for this course')}
                </Typography2>
              )}
            </Radio>
          </div>
        );
      });
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enhancedCourses, styles.radio, theme.palette.green, typography2.bodyPrimary]
  );

  const modalTitleText =
    productVariant === 'ProfessionalCertificateS12n'
      ? _t('This Professional Certificate is a #{courseLength}-course series.', {
          courseLength: enhancedCourses?.length,
        })
      : _t('This Specialization is a #{courseLength}-course series.', {
          courseLength: enhancedCourses?.length,
        });

  return (
    <div className="rc-CourseChoiceModal" css={styles.courseChoiceModal}>
      <Modal modalName="Course choice modal" shouldFocusOnXButton={true} handleClose={handleClose}>
        <div className="title-body-container">
          {loading ? (
            <CenteredLoadingSpinner />
          ) : (
            <Fragment>
              <div className="title modal-container" css={styles.header}>
                <h2 className="modal-title center">{modalTitleText}</h2>
              </div>

              <div className="content modal-container" css={styles.body}>
                <div style={{ minHeight: '51px', width: '100%', marginBottom: '16px' }}>
                  <FinancialAidApplicationsCountNotification />
                </div>

                {courseId && course && course.catalogPrice && (
                  <CourseInfo
                    courseId={courseId}
                    currencyCode={course.catalogPrice.currencyCode}
                    originalPrice={course.catalogPrice.amount}
                    photoUrl={course.photoUrl}
                    courseName={course.courseName}
                    partnerName={partnerName}
                  />
                )}

                <Typography
                  variant="h2semibold"
                  css={css({
                    padding: '25px 0',
                  })}
                >
                  {_t('Which course would you like to apply aid to?')}
                </Typography>

                <RadioGroup onChange={handleChange} value={courseId}>
                  {courseRadios}
                </RadioGroup>
              </div>

              <div css={styles.buttonContainer}>
                <Button
                  data-testid="continue-button"
                  variant="primary"
                  disabled={!courseId}
                  onClick={submitChoice}
                  fullWidth
                >
                  {_t('Next')}
                </Button>

                <Button data-testid="cancel-button" variant="ghost" onClick={handleClose} fullWidth>
                  {_t('Cancel Request')}
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default withCoursesAndFinancialAidApplications(CourseChoiceModal);
