/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import { useMutation } from '@apollo/client';
import type {
  CourseraTierSubscriptions_EndRazorpaySubscriptionForCourseraTierProductInput as EndRazorpaySubscriptionForCourseraTierProductInput,
  CourseraTierSubscriptions_EndRazorpaySubscriptionForCourseraTierProductResult as EndRazorpaySubscriptionResult,
} from '__generated__/graphql-types';

import logger from 'js/app/loggerSingleton';
import fullStory from 'js/lib/fullStoryUtils';
import { useRetracked } from 'js/lib/retracked';

import { Dialog, Typography2 } from '@coursera/cds-core';

import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import { getTranslatedProductName } from 'bundles/browse/utils';
import EndRazorpaySubscriptionForCourseraTierProduct from 'bundles/coursera-plus/api/EndRazorpaySubscriptionForCourseraTierProduct.graphql';
import { SizedImage } from 'bundles/coursera-plus/components/subscriptionTiers/subscriptionTiersUtils';
import StepOneIndiaUpgradeModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IndiaUpgrade/StepOneIndiaUpgradeModal';
import StepTwoIndiaUpgradeModal from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/IndiaUpgrade/StepTwoIndiaUpgradeModal';
import {
  getCopyForStepOneIndiaTiersTest,
  getCopyForStepTwoModalIndiaTiersTest,
  getStepCopy,
} from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/upgradeCopies';
import courseraPlusProductVariants from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import {
  saveRedirectUrlAfterUpgrade,
  setIndiaTiersUpgradeData,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import { useExistingCourseraLiteSubscription } from 'bundles/coursera-plus/utils/withExistingCourseraLiteSubscription';
import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import type { SubmitEnrollmentPromiseOptions } from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import courseraPlusExperiments from 'bundles/epic/clients/courseraPlus';
import { ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';

const styles = {
  courseraPlusLogo: css`
    display: block;
    margin: 0 0 16px 48px;
  `,
  stepCopy: css`
    position: absolute;
    top: 24px;
    left: 48px;
    color: #827e7e;
  `,
};

type EndRazorpaySubscriptionInput = {
  input: EndRazorpaySubscriptionForCourseraTierProductInput;
};

type Props = {
  onClose: () => void;
};

const IndiaPriceTestUpgradeModal: React.FC<Props> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const copy = getStepCopy(step);
  const [ctaState, setCtaState] = useState<'loading' | 'failed' | undefined>(undefined);
  const retrackedTrack = useRetracked();
  const { course, s12n, courseraPlusProductPrice, enrollmentAvailableChoices } = usePageData();
  const hasCourseraPlusFreeTrial = enrollmentAvailableChoices.canSubscribeToCourseraPlusFreeTrial;
  const { existingCourseraLiteSubscription } = useExistingCourseraLiteSubscription();
  const [endRazorpaySubscriptionForCourseraTierProduct, _] = useMutation<
    EndRazorpaySubscriptionResult,
    EndRazorpaySubscriptionInput
  >(EndRazorpaySubscriptionForCourseraTierProduct);

  React.useEffect(() => {
    if (courseraPlusExperiments.preview('subscriptionTiersIndiaFullstoryEnabled')) {
      fullStory.init();
      fullStory.set({
        hasSeenIndiaTiersHackyUpgradeModal_bool: true, // eslint-disable-line camelcase
      });
    }
  }, []);

  if (!existingCourseraLiteSubscription || !courseraPlusProductPrice) {
    logger.error('Missing Coursera Lite subscription or Coursera Plus price not found.');
    return null;
  }

  let productTypeName: string = PRODUCT_TYPE_NAMES.COURSE;
  if (s12n) {
    if (s12n.productVariant === ProfessionalCertificateS12n) {
      productTypeName = PRODUCT_TYPE_NAMES.CERTIFICATE;
    } else {
      productTypeName = PRODUCT_TYPE_NAMES.SPECIALIZATION;
    }
  }
  const translatedProductTypeName: string = getTranslatedProductName(productTypeName) as string;

  const stepOneCopy = getCopyForStepOneIndiaTiersTest({
    courseraPlusProductPrice,
    hasCourseraPlusFreeTrial,
    translatedProductTypeName,
    s12n,
  });
  const stepTwoCopy = getCopyForStepTwoModalIndiaTiersTest(translatedProductTypeName);

  const handleUpgradeToPlus = () => {
    setCtaState('loading');
    endRazorpaySubscriptionForCourseraTierProduct({
      variables: {
        input: {
          subscriptionId: existingCourseraLiteSubscription?.id,
        },
      },
      context: {
        clientName: 'gatewayGql',
      },
    })
      .then(() => {
        saveRedirectUrlAfterUpgrade();
        setIndiaTiersUpgradeData({
          liteSubscriptionId: existingCourseraLiteSubscription?.id,
          s12nId: s12n?.id,
          courseId: course?.id,
        });
        const handleSubmitPromise = choiceTypeToHandleSubmitPromise[EnrollmentChoiceTypes.SUBSCRIBE_TO_COURSERA_PLUS];
        const options: SubmitEnrollmentPromiseOptions = {
          productItemId: courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
          s12nId: s12n?.id,
          courseId: course?.id,
        };
        submitEnrollmentPromise({ handleSubmitPromise, options });
      })
      .catch((error) => {
        setCtaState('failed');
        logger.error(error);
      });
  };

  const handleClose = () => {
    onClose();
    retrackedTrack({
      trackingData: { s12nId: s12n?.id, courseId: course?.id },
      trackingName: 'india_tiers_upgrade_modal_close',
      action: 'click',
    });
  };

  return (
    <Dialog aria-describedby="dialog-content" open variant="standard" onClose={handleClose}>
      <Typography2 component="p" variant="bodySecondary" css={styles.stepCopy}>
        {copy.step}
      </Typography2>
      <span css={styles.courseraPlusLogo}>
        <SizedImage src={logo.PLUS_FULL_BLUE} width={159} height={16} alt={copy.courseraPlus} />
      </span>
      {step === 1 && (
        <StepOneIndiaUpgradeModal s12n={s12n} course={course} goToNextStep={() => setStep(2)} copy={stepOneCopy} />
      )}
      {step === 2 && (
        <StepTwoIndiaUpgradeModal copy={stepTwoCopy} handleUpgradeToPlus={handleUpgradeToPlus} ctaState={ctaState} />
      )}
    </Dialog>
  );
};

export default IndiaPriceTestUpgradeModal;
