/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import { isHotkey } from 'is-hotkey';

import { MuiClickAwayListener, MuiFade } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ToolButtonFactory } from 'bundles/cml/editor/components/toolbars/constants';
import { hoverMenu } from 'bundles/cml/shared/styles';
import type { ToolbarToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const styles = {
  toolbar: css`
    ${hoverMenu()}
    border-radius: 2px;
    z-index: ${zIndex.xxl};
  `,
};

type Props = {
  anchorEl: HTMLElement | null;
  onChange: () => void;
  onClose: () => void;
  isDisabled?: boolean;
  tools: ToolsKeys[];
  pageless?: boolean;
  menuDirection?: 'top' | 'bottom';
  overflowTools: ToolbarToolsKeys[];
  autoFocus: boolean;
};

const isEscapeKey = isHotkey('escape');

const MoreFloatingMenu: React.FC<Props> = ({
  anchorEl,
  onChange,
  onClose,
  overflowTools,
  tools,
  autoFocus,
  ...props
}) => {
  const {
    x: left,
    y: top,
    refs: { setReference, setFloating },
    strategy: position,
  } = useFloating({
    placement: 'bottom-end',
    strategy: 'fixed',
    middleware: [offset({ crossAxis: 12, mainAxis: 12 }), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  useEffect(() => {
    setReference(anchorEl);
  }, [anchorEl, setReference]);

  const handleChange = () => {
    onChange();
    onClose();
  };

  const handleKeyDown = ({ nativeEvent: event }: React.KeyboardEvent) => {
    if (isEscapeKey(event)) {
      onClose();
      anchorEl?.focus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // if focus moves out of the overflow menu, close it
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      onClose();
    }
  };

  const expandButtons = overflowTools.map((tool: ToolbarToolsKeys) => {
    const ToolbarButton = ToolButtonFactory[tool]();
    return <ToolbarButton key={tool} {...props} onChange={handleChange} tools={tools} />;
  });

  return (
    <MuiClickAwayListener onClickAway={onClose}>
      <MuiFade in={true}>
        <Toolbar
          ref={setFloating}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus={autoFocus}
          css={styles.toolbar}
          style={{
            left: left ?? '',
            top: top ?? '',
            position,
          }}
        >
          {expandButtons}
        </Toolbar>
      </MuiFade>
    </MuiClickAwayListener>
  );
};

export default MoreFloatingMenu;
