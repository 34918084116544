import * as React from 'react';

/* Temporary until this icon can be added to CDS */
const DropdownIcon: React.FC = () => {
  return (
    <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.640137 1L2.64014 3L4.64014 1" stroke="currentColor" strokeMiterlimit="10" />
    </svg>
  );
};

export default React.memo(DropdownIcon);
