/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import AssetMenu from 'bundles/cml/editor/components/elements/asset/AssetMenu';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import AssetRenderer from 'bundles/cml/shared/components/asset/Asset';
import { getAssetAttributes } from 'bundles/cml/shared/components/asset/assetUtils';
import { useAsset } from 'bundles/cml/shared/hooks/useAsset';
import type { AssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { Asset as AssetType } from 'bundles/cml/shared/types/assetTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  asset: css`
    position: relative;

    .cml-asset {
      &:hover {
        outline: 2px solid var(--cds-color-interactive-primary);
      }
    }
  `,
  selected: css`
    .cml-asset {
      outline: 2px solid var(--cds-color-callouts-tertiary) !important;
    }
  `,
};

const Asset: React.FC<RenderElementProps> = (props) => {
  const { element, children } = props;

  const staticEditor = useSlateStatic();
  const selected = useSelected();
  const readonly = useReadOnly();

  const { assetManager, pageless } = useContext(AssetContext);
  const { focused, setFocused } = useContext(FocusContext);

  const ref = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (!readonly) {
      setFocused(true);
      setMenuOpen(true);
    }
  }, [setFocused, readonly]);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
    setFocused(false);
  }, [setFocused]);

  const assetElement = element as AssetElement;
  const { id } = assetElement;

  const handleUpdateAsset = useCallback(
    (newAsset: AssetType) => {
      if (!newAsset) {
        return;
      }

      const newAttributes = getAssetAttributes(newAsset);
      const path = ReactEditor.findPath(staticEditor, element);
      Transforms.setNodes(staticEditor, newAttributes, { at: path });
    },
    [staticEditor, element]
  );

  const { asset, assetData } = useAsset(id, assetManager, { onUpdateAsset: handleUpdateAsset });

  useEffect(() => {
    if (!focused || !selected || readonly) {
      setMenuOpen(false);
    }
  }, [focused, selected, readonly]);

  return (
    <AssetRenderer
      ref={ref}
      {...props}
      assetData={(assetData as AssetData) || assetElement.assetData}
      readonly={readonly}
      onClick={handleOpen}
      css={[styles.asset, selected && focused && !readonly && styles.selected]}
    >
      {children}
      {menuOpen && (
        <AssetMenu
          pageless={pageless}
          anchorEl={ref.current}
          asset={asset}
          element={assetElement}
          onClose={handleClose}
        />
      )}
    </AssetRenderer>
  );
};

export default Asset;
