import * as React from 'react';

import PropTypes from 'prop-types';

import type { ActionContext } from 'js/lib/ActionContext';

export const withFluxibleContextAsProps =
  <P extends ActionContext>() =>
  (WrappedComponent: React.ComponentType<P>) => {
    return class Wrapper extends React.Component<P> {
      static displayName = `withFluxibleContextAsProps(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;

      static contextTypes = {
        executeAction: PropTypes.func,
      };

      render() {
        const { executeAction } = this.context;
        return <WrappedComponent {...this.props} executeAction={executeAction} />;
      }
    };
  };

export default withFluxibleContextAsProps;
