/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Fragment, memo } from 'react';

import { CardBodyContent, CardCategory, CardMetadata, ProductCard, Typography2 } from '@coursera/cds-core';
import { VideoIcon } from '@coursera/cds-icons';
import type { ProductType } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import useIsLihpHome from 'bundles/collections-carousel/utils/useIsLihp';
import { getCollectionType } from 'bundles/collections-common/utils/collectionsUtils';
import { getTempBadgeStylesLihpCdsProductCards } from 'bundles/collections-common/utils/experimentUtils';
import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { SearchResultsBadges } from 'bundles/product-card/components/Badges';
import GenAiBadge from 'bundles/product-card/components/GenAiBadge';
import ProductCardCourseraPlus from 'bundles/product-card/components/legacy/ProductCardCourseraPlus';
import ProductCardImage from 'bundles/product-card/components/legacy/ProductCardImage';
import ProductCardReviews from 'bundles/product-card/components/legacy/ProductCardReviews';
import type { ProductCardV2Type } from 'bundles/product-card/components/types/ProductCardV2';
import { isPartOfProductCardRatingsReviewsLIHPExperiment } from 'bundles/product-card/components/utils/productCardRatingsReviewsUtils';
import {
  capitalizeAndTranslateEachWordInEntityType,
  eventingV3ProductType,
  extractEventId,
  getImagePropOverrides,
  getPartnerNamesAndLogos,
} from 'bundles/product-card/components/utils/productCardV2Utils';
import useInfiniteScrollPageObserver from 'bundles/product-card/hooks/useInfiniteScrollPageObserver';
import { getAriaLabel } from 'bundles/product-card/utils/productCardUtils';
import { shouldShowRecentlyUpdatedGenAiBadge } from 'bundles/product-card/utils/recentlyUpdatedBadgeUtils';
import isCreditOffered from 'bundles/search-common/components/search-cards/isCreditOffered';
import isPremiumProduct from 'bundles/search-common/components/search-cards/isPremiumProduct';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import type { NoResultsSearchPageEventingData } from 'bundles/search-common/providers/searchTypes';
import { isPathwaySlug } from 'bundles/search-common/utils/experimentUtils';
import useIsEqp from 'bundles/search-common/utils/useIsEqp';
import { getNoSearchResultsCollectionData } from 'bundles/search-v2/utils/withNoSearchResultsRecommendations';

import _t from 'i18n!nls/product-card';

// this should not be a long-lived const. See more:
// https://docs.google.com/document/d/1aKr0aaeUxHIYjbyOShLU6tFNC4TBO5QKjtHjiD7yu98/edit#heading=h.418n0c5xazlx
export const temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds = css`
  .cds-ProductCard-statusTagsOverlay {
    left: unset !important;
    right: 0;

    .cds-tag-status {
      border: 1px solid #929599 !important;

      > span {
        color: #1f1f1f;
      }
    }
  }
`;

const styles = {
  cardContainer: css`
    min-height: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .cds-ProductCard-partnerLogos img {
      vertical-align: unset;
    }

    .cds-CommonCard-previewImage {
      position: relative;
    }
  `,
  listCardContainer: css`
    /* intentionally unset min-height for list card that was default from CDS */
    min-height: unset;

    .cds-ProductCard-list {
      height: inherit;

      /* intentionally unset min-width for list card that was default from CDS */
      min-width: unset;
    }
  `,
  sessionContainer: () => css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  `,
  tempStyles: () => temporaryStylesThatShouldBeAdoptedByCDSIfExperimentSucceeds,
  skillsLabel: () => css`
    color: var(--cds-color-grey-975);
    font-weight: 600;
  `,
  iconWrapper: css`
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(0 0 0 / 40%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  icon: css`
    width: 30%;
    height: 30%;
  `,
  ratingOverride: css`
    .product-reviews {
      margin-bottom: unset !important;
    }
  `,
  listCardContainerBorder: css`
    border-radius: var(--cds-border-radius-100);
    border: 2px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
};

export const ProductCardV2: React.FC<ProductCardV2Type> = ({
  cardProps,
  productCardImageProps,
  objectUrl,
  variant = 'grid',
  ownsCourseraPlus,
  ownsCourseraLite,
  forceOpenInNewTab,
  customLinkProps,
  useTempStylesForTags,
  isSearchPage,
  isDegreePage,
  isExpandedCollection,
  defaultCardsCount,
  isInCollection,
  indexName,
  shouldPrioritizeImageLoading,
  page,
  setScrollPage,
  applyBorderToListVariant,
}) => {
  const {
    id,
    name,
    imageUrl,
    productType,
    productDifficultyLevel,
    productDuration,
    partners,
    partnerLogos,
    isCostFree,
    skills,
    numProductRatings,
    avgProductRating,
    isPathwayContent,
    isPartOfCourseraPlus,
    session,
    cobrandingEnabled,
    cardPosition,
    isNewContent,
    slug,
    badges,
    titleComponent,
  } = cardProps;
  const isEQP = useIsEqp();
  const showSearchedQuery = isSearchPage || isDegreePage || isEQP;
  const useDynamicDegreePathway = GrowthDiscoveryEpicClient.get('useDynamicDegreePathway');
  const showUpdatedNoResultsPage = GrowthDiscoveryEpicClient.preview('showUpdatedNoResultsPage');
  const isLihpHome = useIsLihpHome();
  if (isLihpHome) isPartOfProductCardRatingsReviewsLIHPExperiment(); // impress to LIHP rating experiment
  const lihpTempBadgeStyles = getTempBadgeStylesLihpCdsProductCards();
  const noResultsSearchPageEventingData = customLinkProps?.data?.noResults as NoResultsSearchPageEventingData;
  const shouldAddCollectionData = noResultsSearchPageEventingData || isInCollection;
  const [scrollPage] = React.useState(page);

  const trackingData = {
    ...(shouldAddCollectionData && {
      carousel: {
        id: (customLinkProps?.data.collectionId as string) ?? '',
        name: customLinkProps?.data.collectionName as string,
        section: customLinkProps?.data.collectionIndex as number,
        model: customLinkProps?.data.collectionId as string,
        type: getCollectionType(customLinkProps?.data.collectionId as string),
      },
    }),
    product: {
      id: extractEventId(id),
      name,
      slug: slug ?? objectUrl ?? '',
      type: eventingV3ProductType(productType) as ProductType,
    },
    ...(showSearchedQuery && { searchedQuery: customLinkProps?.data?.query as string }),
    productCard: {
      ...(typeof customLinkProps?.data.productCard === 'object' ? customLinkProps?.data.productCard : {}),
      index: cardPosition ?? 0,
      ...(indexName && { algoliaIndexName: indexName }),
    },
  };

  if (noResultsSearchPageEventingData && !showUpdatedNoResultsPage) {
    trackingData.carousel = getNoSearchResultsCollectionData(cardPosition);
  }

  const track = useTracker();
  const sendViewEventsCarousel = !(
    isInCollection &&
    defaultCardsCount &&
    isExpandedCollection &&
    cardPosition < defaultCardsCount
  );

  const trackedCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    trackingData,
    [],
    { fullyVisible: true, initialDelay: 1000 }
  );
  const unTrackedCardref = React.useRef<HTMLDivElement>(null);
  const cardRef = sendViewEventsCarousel ? trackedCardRef : unTrackedCardref;
  useInfiniteScrollPageObserver(cardRef, scrollPage, setScrollPage);

  const statusTags = [];

  if (isSearchPage && isNewContent) {
    statusTags.push(_t('New'));
  }
  if (isCostFree) {
    statusTags.push(_t('Free'));
  }
  if (slug && shouldShowRecentlyUpdatedGenAiBadge(slug)) {
    statusTags.push(<GenAiBadge />);
  }

  const entityType = capitalizeAndTranslateEachWordInEntityType(productType);
  const metaData: string[] = [productDifficultyLevel ?? '', entityType ?? '', productDuration ?? ''].filter(
    (val) => !!val
  );

  const ratingElement = (
    <div css={styles.ratingOverride}>
      <ProductCardReviews avgProductRating={avgProductRating} numProductRatings={numProductRatings} />
    </div>
  );
  const cardSkills = !!skills?.length && (
    <CardBodyContent>
      <Typography2 component="p" color="supportText" variant="bodySecondary">
        <b css={[styles.skillsLabel]}>{_t("Skills you'll gain")}: </b>
        {skills.join(', ')}
      </Typography2>
    </CardBodyContent>
  );
  const isDegree = productType === LEARNING_PRODUCTS.Degree;
  const isClip = productType === LEARNING_PRODUCTS.Video;
  const showCreditOffered = isCreditOffered(productType as (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]);
  const partnerNamesAndLogos = partners && getPartnerNamesAndLogos(partners, cobrandingEnabled, partnerLogos);
  const shouldShowThePathwayBadge = isPathwaySlug(slug ?? '');
  const shouldShowCreditBadge =
    productType && isPremiumProduct(productType) && (isDegree || showCreditOffered) && !isDegreePage;

  return (
    <div
      css={[
        styles.cardContainer,
        variant === 'list' && styles.listCardContainer,
        variant === 'grid' && ((isLihpHome && lihpTempBadgeStyles) || useTempStylesForTags) && styles.tempStyles,
        variant === 'list' && applyBorderToListVariant && styles.listCardContainerBorder,
      ]}
      data-testid="product-card-cds"
    >
      <ProductCard
        onClick={() => {
          track('click_product_card', trackingData);
        }}
        key={trackingData.productCard.index}
        ref={cardRef}
        variant={variant}
        partners={partnerNamesAndLogos}
        previewImageSrc={imageUrl}
        renderPreviewImage={() =>
          isClip ? (
            <Fragment>
              <ProductCardImage
                id={id}
                slug={slug}
                imageUrl={imageUrl}
                partnerLogos={partnerLogos}
                cardPosition={cardPosition}
                productCardImageProps={productCardImageProps}
                enableFluidWidthForLazyImg={true}
                shouldPrioritizeImageLoading={shouldPrioritizeImageLoading}
              />
              <div css={styles.iconWrapper}>
                <VideoIcon css={styles.icon} color="invert" />
              </div>
            </Fragment>
          ) : (
            <ProductCardImage
              id={id}
              slug={slug}
              imageUrl={imageUrl}
              partnerLogos={partnerLogos}
              cardPosition={cardPosition}
              productCardImageProps={{
                ...productCardImageProps,
                ...getImagePropOverrides(partners ?? [], imageUrl ?? ''),
              }}
              enableFluidWidthForLazyImg={true}
              shouldPrioritizeImageLoading={shouldPrioritizeImageLoading}
            />
          )
        }
        productType={productType ?? ''}
        // @ts-expect-error https://coursera.atlassian.net/browse/MERCH-742
        statusTags={statusTags}
        aria-label={getAriaLabel(cardProps)}
        title={{
          name: name || '',
          linkProps: {
            href: objectUrl,
            target: forceOpenInNewTab ? '_blank' : undefined,
            rel: forceOpenInNewTab ? 'noopener noreferrer' : undefined,
            component: TrackedLink2,
          },
          customLinkProps,
          component: titleComponent,
        }}
        body={
          (cardSkills || session) && (
            <Fragment>
              {cardSkills}
              {session && (
                <Typography2 component="p" variant="bodySecondary" css={styles.sessionContainer}>
                  <b>{session}</b>
                </Typography2>
              )}
            </Fragment>
          )
        }
        footer={
          <Fragment>
            <CardCategory>
              <ProductCardCourseraPlus
                isPartOfCourseraPlus={isPartOfCourseraPlus ?? false}
                ownsCourseraLite={ownsCourseraLite}
                ownsCourseraPlus={ownsCourseraPlus}
                productType={productType}
              />
            </CardCategory>
            <SearchResultsBadges
              shouldShowThePathwayBadge={useDynamicDegreePathway ? isPathwayContent : shouldShowThePathwayBadge}
              ratingElement={ratingElement}
              isDegree={isDegree}
              shouldShowCreditBadge={shouldShowCreditBadge}
            />
            {badges}
            <CardMetadata metadata={metaData} />
          </Fragment>
        }
      />
    </div>
  );
};

export default memo(ProductCardV2);
