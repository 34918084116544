import type { CourseType } from 'bundles/program-common/utils/courseTypeMetadataUtils';

/**
 * This holds general page information
 */
class PageViewController {
  courseType: CourseType;

  constructor(courseType: CourseType) {
    this.courseType = courseType;
  }
}

export default PageViewController;
