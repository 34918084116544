import { formatPattern } from 'react-router';

import { defaults } from 'lodash';

import cookie from 'js/lib/cookie';
import redirect from 'js/lib/coursera.redirect';

import enterpriseAdminUser from 'bundles/enterprise-admin';
import { LEARNER_ACTIVITY } from 'bundles/enterprise-admin-analytics/constants/routeNames';
import { HELP_CENTER_LINK } from 'bundles/enterprise-admin-constants/help';
import type { PresetId } from 'bundles/enterprise-admin-messages/types';
import {
  ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN,
  ENTERPRISE_ADMIN_PERMISSION_VERB,
} from 'bundles/enterprise-admin-roles/types';
import type { RequiredPermission } from 'bundles/enterprise-admin-roles/types';
import { getActionUrl } from 'bundles/user-account/common/lib';

import _t from 'i18n!nls/admin-dashboard';

const ORG_PATH = '/o/:thirdPartySlug';
const BASE_PATH = ORG_PATH + '/admin';
const PROGRAM_PATH = BASE_PATH + '/programs/:programSlug';
const HOME_PATH = BASE_PATH + '/home';
const CONTRACTS_LINK = BASE_PATH + '/programs/contracts';
const USERS_LINK = BASE_PATH + '/users';
const MESSAGES_LIST_ROOT_LINK = BASE_PATH + '/messages/list';
const AUTOMATED_MESSAGES_LINK = MESSAGES_LIST_ROOT_LINK + '/automated';
const MESSAGES_NEW = BASE_PATH + '/messages/new';
const SETTINGS_LINK = BASE_PATH + '/settings';

const INTEGRATE_LINK = SETTINGS_LINK + '/integrate';
const SSO_CONFIGURE_LINK = BASE_PATH + '/ssoconfig';

const ANALYTICS_LINK = BASE_PATH + '/analytics/:dashboardName';
const ORG_SKILLSETS_LINK = BASE_PATH + '/skill-sets';
const ORG_SKILLS_LINK = BASE_PATH + '/skills-tracking';

const ACCOUNT_LINK = BASE_PATH + '/account';
const RECEIPT_LINK = ACCOUNT_LINK + '/receipt/:contractId/:transactionId';
const PAYMENT_METHOD_EDIT_LINK = ACCOUNT_LINK + '/payment-method/:paymentWalletId';
const CANCEL_PLAN_LINK = ACCOUNT_LINK + '/cancel/:enterpriseContractId';
const AUTO_RENEW_LINK = ACCOUNT_LINK + '/autorenew/:enterpriseContractId';
const ADD_SEATS_LINK = ACCOUNT_LINK + '/addseats/:contractId';
const MANAGE_LICENSES_LINK = ACCOUNT_LINK + '/manage-licenses/:enterpriseContractId';
const CUSTOM_COURSES_LINK = BASE_PATH + '/custom-courses';

type Parameters = { [key: string]: string | number | boolean | undefined };

type ProgramPageParameters = {
  thirdPartySlug: string;
  programSlug: string;
};

const getSearch = (query: Parameters) => {
  const search = Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value ?? '')}`)
    .join('&');
  return search ? `?${search}` : '';
};

function getTranslations() {
  return {
    HOME: _t('Home'),
    CONTRACTS: _t('Contracts'),
    MESSAGES: _t('Messages'),
    USERS: _t('Users'),
    ANALYTICS: _t('Analytics'),
    ENTERPRISE_HELP_CENTER: _t('Admin Help Center'),
    LEARNER_HELP_CENTER: _t('Learner Help Center'),
    ACCOUNT: _t('Account & Billing'),
    SETTINGS: _t('Settings'),
    INTEGRATE: _t('Integrate'),
    CUSTOMIZE: _t('Customize'),
    ORG_TSPS: _t('SkillSets'),
    SKILLS: _t('Skills'),
    CUSTOM_COURSES: _t('Custom Courses'),
    CONFIGURE_SSO: _t('Configure SSO'),
  };
}

type LinkType = {
  id: string;
  text: string;
  href: string;
  isSelfServeOrg?: boolean;
  businessLogicRequirements?: boolean;
  requiredPermissions?: RequiredPermission;
  phaseOneOverride?: boolean;
};

function getMenuLinksUtil(
  enableTSP: boolean,
  canUseCourseBuilder: boolean,
  isSkillTrackingExperimentEnabled: boolean,
  orgId: string
): LinkType[] {
  const _T = getTranslations();
  const menus: LinkType[] = [
    {
      id: 'ADMIN_HOME',
      text: _T.HOME,
      href: HOME_PATH,
    },
    isSkillTrackingExperimentEnabled
      ? {
          id: 'ORG_TSPS',
          text: _T.SKILLS,
          href: ORG_SKILLS_LINK,
          businessLogicRequirements: enableTSP,
          requiredPermissions: {
            domain: 'ORGANIZATION',
            domainId: orgId,
            subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.SKILLSETS,
            verb: ENTERPRISE_ADMIN_PERMISSION_VERB.EDIT,
          },
        }
      : {
          id: 'ORG_TSPS',
          text: _T.ORG_TSPS,
          href: ORG_SKILLSETS_LINK,
          businessLogicRequirements: enableTSP,
          requiredPermissions: {
            domain: 'ANY_PROGRAM',
            subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.SKILLSETS,
            verb: ENTERPRISE_ADMIN_PERMISSION_VERB.VIEW,
          },
        },
    {
      id: 'CONTRACTSV2',
      text: _T.CONTRACTS,
      href: CONTRACTS_LINK,
      businessLogicRequirements: enterpriseAdminUser.isSuperUserOrEnterpriseOutsourcing(),
    },
    {
      id: 'USERS',
      text: _T.USERS,
      href: USERS_LINK,
      requiredPermissions: {
        domain: 'ANY_PROGRAM',
        subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.LEARNERS,
        verb: ENTERPRISE_ADMIN_PERMISSION_VERB.MANAGE,
      },
    },
    {
      id: 'MESSAGES',
      text: _T.MESSAGES,
      href: AUTOMATED_MESSAGES_LINK,
      requiredPermissions: {
        domain: 'ANY_PROGRAM',
        subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.COMMUNICATIONS,
        verb: ENTERPRISE_ADMIN_PERMISSION_VERB.VIEW,
      },
    },
    {
      id: 'ANALYTICS',
      text: _T.ANALYTICS,
      href: ANALYTICS_LINK,
      businessLogicRequirements: !(enterpriseAdminUser.isFreemiumOrg && enterpriseAdminUser.canOnlyManagePrograms()),
      requiredPermissions: {
        domain: 'ANY_PROGRAM',
        subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.ANALYTICS,
        verb: ENTERPRISE_ADMIN_PERMISSION_VERB.VIEW,
      },
    },
  ];

  if (canUseCourseBuilder) {
    const customCourses: LinkType = {
      id: 'CUSTOM_COURSES',
      text: _T.CUSTOM_COURSES,
      href: CUSTOM_COURSES_LINK,
      requiredPermissions: {
        domain: 'ANY_PROGRAM',
        subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.CUSTOM_COURSES,
        verb: ENTERPRISE_ADMIN_PERMISSION_VERB.VIEW,
      },
    };

    menus.splice(1, 0, customCourses);
  }

  return menus;
}

const formatRoute = (currentRoute: string, params: Parameters) => {
  return formatPattern(currentRoute, params);
};

export const getMenuLinks = (
  params: Parameters,
  enableTSP: boolean,
  canUseCourseBuilder: boolean,
  isSkillTrackingExperimentEnabled: boolean,
  orgId: string
) => {
  const paramsWithDefaults = defaults({}, params, {
    dashboardName: LEARNER_ACTIVITY,
  });

  return getMenuLinksUtil(enableTSP, canUseCourseBuilder, isSkillTrackingExperimentEnabled, orgId)
    .filter(({ businessLogicRequirements }) =>
      businessLogicRequirements === undefined ? true : businessLogicRequirements
    )
    .map((menu) =>
      Object.assign({}, menu, {
        href: formatRoute(menu.href, paramsWithDefaults),
      })
    );
};

export const getHomeLink = (params: Parameters) => {
  return formatRoute(HOME_PATH, params);
};

export type UsersPageQuery = {
  presetId?: string;
  contractId?: string;
  contractName?: string;
  comingFromFilterBuilder?: boolean;
  showImportLearnersModalOnMount?: boolean;
};

export const getUsersLink = (params: Parameters, query: UsersPageQuery = {}) => {
  return formatRoute(USERS_LINK, params) + getSearch(query);
};

export const getMessagesNewLink = (params: Parameters, query: { presetId?: PresetId } = {}) => {
  return formatRoute(MESSAGES_NEW, params) + getSearch(query);
};

export const getSettingsLink = (params: Parameters) => {
  return formatRoute(SETTINGS_LINK, params);
};

export const getReceiptLink = (params: Parameters) => {
  return formatRoute(RECEIPT_LINK, params);
};

export const getPaymentMethodEditLink = (params: Parameters) => {
  return formatRoute(PAYMENT_METHOD_EDIT_LINK, params);
};

export const getCancelAutoRenewLink = (params: Parameters) => {
  return formatRoute(CANCEL_PLAN_LINK, params);
};

export const getAutoRenewLink = (params: Parameters) => {
  return formatRoute(AUTO_RENEW_LINK, params);
};

export const getAddSeatsLink = (params: Parameters) => {
  return formatRoute(ADD_SEATS_LINK, params);
};

export const getAccountLink = (params: Parameters) => {
  return formatRoute(ACCOUNT_LINK, params);
};

export const getAnalyticsLink = (params: Parameters) => {
  const paramsWithDefault = defaults({}, params, { dashboardName: LEARNER_ACTIVITY });
  return formatRoute(ANALYTICS_LINK, paramsWithDefault);
};

export const getManageLicensesLink = (params: Parameters) => {
  return formatRoute(MANAGE_LICENSES_LINK, params);
};

export const getContractsLink = (params: Parameters) => {
  return formatRoute(CONTRACTS_LINK, params);
};

export const getSkillsetsLink = (params: Parameters) => {
  return formatRoute(ORG_SKILLSETS_LINK, params);
};

export const getProgramMainLink = (params: ProgramPageParameters) => {
  return formatRoute(PROGRAM_PATH, params);
};

export type ManageProgramLearnersQuery = {
  presetId?: string;
  showImportLearnersModalOnMount?: boolean;
  comingFromFilterBuilder?: boolean;
};
export const getManageProgramLearnersLink = (params: ProgramPageParameters, query: ManageProgramLearnersQuery = {}) => {
  return formatRoute(`${PROGRAM_PATH}/manage-learners`, params) + getSearch(query);
};

export const getSkillSetProgramMainLink = (params: ProgramPageParameters) => {
  return formatRoute(`${PROGRAM_PATH}/skill-sets`, params);
};

export const getProgramGradebookLink = (params: ProgramPageParameters) => {
  return formatRoute(`${PROGRAM_PATH}/gradebook`, params);
};

export const getProgramCurriculumLink = (params: ProgramPageParameters) => {
  return formatRoute(`${PROGRAM_PATH}/curriculum`, params);
};

export type AssignContentQuery = {
  ref: string;
};

export const getAssignContentLink = (params: ProgramPageParameters, query: AssignContentQuery) => {
  return formatRoute(`${ORG_PATH}/content-library/:programSlug`, params) + getSearch(query);
};

export const getHelpLinks = (isDirectSale: boolean) => {
  const _T = getTranslations();
  const helpLinks = [
    {
      id: 'ADMIN_HELP',
      text: _T.ENTERPRISE_HELP_CENTER,
      href: HELP_CENTER_LINK,
    },
  ];

  if (isDirectSale) {
    helpLinks.push({
      id: 'LEARNER_HELP',
      text: _T.LEARNER_HELP_CENTER,
      href: 'https://learner.coursera.help/hc',
    });
  }
  return helpLinks;
};

export const getSettingsLinks = (
  params: Parameters,
  enableWesBilling: boolean,
  enableXapiIntegration: boolean,
  enableSelfServiceSso: boolean,
  canManageOrganization: boolean,
  orgId: string
) => {
  if (!canManageOrganization) return [];
  const _T = getTranslations();
  const links: LinkType[] = [];

  if (enableWesBilling) {
    links.push({
      id: 'SELF_SERVE_CONTRACTS',
      text: _T.ACCOUNT,
      href: formatRoute(ACCOUNT_LINK, params),
    });
  }
  if (enableXapiIntegration) {
    links.push({
      id: 'INTEGRATE_SETTINGS',
      text: _T.INTEGRATE,
      href: formatRoute(INTEGRATE_LINK, params),
    });
    links.push({
      id: 'CUSTOMIZE_SETTINGS',
      text: _T.CUSTOMIZE,
      href: formatRoute(SETTINGS_LINK, params),
    });
  } else {
    links.push({
      id: 'SETTINGS',
      text: _T.SETTINGS,
      href: formatRoute(SETTINGS_LINK, params),
    });
  }
  if (enableSelfServiceSso) {
    links.push({
      id: 'SSO_CONFIGURE',
      text: _T.CONFIGURE_SSO,
      href: formatRoute(SSO_CONFIGURE_LINK, params),
      requiredPermissions: {
        domain: 'ORGANIZATION',
        domainId: orgId,
        subdomain: ENTERPRISE_ADMIN_PERMISSION_SUBDOMAIN.SSO,
        verb: ENTERPRISE_ADMIN_PERMISSION_VERB.MANAGE,
      },
    });
  }
  return links;
};

export const logout = (redirectLocation = '/') => {
  const csrfToken = cookie.get('CSRF3-Token') || '';

  return fetch(getActionUrl('logout', csrfToken), {
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  }).then(() => {
    redirect.setLocation(redirectLocation);
  });
};
