import * as React from 'react';

import URI from 'jsuri';

import { momentWithUserTimezone } from 'js/utils/DateTimeUtils';

/* @ts-expect-error ts-migrate(7016) FIXME: Untyped import */
import type CourseDerivativesV1 from 'bundles/naptimejs/resources/courseDerivatives.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { getAllTabIndexName } from 'bundles/search-common/utils/utils';

import _t from 'i18n!nls/coursera-plus';

export type Skill = {
  relevanceScore: number;
  skillName: string;
};

export const getNextBillingDateFromNumberOfDays = (numberOfDays: number) => {
  return momentWithUserTimezone().locale(_t.getLocale()).utc().add(numberOfDays, 'days').format('MMMM D, YYYY');
};

export const getReadableDateFromTimestamp = (timestamp: number, format: string = 'MMMM D, YYYY') => {
  return momentWithUserTimezone(timestamp).locale(_t.getLocale()).utc().format(format);
};

export const getExploreLink = (courseDerivatives?: CourseDerivativesV1) => {
  const SKILLS_TO_SHOW = 2;

  // Sort by relevanceScore descending and then join the skillNames together
  const skillNames =
    !!courseDerivatives &&
    (courseDerivatives.skillTags as Skill[])
      .sort((skillA, skillB) => skillB.relevanceScore - skillA.relevanceScore)
      .slice(0, SKILLS_TO_SHOW)
      .map((skill) => skill.skillName);

  const exploreLink = new URI('/search')
    .addQueryParam('index', getAllTabIndexName())
    .addQueryParam('isPartOfCourseraPlus', true);

  if (skillNames) {
    skillNames.forEach((skillName) => {
      exploreLink.addQueryParam('skills', skillName);
    });
  }

  return exploreLink.toString();
};

export const getPriceDisplayFromAmount = (amount: number | string, currency: string) => (
  <ReactPriceDisplay value={Number(amount)} currency={currency} hideCurrencyCode={true} />
);
