import * as React from 'react';
import { useEffect, useState } from 'react';

import type { ActionContext } from 'js/lib/ActionContext';

import WidgetSelectionModal from 'bundles/author-widget/components/widgetSelectionModal/WidgetSelectionModal';
import { useWidgetContext } from 'bundles/cml/editor/context/widgetContext';
import type { WidgetModalOptions } from 'bundles/cml/editor/types/widgetTypes';
import { createWidgetMap, getWidgetList } from 'bundles/cml/editor/utils/widgetApiUtils';
import type { WidgetSummary } from 'bundles/cml/editor/utils/widgetApiUtils';
import { useCourseContext, useRouter } from 'bundles/cml/shared/hooks/context';

export type PropsFromCaller = {
  widgetOptions?: WidgetModalOptions;
  onClose: () => void;
};

type PropsToComponent = {
  context?: ActionContext;
};

export type { PropsFromCaller as Props };
type Props = PropsFromCaller & PropsToComponent;

const WidgetModal: React.FC<Props> = ({ widgetOptions, onClose, context }) => {
  const [widgets, setWidgets] = useState<WidgetSummary[]>([]);
  const showDialog = !!widgetOptions;
  const { courseContext } = useCourseContext();
  const { widgetManager } = useWidgetContext();
  const courseId = courseContext?.courseId || '';
  const router = useRouter();

  useEffect(() => {
    const fetchWidgets = async () => {
      // Prevent fetching without courseId: https://coursera.slack.com/archives/C015TU49Z71/p1670471972174819
      if (!widgetManager || !courseId || !showDialog) {
        return;
      }
      const result = await getWidgetList(courseId);
      const widgetMap = createWidgetMap(result);
      widgetManager.cache[courseId] = { ...widgetMap };
      setWidgets(result);
    };

    fetchWidgets();
  }, [widgetManager, courseId, showDialog]);

  if (!showDialog || !widgetOptions.handleSelect || !context) {
    return null;
  }

  return (
    <WidgetSelectionModal
      courseId={courseId}
      onCancel={onClose}
      onContinue={widgetOptions.handleSelect}
      widgets={widgets || []}
      widgetMap={widgetManager?.cache[courseId] || {}}
      branchId=""
      itemId={router.params.itemId}
      atomId=""
      name=""
      isGradedWidget={false}
      selectedWidgetId={widgetOptions.currentId}
      isEmbeddedInCml={true}
      isWidgetListLoaded={true}
      executeAction={context.executeAction}
    />
  );
};

export default WidgetModal;
