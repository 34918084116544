/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, PageGridContainer, Typography } from '@coursera/cds-core';

import ClipsRecsCollection from 'bundles/program-personalized-tab/components/ClipsRecsCollection';
import { OnboardingResultsHeader } from 'bundles/program-personalized-tab/components/OnboardingResultsHeader';
import ProductRecsCollection from 'bundles/program-personalized-tab/components/ProductRecsCollection';
import { PromoBanner } from 'bundles/program-personalized-tab/components/PromoBanner';
import { RoleRecsCollection } from 'bundles/program-personalized-tab/components/RoleRecsCollection';
import ScoredSkillRecsCollection from 'bundles/program-personalized-tab/components/ScoredSkillRecsCollection';
import SelectedSkillRecsCollection from 'bundles/program-personalized-tab/components/SelectedSkillRecsCollection';
import SkillSuggestions from 'bundles/program-personalized-tab/components/SkillSuggestions';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { getCollectionOrder } from 'bundles/program-personalized-tab/utils/collectionOrderUtils';

import _t from 'i18n!nls/program-personalized-tab';

type PropsFromCaller = {
  programSlug: string;
  programName: string;
};

export type Props = PropsFromCaller;

const styles = {
  container: css`
    margin-top: var(--cds-spacing-200);
  `,
  section: css`
    margin-bottom: var(--cds-spacing-800);
  `,
};

export const PersonalizedTabRootContent = ({ programSlug, programName }: Props) => {
  const onboardingData = useOnboardingData();
  const collectionOrder = onboardingData && !onboardingData.loading ? getCollectionOrder(onboardingData) : null;
  const programNameContainer = (
    <Typography variant="h1semibold">{_t('My #{programName} Recommendations', { programName })}</Typography>
  );

  return (
    <PageGridContainer maxWidth="lg">
      <Grid item xs={12} component="main" css={styles.container} data-testid="personalized-tab-root-content">
        {onboardingData?.hasCompletedOnboarding && (
          <OnboardingResultsHeader
            selectedGoals={onboardingData?.selectedGoals ?? []}
            selectedRoleId={onboardingData?.selectedRoleId ?? ''}
            hasCompletedOnboarding={Boolean(onboardingData?.hasCompletedOnboarding)}
            isUnsureOfRole={Boolean(onboardingData?.isUnsureOfRole)}
            programSlug={programSlug}
            programNameContainer={programNameContainer}
          />
        )}
        {!onboardingData?.hasCompletedOnboarding && programNameContainer}
        {collectionOrder?.map((collectionName) => {
          // Add case here when a new collection is created
          switch (collectionName) {
            case 'ONBOARDING_CTA':
              return (
                <div css={styles.section} key={collectionName}>
                  <PromoBanner hasCompletedOnboarding={false} programSlug={programSlug} />
                </div>
              );
            case 'ROLES':
              return <RoleRecsCollection key={collectionName} />;
            case 'PRODUCTS':
              return <ProductRecsCollection key={collectionName} />;
            case 'PRODUCTS_LEADERSHIP':
              return <ProductRecsCollection showLeadershipRecs key={collectionName} />;
            case 'CLIPS':
              return <ClipsRecsCollection programSlug={programSlug} key={collectionName} />;
            case 'CLIPS_LEADERSHIP':
              return <ClipsRecsCollection showLeadershipRecs programSlug={programSlug} key={collectionName} />;
            case 'SCORED_SKILL_RECS':
              return <ScoredSkillRecsCollection programSlug={programSlug} key={collectionName} />;
            case 'RELATED_SKILLS':
              return <SkillSuggestions key={collectionName} />;
            case 'RELATED_SKILLS_RECS':
              return <SelectedSkillRecsCollection programSlug={programSlug} key={collectionName} />;
            default:
              return null;
          }
        })}
      </Grid>
    </PageGridContainer>
  );
};
