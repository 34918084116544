import type React from 'react';
import { useCallback } from 'react';

import type { Editor } from 'slate';

import { KEYDOWN_HANDLERS } from 'bundles/cml/editor/keyboard/constants';
import type { Options } from 'bundles/cml/editor/keyboard/types';

export const useKeyDownHandler = (editor: Editor, options: Options) =>
  useCallback((event: React.KeyboardEvent) => {
    for (const keydownHandler of KEYDOWN_HANDLERS) {
      if (keydownHandler(editor, event, options)) {
        return;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
