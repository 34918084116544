import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';
import { AI_CAMPAIGN_CERTIFICATE_SLUGS } from 'bundles/unified-description-page-common/utils/shouldShowSeptemberAiCampaignPageBanner';

const SeptemberAICampaignRecentlyUpdatedSlugs = AI_CAMPAIGN_CERTIFICATE_SLUGS;

export const isPartOfRecentlyUpdatedBadgeExperiment = () => GrowthDiscoveryEpicClient.get('showRecentlyUpdatedBadge');

export const shouldShowRecentlyUpdatedGenAiBadge = (slug: string, preview?: boolean) => {
  return (
    SeptemberAICampaignRecentlyUpdatedSlugs.includes(slug) &&
    (preview ? GrowthDiscoveryEpicClient.preview('showSeptemberAICampaignBadgeAndBanner') : GrowthDiscoveryEpicClient.get('showSeptemberAICampaignBadgeAndBanner'))
  );
};
