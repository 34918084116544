/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { compose, withProps } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';

import { Button, Checkbox, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { ErrorIcon } from '@coursera/cds-icons';

import type { CourseInfo } from 'bundles/campus-basic-contract-renewal/components/learner/withFreemiumCourseReenrollData';
import CoursesSvg from 'bundles/campus-basic-contract-renewal/components/svgs/CoursesSvg';
import CourseCard from 'bundles/course-cards/components/course-card/enterprise/CourseCard';
import Naptime from 'bundles/naptimejs';
import type { InjectedNaptime } from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import { PRODUCT_ID_INFIX, REFRESH_RESOURCE } from 'bundles/program-common/constants/ProgramActionConstants';
import ProgramActionApiManager from 'bundles/program-common/models/ProgramActionApiManager';

import _t from 'i18n!nls/enterprise-admin-coursera-for-campus-basic';

const modalContainer = (theme: Theme) =>
  css({
    backgroundColor: 'var(--cds-color-white-0)',
  });

const modalBodyContainer = (theme: Theme) =>
  css({
    width: '936px',
    margin: '0 auto',
    padding: theme.spacing(32, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  });

const headerContainer = (theme: Theme) =>
  css({
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 12),
    },
  });

const bodyContainer = (theme: Theme) =>
  css({
    backgroundColor: theme.palette.gray[100],
    padding: theme.spacing(32),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(32, 12),
    },
  });

const dividerContainer = (theme: Theme) =>
  css({
    margin: theme.spacing(48, 0),
    height: '1px',
    borderTop: `1px solid ${theme.palette.gray[500]}`,
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(48, 12),
    },
  });

const divider = (theme: Theme) =>
  css({
    position: 'relative',
    top: '-0.7em',
    background: 'var(--cds-color-white-0)',
    display: 'inline-block',
    padding: theme.spacing(0, 8),
  });

const chooseCourseBody = css({
  display: 'flex',
});

const svgContainer = (theme: Theme) =>
  css({
    marginLeft: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  });

type PropsFromNaptime = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  curriculumProduct: any;
  naptime: InjectedNaptime;
};

type PropsFromCaller = {
  userName: string;
  filteredCourse: CourseInfo;
  onReenrollClick: () => void;
  onBrowseBtnClick: () => void;
};

type PropsFromWithProps = {
  isSaved: boolean;
  apiManager: ProgramActionApiManager;
};

export type Props = PropsFromWithProps & PropsFromCaller;

export const CourseReenrollModal = ({
  userName,
  filteredCourse,
  onReenrollClick,
  onBrowseBtnClick,
  isSaved,
  apiManager,
}: Props) => {
  const theme = useTheme();

  const [showError, setShowError] = React.useState<boolean>(false);
  const [showApiError, setShowApiError] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [browseBtnDisabled, setBrowseBtnDisabled] = React.useState<boolean>(false);
  const [reenrollBtnDisabled, setReenrollBtnDisabled] = React.useState<boolean>(false);

  const setBtnsDisabledState = (val: boolean) => {
    setReenrollBtnDisabled(val);
    setBrowseBtnDisabled(val);
  };

  const onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    setChecked(e.target.checked);
  };

  const resetStates = React.useCallback(() => {
    setShowError(false);
    setChecked(false);
    setBtnsDisabledState(false);
    setShowApiError(false);
  }, []);

  const courseObject = React.useMemo(() => {
    return {
      courseId: filteredCourse.productItemId,
      collectionId: filteredCourse.collectionId ? filteredCourse.collectionId : null,
    };
  }, [filteredCourse.productItemId, filteredCourse.collectionId]);

  const onBrowseClick = React.useCallback(async () => {
    if (!isSaved) {
      setBtnsDisabledState(true);
      try {
        await apiManager.getSelectCoursePromise(courseObject);
        await apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.selectCourse);
      } catch (e) {
        // pass
      }
    }
    resetStates();
    onBrowseBtnClick();
  }, [onBrowseBtnClick, resetStates, isSaved, courseObject, apiManager]);

  const onReenrollBtnClick = React.useCallback(async () => {
    if (!checked) {
      setShowError(true);
    } else {
      setBtnsDisabledState(true);
      try {
        await apiManager.getEnrollInCoursePromise(courseObject);
        await apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.enrollInCourse);
      } catch (e) {
        setShowApiError(true);
        setBrowseBtnDisabled(false);
        return;
      }
      resetStates();
      onReenrollClick();
    }
  }, [apiManager, checked, courseObject, onReenrollClick, resetStates]);

  return (
    <div css={modalContainer(theme)}>
      <div css={modalBodyContainer(theme)}>
        <div css={headerContainer(theme)}>
          <Typography variant="h1semibold" css={{ marginBottom: theme.spacing(12) }}>
            <FormattedMessage
              message={_t(
                'Hi {userName}, a new plan year has started. Continue your course or start fresh by choosing a new one'
              )}
              userName={userName}
            />
          </Typography>
          <Typography2 component="p" color="supportText" css={{ marginBottom: theme.spacing(24) }}>
            {_t(
              'Coursera for Campus Basic allows organizations to offer their students 1 free course per contract year. Since you still have a course in progress and it’s a new year, you can either continue or start fresh.'
            )}
          </Typography2>
        </div>
        <div css={bodyContainer(theme)}>
          <Typography css={{ marginBottom: theme.spacing(24) }} variant="h2semibold">
            {_t('Continue where you left off')}
          </Typography>
          <CourseCard
            programId={filteredCourse.programId}
            courseId={filteredCourse.productItemId}
            isEnrolled={false}
            hideAdditionalInfo={true}
          />
          <Typography2 component="p" color="supportText" css={{ marginBottom: theme.spacing(24) }}>
            {_t('Note: If you choose to re-enroll in this course later, you can find it in the My Courses Tab.')}
          </Typography2>
          {showError && (
            <Typography2
              component="p"
              color="error"
              css={{ fontWeight: 'bold', display: 'flex' }}
              data-test-id="course-reenroll-modal-error-message"
            >
              <FormattedMessage
                message={_t('{errorIcon} Please check the box to continue')}
                errorIcon={<ErrorIcon size="medium" css={{ marginRight: theme.spacing(8) }} />}
              />
            </Typography2>
          )}
          <Checkbox
            label={_t(
              'I confirm that by re-enrolling in this course it will count as my 1 free course for the upcoming year.'
            )}
            css={{ marginBottom: theme.spacing(24) }}
            validationStatus={showError ? 'error' : undefined}
            onChange={onCheckboxClick}
            checked={checked}
            data-test-id="course-reenroll-modal-checkbox"
          />
          <br />
          <Button
            variant="primary"
            onClick={onReenrollBtnClick}
            disabled={reenrollBtnDisabled}
            data-test-id="course-reenroll-button"
          >
            {_t('Re-enroll')}
          </Button>
          {showApiError && (
            <Typography css={{ marginTop: theme.spacing(24) }} variant="h2semibold" color="error" component="p">
              {_t('Failed to re-enroll. Please try again later.')}
            </Typography>
          )}
        </div>
        <div css={dividerContainer(theme)}>
          <Typography variant="h1semibold" component="span" css={divider(theme)}>
            {_t('OR')}
          </Typography>
        </div>
        <div css={bodyContainer(theme)}>
          <Typography css={{ marginBottom: theme.spacing(24) }} variant="h2semibold">
            {_t('Start fresh with a new course')}
          </Typography>
          <div css={chooseCourseBody}>
            <div>
              <Typography2 component="p" color="supportText" css={{ marginBottom: theme.spacing(24) }}>
                {_t(
                  'Choose a new course now or decide later. Before enrolling, make sure to check in with your administrator if they are using Coursera for credit and will be assigning a new course for the year.'
                )}
              </Typography2>
              <br />
              <Button variant="secondary" onClick={onBrowseClick} disabled={browseBtnDisabled}>
                {_t('Browse courses')}
              </Button>
            </div>
            <div css={svgContainer(theme)}>
              <CoursesSvg />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose<Props, PropsFromCaller>(
  Naptime.createContainer<PropsFromNaptime, PropsFromCaller>(({ filteredCourse }) => {
    const productQueryId = `${filteredCourse.programId}~${PRODUCT_ID_INFIX.COURSE}~${filteredCourse.productItemId}`;

    const curriculumProduct = ProgramCurriculumProductsV1.get(productQueryId, {});
    return {
      curriculumProduct,
    };
  }),
  withProps<PropsFromWithProps, PropsFromNaptime & PropsFromCaller>(
    ({ curriculumProduct, naptime, filteredCourse }) => {
      return {
        isSaved: curriculumProduct?.isWishlisted ?? true,
        apiManager: new ProgramActionApiManager({
          programId: filteredCourse.programId,
          naptime,
          userId: user.get().id,
        }),
      };
    }
  )
)(CourseReenrollModal);
