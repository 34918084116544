import * as React from 'react';

import { Button, Collapse, Row } from '@coursera/coursera-ui';

import type { Props as Course } from 'bundles/coursera-ui/components/rich/MiniXDP/AboutS12nCourseListItem';
import AboutS12nCourseListItem from 'bundles/coursera-ui/components/rich/MiniXDP/AboutS12nCourseListItem';
import type { CuiEnterpriseProgramSessionAssociationsQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourses_elements as CourseSession } from 'bundles/coursera-ui/components/rich/MiniXDP/__generated__/CuiEnterpriseProgramSessionAssociationsQuery';
import SectionTitle from 'bundles/enterprise-legacy-xdp/components/SectionTitle';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/coursera-ui';

type Props = {
  courses: Array<Course>;
  s12nProductVariant: S12nProductVariant;
  onShowCDP: (id: string) => void;
  privateSessionDates?: (CourseSession | null)[];
  coursesMetadata?: ({ courseId?: string; isSelectedForCredit?: boolean; isExclusive?: boolean } | null)[];
};

function AboutS12nCourseList({
  courses = [],
  s12nProductVariant,
  onShowCDP,
  privateSessionDates,
  coursesMetadata,
}: Props) {
  const getPrivateSession = (courseId: string) => {
    return privateSessionDates?.find((session: CourseSession | null) => session?.courseId === courseId)?.session;
  };

  const getCourseMetadata = (courseId: string) => {
    return coursesMetadata?.find((metadata) => metadata?.courseId === courseId) ?? undefined;
  };

  const title = _t('Courses in this #{productType}', {
    productType: getS12nVariantLabel(s12nProductVariant),
  });

  return (
    <div className="AboutS12nCourseList">
      <Row rootClassName="m-t-3">
        <SectionTitle title={title} />
      </Row>
      {courses.length > 4 ? (
        <div>
          {courses.slice(0, 4).map((course, index) => (
            <AboutS12nCourseListItem
              {...course}
              key={course.id}
              index={index}
              hideDivider={index === courses.length - 1}
              onShowCDP={onShowCDP}
              privateSessionDates={getPrivateSession(course.id)}
              coursesMetadata={getCourseMetadata(course.id)}
            />
          ))}
          <Collapse>
            <Collapse.Panel
              id="panel-AboutS12nCourseList"
              renderFooter={({ isExpanded }) => {
                const label = isExpanded ? _t('Show Less') : _t('Show More');
                return (
                  <Button rootClassName="d-block m-x-auto" size="md">
                    {label}
                    <span className="sr-only">{title}</span>
                  </Button>
                );
              }}
            >
              {courses.slice(4).map((course, index) => (
                <AboutS12nCourseListItem
                  {...course}
                  key={course.id}
                  index={index + 4}
                  hideDivider={index === courses.length - 1}
                  onShowCDP={onShowCDP}
                  privateSessionDates={getPrivateSession(course.id)}
                  coursesMetadata={getCourseMetadata(course.id)}
                />
              ))}
            </Collapse.Panel>
          </Collapse>
        </div>
      ) : (
        <div>
          {courses.map((course, index) => (
            <AboutS12nCourseListItem
              {...course}
              key={course.id}
              index={index}
              hideDivider={index === courses.length - 1}
              onShowCDP={onShowCDP}
              privateSessionDates={getPrivateSession(course.id)}
              coursesMetadata={getCourseMetadata(course.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default AboutS12nCourseList;
