import { EnterpriseContractTagType } from 'bundles/admin-dashboard/types';
import { org_coursera_product_enterprise_contract_EnterpriseContractState as EnterpriseContractState } from 'bundles/enterprise-admin-contracts/components/__generated__/globalTypes';

const FREEMIUM_RENEWAL_CONTRACT_TAGS = [EnterpriseContractTagType.C4C_FREEMIUM, EnterpriseContractTagType.C4C_UPSWELL];

// Oct 1, 2020 12:00:00 AM pacific
export const OCT_1_2020_MILLISECONDS = 1601535600000;

export const ONE_YEAR_MILLISECONDS = 31556926000;
const ONE_MONTH_MILLISECONDS = 2629743000;
export const ELEVEN_MONTHS_MILLISECONDS = ONE_YEAR_MILLISECONDS - ONE_MONTH_MILLISECONDS;

export const isAllFreemiumContracts = (
  contracts: Array<{
    contractState?: string;
    contractTags?: { elements?: { contractTag?: string }[] };
    contractTag?:
      | {
          contractTagName?: string;
        }
      | string;
  }>
) => {
  if (contracts.length === 0) return false;

  const nonTermintedContracts = contracts.filter(
    (contract) => contract.contractState !== EnterpriseContractState.TERMINATED
  );

  return (
    nonTermintedContracts.length > 0 &&
    nonTermintedContracts.every((contract) => {
      const contractTag =
        contract.contractTags?.elements?.[0]?.contractTag ??
        (typeof contract.contractTag === 'string' ? contract.contractTag : contract.contractTag?.contractTagName);
      return contractTag && FREEMIUM_RENEWAL_CONTRACT_TAGS.includes(contractTag);
    })
  );
};
