import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus/contentfulData/LandingPageDataProvider';
import { getCopy } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { isBlockCertInFreeTrialEnabled } from 'bundles/enroll/utils/blockCertInFreeTrialUtils';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import { getProductVariantString } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/coursera-plus';

export type Copy = JSX.Element | string;

export type CopiesType = Record<string, Copy | Copy[]>;

const getPriceMessage = (message: string, price: SubscriptionTiersProductPrice): JSX.Element => (
  <FormattedMessage
    message={message}
    price={<ReactPriceDisplay value={Number(price.amount)} currency={price.currencyCode} hideCurrencyCode={true} />}
  />
);

const getCheckOptions = ({
  courseraLiteProductPrice,
  courseraPlusProductPrice,
  isSpecialization,
  s12nType,
}: {
  courseraLiteProductPrice: SubscriptionTiersProductPrice;
  courseraPlusProductPrice: SubscriptionTiersProductPrice;
  isSpecialization: boolean;
  s12nType: S12nProductVariant;
}): Record<string, { primary: Copy; secondary: Copy }> => {
  const checkContinueLearningSecondary = _t('Subscribe and save more');
  const checkCancelAnytimePrimary = _t('Cancel anytime');
  const explorePlansSecondary = _t('Explore different plans to find one that fits your goals');

  return {
    unlimitedAccess: {
      primary: isSpecialization
        ? _t('Unlimited access to all courses in this #{productName}', {
            productName: getProductVariantString(s12nType),
          })
        : _t('Unlimited access to everything in this course'),
      secondary: _t('Watch videos, try assignments, participate in discussion forums, and more'),
    },
    learningPrograms7k: {
      primary: _t('7,000+ additional learning programs'),
      secondary: _t('Explore courses, hands-on projects, and job-ready certificate programs at no additional cost'),
    },
    learningPrograms5k: {
      primary: _t('5,000+ additional learning programs'),
      secondary: _t('Explore courses and job-ready certificate programs at no additional cost'),
    },
    continueLearning: {
      primary: (
        <FormattedMessage
          message={_t('{price} a month to continue learning after trial ends')}
          price={
            <ReactPriceDisplay
              currency={courseraPlusProductPrice.currencyCode}
              value={courseraPlusProductPrice.amount}
              hideCurrencyCode={true}
            />
          }
        />
      ),
      secondary: checkContinueLearningSecondary,
    },
    continueLearningExhausted: {
      primary: (
        <FormattedMessage
          message={_t('{price} a month to continue learning')}
          price={
            <ReactPriceDisplay
              currency={courseraPlusProductPrice.currencyCode}
              value={courseraPlusProductPrice.amount}
              hideCurrencyCode={true}
            />
          }
        />
      ),
      secondary: checkContinueLearningSecondary,
    },
    cancelAnytime: {
      primary: checkCancelAnytimePrimary,
      secondary: _t("No penalties—cancel before the trial ends if it's not right for you"),
    },
    cancelAnytimeExhausted: {
      primary: checkCancelAnytimePrimary,
      secondary: _t("No penalties—cancel if it's not right for you"),
    },
    certificate: {
      primary: isBlockCertInFreeTrialEnabled()
        ? _t('A certificate for every program you complete after your trial ends')
        : _t('A certificate for every program you complete'),
      secondary: _t('Share on your resume, LinkedIn, and CV'),
    },
    explorePlans: {
      primary: (
        <FormattedMessage
          message={_t('Plans start at {price} a month to continue learning after trial ends')}
          price={
            <ReactPriceDisplay
              currency={courseraLiteProductPrice.currencyCode}
              value={courseraLiteProductPrice.amount}
              hideCurrencyCode={true}
            />
          }
        />
      ),
      secondary: explorePlansSecondary,
    },
    explorePlansExhausted: {
      primary: (
        <FormattedMessage
          message={_t('Plans start at {price} a month to continue learning')}
          price={
            <ReactPriceDisplay
              currency={courseraLiteProductPrice.currencyCode}
              value={courseraLiteProductPrice.amount}
              hideCurrencyCode={true}
            />
          }
        />
      ),
      secondary: explorePlansSecondary,
    },
  };
};

type HeroCopy = {
  headline: Copy;
  subHeadline?: Copy;
  checks: { primary: Copy; secondary: Copy }[];
  primaryCTA: Copy;
  secondaryCTA?: Copy;
};

export const getHeroCopy = ({
  courseraLiteProductPrice,
  courseraPlusProductPrice,
  hasFreeTrialForLite,
  hasFreeTrialForPlus,
  isSpecialization,
  productName,
  s12nType,
  isVariant3,
  isVariant5,
}: {
  courseraLiteProductPrice: SubscriptionTiersProductPrice;
  courseraPlusProductPrice: SubscriptionTiersProductPrice;
  hasFreeTrialForLite: boolean;
  hasFreeTrialForPlus: boolean;
  isSpecialization: boolean;
  productName?: string;
  s12nType: S12nProductVariant;
  isVariant3?: boolean;
  isVariant5?: boolean;
}): HeroCopy => {
  const monthlyPrice = (price: SubscriptionTiersProductPrice): JSX.Element =>
    getPriceMessage(_t('{price} monthly'), price);

  const checks = getCheckOptions({
    courseraLiteProductPrice,
    courseraPlusProductPrice,
    isSpecialization,
    s12nType,
  });

  const copy = {
    headline: _t('7-day free trial'),
    checks: [
      checks.unlimitedAccess,
      checks.learningPrograms7k,
      checks.continueLearning,
      checks.cancelAnytime,
      checks.certificate,
    ],
    primaryCTA: _t('Start free trial'),
    secondaryCTA: _t('View all plans'),
  };

  if (isVariant3) {
    return !hasFreeTrialForPlus
      ? {
          ...copy,
          headline: monthlyPrice(courseraPlusProductPrice),
          subHeadline: (
            <FormattedMessage
              message={_t('Get access to {productName} with Coursera Plus. Your subscription includes:')}
              productName={productName}
            />
          ),
          checks: [
            checks.unlimitedAccess,
            checks.learningPrograms7k,
            checks.continueLearningExhausted,
            checks.cancelAnytimeExhausted,
            checks.certificate,
          ],
          primaryCTA: _t('Subscribe now'),
        }
      : {
          ...copy,
          subHeadline: (
            <FormattedMessage
              message={_t('Get access to {productName} with Coursera Plus. Your 7-day free trial includes:')}
              productName={productName}
            />
          ),
        };
  }
  if (isVariant5) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { secondaryCTA, ...var5Copy } = copy;
    return !hasFreeTrialForLite && !hasFreeTrialForPlus
      ? {
          ...var5Copy,
          headline: _t('Subscribe now'),
          subHeadline: (
            <FormattedMessage
              message={_t('Get access to {productName}. Your subscription includes:')}
              productName={productName}
            />
          ),
          checks: [
            checks.unlimitedAccess,
            checks.learningPrograms5k,
            checks.cancelAnytimeExhausted,
            checks.explorePlansExhausted,
          ],
          primaryCTA: _t('Subscribe now'),
        }
      : {
          ...var5Copy,
          subHeadline: (
            <FormattedMessage
              message={_t('Get access to {productName} with a subscription. Your 7-day free trial includes:')}
              productName={productName}
            />
          ),
          checks: [checks.unlimitedAccess, checks.learningPrograms5k, checks.cancelAnytime, checks.explorePlans],
        };
  }

  return copy;
};

export const getFeatureTableModalCopy = ({
  hasFreeTrialForLite,
  hasFreeTrialForPlus,
}: {
  hasFreeTrialForLite: boolean;
  hasFreeTrialForPlus: boolean;
}) => {
  const { numberOfPartnersNoPlusSign } = getCopy();
  const copy = {
    back: _t('Back to course page'),
    valueProp1: (
      <FormattedMessage
        message={_t('Learn from experts at {numberOfPartners} leading universities and companies')}
        numberOfPartners={numberOfPartnersNoPlusSign}
      />
    ),
    valueProp2: _t('Set your own schedule with on-demand lectures'),
    valueProp3: _t('Explore 5,000+ courses at no additional cost'),
    pageHeader: _t('Start your 7-day free trial'),
    trialCTA: _t('Start free trial'),
    exhaustedCTA: _t('Subscribe now'),
  };

  return !hasFreeTrialForLite || !hasFreeTrialForPlus
    ? {
        ...copy,
        pageHeader: _t('Choose a plan that fits your goals'),
      }
    : copy;
};

export const getFeatureTableCopy = ({
  courseraLiteProductPrice,
  courseraPlusProductPrice,
  courseraPlusAnnualProductPrice,
}: {
  courseraLiteProductPrice: SubscriptionTiersProductPrice;
  courseraPlusProductPrice: SubscriptionTiersProductPrice;
  courseraPlusAnnualProductPrice: SubscriptionTiersProductPrice;
}): CopiesType => {
  const pricePerMonth = (price: SubscriptionTiersProductPrice): JSX.Element => getPriceMessage(_t('{price}/mo'), price);
  const pricePerYear = (price: SubscriptionTiersProductPrice): JSX.Element => getPriceMessage(_t('{price}/yr'), price);

  return {
    monthlyAccess: _t('Monthly'),
    oneYearAccess: _t('Annually'),
    rowHeader1: _t('Videos'),
    rowHeader2: _t('Graded assignments and quizzes'),
    rowHeader3: isBlockCertInFreeTrialEnabled()
      ? _t('Certificates of completion after trial ends')
      : _t('Certificate of completion'),
    rowHeader4: _t('2,000+ hands-on projects'),
    rowHeader5: _t('Price'),
    row5Lite: pricePerMonth(courseraLiteProductPrice),
    row5Plus: pricePerMonth(courseraPlusProductPrice),
    row5Annually: pricePerYear(courseraPlusAnnualProductPrice),
    rowHeader6: _t('Subscription length'),
    row6Monthly: _t('1 month'),
    row6Annually: _t('1 year'),
  };
};
