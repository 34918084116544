import courseraPlusProductVariants, {
  courseraLiteProductVariants,
} from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { isMonthly } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import type SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';
import { useGetProductPricesV4 } from 'bundles/payments-common/hooks/useGetProductPrices';
import ProductType from 'bundles/payments/common/ProductType';
import SubscriptionStatus from 'bundles/subscriptions/common/SubscriptionStatus';

export const isActiveOrFreeTrialCourseraPlusSubscription = (subscription: SubscriptionsV1) =>
  subscription.isCourseraPlusSubscription &&
  subscription.productSku &&
  isMonthly(subscription.productSku) &&
  (subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.FREE_TRIAL);

export const getEligibleCourseraPlusMonthlySubscription = (subscriptions: Array<SubscriptionsV1>) => {
  return subscriptions.find(isActiveOrFreeTrialCourseraPlusSubscription);
};

export const useGetCourseraLitePrice = (skip?: boolean) => {
  const products = [
    {
      productType: ProductType.COURSERA_TIER_LITE,
      productItemId: courseraLiteProductVariants.MONTHLY_WITH_FREE_TRIAL,
    },
  ];

  const { productPrices: courseraLitePrices } = useGetProductPricesV4({ products, skip });

  return {
    courseraLitePrice: courseraLitePrices?.[0],
  };
};

export const useGetCourseraPlusMonthlyPrice = ({
  productItemId = courseraPlusProductVariants.MONTHLY_WITH_FREE_TRIAL,
  skip,
}: {
  productItemId?: string;
  skip?: boolean;
} = {}) => {
  const products = [
    {
      productType: ProductType.COURSERA_PLUS_SUBSCRIPTION,
      productItemId,
    },
  ];

  const { productPrices: courseraPlusPrices } = useGetProductPricesV4({ products, skip });

  return {
    courseraPlusMonthlyPrice: courseraPlusPrices?.[0],
  };
};

export const useGetCourseraPlusAnnualPrice = (skip?: boolean) => {
  const products = [
    {
      productType: ProductType.COURSERA_PLUS_SUBSCRIPTION,
      productItemId: courseraPlusProductVariants.ANNUAL_MIDCYCLE_UPGRADE,
    },
  ];

  const { productPrices: courseraPlusPrices } = useGetProductPricesV4({ products, skip });

  return {
    courseraPlusAnnualPrice: courseraPlusPrices?.[0],
  };
};
