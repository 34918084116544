/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { IconButton } from '@coursera/cds-core';

import CMLCodeEditorEvaluatorConfig from 'bundles/cml/editor/components/elements/code/CMLCodeEditorEvaluatorConfig';
import type { CodeBlockOptions } from 'bundles/cml/legacy/types/Content';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const styles = {
  root: css`
    display: flex;
    align-items: center;
    column-gap: var(--cds-spacing-100);
    padding: var(--cds-spacing-100);
  `,
};

export type Props = {
  code: CodeElement;
  codeBlockOptions?: CodeBlockOptions;
  codeBlockLabel: string;
  onEvaluatorChange: (evaluatorId: string, value: string) => void;
  toggleEcbConfigModal?: (ecbModalComponent: React.ReactElement | null) => void;
  onMenuOpen: () => void;
};

const CMLCodeEditorToolbar = React.forwardRef<HTMLButtonElement, Props>(
  ({ code, codeBlockOptions, codeBlockLabel, onEvaluatorChange, toggleEcbConfigModal, onMenuOpen }, ref) => {
    const { codeText, evaluatorId, language } = code;
    const context = codeBlockOptions?.context || {};
    const { courseId, itemId, branchId } = context;
    const canCreateEvaluator = codeBlockOptions?.ecbEnabled;

    return (
      <div role="toolbar" css={styles.root} data-testid="cml-code-editor-toolbar">
        {canCreateEvaluator && (
          <CMLCodeEditorEvaluatorConfig
            value={codeText}
            language={language}
            evaluatorId={evaluatorId}
            courseId={courseId}
            itemId={itemId}
            branchId={branchId}
            onEvaluatorChange={onEvaluatorChange}
            toggleEcbConfigModal={toggleEcbConfigModal}
            codeBlockLabel={codeBlockLabel}
          />
        )}
        <IconButton
          ref={ref}
          size="small"
          variant="secondary"
          intent="more"
          onClickCapture={onMenuOpen}
          aria-label={_t('More options #{codeBlockLabel}', { codeBlockLabel })}
        />
      </div>
    );
  }
);

export default CMLCodeEditorToolbar;
