import * as React from 'react';
import Media from 'react-media';

import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

import Retracked from 'js/lib/retracked';

import { StyleSheet, breakPoint, color, css } from '@coursera/coursera-ui';
import { SvgClose } from '@coursera/coursera-ui/svg';

import type { Props as CarouselProps } from 'bundles/design-system/components/Carousel';
import Carousel from 'bundles/design-system/components/Carousel';
import type { CarouselSettings } from 'bundles/design-system/components/types';
import TrackedButton from 'bundles/page/components/TrackedButton';
import pageVariables from 'bundles/page/components/__styles__/pageVariables.json';

import _t from 'i18n!nls/program-common';

const TrackedCarousel = Retracked.createTrackedContainer<CarouselProps>(({ isExpanded }) => ({
  parentComponent: 'ExpandingCarousel',
  isExpanded,
}))(Carousel);

const carouselSettings: CarouselSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [],
  autoplaySpeed: 6000,
  lazyLoad: 'progressive',
  fade: true,
  dots: true,
  dotsClass: 'carousel-dots',
  isFluid: true,
};

const expandedCarouselSettings: CarouselSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [],
  lazyLoad: 'progressive',
  fade: true,
  isFluid: true,
};

const expandedPadding = 48;
const closeSvgPadding = 8; // at the size used here (38), the distance between the edge of the "X" SVG and the actual image contained therein

const styles = StyleSheet.create({
  slideButton: {
    border: 'none',
    padding: 0,
    background: 'none',
  },
  overlay: {
    position: 'fixed',
    display: 'flex', // for carousel vertical alignment
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    padding: expandedPadding, // px
    paddingTop: expandedPadding + pageVariables.header.height, // px
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(31, 31, 31, 0.8)',
  },
  close: {
    border: 'none',
    background: 'none',
    position: 'absolute',
    zIndex: 2,
    top: pageVariables.header.height, // px
    right: 0,
    padding: expandedPadding / 2 - closeSvgPadding, // px
  },
  carousel: {
    zIndex: 1,
    margin: 'auto',
    width: '100%',
  },
  childContainer: {
    display: 'flex !important', // !important to override react-slick's inline styles
    padding: '0 24px', // keep right/left arrows a reasonable distance from the slide content
  },
});

type Props = {
  isExpanded?: boolean;
  children: (isExpanded: boolean) => JSX.Element[];
  prevArrowLabel?: string;
  nextArrowLabel?: string;
  expandItemLabel?: string;
  canExpand?: boolean;
};

type State = {
  currentSlideIndex: number;
  isExpanded: boolean;
  overlayHeight?: number;
};

class ExpandingCarousel extends React.Component<Props, State> {
  static contextTypes = {
    _eventData: PropTypes.object.isRequired,
  };

  static defaultProps = {
    canExpand: true,
  };

  state: State = {
    currentSlideIndex: 0,
    isExpanded: false,
  };

  componentWillUpdate(_nextProps: Readonly<Props>, nextState: Readonly<State>): void {
    if (this.state.isExpanded && !nextState.isExpanded && window.removeEventListener) {
      window.removeEventListener('resize', this.onResizeWindow);
    }
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.state.isExpanded && !prevState.isExpanded && window.addEventListener) {
      window.addEventListener('resize', this.onResizeWindow);
    }
  }

  componentWillUnmount(): void {
    if (this.state.isExpanded && window.removeEventListener) {
      window.removeEventListener('resize', this.onResizeWindow);
      document.removeEventListener?.('keydown', this.onKeyDown);
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        Retracked.trackComponent(this.context._eventData, { usingKeyboard: true }, 'expanding_carousel_close', 'click');
        this.toggleExpanded();
        break;
      case 'ArrowLeft':
        Retracked.trackComponent(
          this.context._eventData,
          { parentComponent: 'ExpandingCarousel', isExpanded: true, usingKeyboard: true },
          'carousel_slider_left_arrow',
          'click'
        );
        this.carouselRef?.slickRef?.slickPrev();
        break;
      case 'ArrowRight':
        Retracked.trackComponent(
          this.context._eventData,
          { parentComponent: 'ExpandingCarousel', isExpanded: true, usingKeyboard: true },
          'carousel_slider_right_arrow',
          'click'
        );
        this.carouselRef?.slickRef?.slickNext();
        break;
      default:
        // Do nothing.
        break;
    }
  };

  onResizeWindow = () => {
    if (!this.overlayRef) {
      return;
    }
    const overlayHeight = this.overlayRef.clientHeight - (expandedPadding * 2 + pageVariables.header.height);
    if (overlayHeight !== this.state.overlayHeight) {
      this.setState(() => ({ overlayHeight }));
    }
  };

  onSlideChange = (currentSlideIndex: number) => this.setState(() => ({ currentSlideIndex }));

  onSwipe = (direction: string) =>
    Retracked.trackComponent(this.context._eventData, {}, 'expanding_carousel', `swipe_${direction}`);

  toggleExpanded = () => {
    const { canExpand } = this.props;
    if (canExpand) {
      if (!this.state.isExpanded) {
        document.addEventListener?.('keydown', this.onKeyDown);
      } else {
        document.removeEventListener?.('keydown', this.onKeyDown);
      }
      this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }
  };

  overlayRef: HTMLDivElement | null = null;

  carouselRef: Carousel | null = null;

  renderOverlay() {
    const { children: renderChildren, prevArrowLabel, nextArrowLabel } = this.props;
    const containerHeight = this.state.overlayHeight ? `${this.state.overlayHeight}px` : 'inherit';
    // react-slick adds in-line styles, overwriting anything I add to the `styles` prop, so we do it this way:
    const dynamicStyles = StyleSheet.create({
      childContainer: {
        height: containerHeight,
      },
    });
    return (
      <FocusTrap>
        <div
          {...css(styles.overlay)}
          ref={(element) => {
            this.overlayRef = element;
          }}
        >
          <div {...css(styles.mask)} />
          <TrackedButton
            trackingName="expanding_carousel_close"
            {...css(styles.close)}
            onClick={this.toggleExpanded}
            onSetRef={(buttonRef) => {
              if (buttonRef) {
                buttonRef.focus();
              }
            }}
          >
            <SvgClose size={38} color={color.white} />
          </TrackedButton>
          <div {...css(styles.carousel)}>
            <TrackedCarousel
              isExpanded={true}
              arrowProps={{
                iconColor: color.white,
                hoverColor: color.lightPrimary,
              }}
              settings={{
                ...expandedCarouselSettings,
                prevArrowLabel,
                nextArrowLabel,
                initialSlide: this.state.currentSlideIndex,
              }}
              // @ts-expect-error TSMIGRATION-3.9 need type support for Retracked forwarded ref
              componentRef={(carouselRef) => {
                this.carouselRef = carouselRef;
              }}
            >
              {renderChildren(true).map((child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} {...css(styles.childContainer, dynamicStyles.childContainer)}>
                  {child}
                </div>
              ))}
            </TrackedCarousel>
          </div>
        </div>
      </FocusTrap>
    );
  }

  render() {
    const {
      children: renderChildren,
      prevArrowLabel,
      nextArrowLabel,
      expandItemLabel = _t('Click to expand item'),
      canExpand,
    } = this.props;
    const { isExpanded } = this.state;
    return (
      <div>
        {canExpand && isExpanded && this.renderOverlay()}
        {/*
        Note that there are 3 possible states here (from smallest to largest):
          1. isSmall === true, isLarge === false (0 - 607px)
             Screenshots span the page, so no need for expanding functionality.
             Page gutters are small, so don't show arrows.
          2. isSmall === false, isLarge === false (608px - 823px)
             Screenshots take up 5 of 12 columns, so enable expanding functionality on click.
             Page gutters are small, so don't show arrows.
          3. isSmall === false, isLarge === true (824px - ∞)
             Screenshots take up 5 of 12 columns, so enable expanding functionality on click.
             Page gutters are large, so show arrows.
         */}
        <Media
          queries={{ isLarge: { minWidth: breakPoint.md }, isSmall: { maxWidth: breakPoint.sm - 1 } }}
          defaultMatches={{ isLarge: false, isSmall: false }}
        >
          {({ isLarge, isSmall }) => (
            <TrackedCarousel
              isExpanded={false}
              settings={{
                ...carouselSettings,
                prevArrowLabel,
                nextArrowLabel,
                arrows: isLarge,
                autoplay: !isExpanded,
                afterChange: this.onSlideChange,
                onSwipe: this.onSwipe,
              }}
            >
              {renderChildren(false).map((child, index) => {
                if (isSmall) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{child}</div>
                  );
                } else {
                  return (
                    <TrackedButton
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      trackingName="expanding_carousel_expand"
                      onClick={this.toggleExpanded}
                      {...css(styles.slideButton)}
                      aria-label={expandItemLabel}
                    >
                      {child}
                    </TrackedButton>
                  );
                }
              })}
            </TrackedCarousel>
          )}
        </Media>
      </div>
    );
  }
}

export default ExpandingCarousel;
