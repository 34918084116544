/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';
import { MED_DATE_ONLY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, Grid, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import Modal from 'bundles/ui/components/Modal';

import _t from 'i18n!nls/finaid';

export type Props = {
  approvalDate: number | null;
  applicationsCount: number;
  applicationsMax: number;
  handleClose(): void;
};

const useStyles = (theme: Theme) => ({
  appLimitsModal: css({
    [theme.breakpoints.only('xs')]: {
      top: '80% !important',
    },
  }),
  gridItem: css({
    paddingTop: theme.spacing(16),
  }),
});

const FinancialAidApplicationsLimitModal: React.FC<Props> = ({
  approvalDate,
  applicationsCount = 0,
  applicationsMax = 0,
  handleClose,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <div className="rc-ApplicationsLimitModal">
      <Modal
        css={styles.appLimitsModal}
        onRequestClose={handleClose}
        modalName="OpenApplicationsModal"
        shouldCloseOnOverlayClick
        size="large"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2semibold">
              {_t('We can only process up to #{max} applications at a time', {
                max: applicationsMax,
              })}
            </Typography>
          </Grid>

          <Grid item xs={12} css={styles.gridItem}>
            <Typography2 component="p">
              {applicationsCount >= applicationsMax ? (
                <FormattedHTMLMessage
                  message={_t(
                    "You've already submitted {max} applications that we are still processing. You can apply again when your earliest application gets processed on <strong>{approvalDate}</strong>."
                  )}
                  max={applicationsMax}
                  approvalDate={formatDateTimeDisplay(approvalDate ?? '', MED_DATE_ONLY_DISPLAY)}
                />
              ) : (
                <FormattedMessage
                  message={_t(
                    "After submitting your {max, select, 10 {tenth} other {last}} application, you won't be able to apply financial aid to any courses until at least one application is completely processed."
                  )}
                  max={applicationsMax}
                />
              )}
            </Typography2>
          </Grid>

          <Grid item xs={12} sm={6} md={6} css={[styles.gridItem, css({ margin: '0 auto' })]}>
            <Button variant="primary" fullWidth onClick={handleClose}>
              {applicationsCount >= applicationsMax ? _t('Back') : _t('Back to application')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default FinancialAidApplicationsLimitModal;
