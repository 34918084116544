/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { autoUpdate, flip, hide, limitShift, offset, shift, useFloating } from '@floating-ui/react-dom';

import { MuiFade } from '@coursera/cds-core';

import { zIndex } from 'bundles/authoring/style-constants/layout';
import MathEditorDialog from 'bundles/cml/editor/components/elements/math/MathEditorDialog';
import type { Props as DialogProps } from 'bundles/cml/editor/components/elements/math/MathEditorDialog';

type Props = DialogProps & {
  anchorEl: HTMLElement;
  isBlock: boolean;
};

const styles = {
  root: css`
    z-index: ${zIndex.modal};
  `,
};

const OUTLINE_OFFSET = -3;

// Use FloatingUI positioning library to position the dialog
// and to adjust the position from the left -> bottom -> right
// depending on the available screen real-estate
//
// https://floating-ui.com/docs/react-dom

const MathEditorDesktop = React.forwardRef<HTMLDivElement, Props>(({ anchorEl, isBlock, ...dialogProps }, ref) => {
  const defaultRef = useRef<HTMLDivElement>(null);

  const {
    x: left,
    y: top,
    refs: { setReference, setFloating },
    strategy: position,
    middlewareData,
  } = useFloating({
    placement: isBlock ? 'bottom' : 'bottom-start',
    strategy: 'fixed',
    middleware: [
      offset({ crossAxis: isBlock ? 0 : OUTLINE_OFFSET, mainAxis: 10 }),
      shift({ limiter: limitShift() }),
      flip({ padding: 8 }),
      hide({ strategy: 'referenceHidden' }),
    ],
    whileElementsMounted: autoUpdate,
  });

  useLayoutEffect(() => {
    setReference(anchorEl);
  }, [anchorEl, setReference]);

  useLayoutEffect(() => {
    const floatingEl = (ref as React.RefObject<HTMLDivElement> | undefined)?.current || defaultRef.current;
    setFloating(floatingEl);
  }, [ref, defaultRef, setFloating]);

  const hidden = middlewareData.hide?.referenceHidden ?? false;
  const onClose = dialogProps.onBlur;
  useEffect(() => {
    if (hidden) {
      onClose();
    }
  }, [hidden, onClose]);

  return (
    <MuiFade in={true}>
      <MathEditorDialog
        ref={ref || defaultRef}
        {...dialogProps}
        className="rc-CMLEditorMenu"
        style={{
          position,
          left: left ?? '',
          top: top ?? '',
        }}
        css={styles.root}
      />
    </MuiFade>
  );
});

export default MathEditorDesktop;
