/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import cx from 'classnames';

import { Button, Typography2, useTheme } from '@coursera/cds-core';

import SingleProgramBrowseCTASvg from 'bundles/program-home/assets/svgs/SingleProgramBrowseCTASvg';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import { QUERY_PARAM_VALUES } from 'bundles/unified-home-common/constants';
import { useCurrentTabContext } from 'bundles/unified-home-common/providers/consumer/CurrentTabContextProvider';

import _t from 'i18n!nls/program-home';

type Props = {
  className?: string;
  toggleCatalogTab: () => void;
};

const SingleProgramBrowseCTA = ({ className, toggleCatalogTab }: Props) => {
  const theme = useTheme();
  const { currentTab } = useCurrentTabContext();

  const styles = {
    container: css`
      max-width: 670px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    `,
    heading: css`
      margin-top: ${theme.spacing(24)};
      margin-bottom: ${theme.spacing(24)};
    `,
    description: css`
      margin-bottom: ${theme.spacing(32)};
    `,
  };

  let description = _t(
    'On this page, you can track the progress of courses you have enrolled in, view completed courses, and find your saved courses. Browse our catalog to get started on your first course!'
  );

  if (currentTab) {
    switch (currentTab) {
      case QUERY_PARAM_VALUES.IN_PROGRESS:
        description = _t(
          'On this page, you can track the progress of courses you have enrolled in. Browse our catalog to get started on your first course!'
        );
        break;
      case QUERY_PARAM_VALUES.COMPLETED:
        description = _t('On this page, you can view completed courses. Browse our catalog to get started!');
        break;
      default:
        break;
    }
  }

  return (
    <div className={cx('rc-SingleProgramBrowseCTA', className)} css={styles.container}>
      <SingleProgramBrowseCTASvg width={97} height={112} />
      <Heading defaultLevel={2} css={styles.heading}>
        {_t("Let's begin learning!")}
      </Heading>
      <Section initialLevel={3}>
        <Typography2 component="p" variant="bodyPrimary" css={styles.description}>
          {description}
        </Typography2>
        <Button variant="primary" size="medium" onClick={toggleCatalogTab}>
          {_t('Browse catalog')}
        </Button>
      </Section>
    </div>
  );
};

export default SingleProgramBrowseCTA;
