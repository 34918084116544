import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { SubscriptionTiersProductPrice } from 'bundles/coursera-plus/contentfulData/LandingPageDataProvider';
import { numberOfProducts as numberOfPlusProducts } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { isBlockCertInFreeTrialEnabled } from 'bundles/enroll/utils/blockCertInFreeTrialUtils';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { getS12nDisplayName } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/coursera-plus';

export type Copy = JSX.Element | string;
const dash = <span>&#8212;</span>;

export type CopiesType = Record<string, Copy | Copy[]>;

const getPriceMessage = (message: string, price: SubscriptionTiersProductPrice): JSX.Element => (
  <FormattedMessage
    message={message}
    price={<ReactPriceDisplay value={Number(price.amount)} currency={price.currencyCode} hideCurrencyCode={true} />}
  />
);

export const getCopies = (
  litePrice: SubscriptionTiersProductPrice,
  plusPrice: SubscriptionTiersProductPrice
): CopiesType => {
  const monthlyPrice = (price: SubscriptionTiersProductPrice): JSX.Element =>
    getPriceMessage(_t('{price} monthly'), price);

  const pricePerMonth = (price: SubscriptionTiersProductPrice): JSX.Element =>
    getPriceMessage(_t('{price}/month'), price);

  const pricePerMonthFeature = (price: SubscriptionTiersProductPrice): JSX.Element => (
    <strong>{getPriceMessage(_t('{price}/month, cancel any time'), price)}</strong>
  );

  const certificateValueProp = isBlockCertInFreeTrialEnabled()
    ? _t('A certificate for every learning program you complete after your trial ends')
    : _t('A certificate for every learning program you complete');

  return {
    dialogTitle: _t('Choose a plan that fits your goals'),
    courseraLite: _t('Coursera Lite'),
    litePrice: monthlyPrice(litePrice),
    plusPrice: monthlyPrice(plusPrice),
    courseraPlus: _t('Coursera Plus'),
    litePromiseText: _t('Learn job relevant skills'),
    liteValuePropsVariantA: [
      <FormattedMessage
        message={_t('5,000+ learning programs{dash}unlimited access to videos and lectures')}
        dash={dash}
      />,
    ],
    liteValuePropsVariantB: [
      _t('Videos and lectures within 5,000+ learning programs'),
      _t('Multiple courses to explore'),
    ],
    plusPromiseText: _t(
      'Learn job-relevant skills, measure your progress, and earn a certificate recognized by employers'
    ),
    plusValuePropsVariantA: [
      <FormattedMessage
        message={_t(
          '{numberOfProducts} learning programs{dash}unlimited access to videos, lectures, graded assignments, quizzes, and hands-on projects'
        )}
        numberOfProducts={numberOfPlusProducts}
        dash={dash}
      />,

      certificateValueProp,
    ],
    plusValuePropsVariantB: [
      _t('Videos and lectures within 5,000+ learning programs'),
      _t('Multiple courses to explore'),
      _t('Hands-on guided projects'),
      _t('Assignments and practice quizzes'),
      certificateValueProp,
    ],
    start7day: _t('Start 7-day free trial'),
    subscribeNow: _t('Subscribe now'),
    liteRecommendationText: _t("Recommended if you're exploring new topics or skills"),
    plusRecommendationText: _t("Recommended if you're working toward a promotion, a new role, or a career change"),
    plusRecommendationTextNew: _t("Recommended if you're working toward a new role, a career change, or a promotion"),
    getStartedFreeTrial: _t('Get started with a 7-day free trial'),
    cancelAnyTime: _t('Cancel any time'),
    freeTrial: _t('Free 7-day trial'),
    recommended: _t('Recommended'),
    litePricePerMonth: pricePerMonth(litePrice),
    plusPricePerMonth: pricePerMonth(plusPrice),
    litePriceFeature: pricePerMonthFeature(litePrice),
    plusPriceFeature: pricePerMonthFeature(plusPrice),
  };
};

export const getWithS12nEnrollModalCopies = ({
  litePrice,
  s12nPrice,
  course,
  s12n,
}: {
  litePrice: SubscriptionTiersProductPrice;
  s12nPrice: S12nDerivativesV1;
  course: CoursesV1;
  s12n: OnDemandSpecializationsV1;
}) => {
  if (!s12nPrice || !s12nPrice.catalogPrice) {
    return {};
  }

  const { catalogPrice } = s12nPrice;

  return {
    freeTrialTitle: _t('7-day Free Trial'),
    paidTitle: (
      <FormattedMessage
        message={_t('{price} per month')}
        price={
          <ReactPriceDisplay currency={catalogPrice.currencyCode} value={catalogPrice.amount} hideCurrencyCode={true} />
        }
      />
    ),
    freeTrialSubtitle: (
      <FormattedMessage
        className="headline-1-text"
        message={_t('{courseName} is part of the larger {productName}. Your 7-day free trial includes:')}
        courseName={course.name}
        productName={getS12nDisplayName(s12n.name, s12n.productVariant)}
      />
    ),
    paidS12nSubtitle: <strong>{_t('Subscribe to the entire series and get access to:')}</strong>,
    paidCourseSubtitle1: <strong>{_t('Subscribe and get access to the entire Specialization.')}</strong>,
    paidCourseSubtitle2: (
      <FormattedMessage
        message={_t(
          "{courseName} is part of the larger {productName} series. When you subscribe you'll get access to:"
        )}
        productName={<strong>{getS12nDisplayName(s12n.name, s12n.productVariant)}</strong>}
        courseName={<strong>{course.name}</strong>}
      />
    ),
    freeTrialCta: _t('Start free trial'),
    paidCta: _t('Subscribe'),
    tryLiteMessage: (
      <FormattedMessage
        message={_t('Try Coursera Lite for {monthlyPrice} monthly instead')}
        monthlyPrice={
          <ReactPriceDisplay
            currency={litePrice.currencyCode}
            value={Number(litePrice.amount)}
            hideCurrencyCode={true}
          />
        }
      />
    ),
  };
};

export const getLiteOnlyEnrollModalCopies = (
  litePrice: SubscriptionTiersProductPrice,
  s12n: OnDemandSpecializationsV1
) => {
  return {
    freeTrialTitle: _t('7-day free trial'),
    courseraLite: _t('Coursera Lite'),
    goBack: _t('Go back'),
    valueProps: [
      <FormattedMessage
        message={_t('{accessToAllVideosAndLectures} in this {productName}')}
        accessToAllVideosAndLectures={<strong>{_t('Access to all videos and lectures')}</strong>}
        productName={getS12nDisplayName(s12n.name, s12n.productVariant)}
      />,
      <FormattedMessage
        message={_t(
          '{unlimitedAccessToVideosAndLectures} in over 90% of courses, Specializations, and Professional Certificates'
        )}
        unlimitedAccessToVideosAndLectures={<strong>{_t('Unlimited access to videos and lectures')}</strong>}
      />,
    ],
    monthlyPrice: (
      <FormattedMessage
        message={_t('{monthlyPrice} monthly')}
        monthlyPrice={
          <ReactPriceDisplay
            currency={litePrice.currencyCode}
            value={Number(litePrice.amount)}
            hideCurrencyCode={true}
          />
        }
      />
    ),
    cta: _t('Enroll now'),
  };
};

export const getEnrollmentModalDefaultFeatureList = (s12n?: OnDemandSpecializationsV1): Record<string, Copy[]> => {
  return {
    sharedFeatures: [
      <FormattedMessage
        message={_t('{fiveThousandLearningPrograms} — access to videos and lectures, {includingThisSpecialization}')}
        fiveThousandLearningPrograms={<strong>{_t('5,000+ learning programs')}</strong>}
        includingThisSpecialization={
          <strong>
            <FormattedMessage
              message={_t('including {productName}')}
              productName={s12n ? getS12nDisplayName(s12n.name, s12n.productVariant) : _t('this Specialization')}
            />
          </strong>
        }
      />,
      <FormattedMessage
        message={_t('{tryDifferentCourses} and easily switch between them')}
        tryDifferentCourses={<strong>{_t('Try different courses')}</strong>}
      />,
    ],
    plusOnlyFeatures: [
      <FormattedMessage
        message={_t('{gradedAssignments} and quizzes')}
        gradedAssignments={<strong>{_t('Graded assignments')}</strong>}
      />,
      <strong>
        <FormattedMessage message={_t('2,000+ hands-on projects')} />
      </strong>,
      <FormattedMessage
        message={
          isBlockCertInFreeTrialEnabled()
            ? _t('{aCertificate} for every learning program you complete after your trial ends')
            : _t('{aCertificate} for every learning program you complete')
        }
        aCertificate={<strong>{_t('A certificate')}</strong>}
      />,
    ],
  };
};
