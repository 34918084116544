import type React from 'react';

import clsx from 'clsx';

import { useId } from '@coursera/cds-common';
import type { BaseComponentProps } from '@coursera/cds-common';

import Grid from '@core/Grid';
import Typography from '@core/Typography2';
import { ItemsCollectionProvider } from '@core/utils';

import ChipsCollection from './ChipsCollection';
import getChipGroupCss, { classes } from './getChipGroupCss';

type LabelProps =
  | {
      /**
       * The group label to display.
       * One of label | aria-label | aria-labelledby props must always be provided in order to keep the ChipGroup accessible.
       */
      label: string;
    }
  | {
      label?: string;
      ['aria-label']: string;
    }
  | {
      label?: string;
      ['aria-labelledby']: string;
    };

export type Props = BaseComponentProps<'div'> &
  LabelProps & {
    /**
     * Defines which type of ChipGroup to render.
     * @default select
     */
    variant?: 'select' | 'delete';

    /**
     * Behaves like a radio-group by default, or a list if set to true.
     */
    multiSelect?: boolean;

    /**
     * If present, the group label will be displayed inline with the chips.
     * @default false
     */
    inlineLabel?: boolean;

    /**
     * Individual `Chip` elements used as group items.
     *
     * ⚠️The `Chip` elements **must** be direct descendants.
     *
     * @see Chip
     */
    children: React.ReactNode;
  };

/**
 * ChipGroup is a wrapper component used to group Chip components
 * that provides an easier API, and proper keyboard accessibility to the group
 *
 * See [Props](__storybookUrl__/components-data-display-chip--default#props)
 */
const ChipGroup = ({
  className,
  label,
  inlineLabel,
  variant = 'select',
  children,
  multiSelect,
  ...props
}: Props) => {
  const labelId = useId();
  const id = useId(props.id);

  let role: 'button' | 'checkbox' | 'radio' = 'button';
  if (variant === 'select') {
    role = multiSelect ? 'checkbox' : 'radio';
  }

  return (
    <Grid
      container
      css={getChipGroupCss}
      direction={inlineLabel ? 'row' : 'column'}
    >
      {label && (
        <Typography
          className={clsx(inlineLabel ? classes.inlineLabel : classes.label)}
          component="label"
          htmlFor={id}
          id={labelId}
          variant="subtitleMedium"
        >
          {label}
        </Typography>
      )}
      <ItemsCollectionProvider commonProps={{ role, variant }}>
        <ChipsCollection
          aria-labelledby={label ? labelId : props['aria-labelledby']}
          aria-live="polite"
          aria-relevant="text"
          className={clsx(classes.group, className)}
          id={id}
          role={role === 'radio' ? 'radiogroup' : 'group'}
        >
          {children}
        </ChipsCollection>
      </ItemsCollectionProvider>
    </Grid>
  );
};

export default ChipGroup;
