import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseCardAutoenrollmentDisclaimer from 'bundles/course-cards/components/course-card/enterprise/CourseCardAutoenrollmentDisclaimer';
import CourseHomeLink from 'bundles/course-cards/components/course-card/enterprise/CourseHomeLink';
import CourseSessionInfoString from 'bundles/course-cards/components/course-card/enterprise/CourseSessionInfoString';
import StaticLinearProgress from 'bundles/course-cards/components/course-card/enterprise/StaticLinearProgress';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import type OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';

import _t from 'i18n!nls/program-home';

type Props = {
  course: CoursesV1;
  cardDropdownWidget: JSX.Element;
  learnerSession?: OnDemandLearnerSessionsV1;
  learnerMaterials?: OnDemandLearnerMaterialsV1;
  isAutoenrolled?: boolean;
  isPartOfS12n?: boolean;
} & Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds'>;

const CourseEnrolledFuturePublicSessionCard: React.FC<Props> = ({
  course: { id, name, homeLink, partnerIds },
  cardDropdownWidget,
  learnerSession,
  learnerMaterials,
  isAutoenrolled,
  isPartOfS12n,
  ...rest
}) => {
  const startDateString = learnerSession && learnerSession.startDateString;

  return (
    <div className="rc-CourseEnrolledFuturePublicSessionCard row flex-1">
      <div className="col-xs-12 col-sm-9 pos-static-override-hack">
        <CourseAndPartnerNameWithModal
          courseId={id}
          courseName={name}
          partnerIds={partnerIds}
          isPartOfS12n={isPartOfS12n}
          {...rest}
        />
        {cardDropdownWidget}
        {learnerMaterials && (
          <div>
            <Typography2 component="span" variant="subtitleMedium">
              <CourseSessionInfoString courseId={id} startDateString={startDateString} />
            </Typography2>
            {isPartOfS12n && (
              <Typography2 component="p" className="m-b-1" variant="bodyPrimary">
                {_t('Started')}
              </Typography2>
            )}
            <StaticLinearProgress
              progress={learnerMaterials.summaryCoreProgressPercentage ?? 0}
              color={color.success}
              aria-label={name}
            />
            {isAutoenrolled && <CourseCardAutoenrollmentDisclaimer />}
          </div>
        )}
      </div>
      <CourseActionBox>
        <CourseHomeLink homeLink={homeLink} label={_t('Go to course')} courseName={name} />
      </CourseActionBox>
    </div>
  );
};

export default CourseEnrolledFuturePublicSessionCard;
