/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo } from 'react';

import Imgix from 'js/components/Imgix';

import { Typography, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { CertificateIcon, GraduationCapIcon, StarFilledColoredIcon } from '@coursera/cds-icons';

import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';
import { Partners } from 'bundles/product-card/components/legacy/Partners';
import type { ProductCardImageFitProps } from 'bundles/product-card/components/legacy/ProductCardImage';
import { getProductCardImageUrl } from 'bundles/product-card/components/legacy/utils/getProductCardImageUrl';
import isCreditOffered from 'bundles/search-common/components/search-cards/isCreditOffered';
import isPremiumProduct from 'bundles/search-common/components/search-cards/isPremiumProduct';
import { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import type { SearchIndexHit } from 'bundles/search-common/types';

import _t from 'i18n!nls/search';

const styles = {
  cardContainer: css`
    min-height: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  cardImage: css`
    height: 216px;
    overflow: hidden;

    > div {
      width: 100%;
    }
  `,
  cardContent: css`
    padding: 16px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    row-gap: 16px;
    justify-content: space-between;
  `,
  cardContentBody: css`
    display: grid;
    row-gap: 8px;
  `,
  reviews: css`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,
  reviewItem: css`
    margin-right: 4px;
  `,
  skillsContainer: css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  `,
  skillsYoullGain: (theme: Theme) => css`
    color: ${theme.palette.black[500]};
  `,
  partnerContainer: css`
    display: flex;
    flex-wrap: wrap;
  `,
  freeBadge: (theme: Theme) => css`
    padding: 1px 8px 1px 8px;
    border-radius: 4px;
    background-color: var(--cds-color-white-0);
    position: absolute;
    right: 8px;
    top: 8px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
  `,
  cPlusContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  `,
  cPlusLogo: css`
    margin-right: 8px;
    border-radius: 4px;
  `,
};

interface SearchCardImageProps {
  hit: SearchIndexHit;
  hitPosition?: number;
  searchCardImageProps?: ProductCardImageFitProps;
  enableFluidWidthForLazyImg?: boolean;
}

const getPremiumText = () => ({
  degree: _t('Earn a degree'),
  credit: _t('Credit offered'),
});

export const SearchCardImage = ({
  hit,
  hitPosition,
  searchCardImageProps,
  enableFluidWidthForLazyImg,
}: SearchCardImageProps) => {
  const imgixProps = {
    src: getProductCardImageUrl(hit.id, hit.imageUrl, hit.partnerLogos),
    alt: '',
    maxHeight: 330,
    maxWidth: 330,
    imgParams: {
      fit: 'fill',
      auto: 'format,compress',
      quality: '25',
      ...(searchCardImageProps || {}),
    },
    style: { width: '100%', maxHeight: 'none', maxWidth: 'none' },
  };

  // If card is after the 6th element in list, use LazyImgix for perf boost.
  if (hitPosition && hitPosition > 5) {
    return <LazyImgix {...{ ...imgixProps, enableFluidWidth: enableFluidWidthForLazyImg }} />;
  } else {
    return <Imgix {...imgixProps} />;
  }
};

export const SearchCardReviews: React.FC<{ hit: SearchIndexHit }> = memo(({ hit }) => {
  const { avgProductRating, numProductRatings } = hit;

  if (!avgProductRating || (numProductRatings && numProductRatings <= 5)) {
    return null;
  }

  let numOfReviews = numProductRatings?.toLocaleString();
  if (numProductRatings && numProductRatings >= 1000) {
    numOfReviews = (Math.round(numProductRatings / 100) / 10).toLocaleString() + 'k';
  }

  return (
    <div css={styles.reviews}>
      <StarFilledColoredIcon size="small" css={styles.reviewItem} />
      {avgProductRating && (
        <Typography2 variant="subtitleMedium" component="p" css={styles.reviewItem}>
          {avgProductRating.toFixed(1)}
        </Typography2>
      )}
      {numProductRatings && (
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {_t('(#{numOfReviews} reviews)', {
            numOfReviews,
          })}
        </Typography2>
      )}
    </div>
  );
});

export const SearchCardDegreeOrCredit: React.FC<{ isDegree: boolean; showCreditOffered: boolean }> = memo(
  ({ isDegree, showCreditOffered }) => {
    if (!isDegree && !showCreditOffered) {
      return null;
    } else {
      return (
        <div css={styles.reviews}>
          {isDegree ? (
            <GraduationCapIcon css={styles.reviewItem} color="support" />
          ) : (
            <CertificateIcon css={styles.reviewItem} color="support" />
          )}
          <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.reviewItem}>
            {isDegree ? getPremiumText().degree : getPremiumText().credit}
          </Typography2>
        </div>
      );
    }
  }
);

export const SearchCardCourseraPlus = ({ isEnrolledInCPlus }: { isEnrolledInCPlus?: boolean }) => {
  if (!isEnrolledInCPlus) {
    return null;
  }

  return (
    <div css={styles.cPlusContainer}>
      <Imgix
        src={logo.PLUS_PILL_BLUE}
        alt={_t('Coursera Plus')}
        maxWidth={41}
        maxHeight={16}
        css={styles.cPlusLogo}
        aria-hidden={true}
      />
      <Typography2 component="p" variant="bodySecondary" color="supportText">
        {_t('Included with Coursera Plus')}
      </Typography2>
    </div>
  );
};

type SearchCardProps = {
  hit: SearchIndexHit;
  hitPosition?: number;
  isEnrolledInCPlus?: boolean;
  searchCardImageProps?: { fit: string };
  metadataTags?: React.ReactNode;
  titleTrackingData?: { trackingName: string; data: Record<string, unknown> };
};

export const SearchCard: React.FC<SearchCardProps> = ({
  hit,
  hitPosition,
  isEnrolledInCPlus,
  searchCardImageProps,
  metadataTags,
  titleTrackingData,
}) => {
  let ariaLabel = hit.name || '';

  if (hit.entityType) {
    ariaLabel += ` ${hit.entityType.toLowerCase()}`;
  }

  if (hit.partners && hit.partners.length) {
    ariaLabel += ` by ${hit.partners.join(', ')}`;
  }

  const getProductInfo = () => {
    const info = [];
    if (hit.productDifficultyLevel) {
      info.push(hit.productDifficultyLevel);
    }
    if (hit.entityType) {
      info.push(
        /* Set string to all lowercase and capitalize each word in string */
        hit.entityType
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ')
      );
    }
    if (hit.productDuration) {
      info.push(hit.productDuration);
    }

    return info.join(' · ');
  };

  return (
    <div css={styles.cardContainer} aria-label={ariaLabel} data-testid="SearchCard">
      <div css={styles.cardImage}>
        <SearchCardImage hit={hit} hitPosition={hitPosition} searchCardImageProps={searchCardImageProps} />
      </div>
      {hit.isFree && (
        <div css={styles.freeBadge}>
          <Typography2 variant="subtitleMedium" component="p">
            {_t('Free')}
          </Typography2>
        </div>
      )}
      <div css={styles.cardContent} aria-hidden>
        <div css={styles.cardContentBody}>
          <div css={styles.partnerContainer}>
            <Partners
              partners={hit.partners}
              partnerLogos={hit.partnerLogos}
              cobrandingEnabled={hit.cobrandingEnabled}
            />
          </div>
          <Typography data-testid="card-name" variant="h2semibold">
            {hit.name}
            {/*
              Summary: titleTrackingData added in order to make this card behave like the new CDS cards in experiments
              Analysis: https://docs.google.com/document/d/1IwonnGDcN9nh-cxkn8NPspKS4S8WcBuHHAinToKQzPQ/edit#heading=h.azyrzx5itcu
              Slack thread: https://coursera.slack.com/archives/C04UJEGH37U/p1686754157564539
             */}
            {titleTrackingData && (
              <TrackedDiv
                trackingName={titleTrackingData.trackingName}
                withVisibilityTracking={true}
                data={titleTrackingData.data}
              />
            )}
          </Typography>
          {hit.skills && hit.skills.length > 0 && (
            <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.skillsContainer}>
              <span css={styles.skillsYoullGain}>
                <b>{_t("Skills you'll gain: ")}</b>
              </span>
              {hit.skills.join(', ')}
            </Typography2>
          )}
          {metadataTags}
          {hit.session && (
            <Typography2 component="p" variant="bodySecondary" color="supportText" css={styles.skillsContainer}>
              <span css={styles.skillsYoullGain}>
                <b>{hit.session}</b>
              </span>
            </Typography2>
          )}
        </div>
        <div>
          {hit.isPartOfCourseraPlus && <SearchCardCourseraPlus isEnrolledInCPlus={isEnrolledInCPlus} />}
          {hit.entityType && isPremiumProduct(hit.entityType) ? (
            <SearchCardDegreeOrCredit
              isDegree={hit.entityType === LEARNING_PRODUCTS.Degree}
              showCreditOffered={isCreditOffered(hit.entityType)}
            />
          ) : (
            <SearchCardReviews hit={hit} />
          )}
          <Typography2 component="p" data-testid="card-product-info" variant="bodySecondary" color="supportText">
            {getProductInfo()}
          </Typography2>
        </div>
      </div>
    </div>
  );
};

export default memo(SearchCard);
