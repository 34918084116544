/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import { Button, Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { ButtonProps, Theme } from '@coursera/cds-core';
import { SpinnerIcon } from '@coursera/cds-icons';
import { API_IN_PROGRESS } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import ErrorMessage from 'bundles/coursera-ui/components/extended/ErrorMessage';
import type { ApiState as ApiStatus } from 'bundles/coursera-ui/constants/apiNotificationConstants';
import type { NaptimeError } from 'bundles/naptimejs';
import type ProgramMembershipsV2 from 'bundles/naptimejs/resources/programMemberships.v2';
import { IdTypes } from 'bundles/product-features';
import { EnrollmentChoiceModalBody } from 'bundles/program-common/components/EnrollmentChoiceModal';
import EnrollmentChoiceModalProductDescription from 'bundles/program-common/components/EnrollmentChoiceModalProductDescription';
import type { ProductCardCourseFragmentFragment as Course } from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import AutoenrolledCourseList from 'bundles/program-home/components/modals/AutoenrolledCourseList';
import getErrorMap from 'bundles/program-home/constants/ProgramJoinModalErrors';
import { SoftDeletedMember, Whitelisted } from 'bundles/program-home/constants/ProgramMembershipState';
import { ProductFeaturesQuery } from 'bundles/program-home/productFeatureFlags';

import _t from 'i18n!nls/program-home';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

function getDefaultErrorMsg() {
  return _t('Error joining the program, please try again later');
}

type PropsFromCaller = {
  programId: string;
  programName: string;
  programMembership: ProgramMembershipsV2;
  thirdPartyOrganizationId: string;
  orgName: string;
  onJoinProgram: () => void;
  apiStatus: ApiStatus;
  error?: NaptimeError | null;
  shouldUseJoinTrackingVerbiage: boolean;
  isGwGProgram?: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  autoEnrolledCoursesWithData?: Course[];
  userId: number;
  isAutoEnrolling: boolean;
};

type PropsFromRouter = {
  router: InjectedRouter;
};

export type PropsForModal = PropsFromCaller & PropsFromRouter;

type PropsForAction = {
  label: string;
  hasNavFromEOCModal: boolean;
  membershipState: string;
} & Pick<
  PropsForModal,
  'programId' | 'programName' | 'onJoinProgram' | 'apiStatus' | 'error' | 'thirdPartyOrganizationId'
>;

const styles = {
  container: (theme: Theme) => ({
    margin: theme.spacing(32, 48, 0, 48),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(24, 16, 8, 16),
    },
  }),
  joinText: (theme: Theme) => ({
    marginTop: theme.spacing(24),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(16),
    },
  }),
  autoenrollmentText: (theme: Theme) => ({
    marginTop: theme.spacing(16),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
    },
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    paddingBottom: theme.spacing(16),
  }),
  trackingText: (theme: Theme) => ({
    whiteSpace: 'pre-wrap' as const,
    marginTop: theme.spacing(16),
    '&&': {
      fontWeight: 'bold' as const,
    },
  }),
};

const ProgramJoinModalAction = ({
  label,
  programId,
  programName,
  onJoinProgram,
  apiStatus,
  error,
  hasNavFromEOCModal,
  membershipState,
}: PropsForAction) => {
  let trackingName: string;

  if (hasNavFromEOCModal && membershipState === Whitelisted) {
    trackingName = 'accept_allowlist_nav_from_eoc_modal';
  } else if (hasNavFromEOCModal) {
    trackingName = 'accept_invitation_nav_from_eoc_modal';
  } else {
    trackingName = 'accept_invitation';
  }

  return (
    <React.Fragment>
      <TrackedButton
        {...Button.defaultProps}
        trackingName={trackingName}
        trackingData={{ programId, programName }}
        data-e2e="ProgramJoinModal-join-button"
        disabled={apiStatus === API_IN_PROGRESS}
        onClick={onJoinProgram}
      >
        {label}
      </TrackedButton>
      {error && (
        <ErrorMessage
          style={{ marginTop: 15 }}
          error={error}
          defaultErrorMsg={
            (error.responseJSON && getErrorMap(programId)[error.responseJSON.errorCode]) || getDefaultErrorMsg()
          }
        />
      )}
    </React.Fragment>
  );
};

const ProgramJoinModal = ({
  programId,
  programName,
  programMembership,
  orgName,
  onJoinProgram,
  apiStatus,
  error,
  shouldUseJoinTrackingVerbiage,
  thirdPartyOrganizationId,
  router,
  isGwGProgram,
  programRecommendationsAvailableCoursesCopy,
  autoEnrolledCoursesWithData,
  userId,
  isAutoEnrolling,
}: PropsForModal) => {
  const theme = useTheme();

  const isReturning = programMembership?.membershipState === SoftDeletedMember;
  const hasNavFromEOCModal = Boolean(router.location.query.eoc);

  // Remove '%Fclips' check once Clips Redesign is generally available to all users. It's only necessary to make the redirect work for local development
  const isFromClipsRedirect =
    Boolean(router.location.query.redirectPath) &&
    (router.location.query.redirectPath.startsWith('/videos') ||
      router.location.query.redirectPath.startsWith('/clips'));

  const contentMap = {
    default: {
      title: _t('Join the learning program'),
      description: _t('A learning program is a repository of content curated by your organization.'),
      // eslint-disable-next-line no-nested-ternary
      buttonLabel: hasNavFromEOCModal
        ? _t('Join program and enroll')
        : isFromClipsRedirect
        ? _t('Join now and watch')
        : _t('Join program'),
    },
    isReturning: {
      title: _t('Join the learning program'),
      description: _t(
        'We’re happy to see you back! You were previously un-enrolled from your courses by your learning team but can re-enroll anytime. Your course progress has been saved so you will pick up right where you left off. Happy learning!'
      ),
      // eslint-disable-next-line no-nested-ternary
      buttonLabel: hasNavFromEOCModal
        ? _t('Return to program and enroll')
        : isFromClipsRedirect
        ? _t('Join now and watch')
        : _t('Return to program'),
    },
    isAutoEnrolling: {
      title: _t('Let’s get you to your destination'),
      description: _t('Join this program and get access to its content.'),
      buttonLabel: _t('Join program'),
    },
    isProgramRecommending: {
      title: _t('Join the learning program'),
      description: _t(
        'A learning program is a repository of content and featured courses to help you achieve your learning goals.'
      ),
      // eslint-disable-next-line no-nested-ternary
      buttonLabel: hasNavFromEOCModal
        ? _t('Join program and enroll')
        : isFromClipsRedirect
        ? _t('Join now and watch')
        : _t('Join program'),
    },
  };

  let buttonLabel: string;
  let description: string;
  let title: string;

  if (isAutoEnrolling) {
    title = contentMap.isAutoEnrolling.title;
    description = contentMap.isAutoEnrolling.description;
    buttonLabel = contentMap.isAutoEnrolling.buttonLabel;
  } else if (isReturning) {
    title = contentMap.isReturning.title;
    description = contentMap.isReturning.description;
    buttonLabel = contentMap.isReturning.buttonLabel;
  } else if (programRecommendationsAvailableCoursesCopy) {
    title = contentMap.isProgramRecommending.title;
    description = contentMap.isProgramRecommending.description;
    buttonLabel = contentMap.isProgramRecommending.buttonLabel;
  } else {
    title = contentMap.default.title;
    description = contentMap.default.description;
    buttonLabel = contentMap.default.buttonLabel;
  }

  return (
    <div className="ProgramJoinModal" data-e2e="ProgramJoinModal">
      <EnrollmentChoiceModalBody
        headerTitle={title}
        button={
          <ProgramJoinModalAction
            programId={programId}
            programName={programName}
            onJoinProgram={onJoinProgram}
            apiStatus={apiStatus}
            error={error}
            label={buttonLabel}
            thirdPartyOrganizationId={thirdPartyOrganizationId}
            hasNavFromEOCModal={hasNavFromEOCModal}
            membershipState={programMembership?.membershipState}
          />
        }
      >
        {!isAutoEnrolling && <EnrollmentChoiceModalProductDescription />}
        <div css={styles.container(theme)}>
          {!isAutoEnrolling && (
            <Typography variant="h2" component="h3">
              {_t('You are invited to join the #{programName}', { programName })}
            </Typography>
          )}
          <Typography2 component="p" className="join-verbiage" css={styles.joinText(theme)}>
            {description}
          </Typography2>
          {autoEnrolledCoursesWithData && Boolean(autoEnrolledCoursesWithData?.length) && (
            <>
              <Typography2 component="p" css={styles.autoenrollmentText(theme)}>
                {_t(
                  'You will be automatically enrolled in the following courses by your organization. Find them in My Courses.'
                )}
              </Typography2>
              <AutoenrolledCourseList
                programId={programId}
                autoEnrolledCoursesWithData={autoEnrolledCoursesWithData}
                userId={userId}
              />
            </>
          )}
          {shouldUseJoinTrackingVerbiage && (
            <Typography2 component="p" className="tracking-verbiage" css={styles.trackingText(theme)}>
              <ProductFeaturesQuery idType={IdTypes.Program} id={programId}>
                {({ loading, features }) => {
                  if (loading) {
                    return <SpinnerIcon aria-busy aria-label={_t('Loading...')} />;
                  }

                  const customTrackingMessage = features.get('enterprise', 'programJoinTrackingCustomMessage');
                  if (customTrackingMessage) {
                    return customTrackingMessage;
                  }

                  if (isGwGProgram) {
                    return _t(
                      'By joining, you agree to #{orgName} tracking your grades and other activity in its learning program. Additionally, the content provider, Google, and support providers such as Global Grid for Learning (GG4L) may have access to your personal and learning data to provide supplementary services.',
                      { orgName }
                    );
                  }

                  return _t(
                    'By joining, you agree to #{orgName} tracking your grades and other activity in its learning program.',
                    { orgName }
                  );
                }}
              </ProductFeaturesQuery>
            </Typography2>
          )}
        </div>
      </EnrollmentChoiceModalBody>
    </div>
  );
};

export default ProgramJoinModal;
