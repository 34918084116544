import type { Entitlement_ProductOwnership } from '__generated__/graphql-types';
import { branch, withProps } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import GetProductOwnershipQuery from 'bundles/enroll/utils/queries/GetProductOwnershipQuery.graphql';
import type { GetProductOwnershipQueryVariables } from 'bundles/enroll/utils/queries/__generated__/GetProductOwnershipQuery';
import paymentsExperiments from 'bundles/epic/clients/payments';
import Naptime from 'bundles/naptimejs';
import ProductOwnershipsV1 from 'bundles/naptimejs/resources/productOwnerships.v1';
import { SPECIALIZATION, VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';

export type ProductOwnershipsInputProps = {
  productOwnershipsId?: string;
};

type InputProps = ProductOwnershipsInputProps;

type NaptimeProps = {
  productOwnerships?: Array<ProductOwnershipsV1>;
};

type Props = InputProps & NaptimeProps;

type APIProps = {
  // eslint-disable-next-line camelcase
  productOwnerships?: Pick<Entitlement_ProductOwnership, 's12nCourseOwnerships'>;
};

/** @deprecated Use the useProductOwnerships custom hook instead */
export const withProductOwnerships = <WithProductOwnershipsProps extends InputProps>() =>
  branch<InputProps>(
    () => paymentsExperiments.preview('useDGSProductOwnership'),
    waitForGraphQL<
      WithProductOwnershipsProps,
      APIProps,
      GetProductOwnershipQueryVariables,
      WithProductOwnershipsProps & APIProps
    >(GetProductOwnershipQuery, {
      skip: (props) => !user.isAuthenticatedUser() || !props.productOwnershipsId,
      options: ({ productOwnershipsId }) => {
        if (!productOwnershipsId) {
          throw new Error(`expected valid productOwnershipsId`);
        }
        return {
          variables: {
            productOwnershipId: productOwnershipsId,
          },
          context: { clientName: 'gatewayGql' },
        };
      },
      props: ({ data, ownProps }) => {
        return {
          ...ownProps,
          productOwnerships: data?.productOwnerships,
        };
      },
    }),
    Naptime.createContainer<Props & WithProductOwnershipsProps, WithProductOwnershipsProps, NaptimeProps>(
      ({ productOwnershipsId }) => {
        if (productOwnershipsId) {
          return {
            productOwnerships: ProductOwnershipsV1.get(productOwnershipsId, {
              fields: ['s12nCourseOwnerships', 'owns'],
            }),
          };
        } else {
          return {};
        }
      }
    )
  );

type SpecializationIdProps = {
  s12nId: string;
};

export const withSpecializationProductOwnershipsId = withProps<ProductOwnershipsInputProps, SpecializationIdProps>(
  ({ s12nId }) => {
    const productOwnershipsId = user.isAuthenticatedUser()
      ? tupleToStringKey([user.get().id, SPECIALIZATION, s12nId])
      : undefined;
    return { productOwnershipsId };
  }
);

type VerifiedCertificateIdProps = {
  courseId: string;
};
export const withVerifiedCertificateProductOwnershipsId = withProps<
  ProductOwnershipsInputProps,
  VerifiedCertificateIdProps
>(({ courseId }) => {
  const productOwnershipsId = user.isAuthenticatedUser()
    ? tupleToStringKey([user.get().id, VERIFIED_CERTIFICATE, courseId])
    : undefined;
  return { productOwnershipsId };
});
