import _ from 'lodash';
import moment from 'moment';

import type { SavedProductsQuery_ProgramCurriculumProductsV1Resource_selected_elements_productState as ProductState } from 'bundles/program-home/components/enterprise-home/__generated__/SavedProductsQuery';
import {
  org_coursera_program_enrollment_ProgramUserProductAction as ProgramUserProductAction,
  org_coursera_program_enrollment_ProgramUserProductState as ProgramUserProductState,
  org_coursera_program_enrollment_ProgramUserProductStateReason as ProgramUserProductStateReason,
} from 'bundles/program-home/components/enterprise-home/__generated__/globalTypes';

class ProgramCurriculumProduct {
  _productState: ProductState;

  constructor({ productState }: { productState: ProductState }) {
    this._productState = productState;
  }

  get productState() {
    return 'programS12nWithState' in this._productState
      ? this._productState.programS12nWithState
      : this._productState.programCourseWithState;
  }

  get isS12n() {
    return this._productState.__typename === 'ProgramCurriculumProductsV1_programS12nWithStateMember';
  }

  get isCourse() {
    return this._productState.__typename === 'ProgramCurriculumProductsV1_programCourseWithStateMember';
  }

  get isAvailable() {
    return this.productState.state === 'AVAILABLE';
  }

  get isUnavailable() {
    return this.productState.state === 'UNAVAILABLE';
  }

  get isCompleted() {
    return this.productState.state === 'COMPLETED';
  }

  get completedAt() {
    if ('completedAt' in this.productState && this.productState.completedAt) {
      return moment(this.productState.completedAt).format('LL');
    }
    return null;
  }

  get isEnrolled() {
    return this.productState.state === 'ENROLLED' || this.isCompleted;
  }

  get isSelected() {
    return this.productState.isWishlisted;
  }

  get isWishlisted() {
    return this.productState.isWishlisted;
  }

  get completionTime() {
    if ('completedAt' in this.productState) {
      return this.productState.completedAt;
    }
    return null;
  }

  get canManage() {
    return this.productState.actions.includes(ProgramUserProductAction.MANAGE);
  }

  get canSelect() {
    return this.isWishlisted !== undefined && !this.isWishlisted;
  }

  get canUnselect() {
    return this.isWishlisted !== undefined && this.isWishlisted;
  }

  get canEnroll() {
    return this.productState.actions.includes(ProgramUserProductAction.ENROLL);
  }

  get canUnenroll() {
    return this.productState.actions.includes(ProgramUserProductAction.UNENROLL);
  }

  get canResume() {
    return this.productState.actions.includes(ProgramUserProductAction.RESUME);
  }

  get canUpgrade() {
    return this.isS12n && this.productState.actions.includes(ProgramUserProductAction.UPGRADE);
  }

  get hasAvailableCourses() {
    if ('courseStates' in this.productState) {
      const courseStates = this.productState.courseStates;
      return (
        this.isS12n &&
        !_.isEmpty(this.productState.courseStates) &&
        !!_.find(courseStates, (courseState) => courseState.state === ProgramUserProductState.AVAILABLE)
      );
    }
    return false;
  }

  get courseId() {
    if ('courseId' in this.productState) {
      return this.productState.courseId;
    }
    return null;
  }

  get s12nId() {
    if ('s12nId' in this.productState) {
      return this.productState.s12nId;
    }
    return null;
  }

  get latestS12nId() {
    if ('latestS12nId' in this.productState) {
      return this.productState.latestS12nId;
    }
    return null;
  }

  get state() {
    return this.productState.state;
  }

  get productId() {
    return this.s12nId || this.courseId;
  }

  get hasNoOpenSessions() {
    return this.productState.reasonsForState.includes(
      ProgramUserProductStateReason.UNAVAILABLE_COURSE_NO_OPEN_SESSIONS
    );
  }

  get s12nCourseStates() {
    if ('courseStates' in this.productState) {
      return this.productState.courseStates.map(
        (courseState) =>
          new ProgramCurriculumProduct({
            productState: {
              __typename: 'ProgramCurriculumProductsV1_programCourseWithStateMember',
              programCourseWithState: {
                ...courseState,
              },
            },
          })
      );
    }
    return null;
  }
}

export default ProgramCurriculumProduct;
