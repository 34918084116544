import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const LINK_MARKDOWN_REGEX = /(\[(.+?)\]\((.+)\))/;

export const normalizeLinkMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (
    !Text.isText(nodeEntry[0]) ||
    hasAncestorOfType(editor, BLOCK_TYPES.LINK, nodeEntry[1]) ||
    !tools.has(Tools.LINK)
  ) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<Text>;
  const { text } = node;

  const match = text.match(LINK_MARKDOWN_REGEX);
  if (!match) {
    return false;
  }

  const startOffset = text.indexOf(match[1]);
  const endOffset = startOffset + match[2].length;
  const distance = match[1].length - match[2].length - 1;

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, { at: { path, offset: startOffset }, distance: 1 });
    Transforms.delete(editor, {
      at: { path, offset: endOffset },
      distance,
      unit: 'character',
    });
    Transforms.wrapNodes(
      editor,
      { type: BLOCK_TYPES.LINK, isInline: true, href: match[3], children: [] },
      { at: { anchor: { path, offset: startOffset }, focus: { path, offset: endOffset } }, split: true }
    );
  });
  return true;
};
