import * as React from 'react';

import { VisuallyHidden } from '@coursera/cds-core';
import { StyleSheet, color, css } from '@coursera/coursera-ui';

import _t from 'i18n!nls/program-home';

// Dynamic styles
function getStyles({
  backgroundColor,
  propColor,
  height,
}: {
  backgroundColor: string | undefined;
  propColor: string;
  height: number;
}) {
  return {
    StaticLinearProgress: {
      height,
      backgroundColor,
      borderRadius: height / 2,
    },
    bar: {
      backgroundColor: propColor,
      borderRadius: height / 2,
    },
  };
}

const DEFAULT_HEIGHT = 8;

const styles = StyleSheet.create({
  StaticLinearProgress: {
    backgroundColor: '#949494',
    width: '100%',
    height: DEFAULT_HEIGHT,
    overflow: 'hidden',
  },
  bar: {
    height: '100%',
    backgroundColor: color.primary,
  },
});

/**
 *  A static progress bar
 * Sample Usage:
 * <StaticLinearProgress progress={20} />
 * <StaticLinearProgress progress={20} color="#456" backgroundColor="#f1f1f1" height={16} />
 */
const StaticLinearProgress: React.FC<{
  // Override the inline-styles of the root element
  style?: React.CSSProperties;

  // The backgroundColor of the progress bar
  backgroundColor?: string;

  // The foreground color of the progress bar
  color: string;

  // The height of the the progress bar
  height?: number;

  progress: number;

  'aria-label': string;
}> = ({
  style,
  progress = 0, // 0 - 100
  backgroundColor,
  color: propColor,
  height = DEFAULT_HEIGHT,
  'aria-label': courseName,
}) => {
  const dynamicStyles = getStyles({ backgroundColor, propColor, height });
  const mergedRootStyle = { ...dynamicStyles.StaticLinearProgress, ...style };
  const barStyle = { ...dynamicStyles.bar, width: `${progress}%` };

  return (
    <div {...css(styles.StaticLinearProgress)} style={mergedRootStyle}>
      <div
        aria-hidden
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
        {...css(styles.bar)}
        style={barStyle}
        aria-label={courseName}
      />
      <VisuallyHidden>{_t('#{courseName}, #{progress}%, progress bar.', { courseName, progress })}</VisuallyHidden>
    </div>
  );
};

export default StaticLinearProgress;
