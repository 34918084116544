import { useCallback, useEffect, useState } from 'react';
import type { RefObject } from 'react';

type FocusCallback = () => void;

export const useFocusHandler = (
  ref: RefObject<HTMLElement>,
  onFocus: FocusCallback | undefined,
  onBlur: FocusCallback | undefined
) => {
  const [focusedOverride, setFocusedOverride] = useState(false);
  const [focused, setFocused] = useState(false);
  const [mergedFocus, setMergedFocus] = useState(false);

  const handleFocus = useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (!focused) {
        return;
      }

      const target = event?.relatedTarget || document.activeElement;
      const isFocused = ref.current?.contains(target as Node) === true;
      if (isFocused) {
        return;
      }

      setFocused(false);
    },
    [ref, focused]
  );

  useEffect(() => {
    const el = ref.current;
    el?.addEventListener('focusin', handleFocus);
    el?.addEventListener('focusout', handleBlur);

    return () => {
      el?.removeEventListener('focusin', handleFocus);
      el?.removeEventListener('focusout', handleBlur);
    };
  }, [ref, handleFocus, handleBlur]);

  useEffect(() => {
    const value = focused || focusedOverride;
    if (mergedFocus === value) {
      return;
    }

    if (value) {
      onFocus?.();
    } else {
      onBlur?.();
    }

    setMergedFocus(value);
  }, [focused, focusedOverride, mergedFocus, onBlur, onFocus]);

  return {
    focused: mergedFocus,
    setFocusedOverride,
    setFocused: handleFocus,
  };
};
