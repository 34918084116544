/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import gql from 'graphql-tag';
import { compose, withProps } from 'recompose';

import { Grid, Typography, Typography2, typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

// Save/Unsave Experiment
import SavingBookmark from 'bundles/browse/components/SavingBookmark';
import { SAVING_BOOKMARK_WHITE } from 'bundles/browse/components/utils/BookmarkUtils';
import type { SavedProductStringTypes } from 'bundles/browse/types/SavedProductQueries';
import CourseraPlusBannerRibbon from 'bundles/enroll/components/xdp/coursera-plus/cds/CourseraPlusBannerRibbon';
import BannerInstructorInfo from 'bundles/enterprise-legacy-xdp/components/banner/BannerInstructorInfo';
import Partner from 'bundles/enterprise-legacy-xdp/components/banner/Partner';
import ProductMetrics, { ProductMetricsRenderType } from 'bundles/enterprise-legacy-xdp/components/cds/ProductMetrics';
import XDPRating from 'bundles/enterprise-legacy-xdp/components/cds/XDPRating';
import BannerInfoBox from 'bundles/enterprise-legacy-xdp/components/miniPDP/BannerInfoBox';
import EnrollButton from 'bundles/enterprise-legacy-xdp/components/miniPDP/EnrollButton';
import PdpSection from 'bundles/enterprise-legacy-xdp/components/miniPDP/PdpSection';
import type { Banner as BannerType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/__generated__/Banner';
import type { BannerInfoBox as BannerInfoBoxType } from 'bundles/enterprise-legacy-xdp/components/miniPDP/bannerInfo/__generated__/BannerInfoBox';
import type { PageDataContext } from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/PageDataContext';
import usePageData from 'bundles/enterprise-legacy-xdp/components/miniPDP/data/usePageData';
import { CreditBadge } from 'bundles/enterprise-ui/components/Badges';
import withFragments from 'bundles/graphql/components/withFragments';
import { VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import type { EnterpriseProductConfiguration } from 'bundles/program-common/types/xdpTypes';
import { getBackgroundAndFontColor } from 'bundles/program-common/utils/colorUtils';
import getSocialCaptions from 'bundles/program-common/utils/getSocialCaptions';
import ShareButtonWithModal from 'bundles/sharing-common/components/modal/ShareButtonWithModal';
import { savedProductTypes } from 'bundles/unified-description-page-common/constants';
import withIsProductSavedData from 'bundles/xddp/components/shared/withIsProductSavedData';
import type { SimpleProductRequestQueryVariables as PropsFromWithPropsForLookup } from 'bundles/xddp/types';
import type { IsProductSavedInjectedProps as PropsFromIsProductSaved } from 'bundles/xddp/types/withIsProductSavedData';

import _t from 'i18n!nls/program-common';

type PropsFromCaller = Omit<BannerType, '__typename'> &
  Omit<BannerInfoBoxType, '__typename'> & {
    projectId: string;
    actionElement?: JSX.Element;
    shouldShowShareButton?: boolean;
    shouldShowCourseraPlusRibbon?: boolean;
    canEnrollWithFullDiscount?: boolean;
    totalEnrollmentCount?: number | null;
    pageViewsInLastMonthCount?: number | null;
    privateSessionDates?: { startsAt: number; endsAt: number };
    isEnterpriseAdminView?: boolean;
    enterpriseProductConfiguration?: EnterpriseProductConfiguration;
    shouldShowInstructorInfoBox?: boolean;
  };

type PropsToComponent = PropsFromCaller & PropsFromIsProductSaved & PropsFromWithPropsForLookup;

const styles = {
  productMetricsWrapper: (theme: Theme) => css`
    margin-top: ${theme.spacing(24)};
    ${theme.breakpoints.down('sm')} {
      /* Adding this negative 12 to remove 12px from the padding inside ProductMetrics given it doesn't expose an interface to customize it */
      margin-top: calc(${theme.spacing(12)} * -1);
    }
  `,
  bannerLeftBox: (theme: Theme) => css`
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing(0, 12)};
  `,
  partnerLogo: css`
    max-height: 36px !important;
  `,
  enrollButtonContainer: (theme: Theme) => css`
    margin-top: ${theme.spacing(24)};
    ${theme.breakpoints.between('xs', 'sm')} {
      display: inline-block;
    }
    ${theme.breakpoints.down('xs')} {
      display: none;
    }

    .enroll-button-cds {
      color: ${theme.palette.blue[600]} !important;
      padding: ${theme.spacing(16)} !important;
      border-radius: 2px !important;
      font-size: 14px !important;
      font-weight: 14px !important;
      ${typography2.bodyPrimary};
    }
  `,
  infoBoxContainer: (theme: Theme) => css`
    padding: ${theme.spacing(0, 12)};
    ${theme.breakpoints.down('sm')} {
      margin-top: ${theme.spacing(16)};
    }
  `,
  actionElementContainer: css`
    width: 100%;
    display: block;
  `,
};

export const Banner: React.FunctionComponent<PropsToComponent> = ({
  name,
  partners,
  ratings,
  projectId,
  level,
  workload,
  subtitleLanguages,
  primaryLanguages,
  avgLearningHoursAdjusted,
  cmlRecommendedBackground,
  cmlLearningObjectives,
  actionElement,
  shouldShowShareButton = false,
  shouldShowCourseraPlusRibbon,
  canEnrollWithFullDiscount,
  totalEnrollmentCount,
  pageViewsInLastMonthCount,
  product,
  isSavedProduct,
  loadingSavedProduct,
  isEnterpriseAdminView,
  enterpriseProductConfiguration,
  shouldShowInstructorInfoBox,
}) => {
  const { userAgent, data } = usePageData() as PageDataContext;
  const shouldShowRatings = !!(ratings?.averageFiveStarRating || ratings?.ratingCount);
  const { fontColor, backgroundColor } = getBackgroundAndFontColor(partners[0]?.primaryColor ?? undefined);

  const pdpSectionStyles = (theme: Theme) => css`
    /* Banner uses a different top/bottom padding than the other PDP sections */
    padding-top: ${theme.spacing(48)};
    padding-bottom: ${theme.spacing(48)};
    ${theme.breakpoints.down('md')} {
      padding-top: ${theme.spacing(24)} !important;
      padding-bottom: ${theme.spacing(24)} !important;
    }
    ${theme.breakpoints.down('xs')} {
      margin-top: ${theme.spacing(12)} !important;
    }
  `;

  const theme = useTheme();

  const shouldRenderPartnerLogo = partners.length === 1;
  const partnerClassName = shouldRenderPartnerLogo ? 'banner-partner-logo' : undefined;

  return (
    <div style={{ backgroundColor }}>
      <PdpSection css={pdpSectionStyles}>
        <Grid container>
          <Grid item container css={styles.bannerLeftBox} direction="row" md={7}>
            <Grid container spacing={16} md={12}>
              <Grid item xs={12} sm={7} md={12}>
                <Typography
                  color={fontColor === '#FFFFFF' ? 'invertBody' : 'body'}
                  variant="h1semibold"
                  component="h1"
                  id="programMiniModalName"
                >
                  {name}
                </Typography>
                <div className="horizontal-box align-items-vertical-center">
                  {!isEnterpriseAdminView && enterpriseProductConfiguration?.isSelectedForCredit && (
                    <div>
                      <CreditBadge className="m-r-2 m-b-1s" />
                    </div>
                  )}
                  {shouldShowRatings && ratings && (
                    <XDPRating
                      averageFiveStarRating={ratings.averageFiveStarRating as number}
                      commentCount={ratings.commentCount as number}
                      ratingCount={ratings.ratingCount as number}
                      sectionName="reviews"
                      a11yIdentifier="banner"
                      isThemeDark={true}
                    />
                  )}
                  {shouldShowShareButton && (
                    <ShareButtonWithModal
                      rootClassName={
                        shouldShowRatings && ratings ? 'pdp-banner-button' : 'pdp-banner-button-no-reviews'
                      }
                      utmMediumParam="page_share"
                      utmContentParam="pdp"
                      utmCampaignParam="banner_button"
                      captions={getSocialCaptions({
                        productName: name,
                        partnerName: partners[0].name,
                        isFree: false,
                      })}
                    />
                  )}
                </div>
              </Grid>
              <Grid item container xs={12} sm={5} md={12}>
                {shouldShowInstructorInfoBox && (
                  <BannerInstructorInfo instructors={data.instructors} userAgent={userAgent} />
                )}
                {!shouldShowInstructorInfoBox && partners.length > 0 && (
                  <Grid item xs={6} sm={7} md={4}>
                    <Typography2
                      component="div"
                      variant="bodySecondary"
                      css={css`
                        margin: ${theme.spacing(12, 0)};
                      `}
                      color={fontColor === '#FFFFFF' ? 'invertBody' : 'body'}
                    >
                      {_t('Offered By')}
                    </Typography2>
                    {partners.map((partner) => (
                      <div key={partner.id} css={styles.partnerLogo}>
                        <Partner
                          renderLogo={shouldRenderPartnerLogo}
                          partner={partner}
                          overrideLogo={partner.productBrandingLogo}
                          rootClassName={partnerClassName}
                        />
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={10} sm={12}>
              {!actionElement && (
                <div css={styles.enrollButtonContainer}>
                  {/* only this EnrollButton should check the login param to prevent multiple modals from appearing */}
                  <EnrollButton
                    courseId={projectId}
                    // {'var(--cds-color-white-0)'} does not work here. Its value is #FFF which does not match with the logic from getBackgroundAndFontColor()
                    accentColor="#FFFFFF"
                    buttonRootClassName="enroll-button-cds"
                    shouldCheckActionParams={true}
                  />
                </div>
              )}
              {actionElement && <div css={styles.actionElementContainer}>{actionElement}</div>}
              <div css={styles.productMetricsWrapper}>
                <ProductMetrics
                  totalEnrollmentCount={totalEnrollmentCount}
                  pageViewsInLastMonthCount={pageViewsInLastMonthCount}
                  productItemId={projectId}
                  productType={VERIFIED_CERTIFICATE}
                  productMetricsRenderType={ProductMetricsRenderType.ALREADY_ENROLLED_LARGE_FONT}
                  isDarkTheme={true}
                />
                {!loadingSavedProduct && (
                  <SavingBookmark
                    saved={isSavedProduct}
                    product={product}
                    color={SAVING_BOOKMARK_WHITE}
                    shouldShowText={true}
                    isLeftAligned={true}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item md={5} css={styles.infoBoxContainer}>
            <BannerInfoBox
              level={level}
              workload={workload}
              subtitleLanguages={subtitleLanguages}
              primaryLanguages={primaryLanguages}
              avgLearningHoursAdjusted={avgLearningHoursAdjusted}
              cmlRecommendedBackground={cmlRecommendedBackground}
              cmlLearningObjectives={cmlLearningObjectives}
              canEnrollWithFullDiscount={canEnrollWithFullDiscount}
            />
          </Grid>
        </Grid>
      </PdpSection>
      <CourseraPlusBannerRibbon
        shouldShowCourseraPlusRibbon={shouldShowCourseraPlusRibbon}
        courseId={projectId}
        isSpecialization={false}
        isPdpPage={true}
      />
    </div>
  );
};

// NOTE: Saved Product Data composed seperately from `withFragment` due to how PageQuery
//       accesses fragment data directly off the class. just keepin' the experiment low-key.
export const BannerWithSavedProductData = compose<PropsToComponent, PropsFromCaller>(
  withProps<PropsFromWithPropsForLookup, PropsFromCaller>(({ projectId }) => {
    const computedType = savedProductTypes.GUIDED_PROJECT as SavedProductStringTypes;

    return {
      product: {
        /* eslint-disable camelcase */
        product_id: projectId,
        /* eslint-disable camelcase */
        product_type: computedType,
      },
    };
  }),
  withIsProductSavedData
)(Banner);

export default withFragments({
  Banner: gql`
    fragment Banner on XdpV1_org_coursera_xdp_cdp_CDPMetadata {
      name
      ratings {
        commentCount
        ratingCount
        averageFiveStarRating
      }
      partners {
        primaryColor
        productBrandingLogo
        ...XdpPartnersFragment
      }
    }
    ${Partner.fragments.partner}
  `,
})(BannerWithSavedProductData);
