import * as React from 'react';

import PropTypes from 'prop-types';

export const withFluxibleContext = <T extends {}>(Component: React.ComponentType<T>) => {
  return class extends React.Component<T> {
    static contextTypes = {
      executeAction: PropTypes.func,
    };

    render() {
      return <Component {...this.props} context={this.context} />;
    }
  };
};
