import gql from 'graphql-tag';

export const adminDashboardPageBaseQuery = gql`
  query AdminDashboardPageBaseQuery($thirdPartySlug: String!) {
    ThirdPartyOrganizationsV1Resource {
      bySlug(slug: $thirdPartySlug, limit: 1) {
        elements {
          id
          slug
          name
          rectangularLogo
          squareLogo
          loginMethod
          landingPageBanner
          primaryColor
          iconColor
          metadata {
            ... on ThirdPartyOrganizationsV1_org_coursera_authentication_thirdpartyauth_jwt_ThirdPartyOrganizationJwtMetadataMember {
              org_coursera_authentication_thirdpartyauth_jwt_ThirdPartyOrganizationJwtMetadata {
                opaqueIdField
                nameField
                emailField
                secretKeys
              }
            }
            ... on ThirdPartyOrganizationsV1_org_coursera_authentication_thirdpartyauth_saml_ThirdPartyOrganizationSamlMetadataMember {
              org_coursera_authentication_thirdpartyauth_saml_ThirdPartyOrganizationSamlMetadata {
                nameAttribute
                firstNameAttribute
                lastNameAttribute
                emailAttribute
                opaqueIdAttribute
                externalIdAttribute
                signSamlRequest
                allowsIdpInitiatedLogin
                signingAlgorithm
                refreshUrl
                signingCertificateFingerprint
              }
            }
          }
          partnerId
          entityId
          emailDomain
          address {
            line1
            line2
            city
            state
            zipCode
            country
          }
          taxId
          isReal
          supportsDegreeSSOLogin
          programVisibilityRule
          lastUpdatedAt
          organizationType
        }
      }
    }
  }
`;

export const adminDashboardPageQuery = gql`
  query AdminDashboardPageQuery($orgId: String!, $userId: String!) {
    EnterpriseAdminRolesV1Resource {
      byUserOrganization(thirdPartyOrganizationId: $orgId, userId: $userId) {
        elements {
          id
          programId
          managedLevelScope
          role
          thirdPartyOrganizationId
        }
      }
    }
    EnterpriseTargetSkillProfilesV1Resource {
      byOrg(id: $orgId, limit: 1) {
        elements {
          id
        }
      }
    }
  }
`;
