/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import _t from 'i18n!nls/program-home';

const styles = {
  textWrapper: (theme: Theme) => css`
    margin-top: ${theme.spacing(24)};
  `,
};

const CourseCardAutoenrollmentDisclaimer = () => {
  const theme = useTheme();

  return (
    <div>
      <Typography2 component="div" color="supportText" variant="bodySecondary" css={styles.textWrapper(theme)}>
        {_t('Automatically enrolled per your organization’s request')}
      </Typography2>
    </div>
  );
};

export default CourseCardAutoenrollmentDisclaimer;
