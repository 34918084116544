import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import { isVoidOrTable } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const isLastNode = (editor: Editor, [, path]: NodeEntry<Node>) => {
  if (path.length !== 1) {
    return false;
  }

  const [index] = path;
  return index === editor.children.length - 1;
};

export const normalizeLastNode = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  const [node] = nodeEntry;
  if (!Element.isElement(node) || !isLastNode(editor, nodeEntry) || !isVoidOrTable(node)) {
    return false;
  }

  Transforms.insertNodes(
    editor,
    { type: BLOCK_TYPES.TEXT, children: [{ text: '' }] },
    { at: [editor.children.length] }
  );

  return true;
};
