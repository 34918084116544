import type { Node, NodeEntry } from 'slate';
import { Editor, Text, Transforms } from 'slate';

import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const HEADING_MARKDOWN_REGEX = /^(#+) (.*)/i;

const LEVELS_TO_HEADINGS: Record<string, ToolsKeys> = {
  '1': Tools.HEADING_1,
  '2': Tools.HEADING_2,
  '3': Tools.HEADING_3,
  '4': Tools.HEADING_4,
};

export const normalizeHeadingMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0]) || hasAncestorOfType(editor, BLOCK_TYPES.HEADING, nodeEntry[1])) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<Text>;
  const { text } = node;

  const match = text.match(HEADING_MARKDOWN_REGEX);
  if (!match) {
    return false;
  }

  const level = `${match[1].length}` as HeadingLevel;
  if (!tools.has(LEVELS_TO_HEADINGS[level])) {
    return false;
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, {
      at: { path, offset: 0 },
      distance: match[1].length + 1,
      unit: 'character',
    });
    Transforms.setNodes(
      editor,
      { type: BLOCK_TYPES.HEADING, level, undo: match[1], children: [] },
      { at: path.slice(0, -1) }
    );
  });
  return true;
};
