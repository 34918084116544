import * as React from 'react';

import { branch, compose, withProps } from 'recompose';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import getPropsFromPromise from 'js/lib/getPropsFromPromise';
import waitFor from 'js/lib/waitFor';

import { useGetSubscriptionTiersProductPrices } from 'bundles/coursera-plus/contentfulData/providerHOC';
import {
  getIsStandaloneCourseEligibleForTiersEnroll,
  isSubscriptionTiersDesignV3Enabled,
  isSubscriptionTiersEnabled,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import withCourseraPlusProductEligibilityCheck from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import type { EligibilityCheckProps } from 'bundles/coursera-plus/utils/withCourseraPlusProductEligibilityCheck';
import withCourseraPlusProductOwnerships from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import type { PropsFromCourseraPlusProductOwnerships } from 'bundles/coursera-plus/utils/withCourseraPlusProductOwnerships';
import {
  getAvailableGroups,
  getAvailableInvitedPrograms,
  getAvailablePrograms,
} from 'bundles/enroll-course/lib/apiClient';
import type { GroupsData, InvitedProgramsData, ProgramsData } from 'bundles/enroll-course/lib/apiClient';
import withEnrollment from 'bundles/enroll/components/xdp/withEnrollment';
import type { PropsFromWithEnrollment } from 'bundles/enroll/components/xdp/withEnrollment';
import PageDataContext from 'bundles/enroll/data/PageDataContext';
import { useGetPartnerNamesByIds } from 'bundles/enroll/hooks/useGetPartnerNamesByIds';
import {
  withProductOwnerships,
  withSpecializationProductOwnershipsId,
} from 'bundles/enroll/utils/withProductOwnerships';
import paymentsExperiments from 'bundles/epic/clients/payments';
import paymentsbackendExperiments from 'bundles/epic/clients/payments-backend';
import Naptime from 'bundles/naptimejs';
import CourseTypeMetadataV1, { CourseType } from 'bundles/naptimejs/resources/courseTypeMetadata.v1';
import CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import PartnersV1 from 'bundles/naptimejs/resources/partners.v1';
import type ProductOwnershipsV1 from 'bundles/naptimejs/resources/productOwnerships.v1';
import S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import { useGetProductPricesV3 } from 'bundles/payments-common/hooks/useGetProductPrices';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import type { ProductType as IProductType } from 'bundles/payments/common/ProductType';
import ProductType from 'bundles/payments/common/ProductType';
import type { PropsFromWithPromotionInfo } from 'bundles/promotions/components/withPromotionInfo';
import withPromotionInfo from 'bundles/promotions/components/withPromotionInfo';
import { withCoursesModulesDuration } from 'bundles/s12n-enroll/components/non-recurring/withS12nProductInfo';

type PropsFromCaller = {
  onSdp?: boolean;
  courseId?: string;
  s12nId?: string;
  selectedS12nId?: string;
};

type PropsFromWithProps = {
  isSpecialization: boolean;
};

type PropsToWithCourseEnrollModalData = Required<PropsFromWithEnrollment> & {
  courseId: string;
  selectedS12nId?: string;
};

type PropsFromNaptimeCourseAndCourseTypeMetadata = {
  course: CoursesV1;
  courseTypeMetadataWithVersion: CourseTypeMetadataV1;
};

type PropsFromNaptimeS12nAndS12nDerivatives = {
  s12n?: OnDemandSpecializationsV1;
  s12nDerivatives?: S12nDerivativesV1;
};

type PropsFromNaptimeS12nMultiGet = {
  s12ns: OnDemandSpecializationsV1[];
};

type PropsFromNaptimeCourseMultiGet = {
  s12nCourses: CoursesV1[];
};

type PropsFromProgramAndGroupData = GroupsData & ProgramsData & InvitedProgramsData;

type PropsFromWithCourseEnrollS12nSelectionModalData = PropsFromNaptimeS12nMultiGet & PropsFromNaptimeCourseMultiGet;

type PropsFromWithCourseEnrollModalData = PropsFromNaptimeCourseAndCourseTypeMetadata &
  PropsFromNaptimeS12nAndS12nDerivatives &
  PropsFromWithCourseEnrollS12nSelectionModalData &
  PropsFromCourseraPlusProductOwnerships;

type PropsToWithS12nEnrollModalData = Required<PropsFromWithEnrollment> & {
  courseId: string;
  s12nId: string;
};

type PropsFromNaptimeS12n = {
  s12n: OnDemandSpecializationsV1;
};

type PropsFromNaptimeS12nAndProductOwnerships = PropsFromNaptimeS12n & {
  productOwnerships: ProductOwnershipsV1;
};

type PropsFromNaptimeCourse = {
  course: CoursesV1;
};

type PropsFromNaptimeS12nDerivatives = {
  s12nDerivatives: S12nDerivativesV1;
};

type PropsFromWithS12nEnrollModalData = PropsFromNaptimeS12nAndProductOwnerships &
  PropsFromNaptimeCourse &
  PropsFromNaptimeS12nDerivatives;

export type PropsToComponent = PropsFromCaller &
  PropsFromWithProps &
  Required<PropsFromWithEnrollment> &
  PropsFromWithCourseEnrollModalData &
  PropsFromWithS12nEnrollModalData &
  PropsFromWithPromotionInfo &
  EligibilityCheckProps;

// All course enrollments that allow individual course purchase need course product price data
// This includes usage in the S12nBulkPayEnrollModal, so need this data regardless of `onSdp` or not
const useGetCourseProductPrice = ({ enrollmentAvailableChoices, courseId, course }: PropsToComponent) => {
  const productItemId = courseId ?? course.id;
  const shouldGetProductPrice = enrollmentAvailableChoices.canPurchaseSingleCourse && productItemId;
  const { productPrices } = useGetProductPricesV3({
    products: [
      {
        productType: ProductType.VERIFIED_CERTIFICATE,
        productItemId,
      },
    ],
    skip: !shouldGetProductPrice,
  });
  const productPrice = productPrices?.[0];

  return {
    productPrice,
    shouldGetProductPrice,
  };
};

// All s12n enrollments that allow bulk pay purchase need s12n bulk product price data
// This includes usage in the CourseEnrollChoiceDescription, so need this data regardless of `onSdp` or not
const useGetS12nProductPrice = ({ enrollmentAvailableChoices, s12nId, course }: PropsToComponent) => {
  const productItemId = s12nId ?? course?.s12nIds?.[0];
  const shouldGetS12nProductPrice = enrollmentAvailableChoices.canBulkPaySpecialization && productItemId;
  const { productPrices: s12nProductPrices } = useGetProductPricesV3({
    products: productItemId
      ? [
          {
            productType: ProductType.SPECIALIZATION,
            productItemId,
          },
        ]
      : [],
    skip: !shouldGetS12nProductPrice,
  });
  const s12nProductPrice = s12nProductPrices?.[0];

  return {
    s12nProductPrice,
    shouldGetS12nProductPrice,
  };
};

// All s12n enrollments that allow s12n prepaid purchase need s12n prepaid product price data
// This includes usage in the NonRecurringChoice, so need this data regardless of `onSdp` or not
const useGetS12nPrepaidProductPrice = ({ enrollmentAvailableChoices, selectedS12nId }: PropsToComponent) => {
  let availablePrepaid;

  // price call is skipped in Mix and Match until the learner selects the s12n
  if (enrollmentAvailableChoices.isMixAndMatch && selectedS12nId) {
    availablePrepaid = enrollmentAvailableChoices?.getS12nPrepaidProducts(selectedS12nId);
  } else if (!enrollmentAvailableChoices.isMixAndMatch) {
    availablePrepaid = enrollmentAvailableChoices?.getS12nPrepaidProducts();
  }

  const products =
    availablePrepaid?.map(({ productItemId }) => ({
      productType: ProductType.SPECIALIZATION_PREPAID,
      productItemId,
    })) ?? [];

  const shouldGetS12nPrepaidProductPrice =
    enrollmentAvailableChoices.canEnrollThroughS12nPrepaid && products.length !== 0;

  const { productPrices: s12nPrepaidProductPrices, loading: loadingS12nPrepaidProductPrice } = useGetProductPricesV3({
    products,
    skip: !shouldGetS12nPrepaidProductPrice,
  });

  // Returns the most inexpensive s12n prepaid product price
  const sortedProductPrices = s12nPrepaidProductPrices?.sort(
    (priceA: ProductPrices, priceB: ProductPrices) => priceA.amount - priceB.amount
  );
  const s12nPrepaidProductPrice = sortedProductPrices?.[0];

  return {
    s12nPrepaidProductPrices,
    s12nPrepaidProductPrice,
    shouldGetS12nPrepaidProductPrice,
  };
};

const useGetCourseraPlusPrepaidProductPrice = ({ enrollmentAvailableChoices, selectedS12nId }: PropsToComponent) => {
  let availablePrepaid;

  // price call is skipped in Mix and Match until the learner selects the s12n
  if (enrollmentAvailableChoices.isMixAndMatch && selectedS12nId) {
    availablePrepaid = enrollmentAvailableChoices?.getCourseraPlusPrepaidProducts(selectedS12nId);
  } else if (!enrollmentAvailableChoices.isMixAndMatch) {
    availablePrepaid = enrollmentAvailableChoices?.getCourseraPlusPrepaidProducts();
  }

  const products =
    availablePrepaid?.map((prepaid) => ({
      productItemId: prepaid.productItemId,
      productType: prepaid.productType as IProductType,
    })) ?? [];

  const shouldGetCourseraPlusPrepaidProductPrice =
    paymentsbackendExperiments.preview('courseraPlusPrepaidEnabled') &&
    enrollmentAvailableChoices.canPurchaseThroughCourseraPlus &&
    products.length !== 0;

  const { productPrices: courseraPlusPrepaidProductPrices, loading: loadingCourseraPlusPrepaidProductPrice } =
    useGetProductPricesV3({
      products,
      skip: !shouldGetCourseraPlusPrepaidProductPrice,
    });

  // Returns the most inexpensive Coursera Plus prepaid product price
  const sortedProductPrices = courseraPlusPrepaidProductPrices?.sort((priceA, priceB) => priceA.amount - priceB.amount);
  const courseraPlusPrepaidProductPrice = sortedProductPrices?.[0];

  return {
    courseraPlusPrepaidProductPrices,
    courseraPlusPrepaidProductPrice,
    shouldGetCourseraPlusPrepaidProductPrice,
  };
};

const DataProvider: React.FC<PropsToComponent> = ({ children, ...data }) => {
  const {
    isSpecialization,
    ownsCourseraLite,
    s12n,
    course,
    courseTypeMetadataWithVersion,
    enrollmentAvailableChoices,
    isCourseraPlusEligibleProduct,
  } = data;
  const hasSpecialization = Boolean(s12n) || isSpecialization || Number(course?.s12nIds?.length) > 0;
  const partnerIds = isSpecialization && s12n ? s12n.partnerIds : course?.partnerIds;
  const isStandaloneCourseEligibleForTiersEnroll = getIsStandaloneCourseEligibleForTiersEnroll({
    hasSpecialization,
    enrollmentAvailableChoices,
    isClosedCourse: course?.isClosedCourse,
  });

  const tiersV3VariantInput = {
    hasSpecialization,
    partners: partnerIds?.map((id) => new PartnersV1({ id })),
    isStandaloneCourseEligibleForTiersEnroll,
  };

  // If Subscription Tiers is enabled and can subscribe to Lite or Plus, retrieve pricing for the initial Tiers enroll modal
  // If learner owns Lite and is enrolled in a course part of Plus, retrieve pricing for the Plus upgrade modal
  const shouldGetTiersPrices =
    (isSubscriptionTiersEnabled(tiersV3VariantInput) &&
      (enrollmentAvailableChoices.canSubscribeToCourseraLite ||
        enrollmentAvailableChoices.canSubscribeToCourseraPlus)) ||
    (ownsCourseraLite && enrollmentAvailableChoices.isEnrolled && isCourseraPlusEligibleProduct);

  // For Coursera Plus Annual, we are only fetching the price if the user is in Subscription Tiers
  // V3 where annaul C Plus is offered on XDP
  const shouldGetCourseraPlusAnnual =
    isSubscriptionTiersDesignV3Enabled(tiersV3VariantInput) && enrollmentAvailableChoices.canSubscribeToCourseraLite;

  const { courseraLiteProductPrice, courseraPlusProductPrice, courseraPlusAnnualProductPrice } =
    useGetSubscriptionTiersProductPrices({
      skip: !shouldGetTiersPrices,
      shouldGetCourseraPlusAnnual,
    });

  const { productPrice, shouldGetProductPrice } = useGetCourseProductPrice(data);
  const { s12nProductPrice, shouldGetS12nProductPrice } = useGetS12nProductPrice(data);
  const { s12nPrepaidProductPrices, s12nPrepaidProductPrice, shouldGetS12nPrepaidProductPrice } =
    useGetS12nPrepaidProductPrice(data);
  const {
    courseraPlusPrepaidProductPrices,
    courseraPlusPrepaidProductPrice,
    shouldGetCourseraPlusPrepaidProductPrice,
  } = useGetCourseraPlusPrepaidProductPrice(data);

  const prices = {
    courseraLiteProductPrice,
    courseraPlusProductPrice,
    ...(shouldGetCourseraPlusAnnual ? { courseraPlusAnnualProductPrice } : {}),
    ...(productPrice ? { productPrice } : {}),
    ...(s12nProductPrice ? { s12nProductPrice } : {}),
    ...(s12nPrepaidProductPrice ? { s12nPrepaidProductPrices, s12nPrepaidProductPrice } : {}),
    ...(courseraPlusPrepaidProductPrice ? { courseraPlusPrepaidProductPrices, courseraPlusPrepaidProductPrice } : {}),
  };

  // Project here means either Guided Projects or Self-Paced Projects
  const isProject = [CourseType.PROJECT, CourseType.RHYME_PROJECT].includes(
    courseTypeMetadataWithVersion?.courseTypeMetadata?.typeName
  );
  const { partners, loading: loadingPartners } = useGetPartnerNamesByIds({
    partnerIds: partnerIds ?? [],
    skip: !isProject,
  });

  if (
    loadingPartners ||
    (shouldGetProductPrice && !productPrice) ||
    (shouldGetS12nProductPrice && !s12nProductPrice) ||
    (shouldGetS12nPrepaidProductPrice && !s12nPrepaidProductPrice) ||
    (shouldGetCourseraPlusPrepaidProductPrice && !courseraPlusPrepaidProductPrice)
  ) {
    return null;
  }

  return <PageDataContext.Provider value={{ ...data, ...prices, partners }}>{children}</PageDataContext.Provider>;
};

const withCourseEnrollS12nSelectionModalData = compose<
  PropsFromWithCourseEnrollS12nSelectionModalData,
  PropsToWithCourseEnrollModalData
>(
  Naptime.createContainer<PropsFromNaptimeS12nMultiGet, PropsToWithCourseEnrollModalData>(
    ({ enrollmentAvailableChoices }) => ({
      s12ns: OnDemandSpecializationsV1.multiGet(enrollmentAvailableChoices.enrollmentS12nIds, {
        fields: ['id', 'name', 'courseIds', 'productVariant'],
      }),
    })
  ),
  waitFor<PropsFromNaptimeS12nMultiGet>(({ s12ns }) => s12ns?.length > 0),
  Naptime.createContainer<PropsFromNaptimeCourseMultiGet, PropsFromNaptimeS12nMultiGet>(({ s12ns }) => {
    const courseIds = s12ns.reduce((allCourseIds, s12n) => allCourseIds.concat(s12n.courseIds), [] as string[]);
    return {
      s12nCourses: CoursesV1.multiGet(courseIds, {
        fields: ['id', 'name'],
        params: {
          showHidden: true,
        },
      }),
    };
  }),
  waitFor<PropsFromNaptimeCourseMultiGet>(({ s12nCourses }) => s12nCourses?.length > 0)
);

const withProgramAndGroupData = compose<
  PropsFromProgramAndGroupData,
  PropsFromCaller & Required<PropsFromWithEnrollment>
>(
  getPropsFromPromise(
    ({ enrollmentAvailableChoices, courseId, s12nId, onSdp }: Required<PropsFromCaller & PropsFromWithEnrollment>) => {
      const options = onSdp ? { s12nId } : { courseId };
      const promises = [
        enrollmentAvailableChoices.canEnrollThroughProgram && getAvailablePrograms(options),
        enrollmentAvailableChoices.canEnrollThroughProgramInvitation && getAvailableInvitedPrograms(options),
        enrollmentAvailableChoices.canEnrollThroughGroup && getAvailableGroups(options),
        // Put an empty promise in place of any falsy values / promises that won't be called
      ].map((promise) => promise || Promise.resolve({}));

      // Request all promises concurrently and then flatten all the returned data
      return Promise.all(promises).then(([programsData, invitedProgramsData, groupsData]) => ({
        ...programsData,
        ...invitedProgramsData,
        ...groupsData,
      }));
    }
  )
);

const withCourseEnrollModalData = compose<PropsFromWithCourseEnrollModalData, PropsToWithCourseEnrollModalData>(
  Naptime.createContainer<PropsFromNaptimeCourseAndCourseTypeMetadata, PropsToWithCourseEnrollModalData>(
    ({ courseId }) => ({
      course: CoursesV1.get(courseId, {
        fields: ['id', 'name', 'slug', 'premiumExperienceVariant', 's12nIds', 'partnerIds', 'courseStatus'],
        params: {
          showHidden: true,
        },
      }),
      courseTypeMetadataWithVersion: CourseTypeMetadataV1.get(courseId),
    })
  ),
  // Retrieve s12n if user can't enroll in multiple s12ns
  // Or can enroll in multiple s12ns but has already made a selection
  branch<PropsToWithCourseEnrollModalData>(
    ({ enrollmentAvailableChoices, selectedS12nId }) => {
      const { isMixAndMatch } = enrollmentAvailableChoices;
      return !isMixAndMatch || (isMixAndMatch && Boolean(selectedS12nId));
    },
    Naptime.createContainer<
      PropsFromNaptimeS12nAndS12nDerivatives,
      PropsToWithCourseEnrollModalData & PropsFromNaptimeCourseAndCourseTypeMetadata
    >(({ enrollmentAvailableChoices, selectedS12nId, partnerIds }) => {
      let enrollmentS12nId;
      const tiersV3VariantInput = {
        hasSpecialization: Boolean(enrollmentAvailableChoices?.specializationId),
        partnerIds: partnerIds?.map((id) => new PartnersV1({ id })),
      };

      if (enrollmentAvailableChoices.isMixAndMatch) {
        enrollmentS12nId = selectedS12nId;
      } else if (
        enrollmentAvailableChoices.canSubscribeToS12n ||
        (isSubscriptionTiersEnabled(tiersV3VariantInput) && enrollmentAvailableChoices.canSubscribeToCourseraPlus)
      ) {
        const s12nIds = enrollmentAvailableChoices.subscriptionEnrollmentS12nIds;
        enrollmentS12nId = s12nIds?.[0];
      } else if (enrollmentAvailableChoices.canEnrollThroughS12nPrepaid) {
        const s12nIds = enrollmentAvailableChoices.prepaidEnrollmentS12nIds;
        enrollmentS12nId = s12nIds?.[0];
      } else if (enrollmentAvailableChoices.isPreEnrolled) {
        const s12nIds = enrollmentAvailableChoices.preEnrolledS12nIds;
        enrollmentS12nId = s12nIds?.[0];
      } else if (
        enrollmentAvailableChoices.isSpecializationSubscribed ||
        enrollmentAvailableChoices.canEnrollThroughCourseraPlus
      ) {
        enrollmentS12nId = enrollmentAvailableChoices.specializationId;
      }

      // If enrolling user into s12n, we need to grab the s12nId returned from enrollmentAvailableChoices
      // to ensure we're displaying the same s12n that will show up on checkout
      // 'productVariant' and 'partnerIds' are used in child components and need to be requested here due to Naptime caching
      if (enrollmentS12nId) {
        return {
          s12n: OnDemandSpecializationsV1.get(enrollmentS12nId, {
            fields: ['name', 'courseIds', 'productVariant', 'partnerIds'],
          }),
          s12nDerivatives: S12nDerivativesV1.get(enrollmentS12nId, {
            fields: ['catalogPrice'],
          }),
        };
      } else {
        return {};
      }
    })
  ),
  branch<PropsToWithCourseEnrollModalData>(
    ({ enrollmentAvailableChoices, selectedS12nId }) => enrollmentAvailableChoices.isMixAndMatch && !selectedS12nId,
    withCourseEnrollS12nSelectionModalData
  ),
  // Retrieve Coursera Lite/Plus ownership if they are enrolled
  // because we need to know if they own Lite and need to see the upgrade modal to Plus
  branch<PropsToWithCourseEnrollModalData>(
    ({ enrollmentAvailableChoices }) => enrollmentAvailableChoices.isEnrolled,
    withCourseraPlusProductOwnerships()
  ),
  // If learner owns Lite and is enrolled, determine whether the course is part of Plus
  // to see if the upgrade modal to Plus should be shown
  branch<Required<PropsFromWithEnrollment & PropsFromCourseraPlusProductOwnerships>>(
    ({ enrollmentAvailableChoices, ownsCourseraLite }) => ownsCourseraLite && enrollmentAvailableChoices.isEnrolled,
    withCourseraPlusProductEligibilityCheck<PropsFromCaller>()
  )
);

const withS12nEnrollModalData = compose<PropsFromWithS12nEnrollModalData, PropsToWithS12nEnrollModalData>(
  Naptime.createContainer<PropsFromNaptimeS12n, PropsToWithS12nEnrollModalData>(({ s12nId }) => ({
    s12n: OnDemandSpecializationsV1.get(s12nId, {
      fields: ['name', 'courseIds', 'productVariant', 'partnerIds', 'capstone'],
    }),
  })),
  withSpecializationProductOwnershipsId,
  withProductOwnerships(),
  Naptime.createContainer<
    PropsFromNaptimeCourse,
    PropsFromNaptimeS12nAndProductOwnerships & PropsToWithS12nEnrollModalData
  >(({ courseId: courseIdOverride, s12n, productOwnerships }) => {
    let courseId = courseIdOverride;

    if (!courseId) {
      // productOwnerships courseIds is not ordered, but s12n courseIds *is* ordered
      // So go through the list of s12n courseIds sequentially and find the first one that is in unowned list
      const firstUnownedCourseId = s12n.courseIds.find((s12nCourseId) => {
        return productOwnerships.unownedCourses.includes(s12nCourseId);
      });

      // Show course info for the specified courseId or the first unowned course in the s12n
      courseId = firstUnownedCourseId ?? s12n.courseIds[0];
    }

    return {
      course: CoursesV1.get(courseId, {
        fields: ['id', 'name', 'slug', 'premiumExperienceVariant', 's12nIds', 'partnerIds', 'courseStatus'],
      }),
    };
  }),
  branch<PropsToWithS12nEnrollModalData>(
    ({ enrollmentAvailableChoices }) => enrollmentAvailableChoices.canSubscribeToS12n,
    Naptime.createContainer<PropsFromNaptimeS12nDerivatives, PropsFromNaptimeS12nAndProductOwnerships>(({ s12n }) => ({
      s12nDerivatives: S12nDerivativesV1.get(s12n.id, {
        fields: ['catalogPrice'],
      }),
    }))
  )
);

export default compose<PropsToComponent, PropsFromCaller>(
  withProps<PropsFromWithProps, PropsFromCaller>(({ onSdp }) => ({
    isSpecialization: !!onSdp,
  })),
  // We are fetching promotion info to use within withEnrollment. This is needed to enable
  // s12n subscription for subscription tiers learners for whom s12n subscription is
  // disabled in favor of subscription tiers enrolllment. If there's a promotion,
  // we now disable subscription tiers in favor of s12n subscription.
  branch(() => isSubscriptionTiersEnabled({ skipProductTypeCheck: true }), withPromotionInfo()),
  withEnrollment(),
  waitFor<PropsFromWithEnrollment>(({ enrollmentAvailableChoices }) => !!enrollmentAvailableChoices),
  branch<PropsFromCaller>(({ onSdp }) => !onSdp, withCourseEnrollModalData),
  branch<PropsFromCaller>(({ onSdp }) => Boolean(onSdp), withS12nEnrollModalData),
  // All course (project, Guided Project, standard course) and s12n subscription purchases need promotion info
  branch<Required<PropsFromWithEnrollment>>(
    ({ enrollmentAvailableChoices }) =>
      enrollmentAvailableChoices.canPurchaseSingleCourse || enrollmentAvailableChoices.canSubscribeToS12n,
    withPromotionInfo()
  ),
  branch<Required<PropsFromWithEnrollment>>(
    ({ enrollmentAvailableChoices }) =>
      enrollmentAvailableChoices.canEnrollThroughS12nPrepaid &&
      paymentsExperiments.preview('preloadEnrollModalEnabled'),
    withCoursesModulesDuration()
  ),
  // All Enterprise enrollments need program and/or group info
  branch<Required<PropsFromWithEnrollment & PropsFromCaller>>(
    ({ enrollmentAvailableChoices }) =>
      enrollmentAvailableChoices.canEnrollThroughProgram ||
      enrollmentAvailableChoices.canEnrollThroughProgramInvitation ||
      enrollmentAvailableChoices.canEnrollThroughGroup,
    withProgramAndGroupData
  )
)(DataProvider);
