import gql from 'graphql-tag';

export const UserProductStateRecordsV1ResourceQuery = gql`
  query UserProductStateRecordsV1ResourceQuery(
    $programId: String!
    $userId: String!
    $excludeGloballyEnrolledCourses: Boolean!
  ) {
    UserProductStateRecordsV1Resource {
      latestCourseDowngradesByUserAndProgram(
        programId: $programId
        userId: $userId
        excludeGloballyEnrolledCourses: $excludeGloballyEnrolledCourses
      ) {
        elements {
          id
          productType
          productItemId
          programId
          collectionId
          state
          stateTimestamp
          completionTimestamp
        }
      }
    }
  }
`;
