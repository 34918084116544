import * as React from 'react';
import ReactDOMServer from 'react-dom/server';

import classNames from 'classnames';
import $ from 'jquery';
import type videojs from 'video.js';

import { languageCodeToName } from 'js/lib/language';

import type { Html5VideoResolutionsSources, Html5VideoTracks } from 'bundles/phoenix/components/HTML5Video';

export const getPlayerOptions = (
  sources?: Html5VideoResolutionsSources,
  hideControlsBar = false
): videojs.PlayerOptions => {
  const playerOptions: videojs.PlayerOptions = hideControlsBar
    ? {}
    : {
        inactivityTimeout: 1000, // Hides after 1 sec
      };

  // TODO: `sources` is usually defined at least with {}. Should this be `if (!isEmpty(sources))`?
  if (sources) {
    playerOptions.plugins = {
      resolutions: {
        sourcesByResolution: sources,
      },
    };
  }

  return playerOptions;
};

/*
 * In order to initiate the videojs object, which is stored in the video store, we need access to the video node
 */

type Props = React.MediaHTMLAttributes<HTMLVideoElement> & {
  width: number | string;
  height: number | string;
  sources: Html5VideoResolutionsSources;
  tracks: Html5VideoTracks;
};

const Html5Video: React.FC<Props> = ({ width, height, sources, tracks, className, ...attributes }) => {
  const classes = classNames(
    'video-js',
    'vjs-circle-play-centered',
    'vjs-coursera-phoenix-skin',
    'vjs-coursera-skin',
    className
  );

  const videoSources = Object.entries(sources ?? {}).flatMap(([resolution, html5Sources]) =>
    html5Sources.map((source) => (
      <source key={resolution + ':' + source.type} src={source.src ?? undefined} type={source.type} />
    ))
  );

  const videoSubtitles = Object.entries(tracks ?? {}).map(([languageCode, subtitleUrl]) => (
    <track
      kind="subtitles"
      key={languageCode}
      label={languageCodeToName(languageCode)}
      srcLang={languageCode}
      src={subtitleUrl}
    />
  ));

  return (
    <video className={classes} width={width} height={height} preload="auto" {...attributes}>
      {videoSources}
      {videoSubtitles}
    </video>
  );
};

export const renderHtml5Video = (props: Props) => {
  return $(ReactDOMServer.renderToStaticMarkup(<Html5Video {...props} />)).get(0);
};

export const getVideoJsNode = (sources: Html5VideoResolutionsSources, tracks: Html5VideoTracks) => {
  return renderHtml5Video({ sources, tracks, width: 780, height: 439, autoPlay: false });
};

export default { getPlayerOptions, getVideoJsNode };
