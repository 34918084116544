import { HIDDEN_SLUG_FOR_DEFAULT_LANDING_PAGE } from 'bundles/coursera-plus/contentful/constants';
import type { Namespaces } from 'bundles/epic/client';
import GrowthDiscoveryEpicClient from 'bundles/epic/clients/GrowthDiscovery';

export type RedesignExperimentData = {
  variant: Namespaces['GrowthDiscovery']['courseraPlusLandingPageRedesign'];
  displayRedesign: boolean;
  onExperimentalPage: boolean;
};

export const makeLandingPageRedesignImpression = () => {
  GrowthDiscoveryEpicClient.get('courseraPlusLandingPageRedesign');
};

export const useLandingPageRedesignViaPathname = (pathname: string): RedesignExperimentData => {
  const variant = GrowthDiscoveryEpicClient.preview('courseraPlusLandingPageRedesign');
  const urlParts = pathname.split('/').filter(Boolean);
  const slug = urlParts.length === 1 ? urlParts[0] : '';
  const onInsite = slug === 'courseraplus';
  const displayRedesign = onInsite && variant !== 'control';
  return {
    variant,
    onExperimentalPage: onInsite,
    displayRedesign,
  };
};

const useLandingPageRedesign = (slug: string): RedesignExperimentData => {
  const variant = GrowthDiscoveryEpicClient.preview('courseraPlusLandingPageRedesign');
  const onInsite = slug === HIDDEN_SLUG_FOR_DEFAULT_LANDING_PAGE;
  const displayRedesign = onInsite && variant !== 'control';
  return {
    variant,
    onExperimentalPage: onInsite,
    displayRedesign,
  };
};

export default useLandingPageRedesign;
