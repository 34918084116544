import * as React from 'react';

import classNames from 'classnames';

import type { PaymentChoiceType } from 'bundles/s12n-enroll/constants';

type PropsFromCaller = {
  title: string | JSX.Element;
  type: PaymentChoiceType;
  currentType: PaymentChoiceType;
  disabled?: boolean;
  onClick?: (newType: PaymentChoiceType) => void;
};

const PaymentChoice: React.FC<PropsFromCaller> = ({
  title,
  type,
  currentType,
  disabled = false,
  onClick,
  children,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(type);
    }
  };

  const componentClasses = classNames('bt3-radio rc-PaymentChoice styleguide', type);
  const titleClasses = classNames('choice-title', {
    disabled,
  });
  const descriptionId = `payment-choice-secondary-description-${type}`;
  return (
    <div className={componentClasses}>
      <label className="horizontal-box align-items-vertical-center" htmlFor={descriptionId}>
        {!disabled && (
          <input
            id={descriptionId}
            type="radio"
            name="bulk-pay-payment-choice"
            value={type}
            defaultChecked={type === currentType}
            onClick={handleClick}
            aria-describedby={descriptionId}
          />
        )}
        {!disabled && (
          <span className="cif-stack payment-choice-radio-button">
            <i className="cif-circle-thin cif-stack-2x" />
            <i className="cif-circle cif-stack-1x" />
          </span>
        )}
        {disabled && <i className="cif-times" />}
        <h4 className={titleClasses} aria-describedby="choice-title-label">
          {title}
        </h4>
      </label>
      {children && (
        <span id={descriptionId} className="color-secondary-text choice-description">
          {children}
        </span>
      )}
    </div>
  );
};

export default PaymentChoice;
