import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { StyleSheet, color, css, font, spacing, transition } from '@coursera/coursera-ui';

const styles = StyleSheet.create({
  CourseInfoRow: {
    transition: transition.easeOut(),
    paddingTop: spacing.sm,
    paddingBottom: spacing.sm,
  },
  borderTop: {
    borderTop: `1px solid ${color.divider}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${color.divider}`,
  },
  title: {
    fontWeight: 'bold',
    fontSize: font.sm,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: font.sm,
  },
});

const CourseInfoRow = ({ icon, title, subtitle, children, hasBorderTop, hasBorderBottom }: $TSFixMe) => {
  const hasYBorder = hasBorderTop || hasBorderBottom;
  const paddingLeftClass = classNames({
    'm-l-1 p-l-0': hasYBorder,
    'p-l-0': !hasYBorder,
  });

  return (
    <div
      {...css(
        'horizontal-box',
        styles.CourseInfoRow,
        hasBorderTop && styles.borderTop,
        hasBorderBottom && styles.borderBottom
      )}
    >
      <div className={`horizontal-box align-items-vertical-center p-a-1 ${paddingLeftClass}`}>{icon}</div>
      <div {...css('horizontal-box align-items-vertical-center p-r-1 flex-1', styles.mainContainer)}>
        <div className="vertical-box">
          <div {...css(styles.title)}>{title}</div>
          {subtitle && <div {...css(styles.subtitle)}>{subtitle}</div>}
        </div>
        {children}
      </div>
    </div>
  );
};

CourseInfoRow.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  // Useful to display arrow, links, button at the right side
  children: PropTypes.node,
  hasBorderTop: PropTypes.bool,
  hasBorderBottom: PropTypes.bool,
};

export default CourseInfoRow;
