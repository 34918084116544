/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Editor, Path, Range, Transforms } from 'slate';
import { ReactEditor, useFocused, useReadOnly, useSelected, useSlate, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import { VisuallyHidden } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import { DEFAULT_LINK_HREF, isValidURL, unlinkText } from 'bundles/cml/editor/components/buttons/link/linkUtils';
import EditLinkDialog from 'bundles/cml/editor/components/elements/link/EditLinkDialog';
import InlineSpacer from 'bundles/cml/editor/components/elements/link/InlineSpacer';
import LinkToolbar from 'bundles/cml/editor/components/elements/link/LinkToolbar';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { StyleContext } from 'bundles/cml/editor/context/styleContext';
import { removeElement } from 'bundles/cml/editor/utils/slateUtils';
import LinkRenderer from 'bundles/cml/shared/components/link/Link';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import { usePrevious } from 'bundles/cml/shared/hooks/usePrevious';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const styles = {
  link: css`
    border-bottom: 2px dashed transparent;
  `,
  error: css`
    border-bottom-color: var(--cds-color-feedback-error);
  `,
};

const Link: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const [editLinkDialog, setEditLinkDialog] = useState(false);
  const [editText, setEditText] = useState(false);
  const [editHref, setEditHref] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const staticEditor = useSlateStatic();
  const path = ReactEditor.findPath(staticEditor, element);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pageless } = useContext(StyleContext);
  const readonly = useReadOnly();
  const { setFocused } = useFocusedContext();
  const ref = useRef<HTMLAnchorElement>(null);

  const link = element as LinkElement;
  const { href = '', title } = link;

  const editor = useSlate();
  const editorFocused = useFocused();
  const focused = useSelected() && !!editor.selection && Range.isCollapsed(editor.selection);
  const error = !isValidURL(editLinkDialog ? editHref : href);
  const editLinkDialogPrevious = usePrevious(editLinkDialog);

  const handleEditDialogOpen = useCallback(() => {
    setMenuOpen(false);
    setEditHref(href || DEFAULT_LINK_HREF);
    setEditTitle(title ?? '');
    setEditText(Editor.string(staticEditor, path) === href);
    setEditLinkDialog(true);
  }, [staticEditor, href, title, path]);

  useEffect(
    () => {
      if (focused) {
        handleEditDialogOpen();
      }

      return () => {
        if (editLinkDialogPrevious && !editorFocused) {
          const end = Editor.end(staticEditor, path);
          Transforms.setSelection(staticEditor, { anchor: end, focus: end });
        }
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleEditDialogClose = useCallback(() => {
    setEditLinkDialog(false);
    setEditHref('');
    setEditTitle('');
    setEditText(false);
    setMenuOpen(false);

    Transforms.setNodes(staticEditor, { href: editHref, title: editTitle }, { at: path });

    if (!error) {
      return;
    }

    if (editText) {
      removeElement(staticEditor, link);
    } else {
      unlinkText(staticEditor, path);
    }

    ReactEditor.focus(staticEditor);
  }, [editText, staticEditor, path, error, link, editHref, editTitle]);

  const handleEditDialogDone = useCallback(() => {
    handleEditDialogClose();
    if (error) {
      return;
    }

    const nextPath = Path.next(path);
    const nextPoint = { path: nextPath, offset: 0 };

    Transforms.setSelection(staticEditor, { anchor: nextPoint, focus: nextPoint });
    ReactEditor.focus(staticEditor);
  }, [handleEditDialogClose, staticEditor, path, error]);

  const handleHrefChange = useCallback(
    (value: string) => {
      setEditHref(value);
      if (editText) {
        Transforms.insertText(staticEditor, value || ' ', {
          at: { anchor: Editor.start(staticEditor, path), focus: Editor.end(staticEditor, path) },
        });
      }
    },
    [staticEditor, editText, path]
  );

  const handleOpenMenu = useCallback(() => {
    if (!readonly && !editLinkDialog) {
      setFocused(true);
      setMenuOpen(true);
    }
  }, [readonly, editLinkDialog, setFocused]);

  const handleCloseMenu = useCallback(() => {
    setMenuOpen(false);
    setFocused(false);
  }, [setFocused]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (readonly) {
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        handleOpenMenu();
      }
    },
    [readonly, handleOpenMenu]
  );

  return (
    <LinkRenderer
      ref={ref}
      element={element}
      attributes={attributes}
      css={error && styles.error}
      onClick={handleOpenMenu}
      onKeyDown={handleKeyDown}
    >
      <InlineSpacer />
      {children}
      <VisuallyHidden component="span"> {_t('Opens in a new tab')}</VisuallyHidden>
      <InlineSpacer />
      <span contentEditable={false}>
        {menuOpen && (
          <FloatingMenu anchorEl={ref.current} pageless={pageless} onClose={handleCloseMenu}>
            <LinkToolbar
              anchorEl={ref.current}
              link={link}
              onEditLink={handleEditDialogOpen}
              onClose={handleCloseMenu}
            />
          </FloatingMenu>
        )}
        {editLinkDialog && (
          <FloatingMenu anchorEl={ref.current} pageless={pageless} onClose={handleEditDialogClose}>
            <EditLinkDialog
              href={editHref}
              title={editTitle}
              onHrefChange={handleHrefChange}
              onTitleChange={setEditTitle}
              onClose={handleEditDialogClose}
              onDone={handleEditDialogDone}
            />
          </FloatingMenu>
        )}
      </span>
    </LinkRenderer>
  );
};

export default Link;
