import * as React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import Q from 'q';
import { branch, compose, getContext, pure, setDisplayName, withProps } from 'recompose';

import Retracked from 'js/app/retracked';
import redirect from 'js/lib/coursera.redirect';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';

import type { ButtonProps } from '@coursera/coursera-ui';
import { API_BEFORE_SEND, API_ERROR, API_SUCCESS } from '@coursera/coursera-ui/lib/constants/sharedConstants';

import type { PropsFromWithApiHandler } from 'bundles/coursera-ui/components/hocs/withApiHandler';
import withApiHandler from 'bundles/coursera-ui/components/hocs/withApiHandler';
import { withRenderNothing } from 'bundles/coursera-ui/components/hocs/withBranches';
import type { EnrollmentChoiceProps } from 'bundles/enterprise-legacy-xdp/components/ProgramMiniModal';
import type { InjectedNaptime } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramEnrollmentsV2 from 'bundles/naptimejs/resources/programEnrollments.v2';
import type { CurriculumProduct } from 'bundles/program-common/components/programActionButton/CurriculumProduct';
import { MultiprogramCurriculumProduct } from 'bundles/program-common/components/programActionButton/CurriculumProduct';
import ProgramActionButton from 'bundles/program-common/components/programActionButton/ProgramActionButton';
import type { PropsFromWithCacheInvalidationForNLJLatestEnrolledCoursesForOrg } from 'bundles/program-common/components/programActionButton/withCacheInvalidationForNLJLatestEnrolledCoursesForOrg';
import withCacheInvalidationForNLJLatestEnrolledCoursesForOrg from 'bundles/program-common/components/programActionButton/withCacheInvalidationForNLJLatestEnrolledCoursesForOrg';
import type { PropsFromWithCacheInvalidationForNLJSavedCourses } from 'bundles/program-common/components/programActionButton/withCacheInvalidationForNLJSavedCourses';
import withCacheInvalidationForNLJSavedCourses from 'bundles/program-common/components/programActionButton/withCacheInvalidationForNLJSavedCourses';
import withCourseSlug from 'bundles/program-common/components/programActionButton/withCourseSlug';
import {
  PRODUCT_ID_INFIX,
  PRODUCT_TYPES,
  REFRESH_RESOURCE,
} from 'bundles/program-common/constants/ProgramActionConstants';
import ProgramActionApiManager from 'bundles/program-common/models/ProgramActionApiManager';

type PropsFromCaller = {
  programId?: string;
  // TODO(ppaskaris): Refactor this interface.
  spookyMultiprogramFlag?: boolean;
  productId?: string;
  thirdPartyOrganizationId?: string;
  collectionId?: string;
  firstCourseId?: string;
  productType?: string;
  onActionSuccess?: () => void;
  onActionFail?: (error: unknown) => void;
  renderIfEnrolled?: boolean;
  renderAdminAction?: boolean;
  renderNothingIfConditionNotMet?: boolean;
  renderCourseHomeLink?: boolean;
  actionType: string;
  isProject?: boolean;
  btnAttributes?: ButtonProps;
  resumeBtnAttributes?: ButtonProps;
  initialBtnLabel?: JSX.Element | string;
  shouldResetToDefaultStatus?: boolean;
  apiStatusResetTime?: number;
  trackingInfo: {
    trackingName: string;
    trackingData: {
      actionType?: string;
      courseId?: string;
      s12nId?: string;
      collectionId?: string;
      source?: string;
    };
  };
  deepLinkItemId?: string;
  autoEnrolling?: boolean;
  setHasEnrolledInCourse?: (val: boolean) => void;
  openEnrollmentChoiceModal?: (props: EnrollmentChoiceProps) => void;
  setAutoEnrolling?: (autoEnrolling: boolean) => void;
};

type PropsFromGetContext = {
  contextProducts?: Record<string, CurriculumProduct | undefined>;
};

type PropsFromWithProps1 = {
  userId: number | undefined;
  isCourse: boolean;
};

type PropsAssertedByNaptime = Required<Pick<PropsFromCaller, 'programId' | 'productId'>>;

type PropsFromNaptime1 = {
  curriculumProduct?: CurriculumProduct;
  spookyMultiprogramEnrollments?: ProgramEnrollmentsV2[];
  pending: boolean;
};

type PropsFromNaptime2 = {
  spooky$keys?: string[];
  pending: boolean;
  pendingFromNaptime1: boolean;
} & Record<string, CurriculumProduct>;

type PropsFromNaptimeWithProps = {
  shouldRenderNothing: boolean;
  curriculumProduct?: CurriculumProduct;
};

type PropsFromNaptimeChain = {
  naptime: InjectedNaptime;
} & Required<PropsFromNaptimeWithProps>;

type PropsFromWithCourseSlug = {
  course?: {
    slug: string;
  };
};

export type Props = PropsFromCaller &
  PropsFromWithProps1 &
  PropsAssertedByNaptime &
  PropsFromNaptimeChain &
  PropsFromWithApiHandler &
  PropsFromWithCacheInvalidationForNLJSavedCourses &
  PropsFromWithCacheInvalidationForNLJLatestEnrolledCoursesForOrg &
  PropsFromWithCourseSlug;

export class ProgramActionButtonContainer extends React.Component<Props> {
  // TODO(ppaskaris): Remove this - it does not respond to prop changes.
  _apiManager: ProgramActionApiManager;

  // TODO(ppaskaris): Remove this - we can simulate it by diffing props to act only on the specific change.
  _callbackTriggered: boolean | null;

  // TODO(ppaskaris): Remove this - it does not respond to prop changes.
  _courseProductObj: {
    courseId: string | null;
    collectionId: string | null;
  } = { courseId: null, collectionId: null };

  // TODO(ppaskaris): Remove this - it does not respond to prop changes.
  _s12nProductObj: {
    s12nId: string | null;
    collectionId: string | null;
    firstCourseId: string | null;
  } = { s12nId: null, collectionId: null, firstCourseId: null };

  static contextTypes = {
    _eventData: PropTypes.object,
  };

  constructor(props: Props) {
    super(props);

    const { naptime, programId, isCourse, productId, collectionId, firstCourseId } = props;
    if (isCourse) {
      this._courseProductObj = {
        courseId: productId || null,
        collectionId: collectionId || null,
      };
    } else {
      this._s12nProductObj = {
        s12nId: productId || null,
        collectionId: collectionId || null,
        firstCourseId: firstCourseId || null,
      };
    }

    // TODO(ppaskaris): Make this class static, its state serves no purpose. This will improve multi-program ergonomics.
    this._apiManager = new ProgramActionApiManager({
      programId,
      naptime,
      userId: user.get().id,
    });
    this._callbackTriggered = null;
  }

  componentDidUpdate({ apiStatus: prevApiStatus }: Props) {
    const { apiStatus, error } = this.props;
    if (apiStatus === prevApiStatus) {
      return;
    }

    // Prevent the callbacks to be triggered many times during api call and reset
    if (apiStatus === API_BEFORE_SEND) {
      this._callbackTriggered = false;
      return;
    }

    if (!this._callbackTriggered) {
      if (apiStatus === API_SUCCESS && this.props.autoEnrolling) {
        this.redirectToItemDeepLink();
        this._callbackTriggered = true;
        return;
      }
      if (apiStatus === API_SUCCESS && this.props.onActionSuccess) {
        this.props.onActionSuccess();
        this._callbackTriggered = true;
      }
      if (apiStatus === API_ERROR && this.props.onActionFail) {
        this.props.onActionFail(error);
        this._callbackTriggered = true;
      }
    }
  }

  enrollInCourseMultiprogram = () => {
    const multiprogramCurriculumProduct = this.props.curriculumProduct as MultiprogramCurriculumProduct;
    const programIds = multiprogramCurriculumProduct?.enrollThroughProgramIds;

    const enrollInCourse = (programId: string) =>
      this._enrollInCourse(
        new ProgramActionApiManager({
          programId,
          naptime: this.props.naptime,
          userId: user.get().id,
        })
      );

    if (programIds?.length === 1) {
      enrollInCourse(programIds[0]);
      return;
    }

    if (programIds?.length > 1) {
      this.props.openEnrollmentChoiceModal?.({
        onSelect: (programId) => programIds.includes(programId) && enrollInCourse(programId),
        productId: this.props.productId,
        productType: PRODUCT_TYPES.COURSE,
        programIds,
      });
    }
  };

  unenrollFromCourseMultiprogram = () => {
    const multiprogramCurriculumProduct = this.props.curriculumProduct as MultiprogramCurriculumProduct;
    const programId = multiprogramCurriculumProduct?.unenrollFromProgramId;
    if (programId) {
      const apiManager = new ProgramActionApiManager({
        programId,
        naptime: this.props.naptime,
        userId: user.get().id,
      });
      this._unenrollFromCourse(apiManager);
    }
  };

  enrollInS12nMultiprogram = () => {
    const multiprogramCurriculumProduct = this.props.curriculumProduct as MultiprogramCurriculumProduct;
    const programIds = multiprogramCurriculumProduct?.enrollThroughProgramIds;

    const enrollInS12n = (programId: string) =>
      this._enrollInS12n(
        new ProgramActionApiManager({
          programId,
          naptime: this.props.naptime,
          userId: user.get().id,
        })
      );

    if (programIds?.length === 1) {
      enrollInS12n(programIds[0]);
      return;
    }

    if (programIds?.length > 1) {
      this.props.openEnrollmentChoiceModal?.({
        onSelect: (programId) => programIds.includes(programId) && enrollInS12n(programId),
        productId: this.props.productId,
        productType: PRODUCT_TYPES.SPECIALIZATION,
        programIds,
      });
    }
  };

  unenrollFromS12nMultiprogram = () => {
    const multiprogramCurriculumProduct = this.props.curriculumProduct as MultiprogramCurriculumProduct;
    const programId = multiprogramCurriculumProduct?.unenrollFromProgramId;
    if (programId) {
      const apiManager = new ProgramActionApiManager({
        programId,
        naptime: this.props.naptime,
        userId: user.get().id,
      });
      this._unenrollFromS12n(apiManager);
    }
  };

  enrollInCourse = () => {
    this._enrollInCourse(this._apiManager);
  };

  _enrollInCourse(apiManager: ProgramActionApiManager) {
    const apiPromise = apiManager.getEnrollInCoursePromise(this._courseProductObj);
    const refreshDataPromiseFn = () => {
      this.props.setHasEnrolledInCourse?.(true);
      return apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.enrollInCourse);
    };
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  }

  unenrollFromCourse = () => {
    this._unenrollFromCourse(this._apiManager);
  };

  _unenrollFromCourse(apiManager: ProgramActionApiManager) {
    const apiPromise = apiManager.getUnenrollFromCoursePromise(this._courseProductObj);
    const refreshDataPromiseFn = () =>
      Q.spread(
        [
          this.props.invalidateLatestEnrolledCoursesForOrg(),
          apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.unenrollFromCourse),
        ],
        (discard, response) => response
      );
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  }

  enrollInS12n = () => {
    this._enrollInS12n(this._apiManager);
  };

  _enrollInS12n(apiManager: ProgramActionApiManager) {
    const apiPromise = apiManager.getEnrollInS12nPromise(this._s12nProductObj);
    const refreshDataPromiseFn = () => {
      this.props.setHasEnrolledInCourse?.(true);
      return apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.enrollInS12n);
    };
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  }

  unenrollFromS12n = () => {
    this._unenrollFromS12n(this._apiManager);
  };

  _unenrollFromS12n(apiManager: ProgramActionApiManager) {
    const apiPromise = apiManager.getUnenrollFromS12nPromise(this._s12nProductObj);
    const refreshDataPromiseFn = () => apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.unenrollFromS12n);
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  }

  selectCourse = () => {
    const apiPromise = this._apiManager.getSelectCoursePromise(this._courseProductObj);
    const refreshDataPromiseFn = () =>
      Q.spread(
        [
          this.props.invalidateSavedCourses(),
          this._apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.selectCourse),
        ],
        (discard, response) => response
      );
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  };

  unselectCourse = () => {
    const apiPromise = this._apiManager.getUnselectCoursePromise(this._courseProductObj);
    const refreshDataPromiseFn = () =>
      Q.spread(
        [
          this.props.invalidateSavedCourses(),
          this._apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.unselectCourse),
        ],
        (discard, response) => response
      );
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  };

  selectS12n = () => {
    const apiPromise = this._apiManager.getSelectS12nPromise(this._s12nProductObj);
    const refreshDataPromiseFn = () =>
      Q.spread(
        [
          this.props.invalidateSavedCourses(),
          this._apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.selectS12n),
        ],
        (discard, response) => response
      );
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  };

  unselectS12n = () => {
    const apiPromise = this._apiManager.getUnselectS12nPromise(this._s12nProductObj);
    const refreshDataPromiseFn = () =>
      Q.spread(
        [
          this.props.invalidateSavedCourses(),
          this._apiManager.getRefreshDataPromise(REFRESH_RESOURCE.programHome.unselectS12n),
        ],
        (discard, response) => response
      );
    this.props.handleApiPromise({ apiPromise, refreshDataPromiseFn });
  };

  autoEnrollAndDeepLink = (onTriggerEnrollment: () => void) => {
    const { curriculumProduct, setAutoEnrolling, autoEnrolling } = this.props;

    if (autoEnrolling) return;

    const { isEnrolled, canEnroll } = curriculumProduct;

    if (!canEnroll && !isEnrolled) {
      return;
    }

    setAutoEnrolling?.(true);

    if (canEnroll) {
      onTriggerEnrollment();
    } else {
      this.redirectToItemDeepLink();
    }
  };

  redirectToItemDeepLink = () => {
    const { deepLinkItemId, course, programId, productId } = this.props;
    const { _eventData } = this.context;

    Retracked.trackComponent(
      _eventData,
      {
        programId,
        courseId: productId,
      },
      'itemid_deeplink',
      'autoenroll'
    );

    redirect.setLocation(`/learn/${course?.slug}/item/${deepLinkItemId}`);
  };

  render() {
    const { curriculumProduct, ...rest } = this.props;

    const propsToPassDown = _.omit(
      rest,
      'naptime',
      'productType',
      'handleApiPromise',
      'onActionFail',
      'onActionSuccess'
    );

    return (
      <ProgramActionButton
        {...propsToPassDown}
        curriculumProduct={curriculumProduct}
        autoEnroll={this.autoEnrollAndDeepLink}
        callbacks={
          this.props.spookyMultiprogramFlag
            ? {
                enrollInCourse: this.enrollInCourseMultiprogram,
                unenrollFromCourse: this.unenrollFromCourseMultiprogram,
                selectCourse: _.noop,
                unselectCourse: _.noop,
                enrollInS12n: this.enrollInS12nMultiprogram,
                unenrollFromS12n: this.unenrollFromS12nMultiprogram,
                selectS12n: _.noop,
                unselectS12n: _.noop,
              }
            : {
                enrollInCourse: this.enrollInCourse,
                unenrollFromCourse: this.unenrollFromCourse,
                selectCourse: this.selectCourse,
                unselectCourse: this.unselectCourse,
                enrollInS12n: this.enrollInS12n,
                unenrollFromS12n: this.unenrollFromS12n,
                selectS12n: this.selectS12n,
                unselectS12n: this.unselectS12n,
              }
        }
      />
    );
  }
}

export default compose<Props, PropsFromCaller>(
  setDisplayName('ProgramActionButtonContainerHOC'),
  getContext({ contextProducts: PropTypes.object }),
  withProps<PropsFromWithProps1, PropsFromCaller>(({ productType }) => ({
    userId: user.get().id,
    isCourse: productType === PRODUCT_TYPES.COURSE,
  })),
  Naptime.createContainer<PropsFromNaptime1, PropsFromCaller & PropsFromGetContext & PropsFromWithProps1>(
    ({ programId, productId, isCourse, contextProducts, spookyMultiprogramFlag, thirdPartyOrganizationId, userId }) => {
      if (!productId) {
        return {};
      }

      // Multi-program case is weird and totally separate.
      if (spookyMultiprogramFlag) {
        if (!thirdPartyOrganizationId || !userId) {
          return {};
        }

        return {
          spookyMultiprogramEnrollments: ProgramEnrollmentsV2.finder(
            isCourse ? 'availableProgramsForUserAndCourseId' : 'availableProgramsForUserAndS12nId',
            {
              params: {
                userId,
                [isCourse ? 'courseId' : 's12nId']: productId,
                thirdPartyOrganizationId,
              },
              // This only returns the linked resources for the first program enrollment, but it saves us an xhr so use
              // it. Maybe BE will be fixed to include all of them and this will gracefully improve.
              includes: ['programCurriculumProduct'],
            }
          ),
        };
      }

      if (!programId) {
        return {};
      }

      const contextProduct = contextProducts?.[productId];
      if (contextProduct) {
        return {
          curriculumProduct: contextProduct,
        };
      }

      let productQueryId;
      // IMPORTANT: make sure the curriculumProduct contains the naptime derived fields
      // e.g. canEnroll, canSelect, canUnenroll, canUnenroll, isEnrolled, isSelected
      if (isCourse) {
        productQueryId = `${programId}~${PRODUCT_ID_INFIX.COURSE}~${productId}`;
      } else {
        // Sample: https://www.coursera.org/api/programCurriculumProducts.v1/O65n5injEeeuuQ7tmHEh3A~Specialization~child!~y1UW-PoUEeaJcg5fc3xSYA
        productQueryId = tupleToStringKey([programId, PRODUCT_ID_INFIX.S12N, productId]);
      }

      return {
        curriculumProduct: ProgramCurriculumProductsV1.get(productQueryId, {}),
      };
    }
  ),
  Naptime.createContainer<PropsFromNaptime2, PropsFromCaller & PropsFromWithProps1 & PropsFromNaptime1>(
    ({ spookyMultiprogramFlag, spookyMultiprogramEnrollments, isCourse, productId, pending: pendingFromNaptime1 }) => {
      if (!spookyMultiprogramFlag) return { pendingFromNaptime1 };
      if (!spookyMultiprogramEnrollments) return { pendingFromNaptime1 };
      const productType = isCourse ? PRODUCT_ID_INFIX.COURSE : PRODUCT_ID_INFIX.S12N;
      const programCurriculumProductIds = _.map(spookyMultiprogramEnrollments, ({ programId }) =>
        tupleToStringKey([programId, productType, productId])
      );
      // Immense hack to avoid multiGet on this resource since it throws for distinct program ids. Bad interface. To
      // mitigate, we issue multiple get requests, and pass through an array to keep track of things.
      return _.reduce(
        programCurriculumProductIds,
        (obj, programCurriculumProductId, index) => {
          const key = `spooky$${index}`;
          // eslint-disable-next-line no-param-reassign
          obj[key] = ProgramCurriculumProductsV1.get(programCurriculumProductId);
          obj.spooky$keys.push(key);
          return obj;
        },
        { pendingFromNaptime1, spooky$keys: [] } as $TSFixMe
      );
    }
  ),
  branch(
    ({ deepLinkItemId, productType, productId }: PropsFromCaller) =>
      Boolean(productId) && Boolean(deepLinkItemId) && productType === PRODUCT_TYPES.COURSE,
    compose(
      withProps<{ id: string }, PropsFromCaller>(({ productId }) => {
        // asserted in branch
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return { id: productId! };
      }),
      withCourseSlug
    )
  ),
  withProps<PropsFromNaptimeWithProps, PropsFromCaller & PropsFromNaptime1 & PropsFromNaptime2>(
    ({
      spookyMultiprogramFlag,
      pendingFromNaptime1,
      pending: pendingFromNaptime2,
      curriculumProduct,
      spooky$keys,
      ...rest
    }) => {
      let curriculumProduct0: CurriculumProduct | undefined;
      if (spookyMultiprogramFlag) {
        if (spooky$keys?.length) {
          const curriculumProducts = _.compact(_.map(spooky$keys, (key) => rest[key]));
          if (curriculumProducts.length) {
            curriculumProduct0 = new MultiprogramCurriculumProduct(curriculumProducts);
          }
        }
      } else {
        curriculumProduct0 = curriculumProduct;
      }
      return {
        curriculumProduct: curriculumProduct0,
        shouldRenderNothing: pendingFromNaptime1 || pendingFromNaptime2 || !curriculumProduct0,
      };
    }
  ),
  withRenderNothing,
  withApiHandler({}),
  withCacheInvalidationForNLJSavedCourses,
  withCacheInvalidationForNLJLatestEnrolledCoursesForOrg,
  pure
)(ProgramActionButtonContainer);
