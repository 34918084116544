import * as React from 'react';
import { graphql } from 'react-apollo';
import { Link as ReactRouterLink } from 'react-router';

import type { EnterpriseSkills_Skillset as SkillSet } from '__generated__/graphql-types';
import gql from 'graphql-tag';
import { branch, compose, withStateHandlers } from 'recompose';

import { isAuthenticatedUser } from 'js/lib/user';

import { Divider, InlineNotification, Link, Typography, useTheme } from '@coursera/cds-core';
import type { LinkProps, Theme } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import { toProgramHomeTab } from 'bundles/program-common/links';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { Section } from 'bundles/program-home/components/AutoHeading';
import DetailedObjectivesInformationLink from 'bundles/program-home/components/StaticInformationCards/DetailedObjectivesInformationLink';
import { SkillSetDiscoveryExperimentHeader } from 'bundles/program-home/components/multiprogram/SkillSetDiscoveryExperimentHeader';
import SkillSetDiscoveryHeader from 'bundles/program-home/components/multiprogram/SkillSetDiscoveryHeader';
import SkillSetInformationCard from 'bundles/program-home/components/multiprogram/SkillSetInformationCard';
import {
  MAX_NUMBER_OF_SKILLSETS as MAX_NUMBER_OF_SKILLSETS_FOR_CAROUSEL,
  SkillSetsCarousel,
  SkillSetsCarouselPlaceholder,
} from 'bundles/program-home/components/multiprogram/SkillSetsCarousel';
import {
  MAX_NUMBER_OF_SKILLSETS as MAX_NUMBER_OF_SKILLSETS_FOR_GRID,
  SkillSetsGrid,
  SkillSetsGridPlaceholder,
} from 'bundles/program-home/components/multiprogram/SkillSetsGrid';
import type {
  ObjectiveListQuery as ObjectiveListQueryData,
  ObjectiveListQueryVariables,
  ObjectiveListQuery_EnterpriseTargetSkillProfileSummariesV1Resource_byProgram_elements as TargetSkillProficiencySummary,
} from 'bundles/program-home/components/multiprogram/__generated__/ObjectiveListQuery';
import SkillSetCardsQuery from 'bundles/program-home/components/multiprogram/api/SkillSetCardsQuery.graphql';
import type {
  SkillSetCardsQuery as SkillSetCardsQueryData,
  SkillSetCardsQueryVariables,
} from 'bundles/program-home/components/multiprogram/api/__generated__/SkillSetCardsQuery';
import { TabName } from 'bundles/program-home/components/single-program/SingleProgramTabs';
import SkillSetFilterResultsA11yAnnouncer from 'bundles/program-home/components/single-program/SkillSetFilterResultsA11yAnnouncer';
import { SkillSetFiltersContainer } from 'bundles/program-home/components/single-program/SkillSetFiltersContainer';
import SkillSetFiltersHOC from 'bundles/program-home/components/single-program/SkillSetFiltersHOC';
import type { PropsFromSkillSetFiltersHOC } from 'bundles/program-home/components/single-program/SkillSetFiltersHOC';
import SkillSetFiltersHOCDGS from 'bundles/program-home/components/single-program/SkillSetFiltersHOCDGS';
import { getFilterTrackingData } from 'bundles/program-home/utils/ProgramHomeUtils';
import withPreventDefault from 'bundles/program-home/utils/withPreventDefault';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/SkillSetCards';

type PropsFromCaller = {
  programId: string;
  showSkillSets?: () => void;
  programSlug: string;
  programName?: string;
  title: string;
  skillSetLength?: number;
  renderOrgHomeVariant?: boolean;
  isAnAcademicDisciplineProgram?: boolean;
  showCarousel?: boolean;
  showSkillSetRoleFilter: boolean;
};

type PropsFromGraphql = {
  skillsets: TargetSkillProficiencySummary[];
  error: boolean;
  loading: boolean;
};

type PropsFromGraphql2 = {
  skillsetsDGS: SkillSet[];
  error: boolean;
  loading: boolean;
};

type State = {
  helpCardId: string;
  helpIsExpanded: boolean;
};

type StateHandlers = {
  toggleHelpIsExpanded: () => State;
};

// TODO(ppaskaris): clean up these props
type PropsForSkillSetDiscoveryPlaceholder = {
  showSkillSets?: () => void;
  programSlug: string;
  programName?: string;
  toggleHelpIsExpanded?: () => State;
  helpIsExpanded?: boolean;
  skillSetLength?: number;
  title?: string;
  renderOrgHomeVariant?: boolean;
  isAnAcademicDisciplineProgram?: boolean;
  showSkillSetRoleFilter: boolean;
  showCarousel?: boolean;
};

type PropsForSkillSetDiscovery = {
  programSlug: string;
  programName?: string;
  showSkillSets?: () => void;
  helpIsExpanded: boolean;
  toggleHelpIsExpanded: () => State;
  helpCardId: string;
  objectives: TargetSkillProficiencySummary[] | SkillSet[];
  title?: string;
  renderOrgHomeVariant?: boolean;
  totalSkillSetLength?: number;
  isAnAcademicDisciplineProgram?: boolean;
  showSkillSetRoleFilter: boolean;
  showCarousel?: boolean;
} & Omit<PropsFromSkillSetFiltersHOC, 'isFilterSelected'>;

type PropsForSkillSetTabLink = {
  showSkillSets?: () => void;
  programSlug: string;
  skillSetLength?: number;
};

type PropsFromSkillSetDiscoveryFilters = {
  skillsets?: TargetSkillProficiencySummary[];
} & PropsFromSkillSetFiltersHOC;

type Props = PropsFromCaller &
  PropsFromGraphql &
  PropsFromGraphql2 &
  State &
  StateHandlers &
  PropsFromSkillSetDiscoveryFilters;

export const ObjectiveListQuery = gql`
  query ObjectiveListQuery($programId: String!, $limit: Int) {
    EnterpriseTargetSkillProfileSummariesV1Resource {
      byProgram(id: $programId, limit: $limit) {
        elements {
          id
          slug
          title
          occupationId
          goalType
          targetSkillProficiencies {
            skillId
            skillName
          }
        }
      }
    }
  }
`;

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps<ReactRouterLink>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Link
);

const styles = {
  divider: (theme: Theme) => ({ marginBottom: theme.spacing(8) }),
};

const SkillsDiscoverySkillSetsTabLink: React.FC<PropsForSkillSetTabLink> = ({
  showSkillSets,
  programSlug,
  skillSetLength,
}) => {
  return (
    <div className="skillset-tab-link-container">
      <Typography variant="h2semibold" className="skillset-tab-prefix" component="span">
        {_t('Want to see more? ')}
      </Typography>
      <TrackedLink
        {...Link.defaultProps}
        component={ReactRouterLink}
        className="skillset-tab-link"
        trackingName="view_all_skillsets_footer_link"
        to={toProgramHomeTab(programSlug, TabName.SkillSets)}
        aria-label={_t('View all SkillSets in this program')}
        typographyVariant="subtitleLarge"
        variant="quiet"
        onClick={showSkillSets ? withPreventDefault(showSkillSets) : undefined}
        icon={<ArrowNextIcon className="skillset-tab-link-icon" size="medium" />}
        iconPosition="after"
      >
        {skillSetLength && skillSetLength > 6
          ? _t('View all SkillSets')
          : _t('View more details for each of these SkillSets')}
      </TrackedLink>
    </div>
  );
};

const SkillSetDiscoveryPlaceholder: React.FC<PropsForSkillSetDiscoveryPlaceholder> = ({
  showSkillSets,
  programSlug,
  programName,
  helpIsExpanded,
  toggleHelpIsExpanded,
  skillSetLength,
  title,
  renderOrgHomeVariant,
  isAnAcademicDisciplineProgram,
  showSkillSetRoleFilter,
  showCarousel,
}) => {
  const theme = useTheme();

  return (
    <div className="rc-SkillSetCards" aria-hidden>
      <SkillSetDiscoveryHeader
        programSlug={programSlug}
        showSkillSets={showSkillSets}
        title={title}
        renderOrgHomeVariant={renderOrgHomeVariant}
        programName={programName}
        isAnAcademicDisciplineProgram={isAnAcademicDisciplineProgram}
      />
      {!renderOrgHomeVariant && typeof helpIsExpanded === 'boolean' && typeof toggleHelpIsExpanded === 'function' && (
        <DetailedObjectivesInformationLink
          rootClassName="skillset-discovery-HelpLink"
          cardHtmlId="ExploreSkillsListInformationCard"
          isOpen={helpIsExpanded}
          onClick={toggleHelpIsExpanded}
          enableSkillsDiscovery
          isCdsEnabled
        />
      )}
      {!renderOrgHomeVariant && typeof helpIsExpanded === 'boolean' && (
        <SkillSetInformationCard
          rootClassName="skillset-information-card"
          id="ExploreSkillsListInformationCard"
          isOpen={helpIsExpanded}
        />
      )}
      {!renderOrgHomeVariant && isAuthenticatedUser() && (
        <>
          <Divider color="light" hidden={helpIsExpanded} css={styles.divider(theme)} />
          {/* not sure if there's any way to get this to render any faster because it looks like
          the skillset cards placeholder is still rendering faster than the filters placeholder */}
          <SkillSetFiltersContainer
            loading={true}
            showSkillSetRoleFilter={showSkillSetRoleFilter}
            filterIdPrefix="SkillSetCards-"
          />
        </>
      )}
      {showCarousel ? (
        <SkillSetsCarouselPlaceholder skillSetLength={skillSetLength} />
      ) : (
        <SkillSetsGridPlaceholder skillSetLength={skillSetLength} />
      )}
      {!renderOrgHomeVariant && (
        <SkillsDiscoverySkillSetsTabLink
          showSkillSets={showSkillSets}
          programSlug={programSlug}
          skillSetLength={skillSetLength}
        />
      )}
    </div>
  );
};

const SkillSetDiscoveryCards: React.FC<PropsForSkillSetDiscovery> = ({
  programSlug,
  programName,
  showSkillSets,
  helpIsExpanded,
  toggleHelpIsExpanded,
  helpCardId,
  objectives,
  title,
  renderOrgHomeVariant,
  filterOptions,
  filterSelections,
  allOccupations,
  skillNames,
  onRoleChange,
  onSkillsChange,
  onGoalChange,
  onFilterReset,
  onFilterInputChange,
  totalSkillSetLength,
  showSkillSetRoleFilter,
  isAnAcademicDisciplineProgram,
  showCarousel = false,
}) => {
  const theme = useTheme();
  const filterTrackingData = getFilterTrackingData(filterSelections);
  const enableHomeReorganize = EnterpriseExperiments.get('enableHomeReorganize');

  // For the enableHomeReorganize experiment we only want the grid.
  const showCarouselCheck = showCarousel && !enableHomeReorganize;

  const maxNumberOfSkillsets = showCarousel ? MAX_NUMBER_OF_SKILLSETS_FOR_CAROUSEL : MAX_NUMBER_OF_SKILLSETS_FOR_GRID;
  return (
    <div className="rc-SkillSetCards">
      {!enableHomeReorganize && (
        <SkillSetFilterResultsA11yAnnouncer
          filteredSkillSetCount={objectives.length > maxNumberOfSkillsets ? maxNumberOfSkillsets : objectives.length}
          filterSelections={filterSelections}
        />
      )}
      {enableHomeReorganize ? (
        <SkillSetDiscoveryExperimentHeader />
      ) : (
        <SkillSetDiscoveryHeader
          programSlug={programSlug}
          showSkillSets={showSkillSets}
          title={title}
          renderOrgHomeVariant={renderOrgHomeVariant}
          programName={programName}
          isAnAcademicDisciplineProgram={isAnAcademicDisciplineProgram}
        />
      )}
      <Section initialLevel={3}>
        {!renderOrgHomeVariant && !enableHomeReorganize && (
          <DetailedObjectivesInformationLink
            rootClassName="skillset-discovery-HelpLink"
            cardHtmlId="ExploreSkillsListInformationCard"
            isOpen={helpIsExpanded}
            onClick={toggleHelpIsExpanded}
            enableSkillsDiscovery
            isCdsEnabled
          />
        )}
        {!renderOrgHomeVariant && (
          <SkillSetInformationCard rootClassName="skillset-information-card" id={helpCardId} isOpen={helpIsExpanded} />
        )}
        {!enableHomeReorganize && !renderOrgHomeVariant && isAuthenticatedUser() && (
          <>
            <Divider color="light" hidden={helpIsExpanded} css={styles.divider(theme)} />
            <SkillSetFiltersContainer
              showSkillSetRoleFilter={showSkillSetRoleFilter}
              filterOptions={filterOptions}
              filterSelections={filterSelections}
              allOccupations={allOccupations}
              skillNames={skillNames}
              onRoleChange={onRoleChange}
              onSkillsChange={onSkillsChange}
              onGoalChange={onGoalChange}
              onFilterReset={onFilterReset}
              onFilterInputChange={onFilterInputChange}
              filterIdPrefix="BrowseCatalogTab-"
            />
          </>
        )}
        {objectives && showCarouselCheck && (
          <SkillSetsCarousel
            objectives={objectives}
            programSlug={programSlug}
            filterTrackingData={filterTrackingData}
          />
        )}
        {objectives && !showCarouselCheck && <SkillSetsGrid objectives={objectives} programSlug={programSlug} />}
        {!renderOrgHomeVariant && (
          <SkillsDiscoverySkillSetsTabLink
            showSkillSets={showSkillSets}
            programSlug={programSlug}
            skillSetLength={totalSkillSetLength}
          />
        )}
      </Section>
    </div>
  );
};

export const SkillSetCardsRenderer: React.FC<Props> = ({
  error,
  loading,
  showSkillSets,
  helpCardId,
  helpIsExpanded,
  toggleHelpIsExpanded,
  programSlug,
  programName,
  title,
  skillSetLength,
  renderOrgHomeVariant,
  skillsets,
  skillsetsDGS,
  filterOptions,
  filterSelections,
  allOccupations,
  skillNames,
  onRoleChange,
  onSkillsChange,
  onGoalChange,
  onFilterReset,
  onFilterInputChange,
  isAnAcademicDisciplineProgram,
  showSkillSetRoleFilter,
  showCarousel = false,
}) => {
  const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
  if (loading) {
    return (
      <SkillSetDiscoveryPlaceholder
        showSkillSetRoleFilter={showSkillSetRoleFilter}
        helpIsExpanded={helpIsExpanded}
        toggleHelpIsExpanded={toggleHelpIsExpanded}
        showSkillSets={showSkillSets}
        programSlug={programSlug}
        programName={programName}
        skillSetLength={skillSetLength}
        title={title}
        renderOrgHomeVariant={renderOrgHomeVariant}
        isAnAcademicDisciplineProgram={isAnAcademicDisciplineProgram}
        showCarousel={showCarousel}
      />
    );
  }
  if (error) {
    return (
      <div className="rc-SkillSetCards">
        <InlineNotification severity="error" role="alert">
          {_t('Sorry! Something went wrong. Please refresh the page.')}
        </InlineNotification>
      </div>
    );
  }
  return (
    <SkillSetDiscoveryCards
      showSkillSetRoleFilter={showSkillSetRoleFilter}
      programSlug={programSlug}
      programName={programName}
      showSkillSets={showSkillSets}
      helpIsExpanded={helpIsExpanded}
      toggleHelpIsExpanded={toggleHelpIsExpanded}
      helpCardId={helpCardId}
      objectives={enableSkillSetsByDefaultDGS ? skillsetsDGS : skillsets}
      renderOrgHomeVariant={renderOrgHomeVariant}
      filterOptions={filterOptions}
      filterSelections={filterSelections}
      allOccupations={allOccupations}
      skillNames={skillNames}
      onRoleChange={onRoleChange}
      onSkillsChange={onSkillsChange}
      onGoalChange={onGoalChange}
      onFilterReset={onFilterReset}
      onFilterInputChange={onFilterInputChange}
      totalSkillSetLength={skillSetLength}
      title={title}
      isAnAcademicDisciplineProgram={isAnAcademicDisciplineProgram}
      showCarousel={showCarousel}
    />
  );
};

export const enhance = compose<Props, PropsFromCaller>(
  graphql<PropsFromCaller, ObjectiveListQueryData, ObjectiveListQueryVariables, PropsFromGraphql>(ObjectiveListQuery, {
    skip: () => {
      const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
      return enableSkillSetsByDefaultDGS;
    },
    options: ({ programId, renderOrgHomeVariant }) => ({
      ssr: false,
      variables: {
        programId,
        limit: renderOrgHomeVariant ? 3 : null,
      },
    }),
    props: ({ data }) => {
      const error = !!data?.error;
      const skillsets = filterExistsOrDefault(
        data?.EnterpriseTargetSkillProfileSummariesV1Resource?.byProgram?.elements
      );
      return { skillsets, error, loading: data?.loading ?? true };
    },
  }),
  graphql<PropsFromCaller, SkillSetCardsQueryData, SkillSetCardsQueryVariables, PropsFromGraphql2>(SkillSetCardsQuery, {
    skip: () => {
      const enableSkillSetsByDefaultDGS = EnterpriseExperiments.get('enableSkillSetsByDefaultDGS');
      return !enableSkillSetsByDefaultDGS;
    },
    options: ({ programId, renderOrgHomeVariant }) => ({
      variables: {
        programId,
        first: renderOrgHomeVariant ? 3 : 100,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }),
    props: ({ data }) => {
      const skillsets = filterExistsOrDefault(data?.EnterpriseSkillset?.queryByProgram?.edges?.map((_) => _.node));
      return { skillsetsDGS: skillsets, error: Boolean(data?.error), loading: data?.loading ?? true };
    },
  }),
  branch(
    ({ renderOrgHomeVariant }: PropsFromCaller) =>
      !renderOrgHomeVariant && !EnterpriseExperiments.get('enableSkillSetsByDefaultDGS') && isAuthenticatedUser(),
    SkillSetFiltersHOC
  ),
  branch(
    ({ renderOrgHomeVariant }: PropsFromCaller) =>
      !renderOrgHomeVariant && EnterpriseExperiments.get('enableSkillSetsByDefaultDGS') && isAuthenticatedUser(),
    SkillSetFiltersHOCDGS
  ),
  withStateHandlers<State, StateHandlers, PropsFromCaller>(
    {
      helpCardId: 'ExploreSkillsListInformationCard',
      helpIsExpanded: false,
    },
    {
      toggleHelpIsExpanded:
        ({ helpIsExpanded }) =>
        () => ({
          helpIsExpanded: !helpIsExpanded,
        }),
    }
  )
);

type SkillSetCardsComponentType = React.ComponentClass<PropsFromCaller> & {
  Placeholder: typeof SkillSetDiscoveryPlaceholder;
};

// Trick to avoid interop error between hooks/recompose, specifically:
//   Error: Invalid hook call. Hooks can only be called inside of the body of a function component.
const SkillSetCardsHookProxy = (props: Props) => <SkillSetCardsRenderer {...props} />;

const SkillSetCards: SkillSetCardsComponentType = Object.assign(enhance(SkillSetCardsHookProxy), {
  Placeholder: SkillSetDiscoveryPlaceholder,
});

export default SkillSetCards;
