import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

export const TOP_LEVEL_ELEMENTS = new Set<BLOCK_VALUES>([
  BLOCK_TYPES.IMAGE,
  BLOCK_TYPES.IMAGE_UPLOADER,
  BLOCK_TYPES.TABLE,
  BLOCK_TYPES.WIDGET,
  BLOCK_TYPES.HEADING,
  BLOCK_TYPES.ASSET,
  BLOCK_TYPES.CODE,
]);

export const normalizeTopLevelElements = (
  editor: Editor,
  tools: Set<ToolsKeys>,
  [node, path]: NodeEntry<Node>
): boolean => {
  if (!Element.isElement(node) || !TOP_LEVEL_ELEMENTS.has(node.type)) {
    return false;
  }

  if (path.length === 1) {
    return false;
  }

  Transforms.liftNodes(editor, { at: path, match: (el) => el === node });
  return true;
};
