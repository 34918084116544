/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, Dialog, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import SearchFilterItem from 'bundles/search-common/components/filters/SearchFilterItem';

import _t from 'i18n!nls/enterprise-collections';

type Props = {
  items: Array<{
    count: number;
    isRefined: boolean;
    label: string;
    value: string;
  }>;
  filterName: string;
  filterLabel: string;
  open: boolean;
  closeModal: () => void;
  addFilters?: (x0: string) => void;
  removeFilters?: (filters: string[]) => void;
  clearFilters?: () => void;
};

const styles = {
  modalCheckboxGroup: css`
    legend {
      border-bottom: none;
    }

    display: grid;
    overflow-y: auto;
    height: 240px;
    grid-template-columns: auto auto auto;
  `,
  applyButton: (theme: Theme) =>
    css`
      margin-right: ${theme.spacing(24)};
    `,
};

export const SearchFilterModal = ({
  items,
  filterName,
  filterLabel,
  open,
  closeModal,
  addFilters,
  removeFilters,
  clearFilters,
}: Props) => {
  const { HeadingGroup, Content, Actions } = Dialog;

  return (
    <Dialog variant="standard" open={open} onClose={closeModal} width="large">
      <HeadingGroup>{filterLabel}</HeadingGroup>
      <Content>
        <Typography2 component="h3" variant="subtitleMedium">
          {_t('Select options')}
        </Typography2>
        <div
          id="checkbox-group"
          aria-label={_t('Select #{filterName} options', { filterName })}
          css={styles.modalCheckboxGroup}
        >
          {items.map((item) => (
            <SearchFilterItem
              addFilters={addFilters}
              removeFilters={(itemToRemove) => removeFilters?.([itemToRemove])}
              key={item.label}
              filterCategoryName={filterName}
              eventingData={{ searchIndex: '', searchIndexPosition: -1 }}
              count={item.count}
              isRefined={item.isRefined}
              value={`${filterName}:${item.value}`}
              label={item.label}
            />
          ))}
        </div>
      </Content>
      <Actions>
        <Button variant="primary" onClick={closeModal} css={styles.applyButton}>
          {_t('Apply')}
        </Button>
        {clearFilters && (
          <Button variant="secondary" onClick={clearFilters}>
            {_t('Clear All')}
          </Button>
        )}
      </Actions>
    </Dialog>
  );
};

export default SearchFilterModal;
