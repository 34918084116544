import gql from 'graphql-tag';

import type { EnterpriseAdminRolesV1ByUserProgramQuery } from 'bundles/naptimejs/resources/__generated__/EnterpriseAdminRolesV1';
import type { ProgramUserCreditsV1GetQuery } from 'bundles/naptimejs/resources/__generated__/ProgramUserCreditsV1';

export type DeferredProgramHomeLoggedInQueryVariables = {
  programMembershipId: string;
  userId: string;
  programId: string;
};

export type DeferredProgramHomeLoggedInQuery = ProgramUserCreditsV1GetQuery & EnterpriseAdminRolesV1ByUserProgramQuery;

const DeferredProgramHomeLoggedInQueryText = gql`
  query DeferredProgramHomeLoggedInQuery($programMembershipId: String!, $userId: String!, $programId: String!) {
    EnterpriseAdminRolesV1 @naptime {
      byUserProgram(userId: $userId, programId: $programId) {
        elements {
          id
          role
        }
      }
    }
  }
`;

export default DeferredProgramHomeLoggedInQueryText;
