import { Editor, Element, Text, Transforms } from 'slate';
import type { Node, NodeEntry, PathRef } from 'slate';

import logger from 'js/app/loggerSingleton';

import { LIST_TYPES_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type { ListElement, ListItemElement } from 'bundles/cml/shared/types/elementTypes';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const LIST_TYPES = new Set<BLOCK_VALUES>([BLOCK_TYPES.NUMBER_LIST, BLOCK_TYPES.BULLET_LIST]);

// Merges next list with the previous one. This normalizer is similar to the one below
// except it gets called when you insert a new list below a pre-existing list
// before:
//   <ul>
//     <li>previous list</li>
//   </ul>
//   <ul>
//     <li>new list</li>
//   </ul>
//
// after:
//   <ul>
//     <li>previous list</li>
//     <li>new list</li>
//   </ul>

const mergeWithPreviousList = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || !LIST_TYPES.has(node.type)) {
    return false;
  }

  const [parent] = Editor.parent(editor, path);
  const index = parent.children.indexOf(node);
  if (index <= 0) {
    return false;
  }

  try {
    const prevEntry = Editor.previous(editor, { at: path });
    if (!prevEntry) {
      return false;
    }

    const [prevNode] = prevEntry;
    if (Element.isElement(prevNode) && prevNode.type === node.type) {
      Transforms.mergeNodes(editor, { at: path });
      return true;
    }
  } catch (e) {
    logger.info('[CMLEditor]: previous node failed', e);
  }

  return false;
};

// Merges previous list with the next one. This normalizer is simliar to the one above
// except it gets called when you insert a new list above a pre-existing list
//
// before:
//   <ul>
//     <li>new list</li>
//   </ul>
//   <ul>
//     <li>next list</li>
//   </ul>
//
// after:
//   <ul>
//     <li>new list</li>
//     <li>next list</li>
//   </ul>

const mergeWithNextList = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || !LIST_TYPES.has(node.type)) {
    return false;
  }

  const [parent] = Editor.parent(editor, path);
  const index = parent.children.indexOf(node);
  if (index >= parent.children.length - 1 || index < 0) {
    return false;
  }

  try {
    const nextEntry = Editor.next(editor, { at: path });
    if (!nextEntry) {
      return false;
    }

    const [nextNode, nextPath] = nextEntry;
    if (Element.isElement(nextNode) && nextNode.type === node.type) {
      Transforms.mergeNodes(editor, { at: nextPath });
      return true;
    }
  } catch (e) {
    logger.info('[CMLEditor]: next node failed', e);
  }

  return false;
};

// Normalizes gdocs sublist format
// before:
//   <ul>
//     <li>item</li>
//     <ul><li>sub item</li></ul>
//   </ul>
//
// after:
//   <ul>
//     <li>
//       item
//       <ul><li>sub item</li></ul>
//     </li>
//   </ul>

const handleGoogleDocsSubListFormat = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || !LIST_TYPES.has(node.type)) {
    return false;
  }

  const list = node as ListElement;
  const index = list.children.findIndex((child) => !Element.isElement(child) || child.type !== BLOCK_TYPES.LIST_ITEM);
  if (index <= 0) {
    return false;
  }

  Transforms.moveNodes(editor, { at: [...path, index], to: [...path, index - 1, 1] });
  return true;
};

// Inserts a <li> node when none is present
// or removes the element if it's a text node with no content
// before:
//   <ul>
//     missing list item
//   </ul>
//   <ul>
//     <li>test</li>
//     <text></text>
//   </ul>
//
// after:
//   <ul>
//     <li>missing list item</li>
//   </ul>
//   <ul>
//     <li>test</li>
//   </ul>

const insertMissingListItem = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || !LIST_TYPES.has(node.type)) {
    return false;
  }

  const list = node as ListElement;
  if (list.children.length !== 1) {
    return false;
  }

  const child = list.children[0];
  if (Element.isElement(child) && child.type === BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  if (Text.isText(child) && !child.text) {
    Transforms.delete(editor, { at: path });
    return true;
  }

  Transforms.wrapNodes(editor, { type: BLOCK_TYPES.LIST_ITEM, children: [] }, { at: [...path, 0] });
  return true;
};

// Inserts a <text> node when none is present
// before:
//   <ul>
//     <li>test <a href="http://example.com">link</a>.</li>
//   </ul>
//
// after:
//   <ul>
//     <li>
//       <text>test <a href="http://example.com">link</a>.</text>
//     </li>
//   </ul>

const insertMissingText = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  const listItem = node as ListItemElement;
  const hasText = listItem.children.every(
    (child) => Text.isText(child) || (Element.isElement(child) && child.isInline)
  );
  if (!hasText) {
    return false;
  }

  const pathRefs: PathRef[] = [];
  for (let i = 0; i < listItem.children.length; i += 1) {
    const pathRef = Editor.pathRef(editor, [...path, i]);
    pathRefs.push(pathRef);
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [] }, { at: [...path, 0] });
    pathRefs.forEach((pathRef: PathRef, index) => {
      if (pathRef.current) {
        Transforms.moveNodes(editor, { at: pathRef.current, to: [...path, 0, index] });
      }
      pathRef.unref();
    });
  });

  return true;
};

// Ensures each list item contains a single text node and an optional nested list
// before:
//   <ul>
//     <li>
//       <text>test</text>
//       <li><text>foo</text></li> // invalid
//     </li>
//     <li>
//       <text>test</text>
//       <ul>
//         <li><text>foo</text></li>
//       </ul>
//       <li><text>test</text></li> // invalid
//     </li>
//   </ul>
//
// after:
//   <ul>
//     <li>
//       <text>test</text>
//     </li>
//     <li>
//       <text>test</text>
//       <ul>
//         <li><text>foo</text></li>
//       </ul>
//     </li>
//   </ul>

const removeInvalidListItemChildren = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  let removed = false;
  Transforms.removeNodes(editor, {
    at: path,
    match: (child, childPath) => {
      if (childPath.length !== path.length + 1) {
        return false;
      }

      if (!Element.isElement(child)) {
        removed = true;
        return true;
      }

      if (child.type === BLOCK_TYPES.TEXT || child.type === BLOCK_TYPES.MATH_BLOCK) {
        return false;
      }

      if (child.type === BLOCK_TYPES.NUMBER_LIST || child.type === BLOCK_TYPES.BULLET_LIST) {
        if (childPath[childPath.length - 1] !== 1) {
          removed = true;
          return true;
        }

        return false;
      }

      removed = true;
      return true;
    },
  });

  return removed;
};

// Removes invalid sublist nesting
// before:
//   <ul>
//     <ul>
//       <li>invalid</li>
//     </ul>
//   </ul>
//
// after:
//   <ul>
//     <li>invalid</li>
//   </ul>

const removeInvalidSubListNesting = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  const listItem = node as ListItemElement;
  const child = listItem.children[0];

  const firstChildIsList = Element.isElement(child) && LIST_TYPES.has(child.type);
  if (!firstChildIsList) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: [...path, 0] });
  Transforms.unwrapNodes(editor, { at: path });
  return true;
};

// Removes empty lists
// before:
//   <ul></ul>
//
// after:
//   (deleted)

const removeEmptyList = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || !LIST_TYPES.has(node.type)) {
    return false;
  }

  const list = node as ListElement;
  if (list.children.length > 0) {
    return false;
  }

  Transforms.removeNodes(editor, { at: path });
  return true;
};

// Removes empty list items
// before:
//   <ul>
//     <li>test</li>
//     <li></li>
//   </ul>
//
// after:
//   <ul>
//     <li>test</li>
//   </ul>

const removeEmptyListItem = (editor: Editor, [node, path]: NodeEntry<Node>): boolean => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  const list = node as ListItemElement;
  if (list.children.length > 0) {
    return false;
  }

  Transforms.removeNodes(editor, { at: path });
  return true;
};

// Removes list items when lists are not supported
// * note: works in conjunction with normalizeList, but must be executed first
// before:
//   <ul>
//     <li>test</li>
//   </ul>
//
// after:
//   <ul>
//     test
//   </ul>

const normalizeListItem = (editor: Editor, tools: Set<ToolsKeys>, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LIST_ITEM) {
    return false;
  }

  const [parentListNode] = Editor.parent(editor, path) as NodeEntry<ListElement>;
  const tool = LIST_TYPES_TO_TOOLS[parentListNode.type];
  if (!tool || tools.has(tool)) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: path, match: (el) => Element.isElement(el) && el.type === node.type });
  return true;
};

// Removes list when lists are not supported
// * note: works in conjunction with normalizeListItem, but must be executed after
// before:
//   <ul>
//     test
//   </ul>
//
// after:
//   <text>test</text>

const normalizeList = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Element.isElement(nodeEntry[0])) {
    return false;
  }

  const [node, path] = nodeEntry;
  if (node.type !== BLOCK_TYPES.BULLET_LIST && node.type !== BLOCK_TYPES.NUMBER_LIST) {
    return false;
  }

  const tool = LIST_TYPES_TO_TOOLS[node.type];
  if (!tool || tools.has(tool)) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: path, match: (el) => Element.isElement(el) && el.type === node.type });
  return true;
};

const LIST_TOOL_NORMALIZERS = [normalizeListItem, normalizeList];

const LIST_FORMAT_NORMALIZERS = [
  mergeWithPreviousList,
  mergeWithNextList,
  handleGoogleDocsSubListFormat,
  insertMissingListItem,
  removeEmptyListItem,
  insertMissingText,
  removeInvalidSubListNesting,
  removeInvalidListItemChildren,
  removeEmptyList,
];

const normalizeListTools = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>): boolean => {
  return LIST_TOOL_NORMALIZERS.some((normalizer) => normalizer(editor, tools, nodeEntry));
};

const normalizeListFormat = (editor: Editor, nodeEntry: NodeEntry<Node>): boolean => {
  return LIST_FORMAT_NORMALIZERS.some((normalizer) => normalizer(editor, nodeEntry));
};

export const normalizeLists = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>): boolean => {
  return normalizeListTools(editor, tools, nodeEntry) || normalizeListFormat(editor, nodeEntry);
};
