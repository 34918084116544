/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { randomUUID } from 'js/lib/uuid';

import { useReadOnlyContext } from 'bundles/authoring/common/components/ReadOnlyContext';
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';
import { type Props } from 'bundles/code-evaluator/components/CodeBlockV2';

export const CodeblockWithReadOnlyHandler: React.FC<Props> = (props) => {
  const readOnly = props.readOnly;
  const [key, setKey] = useState(randomUUID());
  const initialLoad = useRef(true);

  useEffect(() => {
    if (!initialLoad.current) {
      setKey(randomUUID());
    }
    initialLoad.current = false;
  }, [readOnly]);

  return <CodeBlockV2 key={key} {...props} />;
};
export default CodeblockWithReadOnlyHandler;
