import * as React from 'react';
import ReactDOM from 'react-dom';

import { breakpoints, useMediaQuery, useTheme } from '@coursera/cds-core';

import type { Props as DialogProps } from 'bundles/cml/editor/components/elements/math/MathEditorDialog';
import MathEditorEquatio from 'bundles/cml/editor/components/elements/math/MathEditorEquatio';
import MathEditorLarge from 'bundles/cml/editor/components/elements/math/MathEditorLarge';
import MathEditorSmall from 'bundles/cml/editor/components/elements/math/MathEditorSmall';
import { useEquatioOpenState } from 'bundles/cml/editor/components/elements/math/equatioUtils';

type Props = DialogProps & {
  anchorEl: HTMLElement;
  isBlock: boolean;
};

const MathEditor = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const theme = useTheme();
  const breakpoint = breakpoints.down('sm');
  const smallBreakpoint = useMediaQuery(breakpoint);

  // the Equatio API is async and open will be `null` if the request
  // is still pending. in this situation it is better to render nothing
  // rather than rendering our internal Math Editor and then hiding it

  const equatioOpen = useEquatioOpenState();
  if (equatioOpen == null) {
    return null;
  }

  if (equatioOpen) {
    return <MathEditorEquatio {...props} />;
  }

  const MathEditorComponent = smallBreakpoint ? MathEditorSmall : MathEditorLarge;
  const editor = <MathEditorComponent {...props} ref={ref} />;

  return ReactDOM.createPortal(editor, document.body);
});

export default MathEditor;
