/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';
import { TrophyIcon } from '@coursera/cds-icons';

import useSkillPercentileData from 'bundles/skills-common/private/progress-tracker/useSkillPercentileData';
import { isSkillsInsightsInline } from 'bundles/skills-common/private/skillsInsightsEnabled';

import _t from 'i18n!nls/skills-common';

type Props = {
  occupationId?: string;
  skillId: string;
  score: number;
};

const styles = {
  container: css`
    margin: var(--cds-spacing-100) 0 0;
    background: var(--cds-color-callouts-background-tertiary-weak);
    display: flex;
    align-items: center;
    padding: var(--cds-spacing-100) var(--cds-spacing-150);
    gap: var(--cds-spacing-100);
    border-radius: var(--cds-border-radius-50);
  `,
};

const SkillsInsightMessage: React.FC<Props> = ({ occupationId, skillId, score }) => {
  const shouldShowMessage = isSkillsInsightsInline();
  const { skillPercentile, loading } = useSkillPercentileData({
    shouldSkipQuery: !shouldShowMessage || score === 0,
    occupationId: occupationId ?? '',
    skillScores: [
      {
        skillId,
        score,
      },
    ],
  });
  if (!shouldShowMessage || loading) return null;
  const percentile = skillPercentile[0]?.percentile ?? 0;
  if (percentile < 70) return null;

  let message = _t("<b>Top performer:</b> In Coursera's top {percentile}% for this skill");
  if (percentile > 80) {
    message = _t("<b>High Achiever:</b> In Coursera's top {percentile}% for this skill");
  }
  if (percentile > 95) {
    message = _t("<b>Outstanding:</b> In Coursera's top {percentile}% for this skill");
  }

  return (
    <div css={styles.container}>
      <TrophyIcon color="default" size="small" />
      <Typography2 variant="bodySecondary" component="span">
        <FormattedHTMLMessage message={message} percentile={Math.max(1, 100 - percentile)} />
      </Typography2>
    </div>
  );
};

export default SkillsInsightMessage;
