/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useState } from 'react';

import { Button, FullscreenDialog, Typography2, useTheme } from '@coursera/cds-core';
import type { ButtonProps, Theme } from '@coursera/cds-core';
import { ChevronPreviousIcon, SuccessFilledIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import CourseraLiteEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/CourseraLiteEnrollButton';
import SubscriptionTiersCourseraPlusEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersCourseraPlusEnrollButton';
import type { ComparisonProduct } from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/ComparisonTable';
import ComparisonTable from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/ComparisonTable';
import { getFeatureTableModalCopy } from 'bundles/coursera-plus/components/subscriptionTiers/tiersDesignV3/HeroAndCompareAllPlansCopy';
import { courseraPlusProductVariants } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import usePageData from 'bundles/enroll/data/usePageData';
import PageHeader from 'bundles/page-header/components/PageHeader';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const { HeadingGroup, Content, Actions } = FullscreenDialog;

const styles = {
  row: (theme: Theme) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(12)};
    gap: ${theme.spacing(8)};

    &:last-child {
      margin-bottom: ${theme.spacing(48)};

      ${theme.breakpoints.down('xs')} {
        margin-bottom: ${theme.spacing(24)};
      }
    }
  `,
  content: (theme: Theme) => css`
    ${theme.breakpoints.up('xs')} {
      margin-bottom: ${theme.spacing(12)};
    }
  `,
  button: (theme: Theme) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 100%;
    }
  `,
  icon: () => css`
    min-width: 16px;
  `,
  ctaSection: (theme: Theme) => css`
    padding: ${theme.spacing(24)} ${theme.spacing(48)};
  `,
};

export const AllPlansModal = ({ onClose }: { onClose?: () => void }) => {
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState<ComparisonProduct>('PLUS');

  const EnrollButton =
    selectedProduct === 'LITE' ? CourseraLiteEnrollButton : SubscriptionTiersCourseraPlusEnrollButton;
  const additionalProps =
    selectedProduct === 'PLUS_ANNUAL' ? { productItemId: courseraPlusProductVariants.ANNUAL_SEVEN_DAY_FREE_TRIAL } : {};

  const data = usePageData();
  const hasFreeTrialForLite = data.enrollmentAvailableChoices.canSubscribeToCourseraLiteFreeTrial;
  const hasFreeTrialForPlus = data.enrollmentAvailableChoices.canSubscribeToCourseraPlusFreeTrial;
  const copy = getFeatureTableModalCopy({ hasFreeTrialForLite, hasFreeTrialForPlus });

  return (
    <FullscreenDialog
      aria-describedby="dialog-content"
      open={true}
      sticky="actions"
      onClose={close}
      className="rc-AllPlansModal"
      contentWidth="65%"
      disableFocusLock
      css={css`
        .cds-Modal-container {
          transition: none !important;
        }

        .cds-Modal-backdrop {
          display: none;
        }

        .cds-FullscreenDialog-dialog > hr {
          display: none;
        }
      `}
    >
      <PageHeader
        isPageWithSecondaryCta={false}
        logoWrapper="div"
        hideSearch={true}
        hideEnterprise={true}
        hideNotificationCenter={true}
        showShoppingCart={false}
      />
      <Content>
        <TrackedButton
          trackingName="subscription_tiers_all_plans_back_button"
          icon={<ChevronPreviousIcon />}
          iconPosition="before"
          size="small"
          variant="ghost"
          css={css`
            margin-top: ${theme.spacing(32)};
          `}
          onClick={onClose}
        >
          {copy.back}
        </TrackedButton>
      </Content>
      <HeadingGroup
        heading={copy.pageHeader}
        css={css`
          padding-bottom: ${theme.spacing(12)};
        `}
      />
      <Content css={styles.content}>
        <div>
          <div css={styles.row}>
            <SuccessFilledIcon color="interactive" size="small" css={styles.icon} />
            <Typography2 component="p" color="supportText">
              {copy.valueProp1}
            </Typography2>
          </div>
          <div css={styles.row}>
            <SuccessFilledIcon color="interactive" size="small" css={styles.icon} />
            <Typography2 component="p" color="supportText">
              {copy.valueProp2}
            </Typography2>
          </div>
          <div css={styles.row}>
            <SuccessFilledIcon color="interactive" size="small" css={styles.icon} />
            <Typography2 component="p" color="supportText">
              {copy.valueProp3}
            </Typography2>
          </div>
        </div>

        <ComparisonTable onSelect={setSelectedProduct} />
      </Content>
      <Actions css={styles.ctaSection}>
        <EnrollButton
          css={styles.button}
          size="medium"
          variant="primary"
          trackingName="subscription_tiers_all_plans_modal_cta"
          {...additionalProps}
        >
          {(selectedProduct === 'LITE' && !hasFreeTrialForLite) ||
          (['PLUS', 'PLUS_ANNUAL'].includes(selectedProduct) && !hasFreeTrialForPlus)
            ? copy.exhaustedCTA
            : copy.trialCTA}
        </EnrollButton>
      </Actions>
    </FullscreenDialog>
  );
};

export default AllPlansModal;
