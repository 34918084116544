/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_course_CertificateType {
  ACE = "ACE",
  SoA = "SoA",
  Specialization = "Specialization",
  VerifiedCert = "VerifiedCert",
}

export enum org_coursera_course_CourseStatus {
  draft = "draft",
  inactive = "inactive",
  launched = "launched",
  preenroll = "preenroll",
}

export enum org_coursera_course_PremiumExperienceVariant {
  BaseVariant = "BaseVariant",
  PremiumCourse = "PremiumCourse",
  PremiumGrading = "PremiumGrading",
}

export enum org_coursera_enterprise_contract_tag_EnterpriseContractTag {
  C4C = "C4C",
  C4C_CORONA_TRIAL = "C4C_CORONA_TRIAL",
  C4C_FACULTY = "C4C_FACULTY",
  C4C_FREEMIUM = "C4C_FREEMIUM",
  C4C_GP_GOOG = "C4C_GP_GOOG",
  C4C_MOROCCO = "C4C_MOROCCO",
  C4C_PARTNER_CONSORTIUM = "C4C_PARTNER_CONSORTIUM",
  C4C_UKRAINE = "C4C_UKRAINE",
  C4C_UPSWELL = "C4C_UPSWELL",
  C4ER_PORTUGUESE = "C4ER_PORTUGUESE",
  C4ER_SPANISH = "C4ER_SPANISH",
  C4FW_ENGLISH = "C4FW_ENGLISH",
  C4FW_FRENCH = "C4FW_FRENCH",
  C4FW_GERMAN = "C4FW_GERMAN",
  C4FW_PORTUGUESE = "C4FW_PORTUGUESE",
  C4FW_SPANISH = "C4FW_SPANISH",
  C4FW_ARABIC = "C4FW_ARABIC",
  C4P = "C4P",
  C4T_FREE_TRIAL = "C4T_FREE_TRIAL",
  C4WR_CORONA_TRIAL = "C4WR_CORONA_TRIAL",
  CAREER_ACADEMY_FULL = "CAREER_ACADEMY_FULL",
  CAREER_ACADEMY_LIMITED = "CAREER_ACADEMY_LIMITED",
  COURSERA_CORE = "COURSERA_CORE",
  ENT_WD = "ENT_WD",
  ENT_WD_GP = "ENT_WD_GP",
  GCP_CLOUD_LEADERS = "GCP_CLOUD_LEADERS",
  GCP_CLOUD_SELECT = "GCP_CLOUD_SELECT",
  GCP_KICKSTART = "GCP_KICKSTART",
  GCP_RESELLER = "GCP_RESELLER",
  GOOGLE_SCHOLARSHIP = "GOOGLE_SCHOLARSHIP",
  GROW_WITH_GOOGLE = "GROW_WITH_GOOGLE",
  GWG_CC = "GWG_CC",
  GWG_ENTERPRISE = "GWG_ENTERPRISE",
  GWG_SMB = "GWG_SMB",
  GWG_E2E = "GWG_E2E",
  IBM_KICKSTART_COMB = "IBM_KICKSTART_COMB",
  IBM_KICKSTART_IND = "IBM_KICKSTART_IND",
  IBM_STANDARD = "IBM_STANDARD",
  INDUSTRY_PROF_CERTS = "INDUSTRY_PROF_CERTS",
  DATA_TECH_ACADEMY = "DATA_TECH_ACADEMY",
  LEADERSHIP_LITE = "LEADERSHIP_LITE",
  META_SCHOLARSHIP = "META_SCHOLARSHIP",
  PRIVATE_CONTENT_ONLY = "PRIVATE_CONTENT_ONLY",
  SOCIAL_IMPACT = "SOCIAL_IMPACT",
  GEN_AI_PROMO = "GEN_AI_PROMO",
  GEN_AI = "GEN_AI",
  PWC_RESELLER = "PWC_RESELLER",
  GEN_AI_THOUGHT_PARTNER_PROMO = "GEN_AI_THOUGHT_PARTNER_PROMO",
}


export enum org_coursera_enterprise_contract_migration_ContractMigrationStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESSFUL = "SUCCESSFUL",
}

export enum org_coursera_payment_financialaid_FinancialAidApplicationState {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  WITHDRAWN = "WITHDRAWN",
}

export enum org_coursera_payment_transaction_PaymentTransactionActionType {
  CHARGEBACK = "CHARGEBACK",
  PARTIAL_REFUND = "PARTIAL_REFUND",
  PAYMENT = "PAYMENT",
  QUERY = "QUERY",
  REFUND = "REFUND",
  VOID = "VOID",
}

export enum org_coursera_product_BillingCycle {
  ANNUAL = "ANNUAL",
  BIANNUAL = "BIANNUAL",
  MONTHLY = "MONTHLY",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractBillingType {
  INVOICE = "INVOICE",
  NONE = "NONE",
  ONE_TIME = "ONE_TIME",
  QUARTERLY = "QUARTERLY",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractState {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  PAUSED = "PAUSED",
  TERMINATED = "TERMINATED",
}

export enum org_coursera_product_enterprise_contract_EnterpriseContractType {
  SELF_SERVE = "SELF_SERVE",
  STANDARD = "STANDARD",
}

export enum org_coursera_product_enterprise_contract_limits_EnterpriseContractLimitType {
  ENROLLMENT_CAP = "ENROLLMENT_CAP",
  NO_LIMIT = "NO_LIMIT",
  USER_LICENSE = "USER_LICENSE",
  USER_LICENSE_WITH_ENROLLMENT_CAP = "USER_LICENSE_WITH_ENROLLMENT_CAP",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
