/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum org_coursera_course_CertificateType {
  ACE = "ACE",
  SoA = "SoA",
  Specialization = "Specialization",
  VerifiedCert = "VerifiedCert",
}

export enum org_coursera_course_CourseStatus {
  draft = "draft",
  inactive = "inactive",
  launched = "launched",
  preenroll = "preenroll",
}

export enum org_coursera_course_PremiumExperienceVariant {
  BaseVariant = "BaseVariant",
  PremiumCourse = "PremiumCourse",
  PremiumGrading = "PremiumGrading",
}

export enum org_coursera_payment_transaction_PaymentTransactionActionType {
  CHARGEBACK = "CHARGEBACK",
  PARTIAL_REFUND = "PARTIAL_REFUND",
  PAYMENT = "PAYMENT",
  QUERY = "QUERY",
  REFUND = "REFUND",
  VOID = "VOID",
}

export enum org_coursera_product_BillingCycle {
  ANNUAL = "ANNUAL",
  BIANNUAL = "BIANNUAL",
  MONTHLY = "MONTHLY",
}

export enum org_coursera_program_enrollment_UserProductState {
  DOWNGRADED = "DOWNGRADED",
  ENROLLED = "ENROLLED",
  SELECTED = "SELECTED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
