/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/widget-admin';

export const NAME_COLUMN_WIDTH = 450;
export const DATE_COLUMN_WIDTH = 250;

const styles = {
  root: (theme: Theme) =>
    css({
      height: 50,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(32),
    }),
  name: () =>
    css({
      width: NAME_COLUMN_WIDTH,
    }),
  date: (theme: Theme) =>
    css({
      width: DATE_COLUMN_WIDTH,
      paddingLeft: theme.spacing(16),
    }),
};

const WidgetSelectionListHeader: React.FC = () => (
  <header css={styles.root}>
    <Typography2 component="h3" variant="subtitleMedium" css={styles.name}>
      {_t('Name')}
    </Typography2>
    <Typography2 component="h3" variant="subtitleMedium" css={styles.date}>
      {_t('Last Updated')}
    </Typography2>
  </header>
);

export default WidgetSelectionListHeader;
