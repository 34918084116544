import type { RefObject } from 'react';
import * as React from 'react';
import { Component } from 'react';
import Measure, { type ContentRect } from 'react-measure';

import { compose, pure } from 'recompose';

import { Button } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';
import type { ExpandableProps } from '@coursera/coursera-ui';
import { Expandable } from '@coursera/coursera-ui';

import _t from 'i18n!nls/coursera-ui';

type PropsFromCaller = {
  moreLessButtonContextLabel?: string;
  focusRef?: RefObject<HTMLElement>;
};

type Props = {
  expandableProps: ExpandableProps;
} & PropsFromCaller;

type State = {
  isOpened: boolean;
  contentContainerHeight: number;
};

type PropsForMoreLessButton = {
  isOpened: boolean;
  isThemeDark?: boolean;
  contextLabel?: string;
};

const MoreLessButton = ({ isOpened, contextLabel }: PropsForMoreLessButton) => {
  return (
    <Button
      variant="ghost"
      aria-expanded={isOpened ? 'true' : 'false'}
      aria-roledescription={_t('Collapsible toggle button')}
      data-test="TogglableContentFooter"
      icon={isOpened ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />}
      iconPosition="after"
      aria-label={
        isOpened
          ? _t('Less about #{contextLabel}', { contextLabel })
          : _t('More about #{contextLabel}', { contextLabel })
      }
      size="small"
    >
      {isOpened ? _t('Less') : _t('More')}
    </Button>
  );
};

// TODO(Audrey): figure out a way to locate the component
/**
 * A Togglable container that is based on expandable
 * Pass expandableProps to further customize the look and behavior
 * Sample usage:
 *  <TogglableContent
      expandableProps={{
      defaultContentHeight: 68,
      isThemeDark: true
    }}
    >
      {loremText}
    </TogglableContent>
 */
class TogglableContent extends Component<Props, State> {
  _defaultExpandableProps: ExpandableProps;

  _defaultContentHeight: number;

  static defaultProps = {
    expandableProps: {},
  };

  constructor(props: Props, context: unknown) {
    super(props, context);
    // Make sure to overwrite below setting through expandableProps
    // if you wish to customize the component more
    this._defaultExpandableProps = {
      hideBorder: true,
      hideArrow: true,
      hideHeader: true,
      id: 'TogglableContent',
      isOpened: false,
      isThemeDark: false,
      isFullBleed: true,
    };

    this._defaultContentHeight = props.expandableProps.defaultContentHeight || 64;

    this.state = {
      isOpened: !!props.expandableProps.isOpened,
      // Initialize contentContainerHeight with defaultContentHeight
      contentContainerHeight: this._defaultContentHeight,
    };
  }

  onToggle = () => {
    const { focusRef } = this.props;
    this.setState((state) => ({ isOpened: !state.isOpened }));

    if (focusRef?.current && !this.state.isOpened) {
      focusRef.current.setAttribute('tabIndex', '0');
      focusRef.current.focus();
    }
  };

  render() {
    const { children, expandableProps, moreLessButtonContextLabel } = this.props;
    const { isOpened, contentContainerHeight } = this.state;
    const isThemeDark = expandableProps.isThemeDark;
    // Only show footer when there is more content to display
    const showFooter = contentContainerHeight > this._defaultContentHeight;

    return (
      <Expandable
        {...this._defaultExpandableProps}
        {...expandableProps}
        defaultContentHeight={this._defaultContentHeight}
        onToggle={this.onToggle}
        footer={
          showFooter && (
            <MoreLessButton isOpened={isOpened} isThemeDark={isThemeDark} contextLabel={moreLessButtonContextLabel} />
          )
        }
      >
        <Measure
          bounds
          onResize={({ bounds }: ContentRect) => this.setState({ contentContainerHeight: bounds!.height })}
        >
          {({ measureRef }) => <div ref={measureRef}>{children}</div>}
        </Measure>
      </Expandable>
    );
  }
}

export default compose<Props, PropsFromCaller>(pure)(TogglableContent);
