/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import _t from 'i18n!nls/widget-admin';

const styles = {
  form: (theme: Theme) =>
    css({
      display: 'inline-block',
      marginTop: theme.spacing(12),
      width: '100%',
    }),
  input: (theme: Theme) =>
    css({
      ...typography2.bodyPrimary,
      borderRadius: theme.spacing(4),
      border: `1px solid ${theme.palette.gray[700]}`,
      height: 48,
      marginBottom: 6,
      padding: theme.spacing(16, 8),
      width: '100%',
    }),
};

type Props = {
  handleSearch: (x0: string) => void;
};

const WidgetSelectionSearch: React.FC<Props> = ({ handleSearch }) => {
  const onSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const keyword = (event.target as HTMLInputElement).value;

      handleSearch(keyword.toLowerCase());
    },
    [handleSearch]
  );

  return (
    <div>
      <form css={styles.form}>
        <input type="text" placeholder={_t('Search available plugins')} onChange={onSearch} css={styles.input} />
      </form>
    </div>
  );
};

export default WidgetSelectionSearch;
