/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import config from 'js/app/config';
import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import redirect from 'js/lib/coursera.redirect';

import { Button, Typography2, useTheme } from '@coursera/cds-core';

import ErrorPage from 'bundles/coursera-ui/components/rich/ErrorPage';
import type { InjectedNaptime } from 'bundles/naptimejs';
import ProgramSwitcherSelectionsV1 from 'bundles/naptimejs/resources/programSwitcherSelections.v1';

import _t from 'i18n!nls/program-home';

type Props = {
  naptime?: InjectedNaptime;
  userId?: number;
};

export function ProgramArchivedDenyModal({ userId, naptime }: Props) {
  const theme = useTheme();
  const styles = {
    programArchivedDenyModal: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 360px;
      max-width: 100%;
      padding: ${theme.spacing(32)};
      padding-bottom: 0;
      background-color: var(--cds-color-white-0);
    `,

    buttonsWrapper: css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: ${theme.spacing(32)};
      margin-bottom: ${theme.spacing(32)};
    `,
  };

  const onBrowse = () => {
    if (userId && naptime) {
      naptime
        .executeMutation(
          ProgramSwitcherSelectionsV1.update(userId, {
            selectionType: 'COURSERA',
          })
        )
        .done(() => redirect.setLocation('/browse'));
    } else {
      redirect.setLocation('/browse');
    }
  };

  return (
    <ErrorPage>
      <div css={styles.programArchivedDenyModal}>
        <Typography2 component="p">
          <FormattedHTMLMessage
            message={_t(
              'Unfortunately your program is not currently available to all learners in the program. For more details please reach out to your Coursera learning administrator - this is the person who likely invited you to the program or manages learning and development at your company. Rest assured that any course progress you have are saved, and you will be able to continue where you left off once your program access is restored. If that is not an option, you can also purchase courses on your own to continue at {link}'
            )}
            link={<a href={config.url.base}>www.coursera.org</a>}
          />
        </Typography2>
        <div css={styles.buttonsWrapper}>
          <Button variant="secondary" onClick={onBrowse} css={{ marginRight: theme.spacing(16) }}>
            {_t('Browse Coursera')}
          </Button>
          <Button variant="primary" component="a" href="/enterprise" target="_blank" rel="noopener noreferrer">
            {_t('Enterprise')}
          </Button>
        </div>
      </div>
    </ErrorPage>
  );
}

export default ProgramArchivedDenyModal;
