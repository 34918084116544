import * as React from 'react';
import { Component } from 'react';

import URI from 'jsuri';

import * as redirect from 'js/lib/coursera.redirect';

import API from 'bundles/phoenix/lib/apiWrapper';

import _t from 'i18n!nls/program-home';

type OuterProps = {
  thirdPartyOrganizationId: string;
};

class BypassLogin extends Component<OuterProps> {
  componentDidMount() {
    const { thirdPartyOrganizationId } = this.props;
    const params = new URI(redirect.getQueryParams()).deleteQueryParam('attemptSSOLogin');

    const uri = new URI()
      .addQueryParam('action', 'initiateLoginFlow')
      .addQueryParam('id', thirdPartyOrganizationId)
      .addQueryParam('returnTo', redirect.getPathname() + params.toString());

    API('/api/thirdPartyOrganizations.v1')
      .post(uri.toString())
      .then((res: string) => {
        window.location.href = res;
      });
  }

  render() {
    return (
      <div>
        <p>{_t('Redirecting...')}</p>
      </div>
    );
  }
}

export default BypassLogin;
