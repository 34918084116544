/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import user from 'js/lib/user';

import { Typography2, useTheme } from '@coursera/cds-core';
import { ArrowNextIcon, CheckIcon } from '@coursera/cds-icons';
import { breakPoint } from '@coursera/coursera-ui';

import { getLinkTarget } from 'bundles/enterprise-legacy-xdp/components/experiments/enterprise-promo-units/common';
import withEnterprisePromoWrapper from 'bundles/enterprise-legacy-xdp/components/experiments/enterprise-promo-units/withEnterprisePromoWrapper';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/program-common';

type Props = {
  isMobile?: boolean;
  iconColor?: string;
};

export function EnterprisePromoUnitSidebar({ isMobile, iconColor }: Props) {
  const theme = useTheme();
  const styles = {
    root: css`
      display: flex;
      flex-direction: row;
      padding-left: 1.5rem;
      border-top: 1px solid #e3e4e5;
      padding-top: 40px;

      @media (max-width: ${breakPoint.lg}px) {
        padding-left: 0;
        padding-top: ${theme.spacing(32)};
      }
    `,
    iconPanel: css`
      margin: 0 1rem 0.5rem 0.5rem;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 2px solid rgb(225 225 225);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    `,
    content: css`
      display: flex;
      flex-direction: column;
    `,
    headline: css`
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: bold;
      margin-bottom: ${theme.spacing(32)};
    `,
    cta: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      color: #0056d2;
      border: 1px solid #0056d2;
      padding: 12px 32px;
      border-radius: 4px;
      width: 274px;

      &:hover {
        background: #eee;
        text-decoration: none;
      }

      svg {
        margin-left: 8px;
      }
    `,

    hideOnLarge: {
      [`@media (min-width: ${breakPoint.lg + 1}px)`]: {
        display: 'none',
      },
    },
    hideOnSmall: {
      [`@media (max-width: ${breakPoint.lg}px)`]: {
        display: 'none',
      },
    },
  };

  const linkTarget = getLinkTarget('c4b-xdp-m1', user.isAuthenticatedUser());

  return (
    <TrackedDiv
      trackingName="c4b_xdp_m1"
      trackClicks={false}
      withVisibilityTracking
      css={[styles.root, isMobile ? styles.hideOnLarge : styles.hideOnSmall]}
    >
      <div css={styles.iconPanel}>
        <CheckIcon css={{ color: iconColor }} />
      </div>
      <div css={styles.content}>
        <Typography2 component="p" css={styles.headline}>
          {_t('Could your company benefit from training employees on in-demand skills?')}
        </Typography2>
        <TrackedLink2 href={linkTarget} trackingName="c4b_xdp_m1" css={styles.cta}>
          {_t('Try Coursera for Business')}
          <ArrowNextIcon />
        </TrackedLink2>
      </div>
    </TrackedDiv>
  );
}

export default withEnterprisePromoWrapper(EnterprisePromoUnitSidebar);
