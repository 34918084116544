import * as React from 'react';

import flatMap from 'lodash/flatMap';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';
import { LONG_MONTH_YEAR_DISPLAY, SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { color, font } from '@coursera/coursera-ui';

import { isPlusMonthlyToAnnualUpgradeEnabledOnXDP } from 'bundles/coursera-plus/utils/plusMonthlyToAnnualUpgradeUtils';
import StartDateString from 'bundles/enroll-course/components/StartDateString';
import FinaidLink from 'bundles/enroll/components/finaid/FinaidLink';
import type { PropsFromCaller as BannerEnrollTitleComponentProps } from 'bundles/enroll/components/xdp/MultipleLearningProgramsLink';
import MultipleLearningProgramsLink from 'bundles/enroll/components/xdp/MultipleLearningProgramsLink';
import type { EnrollmentChoices } from 'bundles/enroll/components/xdp/withEnrollment';
import { PRE_ENROLLMENT_FLOW_VARIANTS } from 'bundles/enroll/constants/constants';
import partnerAccentColors from 'bundles/enroll/data/xdp/partnerAccentColors';
import type { Product, ProductId } from 'bundles/enroll/types/productTypes';
import type { PartnerType } from 'bundles/enterprise-legacy-xdp/components/banner/Partner';
import paymentsExperiments from 'bundles/epic/clients/payments';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import type ProductPricesV3 from 'bundles/naptimejs/resources/productPrices.v3';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { freeTrial } from 'bundles/payments/common/constants';
import type { FinancialAidApplicationsByUserAndProduct_FinancialAidApplicationsV2Resource_byUserAndProduct_elements as FinancialAidApplicationByUserAndProduct } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsByUserAndProduct';
import type { FinancialAidApplicationsWithUserAndProducts_FinancialAidApplicationsV2Resource_byUserAndProductIds_elements as FinancialAidApplication } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/FinancialAidApplicationsWithUserAndProducts';
import type { OnDemandSpecializations_OnDemandSpecializationsV1Resource_get_courses_elements as FinancialAidCourse } from 'bundles/payments/components/finaid-2021/enhancers/__generated__/OnDemandSpecializations';
import type { Course } from 'bundles/program-common/types/xdpTypes';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import {
  ExternalCertificateS12n,
  NormalS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import { getShareCertificationDescription } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

const getAuditText = (enrollmentAvailableChoices?: EnrollmentChoices): string | undefined => {
  if (enrollmentAvailableChoices?.canAuditCourse && !enrollmentAvailableChoices?.canEnrollThroughCourseraPlus) {
    return _t(
      'You can audit this course for free. When you audit a course, you can access the course materials without graded assignments or the ability to earn a certificate.'
    );
  }

  return undefined;
};

const getBenefits = (s12nProductVariant: S12nProductVariant): Array<{ description: string }> => {
  return [{ description: getShareCertificationDescription(s12nProductVariant) }];
};

// Cases where isEnrolled = false and isSpecializationSubscribed = true
// 1. User subscribes to a s12n and is auto enrolled in the 1st course.
//    User is s12n subscribed but not enrolled for the 2nd/3rd/nth course of the s12n.
// 2. User subscribes to a s12n and then unenrolls from the s12n through the dashboard.
//    User can't re-subscribe to enroll until the subscription lapses.
const getIsEnrolled = ({
  enrollmentAvailableChoices,
  isSpecialization,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
  isSpecialization: boolean;
}): boolean => {
  return enrollmentAvailableChoices
    ? enrollmentAvailableChoices.isEnrolled ||
        (isSpecialization && enrollmentAvailableChoices.isSpecializationSubscribed)
    : false;
};

export const getIsS12nPreEnrollmentEnabled = ({
  s12n,
  s12ns,
}: {
  s12n?: OnDemandSpecializationsV1;
  s12ns?: Array<OnDemandSpecializationsV1>;
}): boolean => {
  // This is for on the SDP, checking if the s12n is pre enroll enabled (and not launched already)
  if (s12n) {
    return Boolean(s12n.preEnrollmentEnabledAt) && !s12n.launchedAt;
  }

  // This is for SCDP, checking if all of the parent s12ns are pre enroll enabled
  if (s12ns && s12ns.length > 0) {
    return s12ns.every(({ preEnrollmentEnabledAt, launchedAt }) => Boolean(preEnrollmentEnabledAt) && !launchedAt);
  }

  return false;
};

const getPreEnrollmentFlowVariant = ({
  enrollmentAvailableChoices,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
}) => {
  if (enrollmentAvailableChoices?.isPreEnrolled) {
    return PRE_ENROLLMENT_FLOW_VARIANTS.isEnrolled;
  }

  if (enrollmentAvailableChoices?.canPreEnroll || enrollmentAvailableChoices?.isNotEligibleToPreEnroll) {
    return PRE_ENROLLMENT_FLOW_VARIANTS.creditCard;
  }

  return undefined;
};

// Pre-enroll button is disabled if the button should be showing pre-enrollment
// (there is no other more immediate enrollment options) but experiment has the button
// disabled
const getIsPreEnrollButtonDisabled = ({
  enrollmentAvailableChoices,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
}): boolean => {
  if (enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns) {
    return enrollmentAvailableChoices?.isNotEligibleToPreEnroll || paymentsExperiments.preview('disablePreEnrollment');
  }
  return false;
};

const getIsEnrollButtonDisabled = ({
  enrollmentAvailableChoices,
  isSpecialization,
  course,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
  isSpecialization: boolean;
  course: Course;
}): boolean => {
  const isEnrolled = getIsEnrolled({ enrollmentAvailableChoices, isSpecialization });
  const isPreEnrollButtonDisabled = getIsPreEnrollButtonDisabled({
    enrollmentAvailableChoices,
  });
  return isPreEnrollButtonDisabled || (isEnrolled && !!course.isPreEnroll);
};

const getEnrollButtonTrackingName = ({
  enrollmentAvailableChoices,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
}): string | undefined => {
  if (enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns) {
    return 'pre_enroll_button';
  }
  return undefined;
};

const shouldShowEnrollForFreeText = ({
  enrollmentAvailableChoices,
  course,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
  course?: Course;
}) => {
  return Boolean(
    !course?.isClosedCourse &&
      (!user.isAuthenticatedUser() ||
        (!!enrollmentAvailableChoices &&
          (enrollmentAvailableChoices.canEnrollWithFreeTrial ||
            enrollmentAvailableChoices.canAuditCourse ||
            enrollmentAvailableChoices.hasFreeEnrollOptionIntoCourse ||
            enrollmentAvailableChoices.canEnrollThroughCourseraPlus)))
  );
};

const getEnrollButtonLabel = ({
  enrollmentAvailableChoices,
  isSpecialization,
  course,
  preEnrollmentFlowVariant,
  s12nId,
  s12n,
  s12ns,
  isEligibleForPlusMonthlyToAnnualUpgrade,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
  isSpecialization: boolean;
  course: Course;
  preEnrollmentFlowVariant?: string;
  s12nId?: string;
  s12n?: OnDemandSpecializationsV1;
  s12ns?: Array<OnDemandSpecializationsV1>;
  pathname?: string;
  isEligibleForPlusMonthlyToAnnualUpgrade?: boolean;
}): JSX.Element | string => {
  const isEnrolled = getIsEnrolled({ enrollmentAvailableChoices, isSpecialization });
  const hasEarnedS12nCert = enrollmentAvailableChoices && enrollmentAvailableChoices.hasEarnedS12nCertificate;
  const isUserLoggedIn = user.isAuthenticatedUser();
  const disablePreEnrollment = paymentsExperiments.preview('disablePreEnrollment');
  const showAnnualUpgradeOnXDP = isEligibleForPlusMonthlyToAnnualUpgrade && isPlusMonthlyToAnnualUpgradeEnabledOnXDP();

  if (!isUserLoggedIn && getIsS12nPreEnrollmentEnabled({ s12n, s12ns })) {
    // Get the plannedLaunchDate from either the s12n or the first parent s12n
    const plannedLaunchDate = s12n?.plannedLaunchDate || s12ns?.[0]?.plannedLaunchDate;
    return (
      <div>
        {_t('Pre-enroll now')}
        {plannedLaunchDate && (
          <div
            className="rc-StartDateString font-xs startdate"
            style={{ fontWeight: 'normal' }}
            data-test="start-date-string"
          >
            {_t('Starts #{launchDay}', {
              launchDay: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
            })}
          </div>
        )}
      </div>
    );
  } else if (preEnrollmentFlowVariant === PRE_ENROLLMENT_FLOW_VARIANTS.isEnrolled) {
    const preEnrolledIds = enrollmentAvailableChoices?.preEnrolledS12nIds;
    const plannedLaunchDate = enrollmentAvailableChoices?.getPlannedLaunchDateForS12n(preEnrolledIds?.[0]);
    return (
      <div>
        {_t(`You're pre-enrolled`)}
        {
          /* Don't show planned launch date if there are multiple preenrolled s12ns */
          plannedLaunchDate && (preEnrolledIds?.length || 0) <= 1 && (
            <div
              className="rc-StartDateString font-xs startdate"
              style={{ fontWeight: 'normal' }}
              data-test="start-date-string"
            >
              {_t('Starts #{launchDay}', {
                launchDay: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
              })}
            </div>
          )
        }
      </div>
    );
  } else if (preEnrollmentFlowVariant && enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns) {
    // Can only pre enroll and not pre-enrolled already in any s12n
    const plannedLaunchDate = enrollmentAvailableChoices?.getPlannedLaunchDateForS12n(s12nId);
    if (disablePreEnrollment || enrollmentAvailableChoices?.isNotEligibleToPreEnroll) {
      // Not eligible to pre-enroll
      return (
        <div>
          {plannedLaunchDate &&
            _t('Launches #{launchMonthYear}', {
              launchMonthYear: formatDateTimeDisplay(plannedLaunchDate, LONG_MONTH_YEAR_DISPLAY),
            })}
        </div>
      );
    } else if ((enrollmentAvailableChoices?.preEnrollEligibleS12nIds?.length || 0) > 1) {
      // Eligible to pre-enroll but there are multiple pre-enrollment choices
      return (
        <div>
          {_t('Pre-enroll & get #{freeTrialDays} days free', {
            freeTrialDays: freeTrial.preEnrollmentNumDays,
          })}
        </div>
      );
    } else {
      // Eligible to pre-enroll with only one s12n
      return (
        <div>
          {_t('Pre-enroll now')}
          {plannedLaunchDate && (
            <div
              className="rc-StartDateString font-xs startdate"
              style={{ fontWeight: 'normal' }}
              data-test="start-date-string"
            >
              {_t('Starts #{launchDay}', {
                launchDay: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
              })}
            </div>
          )}
        </div>
      );
    }
  } else if (!isUserLoggedIn) {
    const showFreeText = !course?.isClosedCourse;
    let message;
    if (showFreeText) {
      message = _t('Enroll for Free');
    } else {
      message = _t('Enroll');
    }

    return (
      <div>
        <span data-test="enroll-button-label">{message}</span>
        {course && <StartDateString courseId={course.id} />}
      </div>
    );
  } else if (isEnrolled && !course.isPreEnroll) {
    return (
      <div>
        {_t('Go To Course')}
        {showAnnualUpgradeOnXDP && isEnrolled && (
          <div className="monthly-to-annual-cplus-subtext"> {_t('Already enrolled')} </div>
        )}
      </div>
    );
  } else if (isEnrolled && course.isPreEnroll) {
    return <StartDateString className="m-b-1s" courseId={course.id} />;
  } else if (hasEarnedS12nCert) {
    return _t('Get Certificate');
  } else {
    const showFreeText = shouldShowEnrollForFreeText({ enrollmentAvailableChoices, course });
    let enrollButtonCopy;
    if (showAnnualUpgradeOnXDP) {
      enrollButtonCopy = _t('Enroll');
    } else if (showFreeText) {
      enrollButtonCopy = _t('Enroll for Free');
    } else {
      enrollButtonCopy = _t('Enroll');
    }
    return (
      <div>
        <span data-test="enroll-button-label">{enrollButtonCopy}</span>
        {!showAnnualUpgradeOnXDP && course && <StartDateString courseId={course.id} />}
        {showAnnualUpgradeOnXDP && (
          <div className="monthly-to-annual-cplus-subtext">{_t('Included with Coursera Plus')}</div>
        )}
      </div>
    );
  }
};

type GetFinaidLinkParams = ProductId &
  Product & {
    isCourseraPlusEligibleProduct: boolean;
    ownsCourseraPlus?: boolean;
    ownsCourseraLite?: boolean;
    isPrimaryText?: boolean;
    preEnrollmentFlowVariant?: string;
    enrollmentAvailableChoices?: EnrollmentChoices;
    canOpenFinAidOnMount?: boolean;
    courseIds?: string[];
  };

const getFinaidLink = ({
  isCourseraPlusEligibleProduct,
  ownsCourseraPlus,
  ownsCourseraLite,
  isPrimaryText,
  preEnrollmentFlowVariant,
  enrollmentAvailableChoices,
  canOpenFinAidOnMount,
  courseIds,
  ...productId
}: GetFinaidLinkParams): JSX.Element | undefined => {
  // Surpress the financial aid if user is in the pre enrollment flow
  if (preEnrollmentFlowVariant && enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns) {
    return undefined;
  }
  // Don't mention financial aid if user is subscribed to Plus or Lite, and viewing Plus content
  else if ((ownsCourseraPlus || ownsCourseraLite) && isCourseraPlusEligibleProduct) {
    return undefined;
  }

  const wrapperStyle = isPrimaryText ? { fontSize: font.sm, color: color.primary, fontWeight: 700 } : {};

  return (
    <span style={wrapperStyle}>
      <FinaidLink
        {...productId}
        courseIds={courseIds}
        enrollmentAvailableChoices={enrollmentAvailableChoices}
        canOpenFinAidOnMount={canOpenFinAidOnMount}
        applyText={_t('Financial aid available')}
        alreadyAppliedText=""
        wrapperClass="reset"
      />
    </span>
  );
};

const getPartnersLogo = (partners: Array<PartnerType> = []): string | undefined => {
  if (partners[0]) {
    const { rectangularLogo, squareLogo } = partners[0];
    return (rectangularLogo || squareLogo) ?? undefined;
  }
  return undefined;
};

const getPartnerAccentColor = (partners: Array<PartnerType> = []): string | undefined => {
  if (partners[0]) {
    const partner = partners[0];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const partnerAccentColor = partner && partnerAccentColors[partner.shortName];
    return partnerAccentColor ?? undefined;
  }
  return undefined;
};

const certificateValueProp = (isSpecialization: boolean): string => {
  return isSpecialization ? _t('Shareable Specialization and Course Certificates') : _t('Shareable Certificates');
};

const s12nCourseValueProp = (isSpecialization: boolean): string | null => {
  return isSpecialization ? null : _t('This Course Plus the Full Specialization');
};

const getProfessionalCertificateValueProps = (shouldShowFreeTrial: boolean): Array<string> => [
  _t('Taught by top companies and universities'),
  ...(shouldShowFreeTrial ? [_t('Affordable programs and 7 day free trial')] : [_t('Affordable programs')]),
  _t('Apply your skills with hands-on projects'),
  _t('Learn on your own schedule'),
  _t('Course videos and readings'),
  _t('Graded quizzes and assignments'),
  _t('No degree or experience required for many programs'),
  _t('Shareable Certificate upon completion'),
];

const getCourseOrS12nValueProps = (isSpecialization: boolean): Array<string> => {
  const valueProps: Array<string> = [];
  const s12nCourseProp = s12nCourseValueProp(isSpecialization);

  if (s12nCourseProp) {
    valueProps.push(s12nCourseProp);
  }

  return valueProps.concat([
    certificateValueProp(isSpecialization),
    _t('Self-Paced Learning Option'),
    _t('Course Videos & Readings'),
    _t('Practice Quizzes'),
    _t('Graded Assignments with Peer Feedback'),
    _t('Graded Quizzes with Feedback'),
    _t('Graded Programming Assignments'),
  ]);
};

const getPriceString = ({
  multiS12nDerivatives,
  s12nDerivative,
  productPrice,
}: {
  multiS12nDerivatives?: Array<S12nDerivativesV1>;
  s12nDerivative?: S12nDerivativesV1;
  productPrice?: ProductPricesV3;
}): JSX.Element | undefined => {
  let priceString;

  // With mix and match, can get multiple prices for each s12n option
  const multiPrices =
    multiS12nDerivatives &&
    multiS12nDerivatives
      .map((s12nDerivatives: $TSFixMe) => s12nDerivatives.catalogPrice)
      .sort((price1: $TSFixMe, price2: $TSFixMe) => price1.amount - price2.amount);
  const highestPrice = multiPrices && multiPrices[multiPrices.length - 1];
  const lowestPrice = multiPrices && multiPrices[0];

  // only show a price range if there are different prices
  if (highestPrice && lowestPrice && highestPrice.amount - lowestPrice.amount > 0) {
    priceString = (
      <span>
        <ReactPriceDisplay value={lowestPrice.amount} currency={lowestPrice.currencyCode} hideCurrencyCode={true} />
        &nbsp;-&nbsp;
        <ReactPriceDisplay value={highestPrice.amount} currency={highestPrice.currencyCode} hideCurrencyCode={true} />
      </span>
    );
  } else {
    const price = productPrice || (s12nDerivative && s12nDerivative.catalogPrice);
    priceString = price && (
      <ReactPriceDisplay value={price.amount} currency={price.currencyCode} hideCurrencyCode={true} />
    );
  }

  return priceString ?? undefined;
};

const getBannerTitleComponent = ({
  enrollmentAvailableChoices,
  onlyShowButton,
  preEnrollmentFlowVariant,
}: {
  enrollmentAvailableChoices?: EnrollmentChoices;
  onlyShowButton?: boolean;
  preEnrollmentFlowVariant?: string;
}): React.FC<BannerEnrollTitleComponentProps> | undefined => {
  const isMixAndMatch = enrollmentAvailableChoices?.isMixAndMatch;
  if (preEnrollmentFlowVariant && !onlyShowButton && isMixAndMatch) {
    const TitleComponent: React.FC<BannerEnrollTitleComponentProps> = (props: BannerEnrollTitleComponentProps) => (
      <span
        style={{
          fontSize: '0.875rem',
          lineHeight: '1.5rem',
          fontWeight: 700,
          maxWidth: 350,
          display: 'block',
        }}
      >
        <MultipleLearningProgramsLink {...props} />
      </span>
    );
    return TitleComponent;
  }
  return undefined;
};

const getBannerTitle = ({
  priceString,
  enrollmentAvailableChoices,
  isSpecialization,
  onlyShowButton,
  preEnrollmentFlowVariant,
}: {
  priceString?: JSX.Element;
  enrollmentAvailableChoices?: EnrollmentChoices;
  isSpecialization: boolean;
  onlyShowButton?: boolean;
  preEnrollmentFlowVariant?: string;
  multipleLearningProgramsLink?: JSX.Element;
}): JSX.Element | undefined => {
  const canEnrollWithFT = enrollmentAvailableChoices?.canEnrollWithFreeTrial;
  const canEnrollThroughSub = enrollmentAvailableChoices?.canEnrollThroughS12nSubscription;
  const canEnrollThroughGroup = enrollmentAvailableChoices?.canEnrollThroughGroup;
  const isEnrolled = getIsEnrolled({ enrollmentAvailableChoices, isSpecialization });
  const isCapstoneAccessLocked = enrollmentAvailableChoices?.isCapstoneAccessLocked;
  const canEnrollThroughCourseraPlus = enrollmentAvailableChoices?.canEnrollThroughCourseraPlus;
  const canEnrollThroughProgram = enrollmentAvailableChoices?.canEnrollThroughProgram;
  const canEnrollThroughProgramInvitation = enrollmentAvailableChoices?.canEnrollThroughProgramInvitation;
  const hasOnlyPreEnrollEnabledS12ns = enrollmentAvailableChoices?.hasOnlyPreEnrollEnabledS12ns;
  const isMixAndMatch = enrollmentAvailableChoices?.isMixAndMatch;
  const disablePreEnrollment = paymentsExperiments.preview('disablePreEnrollment');

  let message;

  // Show pre-enrollment messaging for logged out users as well
  if (preEnrollmentFlowVariant && !onlyShowButton && isMixAndMatch) {
    message = null;
  } else if (preEnrollmentFlowVariant && !onlyShowButton && hasOnlyPreEnrollEnabledS12ns) {
    if (preEnrollmentFlowVariant === PRE_ENROLLMENT_FLOW_VARIANTS.isEnrolled) {
      message = _t(`You'll gain full access to this program when it opens`);
    } else if (
      canEnrollThroughCourseraPlus ||
      canEnrollThroughProgram ||
      canEnrollThroughProgramInvitation ||
      canEnrollThroughGroup ||
      disablePreEnrollment ||
      enrollmentAvailableChoices?.isNotEligibleToPreEnroll
    ) {
      message = null;
    } else {
      message = _t('Enroll early and get your first {numOfDays} days free');
    }
  } else if (
    !user.isAuthenticatedUser() ||
    isCapstoneAccessLocked ||
    isEnrolled ||
    onlyShowButton ||
    canEnrollThroughGroup ||
    canEnrollThroughCourseraPlus
  ) {
    message = null;
    // With mix and match, user can have both FT and paid sub options for different s12ns
  } else if (canEnrollThroughProgram || canEnrollThroughProgramInvitation) {
    message = _t("Included in your organization's learning program");
  } else if (canEnrollWithFT && canEnrollThroughSub) {
    message = _t('Try for Free: 7-day free trial available');
  } else if (canEnrollWithFT) {
    message = _t('Try for Free: Enroll to start your 7-day full access free trial');
  } else if (canEnrollThroughSub) {
    message = _t('Premium Access: {price}/mo');
  }

  if (message) {
    return <FormattedMessage message={message} price={priceString} numOfDays={freeTrial.preEnrollmentNumDays} />;
  } else {
    return undefined;
  }
};

const getValueProps = (
  isSpecialization: boolean,
  s12nProductVariant?: S12nProductVariant,
  enrollmentAvailableChoices?: EnrollmentChoices
): Array<string> => {
  const canEnrollWithFT = enrollmentAvailableChoices?.canEnrollWithFreeTrial ?? false;

  switch (s12nProductVariant) {
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
      return getProfessionalCertificateValueProps(canEnrollWithFT);
    case NormalS12n:
    default:
      return getCourseOrS12nValueProps(isSpecialization);
  }
};

type GetSubtitleElementArgs = {
  onlyShowButton?: boolean;
  isCapstoneAccessLocked?: boolean;
  courseCount?: number;
  isEnrolled?: boolean;
  finaidLink: JSX.Element | undefined;
  canEnrollThroughCourseraPlus?: boolean;
  courseName?: string;
};

const getSubtitleElement = ({
  onlyShowButton,
  isCapstoneAccessLocked,
  courseCount,
  isEnrolled,
  finaidLink,
  canEnrollThroughCourseraPlus,
  courseName,
}: GetSubtitleElementArgs) => {
  const subtitleContainerClassname = 'subtitle-container';
  const subtitleStyle = { fontSize: font.xs, fontWeight: 'bold', margin: '4px 0', maxWidth: '400px' };

  let subtitleElem: any = null;

  // The order of these branches are important!
  if (onlyShowButton) {
    subtitleElem = null;
  } else if (isCapstoneAccessLocked) {
    subtitleElem = courseCount && (
      <div className={subtitleContainerClassname} style={subtitleStyle}>
        <FormattedMessage
          message={_t(`
              The {courseName} course is open for enrollment for users who have completed courses 1-{courseCount} of the Specialization.
              `)}
          courseCount={courseCount - 1}
          courseName={courseName}
        />
      </div>
    );
  } else if (isEnrolled) {
    subtitleElem = (
      <div className={subtitleContainerClassname} style={subtitleStyle}>
        <div style={{ margin: '4px 0' }}>{_t('Already enrolled')}</div>
        {finaidLink}
      </div>
    );
  } else if (!canEnrollThroughCourseraPlus) {
    subtitleElem = (
      <div className={subtitleContainerClassname} style={subtitleStyle}>
        {finaidLink}
      </div>
    );
  } else {
    subtitleElem = (
      <div className={subtitleContainerClassname} style={subtitleStyle}>
        {finaidLink}
      </div>
    );
  }

  return subtitleElem;
};

type GetShowOpenAidModalArgs = {
  financialAidApplications?: FinancialAidApplication[];
  financialAidApplicationsByUserAndProduct?: FinancialAidApplicationByUserAndProduct[];
  isSpecialization?: boolean;
  s12nId?: string;
  courses?: FinancialAidCourse[];
};

const getShowOpenAidModal = ({
  financialAidApplications,
  financialAidApplicationsByUserAndProduct,
  isSpecialization,
  s12nId,
  courses,
}: GetShowOpenAidModalArgs) => {
  let openFinAidInfo = null;
  if (isSpecialization && s12nId && financialAidApplications && courses) {
    openFinAidInfo = flatMap(financialAidApplications || [], (app: FinancialAidApplication) => {
      if (!app) {
        return [];
      }
      if (app?.state !== 'PAYMENT_PENDING' && app?.state !== 'PENDING') {
        return [];
      }
      const finAidCourse = courses?.find((potentialFinAidCourse) => {
        if (potentialFinAidCourse)
          return tupleToStringKey(['VerifiedCertificate', potentialFinAidCourse.id]) === app?.productId;
        return false;
      });
      return [
        {
          courseName: finAidCourse?.name,
          courseId: finAidCourse?.id,
          ...app,
        },
      ];
    }).sort(
      (a, b) =>
        (b.applicationDate !== null ? b.applicationDate : 0) - (a.applicationDate !== null ? a.applicationDate : 0)
    )[0];
  } else {
    openFinAidInfo = flatMap(financialAidApplicationsByUserAndProduct || [], (app) =>
      app.state === 'PENDING' || app.state === 'PAYMENT_PENDING' ? [app] : []
    )[0];
  }
  if (openFinAidInfo) {
    return { openFinAidInfo, showOpenAidModal: true };
  }

  return { openFinAidInfo, showOpenAidModal: false };
};

export {
  getAuditText,
  getBenefits,
  getEnrollButtonLabel,
  getIsEnrolled,
  getIsEnrollButtonDisabled,
  getEnrollButtonTrackingName,
  getFinaidLink,
  getPartnersLogo,
  getPriceString,
  getBannerTitle,
  getValueProps,
  getPartnerAccentColor,
  getSubtitleElement,
  getPreEnrollmentFlowVariant,
  getBannerTitleComponent,
  getShowOpenAidModal,
  shouldShowEnrollForFreeText,
};
