import path from 'js/lib/path';

import { requireFields } from 'bundles/naptimejs/util/requireFieldsDecorator';

import NaptimeResource from './NaptimeResource';

class Partner extends NaptimeResource {
  static RESOURCE_NAME = 'partners.v1';

  // These properties are always included.
  id!: string;

  name!: string;

  shortName!: string;

  // These properties must be requested.
  courseIds?: string[];

  abbrName?: string;

  display?: boolean;

  primaryColor?: string;

  description?: string;

  homeLink?: string;

  logo?: string;

  classLogo?: string;

  rectangularLogo?: string;

  squareLogo?: string;

  links?: Record<string, string>;

  location?: Record<string, string>;

  partnerType?: number;

  instructorIds?: string[];

  @requireFields('links')
  get prettyWebsiteUrl() {
    let url = this.links?.website ?? '';
    url = url.replace(/^.*:\/\//, '');
    url = url.replace(/\/$/, '');
    return url;
  }

  @requireFields('homeLink', 'shortName')
  get partnerPageUrl() {
    if (this.homeLink) {
      return this.homeLink;
    } else {
      return path.join('/', this.shortName);
    }
  }

  @requireFields('rectangularLogo', 'logo', 'classLogo', 'squareLogo')
  get logoForCert() {
    return this.rectangularLogo || this.logo || this.classLogo || this.squareLogo;
  }
}

export default Partner;
