import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { MathElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

export const normalizeMath = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Element.isElement(nodeEntry[0])) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<MathElement>;
  if (node.type !== BLOCK_TYPES.MATH_BLOCK && node.type !== BLOCK_TYPES.MATH_INLINE) {
    return false;
  }

  if (tools.has(Tools.MATH)) {
    return false;
  }

  Transforms.removeNodes(editor, { at: path });
  Transforms.insertText(editor, node.formula, { at: path });
  return true;
};
