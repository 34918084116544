import * as React from 'react';
import { useCallback, useContext, useRef, useState } from 'react';

import { useSlateStatic } from 'slate-react';

import { MoreHorizontalFilledIcon } from '@coursera/cds-icons';

import { Menu, MenuItem } from 'bundles/authoring/common/components/Menu';
import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import DeleteConfirmationDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';
import { toggleHeader } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
};

const MoreTableOptionsButton: React.FC<Props> = ({ table }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { setFocused } = useContext(FocusContext);
  const staticEditor = useSlateStatic();
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);

  const handleDelete = useCallback(() => {
    setDeleteConfirm(true);
  }, [setDeleteConfirm]);

  const handleToggleHeader = useCallback(() => {
    toggleHeader(staticEditor, table);
  }, [staticEditor, table]);

  const handleOpenMenu = useCallback(() => {
    setFocused(true);
    setMenuOpen(true);
  }, [setFocused, setMenuOpen]);

  const handleCloseMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setMenuOpen(false);
      setTimeout(() => setFocused(false), 250);
    },
    [setFocused, setMenuOpen]
  );

  return (
    <React.Fragment>
      <Button
        role="menuitem"
        ref={ref}
        title="More Options"
        active={menuOpen}
        onClick={handleOpenMenu}
        type={TOOLBAR_BUTTON_TYPES.menu}
      >
        <MoreHorizontalFilledIcon size="small" />
      </Button>
      {menuOpen && (
        <Menu
          open
          anchorEl={ref.current}
          onClose={handleCloseMenu}
          className="rc-CMLEditorMenu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          offset={{ y: '12px', x: '12px' }}
        >
          <MenuItem label={table.headless ? _t('Add header') : _t('Remove header')} onClick={handleToggleHeader} />
          <MenuItem label={_t('Remove table')} onClick={handleDelete} />
          <MenuItem label={_t('Close')} onClick={handleCloseMenu} />
        </Menu>
      )}
      {deleteConfirm && <DeleteConfirmationDialog editor={staticEditor} setConfirm={setDeleteConfirm} />}
    </React.Fragment>
  );
};

export default MoreTableOptionsButton;
