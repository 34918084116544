/* @jsxFrag React.Fragment */

/** @jsx jsx */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Typography2 } from '@coursera/cds-core';
import { CheckIcon, CloseIcon } from '@coursera/cds-icons';

import type { Copy } from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import { isSubscriptionTiersVariantA } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';

const styles: Record<string, Interpolation<Theme>> = {
  container: css`
    display: grid;
    grid-template-columns: 26px auto;
    row-gap: 0;

    s strong {
      font-weight: 400 !important;
    }
  `,
  iconPadding: (theme) => css`
    padding: ${theme.spacing(4, 12, 0, 0)};
  `,
  text: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  linePaddingA: css`
    padding: 16px 0;
  `,
  linePaddingB: css`
    padding: 6px 0;
  `,
  separatorA: css`
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
  `,
};

type PropsType = { points: Copy[]; hasFeatures?: boolean };

const ValueRow: React.FC<{ point: Copy; lastValue: boolean; hasFeatures: boolean }> = (props) => {
  let lineCss;
  if (isSubscriptionTiersVariantA()) {
    lineCss = props.lastValue ? styles.linePaddingA : [styles.linePaddingA, styles.separatorA];
  } else {
    lineCss = styles.linePaddingB;
  }
  return (
    <>
      <div css={lineCss} data-testid="iconDiv">
        <div css={styles.iconPadding}>
          {props.hasFeatures ? (
            <CheckIcon color="interactive" size="small" />
          ) : (
            <CloseIcon color="support" size="small" />
          )}
        </div>
      </div>
      <div css={lineCss}>
        <Typography2
          component="p"
          variant="bodySecondary"
          css={styles.text}
          color={props.hasFeatures ? 'body' : 'supportText'}
        >
          {props.hasFeatures ? props.point : <s>{props.point}</s>}
        </Typography2>
      </div>
    </>
  );
};

const ValueList: React.FC<PropsType> = ({ points, hasFeatures = true }) => {
  return (
    <div css={styles.container}>
      {points.map((point, index: number) => (
        <ValueRow
          point={point}
          lastValue={index === points.length - 1}
          key={point.toString()}
          hasFeatures={hasFeatures}
        />
      ))}
    </div>
  );
};

export default ValueList;
