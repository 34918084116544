/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useState } from 'react';
import type { OnChangeSingleHandler } from 'react-select';
import Select from 'react-select';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { useRetracked } from 'js/lib/retracked';
import { SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { SelectField, SelectOption, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';
import { SvgChevronDown, SvgChevronUp } from '@coursera/coursera-ui/svg';

import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import { getS12nOrder } from 'bundles/enroll/utils/mixAndMatchUtils';
import paymentsExperiments from 'bundles/epic/clients/payments';
import TrackedButton from 'bundles/page/components/TrackedButton';
import Modal from 'bundles/phoenix/components/Modal';
import { getS12nDisplayName } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/CourseEnrollS12nSelectionModal';

type PropsFromCaller = {
  onClose: (event?: React.MouseEvent<HTMLElement>) => void;
  onSubmit: (selectedS12nId: string) => void;
  isTerminal: boolean;
  altMessageGenerator?: (courseName: string) => React.ReactNode;
};

const useStyles = (theme: Theme) => ({
  selectOption: css({
    display: 'flex',
  }),
  preenrollmentStatus: css({
    paddingLeft: theme.spacing(4),
  }),
});

const CourseEnrollS12nSelectionModal: React.FC<PropsFromCaller> = ({
  onClose,
  onSubmit,
  isTerminal,
  altMessageGenerator,
}) => {
  const { course, s12ns, s12nCourses, enrollmentAvailableChoices } = useCourseEnrollModalData();
  const initialS12nId = s12ns ? getS12nOrder(course.id, s12ns)[0]?.id : undefined;
  const [selectedS12nId, setSelectedS12nId] = useState<string | undefined>(initialS12nId);
  const theme = useTheme();
  const styles = useStyles(theme);

  const track = useRetracked();

  useEffect(() => {
    if (s12ns && s12nCourses && s12ns.length !== enrollmentAvailableChoices.enrollmentS12nIds.length) {
      const foundS12nIds = s12ns.map((s12n) => s12n.id);
      const missingS12nIds = enrollmentAvailableChoices.enrollmentS12nIds.filter(
        (s12nId) => !foundS12nIds.includes(s12nId)
      );
      track({
        trackingData: {
          courseId: course.id,
          missingS12nIds,
        },
        trackingName: 'course_enroll_s12n_selection_modal_s12ns_mismatch',
        action: 'view',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!s12ns || !s12nCourses || !selectedS12nId) {
    return null;
  }

  const handleSubmit = () => {
    onSubmit(selectedS12nId);
  };

  const onChange: OnChangeSingleHandler<string> = (newValue) => {
    const value = newValue?.value;
    if (value) {
      setSelectedS12nId(value);
    }
  };

  const preenrollmentOnChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void = (newValue) => {
    const value = newValue?.target.value as string;
    if (value) {
      setSelectedS12nId(value);
    }
  };

  const renderArrow = ({ isOpen }: { isOpen: boolean }) => {
    const props = {
      size: 24,
      style: { verticalAlign: 'bottom' },
      color: color.primary,
    };

    return isOpen ? <SvgChevronUp {...props} /> : <SvgChevronDown {...props} />;
  };

  const selectedS12n = s12ns.find(({ id }) => id === selectedS12nId);
  const orderedS12ns = getS12nOrder(course.id, s12ns).filter((s12n) => !!s12n?.id);
  const isPreenrollmentEnabled = !paymentsExperiments.preview('disablePreEnrollment');

  const buttonText = (): string => {
    if (isPreenrollmentEnabled && enrollmentAvailableChoices.preEnrolledS12nIds.includes(selectedS12nId)) {
      return _t('You’re already pre-enrolled');
    }

    return isTerminal ? _t('Continue') : _t('Next');
  };

  return (
    <div className="rc-CourseEnrollS12nSelectionModal">
      <CSSTransitionGroup transitionName="fade" transitionEnter={false} transitionLeaveTimeout={300}>
        <Modal
          trackingName="course_enroll_s12n_selection_modal"
          key="CourseEnrollS12nSelectionModal"
          modalName={_t('Choose a program')}
          handleClose={onClose}
        >
          <div className="s12n-selection-modal-container m-t-1s">
            {!isTerminal && <strong>{_t('Step 1 of 2')}</strong>}
            <p className="headline-5-text punch-line">{_t('Choose a program')}</p>
            <p>
              {altMessageGenerator ? (
                altMessageGenerator(course.name)
              ) : (
                <FormattedMessage
                  message={_t(`You're enrolling in {courseName}, which is available as part of multiple learning programs.
                    These programs are designed to help you become job ready or master a skill.
                    Select the program that is right for you from the list below.`)}
                  courseName={<strong>{course.name}</strong>}
                />
              )}
            </p>
            {orderedS12ns &&
              (isPreenrollmentEnabled ? (
                <SelectField
                  label={_t('Learning program')}
                  id="s12n-selection-dropdown"
                  value={selectedS12nId}
                  onChange={preenrollmentOnChange}
                  fullWidth
                >
                  {orderedS12ns.map((s12n) => {
                    const plannedLaunchDate = enrollmentAvailableChoices.getPlannedLaunchDateForS12n(s12n.id);
                    const shouldShowPreenrollmentStatus =
                      isPreenrollmentEnabled &&
                      s12n.id &&
                      enrollmentAvailableChoices.canPreEnrollOrIsPreEnrolledS12n(s12n.id) &&
                      plannedLaunchDate;
                    const isPreEnrolled = enrollmentAvailableChoices.preEnrolledS12nIds.includes(s12n.id);
                    return (
                      <SelectOption key={s12n.id} value={s12n.id} css={styles.selectOption}>
                        <Typography2 component="p" color="highlightBlue" display="inline">
                          {getS12nDisplayName(s12n.name, s12n.productVariant)}
                        </Typography2>
                        {shouldShowPreenrollmentStatus && (
                          <Typography2
                            component="p"
                            color="supportText"
                            display="inline"
                            css={styles.preenrollmentStatus}
                          >
                            {_t(' (#{preenrollmentStatus} — opens #{startDate})', {
                              startDate: formatDateTimeDisplay(plannedLaunchDate, SHORT_MONTH_DAY_DISPLAY),
                              preenrollmentStatus: isPreEnrolled ? _t('Pre-enrolled') : _t('Pre-enrollment available'),
                            })}
                          </Typography2>
                        )}
                      </SelectOption>
                    );
                  })}
                </SelectField>
              ) : (
                <Select
                  id="s12n-selection-dropdown"
                  onChange={onChange}
                  value={selectedS12nId}
                  options={orderedS12ns.map((s12n) => {
                    return {
                      label: getS12nDisplayName(s12n.name, s12n.productVariant),
                      value: s12n.id,
                    };
                  })}
                  arrowRenderer={renderArrow}
                  searchable={false}
                  clearable={false}
                />
              ))}
            {selectedS12n && (
              <div className="m-t-1">
                <p className="m-y-0">
                  <FormattedMessage
                    message={_t('There are {numCourses} courses in this program')}
                    numCourses={selectedS12n.courseIds.length}
                  />
                </p>
                <ul data-e2e="course-items-list">
                  {selectedS12n.courseIds.map((courseId, i) => {
                    const s12nCourse = s12nCourses.find(({ id }) => id === courseId);
                    const courseItemClass = classNames('course-item m-t-1s', {
                      'is-enrolling-in-course': course.id === courseId,
                    });
                    return (
                      s12nCourse && (
                        <li key={courseId} className={courseItemClass}>
                          <FormattedMessage
                            message={_t('Course {courseNumber}: {courseName}')}
                            courseNumber={i + 1}
                            courseName={s12nCourse.name}
                          />
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            )}
            <TrackedButton
              trackingName="course_enroll_s12n_selection_button"
              data={{ courseId: course.id, s12nId: selectedS12nId }}
              data-e2e="course_enroll_s12n_selection_button"
              className="primary cozy m-y-1"
              onClick={handleSubmit}
              disabled={
                isPreenrollmentEnabled && enrollmentAvailableChoices.preEnrolledS12nIds.includes(selectedS12nId)
              }
            >
              {buttonText()}
            </TrackedButton>
          </div>
        </Modal>
      </CSSTransitionGroup>
    </div>
  );
};

export default CourseEnrollS12nSelectionModal;
