import * as React from 'react';

import { Menu } from 'bundles/authoring/common/components/Menu';
import BodyMenu from 'bundles/cml/editor/components/buttons/typography/BodyMenu';
import HeadingMenu from 'bundles/cml/editor/components/buttons/typography/HeadingMenu';
import { getMenuDirectionProps } from 'bundles/cml/editor/utils/menuUtils';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

type Props = {
  anchorEl?: HTMLElement;
  tools: ToolsKeys[];
  open: boolean;
  handleClose: React.ReactEventHandler<Element>;
  menuDirection?: 'top' | 'bottom';
};

const TypographyMenu: React.FC<Props> = ({ anchorEl, open, handleClose, tools, menuDirection }: Props) => {
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      {...getMenuDirectionProps(menuDirection)}
      autoFocus={true}
      disableScrollLock={true}
      className="rc-CMLEditorMenu"
    >
      <HeadingMenu tools={tools} />
      <BodyMenu tools={tools} />
    </Menu>
  );
};

export default TypographyMenu;
