import _ from 'lodash';

import _t from 'i18n!nls/program-home';

const getButtonTextByCourseProgress = (summaryCoreProgressPercentage = 0) => {
  return summaryCoreProgressPercentage === 100 ? _t('Review') : _t('Resume');
};

const getProgressByLearnerMaterial = (learnerMaterials: $TSFixMe) => {
  return (learnerMaterials && learnerMaterials.summaryCoreProgressPercentage) || 0;
};

const getEnrolledSession = (learnerSessions: $TSFixMe) => {
  return learnerSessions && _.first(_.filter(learnerSessions, (item) => item.isEnrolled));
};

export { getButtonTextByCourseProgress, getProgressByLearnerMaterial, getEnrolledSession };
