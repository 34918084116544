import * as React from 'react';

import { StyleSheet, css } from '@coursera/coursera-ui';

import { useLandingPageExtendedMaybe } from 'bundles/coursera-plus/contentfulData/useLandingPageData';

const styles = StyleSheet.create({
  wrapper: {
    margin: '20px auto',
    padding: '20px',
    borderColor: '#bb0000',
    borderWidth: '2px',
    borderStyle: 'solid',
    width: '50%',
    lineHeight: 1.8,
    backgroundColor: 'white',
  },

  'wrapper>div': {
    marginBottom: '20px',
  },

  separator: {
    margin: '10px 0px',
    borderTopStyle: 'solid',
    borderColor: '#dddddd',
    borderWidth: '1px',
  },
  error: {
    color: '#bb0000',
  },
  public: {
    padding: '50px 20px',
    fontSize: '20px',
  },
});

export const ERROR_MESSAGES = {
  noPrimary: 'Please add a valid Primary Product to the page entry',
  multipleMessages: 'Out of Freeform, Freeform Rich and Pretranslated, only one can be present',
  missingTranslation: 'No translation for pretranslated',
  missingSecondary: `This part is configured to work with the Secondary product, but no Secondary product 
    is entered for the page`,
  missingMessage: 'No message entered for the part',
  noPrimaryLabel: 'Please add a label for Primary Checkout buttons',
  primaryAndSecondaryTheSame: 'Primary and secondary products are identical.',
  missingExpiryDate: "Trying to display expiry data but the Contentful entry doesn't have one",
  invalidExpiryDate: 'Invalid page expiry',
  invalidDesign: 'Invalid hero design',
};

export type ErrorMessageProps = {
  errorMessage: string;
  ctaLocation?: string;
  ctaPieceTitle?: string;
  pretranslated?: string;
  title?: string | null; // for the time when we can't create a context yet
};

const ContentfulError: React.FC<ErrorMessageProps> = (props) => {
  const landingPageExtended = useLandingPageExtendedMaybe();
  const { errorMessage, ctaLocation, ctaPieceTitle, pretranslated, title } = props;
  const pageTitle = landingPageExtended ? landingPageExtended.landingPage.title : title;
  return (
    <div {...css(styles.wrapper)}>
      <div>
        <span {...css(styles.error)}>Contentful data error</span>
        {pageTitle && <div>page &quot;{pageTitle}&quot;</div>}
        {ctaLocation && <span>, in {ctaLocation} CTA</span>}
      </div>
      {ctaPieceTitle && (
        <React.Fragment>
          <div {...css(styles.separator)} />
          <div>part : &quot;{ctaPieceTitle}&quot;</div>
          {pretranslated && (
            <React.Fragment>
              <div {...css(styles.separator)} />
              <div>pretranslated : &quot;{pretranslated}&quot;</div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <div {...css(styles.separator)} />
      <div data-testid="message">{errorMessage}</div>
    </div>
  );
};

export default ContentfulError;
