import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';
import { color } from '@coursera/coursera-ui';

import StaticLinearProgress from 'bundles/course-cards/components/course-card/enterprise/StaticLinearProgress';

import _t from 'i18n!nls/program-home';

type Props = {
  summaryCoreProgressPercentage: number;
  numberOfAssignments: number;
  isProgressCritical?: boolean;
  courseName: string;
};

const CourseProgressInfo = ({
  summaryCoreProgressPercentage,
  isProgressCritical,
  numberOfAssignments,
  courseName,
}: Props): JSX.Element => {
  return (
    <div className="CourseProgressInfo flex-1">
      <Typography2 component="span" variant="subtitleMedium" className="text-capitalize" aria-hidden="true">
        {_t('progress: #{summaryCoreProgressPercentage}%', { summaryCoreProgressPercentage })}
      </Typography2>
      <StaticLinearProgress
        progress={summaryCoreProgressPercentage}
        color={isProgressCritical ? color.danger : color.success}
        aria-label={courseName}
      />
      {numberOfAssignments !== 0 && (
        <Typography2 component="span" variant="subtitleMedium">
          <FormattedMessage
            message={_t('{numberOfAssignments, plural, =1 {# assignment} other {# assignments}} this week')}
            numberOfAssignments={numberOfAssignments}
          />
        </Typography2>
      )}
    </div>
  );
};

export default CourseProgressInfo;
