import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';
import { compose } from 'recompose';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import { Grid, Typography2, useTheme } from '@coursera/cds-core';
import { Card, StyleSheet, color, css, spacing } from '@coursera/coursera-ui';

import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import Naptime from 'bundles/naptimejs';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { CircleMenuItem } from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductCirclesMenu';
import MultiCourseProductEnrolledCard from 'bundles/program-home/components/multiCourseProductCard/MultiCourseProductEnrolledCard';
import { removeCredentialFromBadgeTitle } from 'bundles/program-home/utils/EnterpriseBadgeUtils';

import _t from 'i18n!nls/program-home';

const MIN_CARD_HEIGHT = 112;

const styles = StyleSheet.create({
  BadgeCard: {
    position: 'relative',
    backgroundColor: color.white,
    marginBottom: spacing.md,
    overflow: 'hidden',
  },
});

type PropsFromCaller = {
  programId: string;
  userId: number;
  enterpriseBadge: EnterpriseBadge;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

type PropsFromNaptime = {
  curriculumProducts?: Array<ProgramCurriculumProductsV1>;
};

type Props = PropsFromCaller & PropsFromNaptime;

export function BadgeCard({
  programId,
  userId,
  enterpriseBadge,
  curriculumProducts,
  refetchEnterpriseBadgeCollections,
}: Props) {
  const theme = useTheme();
  if (!enterpriseBadge || !curriculumProducts) {
    return null;
  }
  const cardStyles = {
    minHeight: MIN_CARD_HEIGHT,
    marginBottom: spacing.md,
    padding: 0,
    marginTop: '2px',
    marginLeft: '2px',
    marginRight: '2px',
    boxShadow: '0px 1px 4px rgba(49, 49, 49, 0.24)',
    border: '1px solid #EAEAEA',
  };

  const badgeTitle = removeCredentialFromBadgeTitle(enterpriseBadge.title);
  const badgeProductIds = enterpriseBadge.productIds.map((productId) => productId.id);
  const activeEnrolledCourseIds: Array<string> = curriculumProducts
    .filter((session) => session.isEnrolled)
    .map((session) => session.courseId);

  const completedCourseIds = curriculumProducts
    .filter((product) => product.isCompleted)
    .map((product) => product.courseId);
  const selectedCourseId = badgeProductIds[0];
  const courseCount = badgeProductIds.length;

  const circleMenuData: CircleMenuItem[] = badgeProductIds.map((id, index) => ({
    courseId: id,
    isActive: selectedCourseId === id,
    isEnrolled: activeEnrolledCourseIds.includes(id),
    isCompleted: completedCourseIds.includes(id),
    index,
    isCapstone: false,
  }));

  return (
    <div>
      {enterpriseBadge.userState === 'ISSUED' && (
        <Grid
          container
          alignItems="center"
          css={{
            backgroundColor: theme.palette.blue[100],
            padding: theme.spacing(16),
            borderRadius: '2px',
          }}
        >
          <Grid xs={12}>
            <Typography2 component="p" variant="bodySecondary">
              {_t("You've completed all courses in the #{title}.", {
                title: removeCredentialFromBadgeTitle(enterpriseBadge.title),
              })}
            </Typography2>
          </Grid>
        </Grid>
      )}
      <Card
        tagAttributes={{
          xsMinWidth: true,
        }}
        isInteractive={true}
        style={cardStyles}
      >
        <div {...css('m-b-1', styles.BadgeCard)} data-e2e={`BadgeCard~${enterpriseBadge.badgeTemplateId}`}>
          {userId && (
            <MultiCourseProductEnrolledCard
              id={enterpriseBadge.badgeTemplateId}
              name={badgeTitle}
              logo={enterpriseBadge.imageUrl}
              description={enterpriseBadge.description || ''}
              programId={programId}
              userId={userId}
              itemType="BADGE"
              canUnenroll={true}
              courseCount={courseCount || 0}
              firstCourseId={selectedCourseId}
              circleMenuData={circleMenuData}
              refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

const BadgeCardWithData = compose<Props, PropsFromCaller>(
  Naptime.createContainer(({ enterpriseBadge, programId }: PropsFromCaller) => {
    const courseIds: Array<string> = enterpriseBadge.productIds.map((productId) => productId.id);
    const tupleCourseIds: Array<string> = courseIds.map((id) =>
      tupleToStringKey([programId, 'VerifiedCertificate', id])
    );
    return {
      curriculumProducts: ProgramCurriculumProductsV1.multiGet(tupleCourseIds, {}),
    };
  })
)(BadgeCard);

export default BadgeCardWithData;
