/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/product-card';

const styles = {
  logoContainer: (theme: Theme) => css`
    display: flex;
    gap: 5px;

    img {
      border: 1px solid ${theme.palette.gray[300]};
      border-radius: 4px;
      height: 24px;
      width: 24px;
    }

    & :last-child {
      margin-right: ${theme.spacing(8)};
    }

    > div {
      padding-bottom: 0; /* Avoids wrapping issues when resizing window */
    }
  `,
  partnerContainer: (theme: Theme) => css`
    @media (min-width: 600px) and (max-width: 680px) {
      margin-left: ${theme.spacing(64)};
    }

    ${theme.breakpoints.only('md')} {
      margin-left: ${theme.spacing(64)};
    }
  `,
  partnerWrap: (theme: Theme) => css`
    && {
      ${theme.breakpoints.only('lg')} {
        flex-wrap: nowrap;
      }
    }
  `,
  truncatePartnerName: css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  `,
};

type PartnersProps = {
  partners?: string[];
  partnerLogos?: string[];
  cobrandingEnabled?: boolean;
  truncateName?: boolean;
};

/**
 * Handles co-branded and regular search card results.
 */
export const Partners = ({ partners, partnerLogos, cobrandingEnabled, truncateName }: PartnersProps) => {
  return (
    <Grid container css={styles.partnerWrap} wrap="nowrap">
      <Grid item css={styles.logoContainer}>
        {partners && partnerLogos && !cobrandingEnabled ? (
          <LazyImgix src={partnerLogos[0]} alt={partners[0]} maxWidth={25} maxHeight={25} width={25} height={25} />
        ) : (
          partners &&
          partnerLogos &&
          partnerLogos.map((logo, index) => (
            <LazyImgix
              src={logo}
              alt={partners[index]}
              maxWidth={25}
              maxHeight={25}
              width={25}
              height={25}
              key={logo}
            />
          ))
        )}
      </Grid>
      <Grid item css={partners && partners.length > 1 && cobrandingEnabled ? styles.partnerContainer : ''}>
        <Typography2
          component="span"
          color="supportText"
          variant="bodySecondary"
          css={truncateName ? styles.truncatePartnerName : ''}
        >
          {partners && partners.length > 1 && cobrandingEnabled
            ? _t('#{partner1}, #{partner2}', { partner1: partners[0], partner2: partners[1] })
            : partners && partners[0]}
        </Typography2>
      </Grid>
    </Grid>
  );
};
