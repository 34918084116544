/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Link, Typography2, useTheme } from '@coursera/cds-core';
import type { LinkProps, Theme } from '@coursera/cds-core';
import { ExternalLinkIcon, InfoOutlineIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { HelpCenters, buildSalesforceArticleUrl } from 'bundles/common/utils/salesforceUrlBuilder';

import _t from 'i18n!nls/enterprise-admin-grow-with-google';

const TrackedLink = withSingleTracked({ type: 'BUTTON' })<LinkProps>(Link);

export type PropsFromCaller = {
  isAuthenticatedUser: boolean;
  isAdminFacing?: boolean;
};

const gwgHelpBannerContainer = (theme: Theme) =>
  css({
    maxWidth: '1224px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(64),
    backgroundColor: theme.palette.blue[100],
    padding: theme.spacing(24, 48),
    borderRadius: '4px',
  });

const textContainer = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(24),
      justifyContent: 'center',
    },
  });

const linkContainer = (theme: Theme) =>
  css({
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  });

const flexFirstItem = (theme: Theme) =>
  css({
    marginRight: theme.spacing(24),
  });

const GwGHelpBanner = ({ isAuthenticatedUser, isAdminFacing }: PropsFromCaller) => {
  const learnerGuideLink = isAdminFacing
    ? buildSalesforceArticleUrl(
        HelpCenters.ENTERPRISE,
        isAuthenticatedUser,
        '',
        '4405584289555-Google-Professional-Certificates-on-Coursera-Enterprise-Admin-and-Learner-Guides?language=en_US'
      )
    : buildSalesforceArticleUrl(
        HelpCenters.LEARNER,
        isAuthenticatedUser,
        '',
        '4408311668749-Google-Professional-Certificates-on-Coursera-Enterprise-Learner-Guide'
      );

  const faqLink = isAdminFacing
    ? buildSalesforceArticleUrl(
        HelpCenters.ENTERPRISE,
        isAuthenticatedUser,
        '',
        '4405575590931-Google-Professional-Certificates-on-Coursera-Enterprise-Admin-FAQ?language=en_US'
      )
    : buildSalesforceArticleUrl(
        HelpCenters.LEARNER,
        isAuthenticatedUser,
        '',
        '4408323183245-Google-Professional-Certificates-on-Coursera-Enterprise-Learner-FAQ'
      );

  const gewLearnerGuideText = () => {
    return isAdminFacing ? _t('View the Guide') : _t('View the Learner Guide');
  };

  const theme = useTheme();

  return (
    <div css={gwgHelpBannerContainer(theme)}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item md={5} sm={12} css={textContainer(theme)}>
          <InfoOutlineIcon color="interactive" size="large" css={flexFirstItem(theme)} />
          <div>
            <Typography2 component="p" css={{ fontWeight: 'bold' }} variant="bodyPrimary">
              {_t('Need help or have a question?')}
            </Typography2>
            <Typography2 component="p" variant="bodyPrimary">
              {_t('Check out these helpful resources')}
            </Typography2>
          </div>
        </Grid>
        <Grid item md={7} sm={12}>
          <div css={linkContainer(theme)}>
            <TrackedLink
              icon={<ExternalLinkIcon color="interactive" />}
              iconPosition="after"
              target="_blank"
              href={learnerGuideLink}
              css={flexFirstItem(theme)}
              trackingName="gwg_view_guide_button"
            >
              {gewLearnerGuideText()}
            </TrackedLink>
            <TrackedLink
              icon={<ExternalLinkIcon color="interactive" />}
              iconPosition="after"
              target="_blank"
              href={faqLink}
              trackingName="gwg_view_faq_button"
            >
              {_t('Visit our FAQ')}
            </TrackedLink>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GwGHelpBanner;
