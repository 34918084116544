import type { NodeEntry } from 'slate';
import { Editor, Element, Node, Transforms } from 'slate';

import { createTableRow } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const TABLE_MARKDOWN_REGEX = /^\| *.+ *\|(?: *.+ *\|)*$/i;
const TABLE_HEADING_MARKDOWN_REGEX = /^\| *-+ *\|(?: *-+ *\|)*$/i;
const TABLE_PARSE_REGEX = /^\|(.+)\|$/i;
const MIN_NUM_ROWS = 3;

const isTableNode = (nodeEntry?: NodeEntry<Node>): nodeEntry is NodeEntry<Node> => {
  if (!nodeEntry || !Element.isElement(nodeEntry[0])) {
    return false;
  }

  const [node] = nodeEntry;
  const text = Node.string(node);

  return !!text.match(TABLE_MARKDOWN_REGEX);
};

const getTableStartNodes = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const nodes: NodeEntry<Node>[] = [];
  let prevEntry = nodeEntry;
  while (prevEntry) {
    try {
      const entry = Editor.previous(editor, { at: [prevEntry[1][0]] });
      if (!isTableNode(entry)) {
        return nodes;
      }
      prevEntry = entry;
      nodes.unshift(entry);
    } catch (e) {
      return nodes;
    }
  }

  return nodes;
};

const getTableEndNodes = (editor: Editor, nodeEntry: NodeEntry<Node>) => {
  const nodes: NodeEntry<Node>[] = [];
  let nextEntry = nodeEntry;
  while (nextEntry) {
    try {
      const entry = Editor.next(editor, { at: [nextEntry[1][0]] });
      if (!isTableNode(entry)) {
        return nodes;
      }
      nextEntry = entry;
      nodes.push(entry);
    } catch (e) {
      return nodes;
    }
  }

  return nodes;
};

const hasHeadingRow = (text?: string) => {
  return !!text?.match(TABLE_HEADING_MARKDOWN_REGEX);
};

const hasEqualNumberOfCells = (lines: string[]) => {
  const numCells = lines[0].split('|').length;
  return lines.every((line) => line.split('|').length === numCells);
};

const parseTableRows = (lines: string[]) => {
  return lines.map((line) => {
    const row = line.match(TABLE_PARSE_REGEX)?.[1] ?? '';
    return row.split('|').map((value) => value.trim());
  });
};

export const normalizeTableMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (hasAncestorOfType(editor, BLOCK_TYPES.TABLE, nodeEntry[1]) || !tools.has(Tools.TABLE)) {
    return false;
  }

  if (!isTableNode(nodeEntry)) {
    return false;
  }

  const startNodes = getTableStartNodes(editor, nodeEntry);
  const endNodes = getTableEndNodes(editor, nodeEntry);

  const nodes = [...startNodes, nodeEntry, ...endNodes];
  const lines = nodes.map(([node]) => Node.string(node));
  if (lines.length < MIN_NUM_ROWS) {
    return false;
  }

  if (!hasEqualNumberOfCells(lines)) {
    return false;
  }

  if (!hasHeadingRow(lines[1])) {
    return false;
  }

  const rows = parseTableRows(lines);
  const headingRow = rows[0];
  const tableRows = rows.slice(2);
  const numColumns = headingRow.length;

  const table = {
    type: BLOCK_TYPES.TABLE,
    headless: false,
    children: [
      createTableRow(numColumns, true, (col) => headingRow[col]),
      ...tableRows.map((tableCells) => createTableRow(numColumns, false, (col) => tableCells[col])),
    ],
  };

  const startPath = nodes[0][1];
  const endPath = nodes[nodes.length - 1][1];

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, {
      at: {
        anchor: { path: [startPath[0]], offset: 0 },
        focus: { path: [endPath[0]], offset: 0 },
      },
      mode: 'highest',
    });
    Transforms.insertNodes(editor, table, { at: [startPath[0]] });
  });
  return true;
};
