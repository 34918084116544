import { ThemeProvider } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import _, { every, omit, uniq } from 'lodash';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import Retracked from 'js/app/retracked';
import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import cookie from 'js/lib/cookie';
import redirect from 'js/lib/coursera.redirect';
import fullStory from 'js/lib/fullStoryUtils';
import pendo, { LEARNER_API_KEY } from 'js/lib/pendo';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import Alice from 'bundles/alice/components/Alice';
import { PROGRAM_HOME } from 'bundles/alice/constants/AliceContextType';
import withAliceNotification from 'bundles/alice/lib/withAliceNotification';
import AlicePageviewEvent from 'bundles/alice/models/AlicePageviewEvent';
import CourseReenrollModals from 'bundles/campus-basic-contract-renewal/components/learner/CourseReenrollModals';
import withFreemiumCourseReenrollData from 'bundles/campus-basic-contract-renewal/components/learner/withFreemiumCourseReenrollData';
import type { PropsFromWithFreemiumCourseReenrollData } from 'bundles/campus-basic-contract-renewal/components/learner/withFreemiumCourseReenrollData';
import { withErrorPage } from 'bundles/coursera-ui/components/hocs/withBranches';
import { enterpriseContractType } from 'bundles/enterprise-admin-constants/EnterpriseContractConstants';
import { allContractsHardPausedOrTerminated } from 'bundles/enterprise-admin-contracts';
import IsProgramAdmin from 'bundles/enterprise-admin/queries/IsProgramAdmin.graphql';
import type {
  IsProgramAdminQuery,
  IsProgramAdminQueryVariables,
} from 'bundles/enterprise-admin/queries/__generated__/IsProgramAdmin';
import C4CExperiments from 'bundles/epic/clients/C4C';
import EnterpriseExperiments from 'bundles/epic/clients/Enterprise';
import FullStoryExperiments from 'bundles/epic/clients/FullStory';
import withCCPA from 'bundles/marketing-consent/components/withCCPA';
import withGDPR from 'bundles/marketing-consent/components/withGDPR';
import type { InjectedNaptime } from 'bundles/naptimejs';
import Naptime from 'bundles/naptimejs';
import type { EnterpriseContractTagsV1 as EnterpriseContractTagsV1Type } from 'bundles/naptimejs/resources/__generated__/EnterpriseContractTagsV1';
import type { EnterpriseContractsV1 as EnterpriseContractsV1Type } from 'bundles/naptimejs/resources/__generated__/EnterpriseContractsV1';
import type { EnterpriseProgramsV1 as EnterpriseProgramsV1Type } from 'bundles/naptimejs/resources/__generated__/EnterpriseProgramsV1';
import type { ProgramBrowsingExperiencesV1 as ProgramBrowsingExperiencesV1Type } from 'bundles/naptimejs/resources/__generated__/ProgramBrowsingExperiencesV1';
import type { ThirdPartyOrganizationsV1 as ThirdPartyOrganizationsV1Type } from 'bundles/naptimejs/resources/__generated__/ThirdPartyOrganizationsV1';
import EnterpriseNoticeAcceptanceLogsV1 from 'bundles/naptimejs/resources/enterpriseNoticeAcceptanceLogs.v1';
import EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import ExternallyAccessibleNostosV1 from 'bundles/naptimejs/resources/externallyAccessibleNostos.v1';
import ProgramBrowsingExperienceV1 from 'bundles/naptimejs/resources/programBrowsingExperiences.v1';
import ProgramMembershipsV2 from 'bundles/naptimejs/resources/programMemberships.v2';
import ProgramSwitcherSelectionsV1 from 'bundles/naptimejs/resources/programSwitcherSelections.v1';
import TargetSkillProfileUserStatesV1 from 'bundles/naptimejs/resources/targetSkillProfileUserStates.v1';
import ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import NotFound from 'bundles/phoenix/components/NotFound';
import { IdTypes } from 'bundles/product-features';
import { PROGRAM_VISIBILITY_RULE } from 'bundles/program-common/constants/ProgramCommonConstants';
import ProductCoursesQuery from 'bundles/program-common/queries/ProductCoursesQuery.graphql';
import type {
  ProductCardCourseFragmentFragment as Course,
  ProductCoursesQuery as ProductCoursesQueryType,
  ProductCoursesQueryVariables,
} from 'bundles/program-common/queries/__generated__/ProductCoursesQuery';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import BypassLogin from 'bundles/program-home/components/BypassLogin';
import ProgramNotFound from 'bundles/program-home/components/ProgramNotFound';
import ProgramPreJoinCheck from 'bundles/program-home/components/ProgramPreJoinCheck';
import type {
  TargetSkillProfileUserStatesQuery as TargetSkillProfileUserStatesQueryData,
  TargetSkillProfileUserStatesQueryVariables,
  TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType,
} from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import type { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';
import AgeVerificationPage from 'bundles/program-home/components/age-verification/AgeVerificationPage';
import ProgramArchivedDenyModal from 'bundles/program-home/components/modals/ProgramArchivedDenyModal';
import SingleProgramPage from 'bundles/program-home/components/single-program/SingleProgramPage';
import {
  C4C_CORONA_TRIAL,
  C4C_FREEMIUM,
  C4C_UPSWELL,
  C4WR_CORONA_TRIAL,
  COURSERA_CORE,
  GWG_FREEMIUM,
} from 'bundles/program-home/constants/ProgramTypes';
import { withProductFeatures } from 'bundles/program-home/productFeatureFlags';
import DeferredProgramHomeLoggedInQueryText from 'bundles/program-home/queries/DeferredProgramHomeLoggedInQuery';
import type {
  DeferredProgramHomeLoggedInQuery,
  DeferredProgramHomeLoggedInQueryVariables,
} from 'bundles/program-home/queries/DeferredProgramHomeLoggedInQuery';
import type {
  EnterpriseProgramBySlugQueryResult,
  EnterpriseProgramBySlugQueryVariables,
} from 'bundles/program-home/queries/EnterpriseProgramBySlugQuery';
import EnterpriseProgramBySlugQuery from 'bundles/program-home/queries/EnterpriseProgramBySlugQuery';
import type {
  FindUserEmailsByUserIdQueryResult,
  FindUserEmailsByUserIdQueryVariables,
} from 'bundles/program-home/queries/FindUserEmailsByUserIdQuery';
import FindUserEmailsByUserIdQuery from 'bundles/program-home/queries/FindUserEmailsByUserIdQuery';
import ProgramHomeLoggedInQueryText from 'bundles/program-home/queries/ProgramHomeLoggedInQuery';
import type {
  ProgramHomeLoggedInQuery,
  ProgramHomeLoggedInQueryVariables,
} from 'bundles/program-home/queries/ProgramHomeLoggedInQuery';
import UserSkillScoresBySkillIdsQuery from 'bundles/program-home/queries/UserSkillScoresBySkillIds.graphql';
import type {
  UserSkillScoresBySkillIdsQuery as UserSkillScoresBySkillIdsQueryData,
  UserSkillScoresBySkillIdsQueryVariables,
} from 'bundles/program-home/queries/__generated__/UserSkillScoresBySkillIds';
import {
  AutoenrolledCoursesQuery,
  TargetSkillProfileUserStatesQuery,
} from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import { getAgeVerification } from 'bundles/program-home/utils/ProgramHomeUtils';
import type {
  AutoenrolledCoursesQuery as AutoenrolledCoursesQueryData,
  AutoenrolledCoursesQueryVariables,
  AutoenrolledCoursesQuery_ProgramEnrollmentSettingsV1Resource_joinProgramCourses_elements_enrollmentTarget_targetProduct_ProgramEnrollmentSettingsV1_courseEnrollmentMember as CourseEnrollment,
} from 'bundles/program-home/utils/__generated__/AutoenrolledCoursesQuery';
import type { PropsFromWithIsMounted } from 'bundles/program-home/utils/withIsMounted';
import withIsMounted from 'bundles/program-home/utils/withIsMounted';
import { isForcedSSOLoginFlowEnabled } from 'bundles/saml-sso/epicFeatureFlags';
import * as JWTLoginAPIUtils from 'bundles/saml-sso/utils/JWTLoginAPIUtils';
import { searchTheme } from 'bundles/search-common/searchTheme';
import SimpleCourseraMetatags from 'bundles/seo/components/SimpleCourseraMetatags';
import ApplicationStore from 'bundles/ssr/stores/ApplicationStore';
import { getProgramCareerAcademyVariantQueryGraphQl } from 'bundles/unified-career-academy/queries/enterpriseQueries';
import type { PropCareerAcademyVariant } from 'bundles/unified-career-academy/utils/helpers';
import { isCareerAcademyFreemiumEnabled, isCareerAcademyV2Enabled } from 'bundles/unified-career-academy/utils/helpers';

import _t from 'i18n!nls/program-home';

type PropsFromStores = {
  csrfToken: string | null | undefined;
  requestCountryCode: string | null | undefined;
};

type PropsFromRouter = {
  router: InjectedRouter;
  programSlug: string;
  renderErrorPage: boolean;
  isInLoginMode: boolean;
  selectedDomainIds: string[];
};

type PropsForWithEnterpriseProgramData = Pick<PropsFromRouter, 'programSlug'>;

type PropsFromWithEnterpriseProgramDataQuery = {
  programs?: EnterpriseProgramsV1Type[] | null;
  thirdPartyOrg?: ThirdPartyOrganizationsV1Type | null;
  programBrowsingExperience?: ProgramBrowsingExperiencesV1Type | null;
  programContractTags?: EnterpriseContractTagsV1Type[] | null;
  enterpriseContracts?: EnterpriseContractsV1Type[] | null;
  renderErrorPage: boolean;
};

type PropsFromWithUserSkillProfileStatesQuery = {
  userSkillProfileStates?: UserSkillProfileStateType[];
  refetchUserSkillProfileStates?: () => Promise<unknown> | unknown;
  userSkillProfileStatesLoading?: boolean;
  allTargetSkillIds?: string[];
};

type PropsFromUserSkillScoresBySkillIdsQuery = {
  finishedSkillsets?: UserSkillProfileStateType[];
};

type PropsForWithEnterpriseProgramDataBranch = Pick<
  PropsFromWithEnterpriseProgramDataQuery,
  'programs' | 'thirdPartyOrg'
>;

type PropsFromCAWithProps = {
  isCareerAcademyV2: boolean;
};

type PropsFromWithEnterpriseProgramDataWithProps = {
  program: EnterpriseProgramsV1;
  programId: string;
  programName: string;
  thirdPartyOrganizationId: string;
  requirePreJoinCheck: boolean;
  browsingExperienceType?: ProgramBrowsingExperienceV1;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  orgName: string;
  isC4cv: boolean;
  isUpswell: boolean;
  isC4er: boolean;
  isC4wr: boolean;
  isC4C: boolean;
  isGwGProgram: boolean;
  isWESContract: boolean;
  showNoCertificateNotification: boolean;
  isC4Program: boolean;
};
type PropsFromWithEnterpriseProgramData = PropsFromWithEnterpriseProgramDataQuery &
  PropsFromWithEnterpriseProgramDataWithProps &
  PropsFromCAWithProps;

type PropsForBranchBypassLogin = Pick<PropsFromRouter, 'router'> &
  Pick<PropsFromWithEnterpriseProgramData, 'thirdPartyOrganizationId'>;

type PropsForBranchAgeVerification = Pick<
  PropsFromWithEnterpriseProgramData,
  'programId' | 'thirdPartyOrganization' | 'program'
> &
  PropsFromWithIsMounted &
  PropsFromWithProps2 &
  PropsFromWithState & {
    showRequireAgeVerification: boolean;
  } & Pick<PropsFromProgramHomeLoggedInQuery, 'isProgramMember'>;

type PropsForBranchBypassLoginRenderComponent = Required<Pick<PropsForBranchBypassLogin, 'thirdPartyOrganizationId'>>;

type PropsForBranchProgramNotFound = Pick<PropsFromWithEnterpriseProgramData, 'program' | 'thirdPartyOrganization'>;

type PropsForBranchProgramNotFoundRenderComponent = Required<PropsForBranchProgramNotFound>;

type PropsForBranchAllowlistedC4ERProgram = Pick<PropsFromWithEnterpriseProgramData, 'isC4er' | 'programId'>;

type PropsFromBranchAllowlistedC4ERProgram = {
  allowlistedProgramForC4er?: ExternallyAccessibleNostosV1[];
  naptime: InjectedNaptime;
};

type PropsForWithProps1 = Pick<PropsFromWithEnterpriseProgramData, 'isC4cv'> & PropsFromBranchAllowlistedC4ERProgram;

type PropsFromWithProps1 = {
  isNonBlacklistedC4cv: boolean;
  isAllowlistedC4er: boolean;
};

type PropsFromWithProps2 = {
  userId: number;
  isAuthenticatedUser: boolean;
  isSuperuser: boolean;
};

type PropsForBranch = PropsFromWithProps2 & PropsFromWithEnterpriseProgramData;

type PropsForProgramHomeLoggedInQuery = Pick<PropsFromWithProps2, 'userId'> &
  Pick<PropsFromWithEnterpriseProgramData, 'programId' | 'thirdPartyOrganizationId' | 'requirePreJoinCheck'> &
  Pick<PropsFromWithProps2, 'userId'> &
  Pick<PropsFromWithEnterpriseProgramData, 'requirePreJoinCheck'>;

type PropsFromProgramHomeLoggedInQuery = {
  renderErrorPage: boolean;
  programMembership?: ProgramMembershipsV2;
  acceptanceNoticeLogs?: EnterpriseNoticeAcceptanceLogsV1[];
  reloadProgramMembership?: () => void;
  isProgramMember: boolean;
};

type PropsFromDeferredIsProgramAdminQuery = { isProgramAdmin?: boolean; renderErrorPage?: boolean };

type PropsForDeferredProgramHomeLoggedInQuery = Pick<PropsFromWithProps2, 'userId'> &
  Pick<PropsFromWithEnterpriseProgramData, 'programId'> &
  PropsFromDeferredIsProgramAdminQuery;

type PropsFromDeferredProgramHomeLoggedInQuery = {
  renderErrorPage: boolean;
  isProgramAdmin: boolean;
};

type PropsFromFindUserEmailsByUserIdQuery = {
  userEmails?: UserEmail[];
};

type PropsForWithLoggedInData = PropsForProgramHomeLoggedInQuery &
  PropsForDeferredProgramHomeLoggedInQuery &
  PropsForWithSkillSetSaving;

type PropsFromWithLoggedInData = Required<Omit<PropsFromProgramHomeLoggedInQuery, 'renderErrorPage'>> &
  Required<Omit<PropsFromDeferredProgramHomeLoggedInQuery, 'renderErrorPage'>> &
  PropsFromWithSkillSetSaving;

type PropsForBranchProgramPreJoinCheck = Pick<PropsFromWithEnterpriseProgramData, 'requirePreJoinCheck' | 'program'> &
  Pick<PropsFromWithLoggedInData, 'acceptanceNoticeLogs'>;

export type PropsFromRefetchUserSkillProfileStates = {
  shouldRefetchUserSkillProfileStates: boolean;
  setShouldRefetchUserSkillProfileStates: (value: boolean) => void;
};

export type PropsFromSaveSkillSetToggle = {
  onSkillSetSaveToggle: ({
    targetSkillProfileId,
    state,
    setSaveInProgress,
    shouldRefetch,
  }: {
    targetSkillProfileId: string;
    state: SkillProfileStates;
    setSaveInProgress?: (b: boolean) => void;
    shouldRefetch?: boolean;
  }) => Promise<void>;
};

type PropsFromProductFeatures = {
  isBrowseOnlyProgram: boolean;
  shouldShowSponsoredByMessage: boolean;
  shouldShowEulaBanner: boolean;
  shouldHideCourseraRecommendations: boolean;
  showRequireAgeVerification: boolean;
  programCollectionSectionDescription: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  allowOrgForCurriculumBuilder: boolean;
  shouldUseJoinTrackingVerbiage: boolean;
  forceEnableProgramSkillSetFilters: boolean | null;
  isCareerAcademyLimitedCatalogProgram: boolean;
  enableEnterpriseCustomBadges: boolean;
  enableSkillsInSearchAndBrowse: boolean;
  shouldShowShortFormContent: boolean;
  enableSkillsDashboard: boolean;
  isFeatureToggleLoading: boolean;
  enableRenderDomainsMenu: boolean;
  enableInvitedOnlyProgram: boolean;
};

type PropsFromWithOrgProductFeatures = {
  allowOrgForSpecializationConfiguration?: boolean;
  enableAcademicDisciplines?: boolean;
  isLevelSetsEnabled: boolean;
  showSkillSetRoleFilter: boolean;
  enablePersonalizedRecommendationsForOrg: boolean;
};

type PropsForWithAutoEnrolledCourseData = Pick<
  Props,
  'programId' | 'allowOrgForCurriculumBuilder' | 'programMembership'
> &
  PropsFromWithProps2;

type PropsFromWithAutoenrolledCourses = {
  autoenrolledCourseIds?: string[];
};

type PropsFromWithAutoenrolledCoursesData = {
  autoEnrolledCoursesWithData?: Course[];
};

type PropsFromWithState = {
  enableAgeVerification: boolean;
  setEnableAgeVerification: (val: boolean) => void;
};

export type PropsFromWithState2 = {
  showFreemiumCourseReenrollModal: boolean;
  setShowFreemiumCourseReenrollModal: (val: boolean) => void;
};

type PropsForBranchFreemiumContractRenewalModal = PropsFromWithState2 & PropsFromWithFreemiumCourseReenrollData;

type PropsForBranchFreemiumContractRenewalModalRenderComponent = Required<
  Pick<
    PropsForBranchFreemiumContractRenewalModal,
    'setShowFreemiumCourseReenrollModal' | 'freemiumEnrollmentFilteredCourses'
  >
>;

type Props = PropsFromStores &
  PropsFromRouter &
  PropsFromWithEnterpriseProgramData &
  PropsFromBranchAllowlistedC4ERProgram &
  PropsFromWithProps1 &
  PropsFromWithProps2 &
  PropsFromWithLoggedInData &
  PropsFromSaveSkillSetToggle &
  PropsFromWithUserSkillProfileStatesQuery &
  PropsFromRefetchUserSkillProfileStates &
  PropsFromProductFeatures &
  PropsFromWithIsMounted &
  PropsFromWithState &
  PropsFromWithState2 &
  PropsFromWithFreemiumCourseReenrollData &
  PropsFromWithAutoenrolledCourses &
  PropsFromWithOrgProductFeatures &
  PropsFromFindUserEmailsByUserIdQuery;

class ProgramHomeApp extends React.Component<Props, {}> {
  static contextTypes = {
    getStore: PropTypes.func.isRequired,
  };

  static childContextTypes = {
    csrfToken: PropTypes.string,
    requestCountryCode: PropTypes.string,
  };

  static statics = {
    storeListeners: [ApplicationStore],
  };

  getChildContext() {
    const { csrfToken, requestCountryCode } = this.props;
    return {
      csrfToken,
      requestCountryCode,
    };
  }

  componentDidMount() {
    const {
      thirdPartyOrganization,
      userId,
      isSuperuser,
      isProgramMember,
      isProgramAdmin,
      programId,
      naptime,
      isAuthenticatedUser,
      thirdPartyOrganizationId,
      program,
      programContractTags,
      enterpriseContracts,
      programSlug,
      isCareerAcademyV2,
    } = this.props;

    if (userId && isProgramMember) {
      naptime.executeMutation(
        ProgramSwitcherSelectionsV1.update(userId, {
          selectionType: 'PROGRAM',
          programId,
        })
      );
    }

    const fullstoryEnabledOrgs = FullStoryExperiments.get('enableFullstoryProgramHomeOrgs');
    const enableFullstoryForCAV2 =
      isCareerAcademyV2 && FullStoryExperiments.get('enableFullstoryEnterpriseCareerAcademy');

    if (fullstoryEnabledOrgs.includes(thirdPartyOrganizationId) || enableFullstoryForCAV2) {
      fullStory.init();
      fullStory.set({
        isCareerAcademyV2,
      });
    }

    if (!EnterpriseExperiments.get('disablePendo').includes(thirdPartyOrganizationId)) {
      const contractTag =
        (programContractTags?.map((programContractTag) => programContractTag.contractTag) as
          | Array<string>
          | undefined) ?? [];
      let visitor = {};
      const isSkillSetsEnabled = Boolean(program?.metadata?.linkedTargetSkillProfileIds?.length);
      if (isAuthenticatedUser) {
        const { external_id: externalId, is_staff: isStaff } = user.get();
        visitor = {
          id: externalId,
          isProgramAdmin,
          isProgramMember,
          isStaff,
          isSuperuser,
          contractTag,
          isSkillSetsEnabled,
          isCourserian: user.isCourserian(),
          programSlug,
        };
      }
      const isStandardContract = !!enterpriseContracts?.some(({ contractType }) => contractType === 'STANDARD');
      const name: string = thirdPartyOrganization.name;
      const slug: string = thirdPartyOrganization.slug;
      const organizationType = thirdPartyOrganization.organizationType;
      pendo.init(
        {
          visitor,
          account: {
            id: thirdPartyOrganizationId,
            name,
            slug,
            contractTag,
            isStandardContract,
            isEnterprise: true,
            ...(organizationType && { organizationType }),
            programSlug,
            isCareerAcademyV2,
          },
        },
        LEARNER_API_KEY
      );
    }
  }

  componentWillUnmount() {
    fullStory.endSession();
  }

  render() {
    const {
      children,
      program,
      programName,
      naptime,
      userId,
      enableInvitedOnlyProgram,
      isAuthenticatedUser,
      isProgramAdmin,
      programMembership,
      enterpriseContracts,
    } = this.props;
    const shouldRenderAccessErrorPage =
      enableInvitedOnlyProgram && (!isAuthenticatedUser || (!isProgramAdmin && programMembership.isNotProgramMember));

    if (
      program.isArchived ||
      (EnterpriseExperiments.get('enableNoActiveContractsPage') &&
        enterpriseContracts &&
        allContractsHardPausedOrTerminated(enterpriseContracts))
    ) {
      return <ProgramArchivedDenyModal naptime={naptime} userId={userId} />;
    }
    // removing children from props so that cloneElement would not add children props to actual children
    const props = omit(this.props, 'children');
    return (
      <React.Fragment>
        {/* Additional theme provider required for search components */}
        <ThemeProvider theme={searchTheme}>
          <div className="rc-ProgramHomeApp">
            <SimpleCourseraMetatags disableCrawlerIndexing title={shouldRenderAccessErrorPage ? '' : programName} />
            <Alice />
            {children && React.cloneElement(children as React.ReactElement, props)}
            {!children && (
              <SingleProgramPage {...this.props} shouldRenderAccessErrorPage={shouldRenderAccessErrorPage} />
            )}
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const branchProgramNotFound = branch<PropsForBranchProgramNotFound>(
  (props) => !props.program || !props.thirdPartyOrganization,
  renderComponent<PropsForBranchProgramNotFoundRenderComponent>(ProgramNotFound)
);

const branchAllowlistedC4ERProgram = branch<PropsForBranchAllowlistedC4ERProgram>(
  (props) => Boolean(props.isC4er),
  Naptime.createContainer(({ programId }) => ({
    allowlistedProgramForC4er: ExternallyAccessibleNostosV1.finder('getAllProperties', {
      // eslint-disable-next-line camelcase
      params: { job_name: 'c4er_social_share_allowlist', keys: programId },
    }),
  }))
);

export const branchBypassLogin = branch<PropsForBranchBypassLogin>(
  ({ router, thirdPartyOrganizationId }) => {
    const { attemptSSOLogin } = router.location.query;
    return attemptSSOLogin && !user.isAuthenticatedUser() && !!thirdPartyOrganizationId;
  },
  renderComponent<PropsForBranchBypassLoginRenderComponent>(({ thirdPartyOrganizationId }) => (
    <BypassLogin thirdPartyOrganizationId={thirdPartyOrganizationId} />
  ))
);

const branchAgeVerification = branch<PropsForBranchAgeVerification>(
  ({ enableAgeVerification, programId, isMounted, showRequireAgeVerification, isAuthenticatedUser, isProgramMember }) =>
    !isProgramMember &&
    isMounted &&
    showRequireAgeVerification &&
    enableAgeVerification &&
    !getAgeVerification(programId, isAuthenticatedUser),
  renderComponent(AgeVerificationPage)
);

const branchFreemiumContractRenewalModal = branch<PropsForBranchFreemiumContractRenewalModal>(
  ({ showFreemiumCourseReenrollModal, freemiumEnrollmentFilteredCourses }) =>
    showFreemiumCourseReenrollModal &&
    freemiumEnrollmentFilteredCourses &&
    freemiumEnrollmentFilteredCourses.length > 0,
  renderComponent<PropsForBranchFreemiumContractRenewalModalRenderComponent>(
    ({ setShowFreemiumCourseReenrollModal, freemiumEnrollmentFilteredCourses }) => (
      <CourseReenrollModals
        userName={user.get().fullName}
        setShowFreemiumCourseReenrollModal={setShowFreemiumCourseReenrollModal}
        freemiumEnrollmentFilteredCourses={freemiumEnrollmentFilteredCourses}
      />
    )
  )
);

// Branch off when the program is valid and the org requires privacy check and: the user isn't logged in or he user hasn't accepted the privacy agreement
const branchProgramPreJoinCheck = branch<PropsForBranchProgramPreJoinCheck>(
  (props) =>
    Boolean(
      props.requirePreJoinCheck &&
        _.size(props.acceptanceNoticeLogs) === 0 &&
        !props.program.isArchived &&
        !isForcedSSOLoginFlowEnabled()
    ),
  renderComponent(ProgramPreJoinCheck)
);

function getXhrErrorMessage() {
  return _t('Sorry! Something went wrong. Please refresh the page.');
}

function getRoutingErrorMessage() {
  return _t(
    'The program slug missing from the URL: the correct format is: https://www.coursera.org/programs/[YOUR PROGRAM SLUG]'
  );
}

export const withAutoEnrolledCourseData = compose<
  PropsForWithAutoEnrolledCourseData,
  PropsFromWithAutoenrolledCoursesData
>(
  graphql<
    Pick<Props, 'programId' | 'allowOrgForCurriculumBuilder' | 'programMembership'>,
    AutoenrolledCoursesQueryData,
    AutoenrolledCoursesQueryVariables,
    PropsFromWithAutoenrolledCourses
  >(AutoenrolledCoursesQuery, {
    skip: ({ allowOrgForCurriculumBuilder }) =>
      !allowOrgForCurriculumBuilder || !C4CExperiments.get('enableSessionAutoEnrollment'),
    options: ({ programId, programMembership }) => {
      // If the user is already a member of the program only, include the courses they were successfully autoenrolled in
      // otherwise include all the courses that autoenroll triggers have been set
      const includeEnrolledCoursesOnly = Boolean(programMembership?.isProgramMember);
      return { variables: { programId, includeEnrolledCoursesOnly }, ssrBlocking: true };
    },
    props: ({ data }) => {
      const allTargets = filterExistsOrDefault(data?.ProgramEnrollmentSettingsV1Resource?.joinProgramCourses?.elements);
      const autoenrolledCourseIds = allTargets
        .filter((target) => Boolean(target.triggerEventJoinedProgram))
        .map((target) => (target?.enrollmentTarget.targetProduct as CourseEnrollment).courseEnrollment.targetCourseId);
      return { autoenrolledCourseIds };
    },
  }),
  graphql<
    PropsFromWithAutoenrolledCourses,
    ProductCoursesQueryType,
    ProductCoursesQueryVariables,
    PropsFromWithAutoenrolledCoursesData
  >(ProductCoursesQuery, {
    skip: ({ autoenrolledCourseIds }) => !autoenrolledCourseIds?.length,
    options: ({ autoenrolledCourseIds }) => ({
      variables: {
        // autoenrolledCourseIds is checked if it's defined above in skip

        courseIds: autoenrolledCourseIds!,
        showHidden: true,
        withCorrectBehavior: true,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }),
    props: ({ data }) => {
      // CoursesV1 will not return any data about private courses for learners that have been inivited but haven't enrolled yet
      // EMPLOYER-11274 was created to update the permissions
      const autoEnrolledCoursesWithData = filterExistsOrDefault(data?.Course?.queryByIds);
      return { autoEnrolledCoursesWithData };
    },
  })
);

const withEnterpriseProgramData = compose<PropsForWithEnterpriseProgramData, PropsFromWithEnterpriseProgramData>(
  waitForGraphQL<
    PropsForWithEnterpriseProgramData,
    EnterpriseProgramBySlugQueryResult,
    EnterpriseProgramBySlugQueryVariables,
    PropsFromWithEnterpriseProgramDataQuery
  >(EnterpriseProgramBySlugQuery, {
    options: ({ programSlug }) => ({ variables: { programSlug } }),
    props: ({ data }) => {
      return {
        programs: data?.EnterpriseProgramsV1?.slug?.elements,
        thirdPartyOrg: data?.EnterpriseProgramsV1?.slug?.linked?.thirdPartyOrganizationsV1?.[0],
        programBrowsingExperience: data?.EnterpriseProgramsV1?.slug?.linked?.programBrowsingExperiencesV1?.[0],
        programContractTags: data?.EnterpriseProgramsV1?.slug?.linked?.enterpriseContractTagsV1,
        enterpriseContracts: data?.EnterpriseProgramsV1?.slug?.linked?.enterpriseContractsV1,
        renderErrorPage: Boolean(data?.error),
      };
    },
  }),

  withErrorPage({ errorPageMsg: getXhrErrorMessage, errorPageIgnoreChildren: true }),
  branch<PropsForWithEnterpriseProgramDataBranch>(
    ({ programs, thirdPartyOrg }) => programs?.[0] == null || thirdPartyOrg == null,
    renderComponent(NotFound)
  ),
  withProps<PropsFromWithEnterpriseProgramDataWithProps, PropsFromWithEnterpriseProgramDataQuery>(
    ({ enterpriseContracts, programs, thirdPartyOrg, programBrowsingExperience, programContractTags }) => {
      // See above branch().

      const program = new EnterpriseProgramsV1(programs![0]);
      // See above branch().

      const thirdPartyOrganization = new ThirdPartyOrganizationsV1(thirdPartyOrg!);
      const requirePreJoinCheck =
        thirdPartyOrganization.programVisibilityRule === PROGRAM_VISIBILITY_RULE.ACCEPTED_PRIVACY_NOTICE;

      const browsingExperience = programBrowsingExperience
        ? new ProgramBrowsingExperienceV1(programBrowsingExperience)
        : undefined;

      const contractType = enterpriseContracts?.[0]?.contractType;
      const hasFreemiumTag =
        programContractTags?.some((programContractTag) => programContractTag.contractTag === C4C_FREEMIUM) ?? false;
      const isC4cv =
        programContractTags?.some((programContractTag) => programContractTag.contractTag === C4C_CORONA_TRIAL) ||
        hasFreemiumTag;
      const isUpswell =
        programContractTags?.some((programContractTag) => programContractTag.contractTag === C4C_UPSWELL) ?? false;
      const isC4er =
        programContractTags?.some((programContractTag) => programContractTag.contractTag === COURSERA_CORE) ?? false;
      const isC4wr =
        programContractTags?.some((programContractTag) => programContractTag.contractTag === C4WR_CORONA_TRIAL) ??
        false;

      const isGwGProgram =
        programContractTags?.some((programContractTag) => GWG_FREEMIUM.includes(programContractTag.contractTag)) ??
        false;

      const isC4C =
        programContractTags?.some((programContractTag) => programContractTag.contractTag.startsWith('C4C')) ?? false;
      const isWESContract = contractType === enterpriseContractType.SELF_SERVE;
      const showNoCertificateNotification = isUpswell || isC4cv || isC4wr || hasFreemiumTag;
      const isC4Program = isC4cv || isC4er || isC4wr || isUpswell;

      return {
        program,
        programId: program.id,
        programName: program.name,
        thirdPartyOrganizationId: program.thirdPartyOrganizationId,
        requirePreJoinCheck,
        browsingExperienceType: browsingExperience,
        thirdPartyOrganization,
        orgName: thirdPartyOrganization.name,
        isC4cv,
        isUpswell,
        isC4er,
        isC4wr,
        isWESContract,
        showNoCertificateNotification,
        isC4Program,
        hasFreemiumTag,
        isC4C,
        isGwGProgram,
      };
    }
  ),
  getProgramCareerAcademyVariantQueryGraphQl<{ program: EnterpriseProgramsV1 }>(),
  withProps<PropsFromCAWithProps, PropCareerAcademyVariant>(({ careerAcademyVariant }) => {
    return {
      isCareerAcademyV2:
        isCareerAcademyV2Enabled(careerAcademyVariant) || isCareerAcademyFreemiumEnabled(careerAcademyVariant),
    };
  })
);

type PropsForWithSkillSetSaving = {
  userId: number;
  program: { id: string } | undefined;
};

type PropsFromWithSkillSetSavingWithState = {
  shouldRefetchUserSkillProfileStates: boolean;
  setShouldRefetchUserSkillProfileStates: (shouldRefetchUserSkillProfileStates: boolean) => void;
};

type PropsForWithSkillSetSavingWithHandlers = PropsForWithSkillSetSaving &
  PropsFromWithUserSkillProfileStatesQuery &
  PropsFromWithSkillSetSavingWithState & {
    naptime: InjectedNaptime;
  };

export type PropsFromWithSkillSetSaving = PropsFromWithUserSkillProfileStatesQuery &
  PropsFromWithSkillSetSavingWithState &
  PropsFromSaveSkillSetToggle &
  PropsFromUserSkillScoresBySkillIdsQuery;

export const withSkillSetSaving = compose<PropsFromWithSkillSetSaving, PropsForWithSkillSetSaving>(
  // Needed for naptime.executeMutation in withHandlers
  Naptime.createContainer(() => ({})),
  graphql<
    PropsForWithSkillSetSaving,
    TargetSkillProfileUserStatesQueryData,
    TargetSkillProfileUserStatesQueryVariables,
    PropsFromWithUserSkillProfileStatesQuery
  >(TargetSkillProfileUserStatesQuery, {
    // @ts-expect-error react-apollo@2.2.0
    skip: ({ userId, program }) => !userId || !program?.id || !program?.metadata?.linkedTargetSkillProfileIds?.length,
    options: ({ userId, program }) => ({
      variables: {
        userId: userId.toString(),
        // See above skip().

        programId: program!.id,
      },
    }),
    props: ({ data }) => {
      const userSkillProfileStates = filterExistsOrDefault(
        data?.TargetSkillProfileUserStatesV1Resource?.byUserAndProgram?.elements
      );
      const refetchUserSkillProfileStates = data?.refetch;
      const userSkillProfileStatesLoading = data?.loading ?? true;
      const allTargetSkillIds =
        uniq(
          userSkillProfileStates?.flatMap((skillset) => skillset?.targetSkillProfileSummary?.targetSkillProficiencies)
        )?.map((skill) => skill?.skillId ?? '') ?? [];
      return {
        userSkillProfileStates,
        refetchUserSkillProfileStates,
        userSkillProfileStatesLoading,
        allTargetSkillIds,
      };
    },
  }),
  graphql<
    PropsFromWithUserSkillProfileStatesQuery,
    UserSkillScoresBySkillIdsQueryData,
    UserSkillScoresBySkillIdsQueryVariables,
    PropsFromUserSkillScoresBySkillIdsQuery
  >(UserSkillScoresBySkillIdsQuery, {
    skip: ({ userSkillProfileStatesLoading, allTargetSkillIds }) =>
      userSkillProfileStatesLoading || !allTargetSkillIds?.length,
    options: ({ allTargetSkillIds }) => ({
      variables: { skillIds: allTargetSkillIds ?? [] },
      context: { clientName: 'gatewayGql' },
    }),
    props: ({ data, ownProps }) => {
      const { userSkillProfileStates = [] } = ownProps;
      const allSkillScores = data?.SkillScores?.userSkillScoresBySkillIds ?? [];
      const skillsScoresById = allSkillScores.reduce(
        (acc, skillScore) => ({
          ...acc,
          [skillScore.skill.id]: skillScore.readableScore ?? 0,
        }),
        {} as { [id: string]: number }
      );

      const finishedSkillsets = userSkillProfileStates.reduce((acc, skillset) => {
        const allTargetSkillScores = skillset?.targetSkillProfileSummary?.targetSkillProficiencies;
        return every(
          allTargetSkillScores,
          (target) => skillsScoresById[target.skillId] && skillsScoresById[target.skillId] >= target.targetProficiency
        )
          ? [...acc, skillset]
          : acc;
      }, [] as UserSkillProfileStateType[]);

      return { finishedSkillsets };
    },
  }),
  withState('shouldRefetchUserSkillProfileStates', 'setShouldRefetchUserSkillProfileStates', false),
  withHandlers<PropsForWithSkillSetSavingWithHandlers, PropsFromSaveSkillSetToggle>({
    onSkillSetSaveToggle:
      ({ naptime, userId, refetchUserSkillProfileStates, setShouldRefetchUserSkillProfileStates }) =>
      async ({ targetSkillProfileId, state, setSaveInProgress, shouldRefetch = true }) => {
        try {
          if (!targetSkillProfileId) {
            const error = new Error('Invalid TSP id');
            Object.assign(error, { targetSkillProfileId });
            throw error;
          }
          setSaveInProgress?.(true);
          await naptime.executeMutation(
            TargetSkillProfileUserStatesV1.action('set', { userId, state, targetSkillProfileId })
          );
          if (shouldRefetch && refetchUserSkillProfileStates) {
            await refetchUserSkillProfileStates();
          } else {
            setShouldRefetchUserSkillProfileStates(true);
          }
        } finally {
          setSaveInProgress?.(false);
        }
      },
  })
);

const withLoggedInData = compose<PropsForWithLoggedInData, PropsFromWithLoggedInData>(
  waitForGraphQL<
    PropsForProgramHomeLoggedInQuery,
    ProgramHomeLoggedInQuery,
    ProgramHomeLoggedInQueryVariables,
    PropsFromProgramHomeLoggedInQuery
  >(ProgramHomeLoggedInQueryText, {
    options: ({ programId, userId, thirdPartyOrganizationId, requirePreJoinCheck }) => ({
      variables: {
        programMembershipId: `${userId}~${programId}`,
        userId: userId.toString(),
        thirdPartyOrganizationId,
        requirePreJoinCheck,
      },
    }),
    props: ({ data, ownProps: { requirePreJoinCheck } }): PropsFromProgramHomeLoggedInQuery => {
      const programMembershipsV2 = data?.ProgramMembershipsV2?.get?.elements?.[0];
      const programMembership = programMembershipsV2 && new ProgramMembershipsV2(programMembershipsV2);
      const enterpriseNoticeAcceptanceLogsV1 = requirePreJoinCheck
        ? data?.EnterpriseNoticeAcceptanceLogsV1?.getByUserIdAndOrganizationId?.elements
        : undefined;
      const acceptanceNoticeLogs = enterpriseNoticeAcceptanceLogsV1?.map(
        (x) => new EnterpriseNoticeAcceptanceLogsV1(x)
      );
      const reloadProgramMembership = data?.refetch;
      const isProgramMember = Boolean(programMembership?.isMember);

      return {
        renderErrorPage: Boolean(data?.error),
        programMembership,
        acceptanceNoticeLogs,
        reloadProgramMembership,
        isProgramMember,
      };
    },
  }),
  withErrorPage({ errorPageMsg: getXhrErrorMessage, errorPageIgnoreChildren: true }),
  graphql<
    {},
    FindUserEmailsByUserIdQueryResult,
    FindUserEmailsByUserIdQueryVariables,
    PropsFromFindUserEmailsByUserIdQuery
  >(FindUserEmailsByUserIdQuery, {
    props: ({ data }) => {
      const userEmails = data?.FindUserEmailsByUserIdResponse?.userEmails;
      return { userEmails };
    },
    options: () => ({
      variables: {
        userId: `${user.get().id}`,
      },
      errorPolicy: 'all',
    }),
  }),
  // IsProgramAdmin is a replacement for EnterpriseAdminRolesV1 used in DeferredProgramHomeLoggedInQueryText
  // it currently accounts for Staff/Mentor roles
  graphql<
    PropsForDeferredProgramHomeLoggedInQuery,
    IsProgramAdminQuery,
    IsProgramAdminQueryVariables,
    PropsFromDeferredProgramHomeLoggedInQuery
  >(IsProgramAdmin, {
    options: ({ programId }) => ({
      variables: { programId },
      context: { clientName: 'gatewayGql' },
      skip: !user.isAuthenticatedUser(),
      ssr: false, // same as DeferredProgramHomeLoggedInQueryText
    }),
    props: ({ data }) => {
      return {
        renderErrorPage: Boolean(data?.error),
        isProgramAdmin: Boolean(data?.EnterprisePermission?.queryIsProgramAdmin),
      };
    },
  }),
  graphql<
    PropsForDeferredProgramHomeLoggedInQuery,
    DeferredProgramHomeLoggedInQuery,
    DeferredProgramHomeLoggedInQueryVariables,
    PropsFromDeferredProgramHomeLoggedInQuery
  >(DeferredProgramHomeLoggedInQueryText, {
    options: ({ programId, userId }) => ({
      variables: {
        programMembershipId: `${userId}~${programId}`,
        userId: userId.toString(),
        programId,
      },
      ssr: false,
    }),
    props: ({ data, ownProps }) => {
      // `EnterpriseAdminRolesV1` is for legacy roles (ie Program Admin),
      const enterpriseAdminRolesV1 = data?.EnterpriseAdminRolesV1?.byUserProgram?.elements;
      const isProgramAdmin = Boolean(enterpriseAdminRolesV1?.length || ownProps.isProgramAdmin);
      return {
        renderErrorPage: Boolean(data?.error ?? ownProps.renderErrorPage),
        isProgramAdmin,
      };
    },
  }),
  withErrorPage({ errorPageMsg: getXhrErrorMessage, errorPageIgnoreChildren: true }),
  withSkillSetSaving
);

const TrackedProgramHomeApp = Retracked.createTrackedContainer(({ programName, programId, programSlug }: Props) => ({
  namespace: {
    app: 'program_home_v3',
    page: 'home_page',
  },
  program: {
    id: programId,
    name: programName,
    slug: programSlug,
  },
}))(ProgramHomeApp) as typeof ProgramHomeApp;

export type JwtInviteCheckProps = Pick<PropsFromRouter, 'router'> &
  Pick<PropsFromWithLoggedInData, 'isProgramAdmin' | 'programMembership'> &
  Pick<PropsFromWithProps2, 'isAuthenticatedUser'>;

export const withJwtInviteCheck = lifecycle<JwtInviteCheckProps, {}>({
  componentDidMount() {
    const { router, programMembership, isProgramAdmin, isAuthenticatedUser } = this.props;
    const { organization, webToken, invitationToken, authMode } = router.location.query;
    const shouldTryJwtRedirect =
      // query params needed for jwt validation
      organization &&
      (webToken || invitationToken) &&
      // bypass for login form (has its own version of this)
      !authMode &&
      // bypass for learners not invited to the program, program membership will always be defined
      (!isAuthenticatedUser || programMembership.isInvited || programMembership.isEmailNotVerified) &&
      // bypass for admins
      !isProgramAdmin;

    if (!shouldTryJwtRedirect) return;

    // above we check for webToken || invitationToken
    (webToken
      ? JWTLoginAPIUtils.getToken(organization, webToken)
      : JWTLoginAPIUtils.getTokenFromInvitation(organization, invitationToken)
    )
      .then(
        (response) => {
          if (response.id) {
            const returnUrlBuilder = new URL(window.location.href);
            returnUrlBuilder.searchParams.delete('organization');
            returnUrlBuilder.searchParams.delete('webToken');
            const returnUrl = returnUrlBuilder.toString();
            cookie.set('returnTo', returnUrl, { expires: 1 });
            redirect.setLocation(`/sso/return/jwt_invite/${response.id}?returnTo=${encodeURIComponent(returnUrl)}`);
          }
        },
        () => {
          router.replace({
            pathname: router.location.pathname,
            params: router.params,
            query: {
              ...router.location.query,
              organization: null,
              webToken: null,
              errorCode: 'invalidWebToken',
            },
          });
        }
      )
      .done();
  },
});

export type SsoCheckProps = Pick<
  Props,
  | 'thirdPartyOrganization'
  | 'router'
  | 'userId'
  | 'isProgramMember'
  | 'isProgramAdmin'
  | 'isAuthenticatedUser'
  | 'isInLoginMode'
  | 'program'
>;

export const withSsoCheck = lifecycle<SsoCheckProps, {}>({
  componentDidMount() {
    const {
      thirdPartyOrganization,
      router,
      userId,
      isProgramMember,
      isProgramAdmin,
      isAuthenticatedUser,
      isInLoginMode,
      program,
    } = this.props;

    let { query } = router.location;

    if (isAuthenticatedUser) {
      const { itemId, attemptSSOLogin, productType } = query;

      query = _.omit(router.location.query, 'attemptSSOLogin');

      if (itemId && attemptSSOLogin && productType === 'course') {
        query = {
          ...query,
          deepLinkItemId: itemId,
        };
      }
    }

    if (thirdPartyOrganization.loginMethod === 'SAML') {
      router.push({
        pathname: router.location.pathname,
        params: router.params,
        query: { ...query, authProvider: thirdPartyOrganization.slug },
        state: { curriculumId: program && program.curriculumId },
      });
    }

    if (userId && !(isProgramMember || isProgramAdmin)) {
      // Redirect to program home if the user is not a member or admin
      if (thirdPartyOrganization.loginMethod === 'SAML') {
        query = { ...query, authProvider: thirdPartyOrganization.slug };
      }

      router.push({
        pathname: router.location.pathname,
        params: { programSlug: router.params.programSlug },
        query,
      });
    }

    if (isAuthenticatedUser && isInLoginMode) {
      router.push({
        pathname: router.location.pathname,
        params: router.params,
        query: _.omit(router.location.query, 'authMode', 'attemptSSOLogin'),
      });
    }
  },

  componentDidUpdate({ isAuthenticatedUser: prevIsAuthenticatedUser, isInLoginMode: prevIsInLoginMode }) {
    const { isAuthenticatedUser, isInLoginMode, router } = this.props;
    if (
      (isAuthenticatedUser !== prevIsAuthenticatedUser || isInLoginMode !== prevIsInLoginMode) &&
      isAuthenticatedUser &&
      isInLoginMode
    ) {
      router.push({
        pathname: router.location.pathname,
        params: router.params,
        query: _.omit(router.location.query, 'authMode', 'attemptSSOLogin'),
      });
    }
  },
});

export default compose<Props, {}>(
  setDisplayName('ProgramHomeAppGQLHOC'),
  connectToStores<PropsFromStores, {}>(['ApplicationStore'], (context: { ApplicationStore: ApplicationStore }) => ({
    csrfToken: context.ApplicationStore.getState().csrfToken,
    requestCountryCode: context.ApplicationStore.getState().requestCountryCode,
  })),
  connectToRouter<PropsFromRouter, {}>((router): PropsFromRouter => {
    const selectedDomainIdsString: string = router.location.query.selectedDomainIds || '';
    return {
      programSlug: router.params.programSlug,
      renderErrorPage: !router.params.programSlug,
      router,
      isInLoginMode: router.location.query.authMode === 'login',
      selectedDomainIds: selectedDomainIdsString.split(','),
    };
  }),
  withErrorPage({ errorPageMsg: getRoutingErrorMessage, errorPageIgnoreChildren: true }),
  withGDPR,
  withCCPA,
  Naptime.createContainer(() => ({})), // naptime container needed in ProgramHomeApp for mutation
  withEnterpriseProgramData,
  branchBypassLogin,
  branchProgramNotFound,
  branchAllowlistedC4ERProgram,
  withProps<PropsFromWithProps1, PropsForWithProps1>(({ isC4cv, allowlistedProgramForC4er }): PropsFromWithProps1 => {
    const isNonBlacklistedC4cv = Boolean(isC4cv);
    const isAllowlistedC4er = !!allowlistedProgramForC4er?.[0]?.content?.isAllowlistedProgram;

    return { isNonBlacklistedC4cv, isAllowlistedC4er };
  }),
  withProps<PropsFromWithProps2, {}>(() => {
    return {
      userId: user.get().id,
      isAuthenticatedUser: user.isAuthenticatedUser(),
      isSuperuser: user.isSuperuser(),
    };
  }),
  branch<PropsForBranch>((props) => Boolean(props.userId && !props.program.isArchived), withLoggedInData),
  withState('showFreemiumCourseReenrollModal', 'setShowFreemiumCourseReenrollModal', true),
  withFreemiumCourseReenrollData,
  branchProgramPreJoinCheck,
  withAliceNotification(
    () => {
      return new AlicePageviewEvent({ contextType: PROGRAM_HOME });
    },
    // eslint-disable-next-line camelcase
    () => ({ course_id: 'NO_COURSE_ID' })
  ),
  withProductFeatures<PropsFromProductFeatures, PropsFromWithEnterpriseProgramDataWithProps>(
    ({ programId }) => ({
      idType: IdTypes.Program,
      id: programId,
    }),
    ({ loading, error, features }) => ({
      isBrowseOnlyProgram: features.get('enterprise', 'browseOnlyProgram'),
      shouldShowSponsoredByMessage: features.get('enterprise', 'programSponsoredByMessage'),
      shouldShowEulaBanner: features.get('enterprise', 'programEulaBanner'),
      shouldHideCourseraRecommendations: features.get('enterprise', 'programHideCourseraRecommendations'),
      showRequireAgeVerification: features.get('enterprise', 'programJoinAgeVerification'),
      programCollectionSectionDescription: features.get('enterprise', 'programCollectionSectionDescription'),
      programRecommendationsAvailableCoursesCopy: features.get(
        'enterprise',
        'programRecommendationsAvailableCoursesCopy'
      ),
      allowOrgForCurriculumBuilder: features.get('enterprise', 'allowOrgForCurriculumBuilder'),
      shouldUseJoinTrackingVerbiage: features.get('enterprise', 'programJoinTrackingVerbiage'),
      forceEnableProgramSkillSetFilters: loading || error ? null : features.get('enterprise', 'programSkillSetFilters'),
      isCareerAcademyLimitedCatalogProgram: features.get('enterprise', 'isCareerAcademyLimitedCatalog'),
      enableEnterpriseCustomBadges: features.get('enterprise', 'enableEnterpriseCustomBadges'),
      allowOrgForSpecializationConfiguration: features.get('enterprise', 'allowOrgForSpecializationConfiguration'),
      enableSkillsInSearchAndBrowse: features.get('enterprise', 'enableSkillsInSearchAndBrowse'),
      shouldShowShortFormContent: features.get('enterprise', 'allowShortFormContentBasedOnAudience'),
      enableSkillsDashboard: features.get('enterprise', 'enableSkillsDashboard'),
      enableRenderDomainsMenu: features.get('enterprise', 'enableRenderDomainsMenu'),
      enableInvitedOnlyProgram: features.get('enterprise', 'enableInvitedOnlyProgram'),
      isFeatureToggleLoading: loading,
    })
  ),
  withProductFeatures<PropsFromWithOrgProductFeatures, { thirdPartyOrg: { id: string } }>(
    ({ thirdPartyOrg }) => ({ idType: IdTypes.Organization, id: thirdPartyOrg.id }),
    ({ features }) => ({
      allowOrgForSpecializationConfiguration: features.get('enterprise', 'allowOrgForSpecializationConfiguration'),
      enableAcademicDisciplines: features.get('enterprise', 'enableAcademicDisciplines'),
      isLevelSetsEnabled: features.get('diagnostics', 'enableSkillsDiagnostics'),
      showSkillSetRoleFilter: features.get('enterprise', 'enableSkillsetRoleFilter'),
      enablePersonalizedRecommendationsForOrg: features.get('enterprise', 'personalizedRecommendations'),
    })
  ),
  withAutoEnrolledCourseData,
  withIsMounted(),
  withState('enableAgeVerification', 'setEnableAgeVerification', true),
  branchAgeVerification,
  branchFreemiumContractRenewalModal,
  withJwtInviteCheck,
  withSsoCheck
)(TrackedProgramHomeApp);
