import * as React from 'react';

import type { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';

import { Col, Row } from '@coursera/coursera-ui';
import { SvgLoaderSignal } from '@coursera/coursera-ui/svg';

import ProductListWithExpandToggle from 'bundles/course-cards/components/course-card/enterprise/ProductListWithExpandToggle';
import type {
  EnterpriseBadgeCollectionsQuery as EnterpriseBadgeCollectionsQueryData,
  EnterpriseBadgeCollectionsQueryVariables,
} from 'bundles/enterprise-learner-onboarding/queries/__generated__/EnterpriseBadgeCollectionsQuery';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import filterExistsOrDefault from 'bundles/program-common/utils/filterExistsOrDefault';
import { Heading, Section } from 'bundles/program-home/components/AutoHeading';
import CourseCompletionBanner from 'bundles/program-home/components/CourseCompletionBanner';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import { org_coursera_enterprise_skills_TargetSkillProfileUserGoalState as SkillProfileStates } from 'bundles/program-home/components/__generated__/globalTypes';
import AdditionalEnrolledProductList from 'bundles/program-home/components/available/AdditionalEnrolledProductList';
import SavedSkillSetList from 'bundles/program-home/components/available/SavedSkillSetList';
import type { CourseStub, ProductStub, S12nStub } from 'bundles/program-home/types/Products';
import {
  filterDuplicatedCourses,
  groupEnrolledProductsBySkill,
  orderProducts,
} from 'bundles/program-home/utils/ProgramHomeUtils';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/MergedEnrolledProductsList';

export type Props = PropsFromSaveSkillSetToggle & {
  userSkillProfileStates?: UserSkillProfileStateType[];
  userSkillProfileStatesLoading?: boolean;
  enterpriseBadgeCollections?: Array<EnterpriseBadge>;
  enrolledProducts: Array<ProductStub>;
  enrolledFutureProducts?: Array<ProductStub>;
  programId: string;
  programSlug: string;
  programName: string;
  userId: number;
  thirdPartyOrganizationId: string;
  refetchEnterpriseBadgeCollections?: (
    variables?: EnterpriseBadgeCollectionsQueryVariables
  ) => Promise<ApolloQueryResult<EnterpriseBadgeCollectionsQueryData>>;
};

export type GroupedEnrolledProductsBySkillType = {
  [key: string]: Array<ProductStub>;
};

const MergedEnrolledProductsList: React.FC<Props> = ({
  programId,
  programSlug,
  programName,
  enterpriseBadgeCollections,
  enrolledProducts,
  enrolledFutureProducts,
  userId,
  userSkillProfileStates,
  userSkillProfileStatesLoading,
  onSkillSetSaveToggle,
  thirdPartyOrganizationId,
  refetchEnterpriseBadgeCollections,
}) => {
  const enrolledBadgeCourseIds: Set<string> = new Set<string>();
  filterExistsOrDefault(enterpriseBadgeCollections)
    .filter((badge) => badge.userState === 'ENROLLED' || badge.userState === 'ISSUED')
    .forEach((badge) => {
      badge.productIds.forEach((productId) => enrolledBadgeCourseIds.add(productId.id));
    });

  const enrolledProductsBySkill = groupEnrolledProductsBySkill(enrolledProducts);
  const enrolledProductsWithoutSkill = enrolledProducts.filter(
    (product) => !product.productState?.definition?.enrolledTargetSkillProfileId
  );
  const enrolledCoursesWithoutSkill = enrolledProductsWithoutSkill.filter(
    (product) => product.isCourse
  ) as Array<CourseStub>;
  const enrolledS12nsWithoutSkill = enrolledProductsWithoutSkill.filter((product) => product.isS12n) as Array<S12nStub>;
  const filteredEnrolledCoursesWithoutSkill = filterDuplicatedCourses(
    enrolledCoursesWithoutSkill,
    enrolledS12nsWithoutSkill
  );

  const filteredEnrolledProductsWithoutSkill = orderProducts([
    ...filteredEnrolledCoursesWithoutSkill,
    ...enrolledS12nsWithoutSkill,
  ]);

  const filteredEnrolledProductsWithoutSkillWithoutBadges = filteredEnrolledProductsWithoutSkill.filter(
    (product) => !enrolledBadgeCourseIds.has(product.id.split('~')[2])
  );

  const savedSkillSets =
    userSkillProfileStates?.filter((skillProfileState) => skillProfileState.state === SkillProfileStates.SAVED) || [];

  const savedSkillSetsByActivity = _.orderBy(
    savedSkillSets,
    (savedSkillSet) =>
      _.max(
        _.map(
          enrolledProductsBySkill[savedSkillSet.targetSkillProfileId],
          (product) => product.productState?.definition.lastUpdatedAt ?? -Infinity
        )
      ),
    'desc'
  );

  const unsavedEnrolledProductsBySkill = _.pickBy(
    enrolledProductsBySkill,
    (_i, tspId) => !savedSkillSets.some((savedSkillSet) => savedSkillSet.targetSkillProfileId === tspId)
  );

  const hasAdditionalCourses =
    Object.keys(unsavedEnrolledProductsBySkill).some((tspId) => unsavedEnrolledProductsBySkill[tspId].length > 0) ||
    enrolledProductsWithoutSkill.length > 0 ||
    enrolledBadgeCourseIds.size > 0;
  const hasSavedSkillSets = savedSkillSets.length > 0;

  if (userSkillProfileStatesLoading) {
    return (
      <Row justifyContent="center">
        <SvgLoaderSignal />
      </Row>
    );
  }
  const showCompletionBannerWithLastCompletedTime = hasSavedSkillSets
    ? -Infinity
    : enrolledProducts
        .filter((product) => product.isCompleted)
        .reduce((time, product) => Math.max(time, product.completionTime / 1000), -Infinity);
  return (
    <Row rootClassName="rc-MergedEnrolledProductsList">
      {showCompletionBannerWithLastCompletedTime > 0 && (
        <CourseCompletionBanner
          programId={programId}
          programName={programName}
          lastCompletedTime={showCompletionBannerWithLastCompletedTime}
        />
      )}
      {hasSavedSkillSets && (
        <SavedSkillSetList
          savedSkillSets={savedSkillSetsByActivity}
          enrolledProductsBySkill={enrolledProductsBySkill}
          userId={userId}
          programId={programId}
          programSlug={programSlug}
          onSkillSetSaveToggle={onSkillSetSaveToggle}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
        />
      )}
      {hasAdditionalCourses && (
        <AdditionalEnrolledProductList
          enterpriseBadgeCollections={enterpriseBadgeCollections}
          unsavedEnrolledProductsBySkill={unsavedEnrolledProductsBySkill}
          enrolledProductsBySkill={enrolledProductsBySkill}
          enrolledProductsWithoutSkill={filteredEnrolledProductsWithoutSkillWithoutBadges}
          userId={userId}
          programId={programId}
          programSlug={programSlug}
          onSkillSetSaveToggle={onSkillSetSaveToggle}
          hasSavedSkillSets={hasSavedSkillSets}
          thirdPartyOrganizationId={thirdPartyOrganizationId}
          refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
        />
      )}
      {enrolledFutureProducts && enrolledFutureProducts.length > 0 && (
        <Col col={12} data-testid="future_enrollments">
          <Heading defaultLevel={2} className="header-section-title">
            {_t('Starting later')}
          </Heading>
          <Section initialLevel={3}>
            <ProductListWithExpandToggle
              enterpriseBadgeCollections={enterpriseBadgeCollections}
              products={enrolledFutureProducts as Array<ProductStub>}
              programId={programId}
              programSlug={programSlug}
              userId={userId}
              onSkillSetSaveToggle={onSkillSetSaveToggle}
              viewMoreLessContext={_t('Starting later')}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
              refetchEnterpriseBadgeCollections={refetchEnterpriseBadgeCollections}
            />
          </Section>
        </Col>
      )}
    </Row>
  );
};

export default MergedEnrolledProductsList;
