/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { throttle } from 'lodash';
import { Element, Range, Transforms } from 'slate';
import type { RenderElementProps } from 'slate-react';
import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import MathEditor from 'bundles/cml/editor/components/elements/math/MathEditor';
import MathPlaceholder from 'bundles/cml/editor/components/elements/math/MathPlaceholder';
import { parseFormula } from 'bundles/cml/editor/components/elements/math/utils';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useFocusTrap } from 'bundles/cml/editor/utils/dialogUtils';
import MathRenderer from 'bundles/cml/shared/components/math/Math';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type { MathElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  button: css`
    display: inline-block;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    text-align: unset;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: none;
    user-select: none;

    &:not(:disabled) {
      &:hover,
      &:focus {
        outline: 3px solid var(--cds-color-interactive-primary);
      }

      &[aria-pressed='true'],
      &:active {
        outline: 3px solid var(--cds-color-callouts-tertiary);
      }
    }
  `,
  selected: css`
    outline: 3px solid var(--cds-color-callouts-tertiary);
  `,
  fullWidth: css`
    width: 100%;
  `,
};

const MATH_TYPES: BLOCK_VALUES[] = [BLOCK_TYPES.MATH_BLOCK, BLOCK_TYPES.MATH_INLINE];
const TRANSITION_MS = 250;

const Math: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const math = element as MathElement;
  const isBlock = !math.isInline;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  const isSelected = useSelected();
  const isReadOnly = useReadOnly();
  const selected = isSelected && !isReadOnly;

  const { setFocused } = useFocusedContext();
  const staticEditor = useSlateStatic();

  const formula = parseFormula(math.formula, isBlock);

  const openEditor = useCallback(() => {
    setFocused(true);
    setShowEditor(true);
  }, [setFocused]);

  const closeEditor = useCallback(
    ({ immediately = false }: { immediately?: boolean } = {}) => {
      setShowEditor(false);

      if (immediately) {
        setFocused(false);
        return;
      }

      // wait until until the dialog fade/slide transition has completed
      // before updating the CMLEditor's state so that we don't inadvertantly
      // trigger the blur handler
      setTimeout(() => {
        setFocused(false);
      }, TRANSITION_MS);
    },
    [setFocused]
  );

  const handleDone = useCallback(() => {
    closeEditor();
    ReactEditor.focus(staticEditor);
    if (isBlock) {
      Transforms.move(staticEditor, { distance: 1, unit: 'line' });
    } else {
      Transforms.move(staticEditor, { distance: 1, unit: 'word' });
    }
  }, [staticEditor, closeEditor, isBlock]);

  const handleBlur = useCallback(() => {
    closeEditor({ immediately: true });
  }, [closeEditor]);

  const handleDelete = useCallback(() => {
    closeEditor();
    ReactEditor.focus(staticEditor);
    Transforms.removeNodes(staticEditor, {
      match: (node) => Element.isElement(node) && MATH_TYPES.includes(node.type),
    });
  }, [staticEditor, closeEditor]);

  const handleChange = useMemo(
    () =>
      throttle((value: string) => {
        const textFormula = value ? `${formula.delims[0]}${value}${formula.delims[1]}` : '';
        Transforms.setNodes<MathElement>(
          staticEditor,
          { ...math, formula: textFormula },
          { match: (node) => Element.isElement(node) && MATH_TYPES.includes(node.type) }
        );
      }, 100),
    [staticEditor] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (selected && staticEditor.selection && Range.isCollapsed(staticEditor.selection)) {
        openEditor();
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useFocusTrap(ref);

  const isPlaceholder = !formula.value.trim();

  return (
    <MathRenderer element={element} attributes={attributes} onError={setError}>
      {(mathFormula: React.ReactElement) => (
        <React.Fragment>
          <button
            tabIndex={-1}
            ref={buttonRef}
            type="button"
            aria-pressed={showEditor ? 'true' : 'false'}
            disabled={isReadOnly}
            css={[styles.button, isBlock && styles.fullWidth, (selected || showEditor) && styles.selected]}
            onClick={openEditor}
            contentEditable={false}
          >
            {isPlaceholder ? !isReadOnly && <MathPlaceholder isBlock={isBlock} /> : mathFormula}
          </button>
          {showEditor && buttonRef.current && (
            <MathEditor
              ref={ref}
              anchorEl={buttonRef.current}
              value={formula.value}
              error={error}
              isBlock={isBlock}
              onDone={handleDone}
              onChange={handleChange}
              onDelete={handleDelete}
              onBlur={handleBlur}
            />
          )}
          {children}
        </React.Fragment>
      )}
    </MathRenderer>
  );
};

export default Math;
