/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import { Button } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import type { Asset } from 'bundles/asset-admin/types/assets';
import AssetDescriptionEditor from 'bundles/author-common/components/AssetDescriptionEditor';
import AutoGeneratedImageDescription from 'bundles/cml/editor/components/elements/image/dialogs/AutoGeneratedImageDescripiton';
import type { PropsToComponent } from 'bundles/cml/editor/components/elements/image/utils/withAssetAdminStore';
import { NotificationContext } from 'bundles/cml/editor/context/notificationContext';
import { useFocusTrap } from 'bundles/cml/editor/utils/dialogUtils';
import { getAssetDataPayload, getAssetDescription } from 'bundles/cml/editor/utils/imageUtils';

import _t from 'i18n!nls/cml';

const styles = {
  dialog: css`
    padding: var(--cds-spacing-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--cds-spacing-300);
    width: 356px;
  `,
};

type PropsFromCaller = {
  asset: Asset;
  onClose: () => void;
};

export type { PropsFromCaller as Props };
type Props = PropsFromCaller & PropsToComponent;

export const ImageDescriptionDialog: React.FC<Props> = ({ asset, updateAssetData, updateDataFailed, onClose }) => {
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);
  const [description, setDescription] = useState(getAssetDescription(asset));
  const { setNotification } = useContext(NotificationContext);
  const showNotification = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    showNotification.current = !updateDataFailed && descriptionUpdated;
  }, [updateDataFailed, descriptionUpdated]);

  useEffect(
    () => {
      // display notification when the dialog is unmounted
      return () => {
        if (showNotification.current) {
          setNotification({ message: 'Accessibility description saved', type: 'information' });
        }
      };
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useFocusTrap(ref);

  const handleUpdateAssetStore = (newDescription: string) => {
    if (!asset) {
      return;
    }

    const assetDataPayload = {
      ...getAssetDataPayload(asset),
      description: newDescription,
    };

    updateAssetData(assetDataPayload);
  };

  const handleSave = (newDescription: string) => {
    if (!asset) {
      return;
    }

    handleUpdateAssetStore(newDescription.trim());
    setDescriptionUpdated(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handleSave(e.target.value);
    setDescription(e.target.value);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const isGeneratedDescription = asset.isDescriptionAutoGenerated && description === getAssetDescription(asset);

  return (
    <div css={styles.dialog} ref={ref}>
      <Button
        variant="ghost"
        size="small"
        icon={<ChevronPreviousIcon />}
        iconPosition="before"
        onClick={handleClose}
        data-test="back-btn"
        edgeAlign="start"
      >
        {_t('Back')}
      </Button>

      <AssetDescriptionEditor
        defaultValue={description}
        handleChange={handleChange}
        descriptionLength={description.length}
        hasServerError={updateDataFailed}
        multiline
      />

      {isGeneratedDescription && <AutoGeneratedImageDescription asset={asset} />}

      <Button size="small" onClick={handleClose} data-test="done-btn">
        {_t('Done')}
      </Button>
    </div>
  );
};

export default ImageDescriptionDialog;
