/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { useQuery } from '@apollo/client';
import type * as Types from '__generated__/graphql-types';

import { useRetracked } from 'js/lib/retracked';

import type { ButtonProps, Theme } from '@coursera/cds-core';
import { Button, ProductCard as CdsProductCard, breakpoints, useMediaQuery } from '@coursera/cds-core';

import { getProductCardDisplayProps } from 'bundles/browse/utils';
import { ShowMoreGridSection, ShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components/';
import { getCollectionType } from 'bundles/collections-common/utils/collectionsUtils';
import withSingleTracked, { TrackedCdsButton } from 'bundles/common/components/withSingleTracked';
import type {
  ClipsQueryQuery,
  ClipsQueryQueryVariables,
} from 'bundles/enterprise-admin-program-catalog/api/__generated__/clipsQuery';
import clipsQuery from 'bundles/enterprise-admin-program-catalog/api/clipsQuery.graphql';
import type { CollectionType } from 'bundles/enterprise-collections/components/CollectionItem';
import {
  getAllProducts,
  getProductTypeFromProduct,
} from 'bundles/enterprise-collections/components/getCollectionItemList';
import { extractEventId } from 'bundles/product-card/components/utils/productCardV2Utils';
import type { ProductCardType } from 'bundles/program-common/components/ProductCardBase';
import { ProductCardCds } from 'bundles/program-common/components/ProductCardCds';
import type {
  EnterpriseProductMetadataConfiguration,
  Product,
  ProductType,
} from 'bundles/program-common/types/programCommon';
import { getProductId } from 'bundles/program-common/utils/ProductUtils';
import { isProjectOrSelfPacedProject } from 'bundles/program-common/utils/courseUtils';
import { getProductMetadata } from 'bundles/program-common/utils/programCommonUtils';

import _t from 'i18n!nls/enterprise-collections';

const TrackedLinkButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'a'>>(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

const styles = {
  cardWrapper: (theme: Theme) => css`
    margin: 10px;
    padding: 0;
    ${theme.breakpoints.down('xs')} {
      margin: 10px 0;
    }

    /* terrible hack until we have CDS cards */

    * {
      font-family: 'Source Sans Pro', Arial, sans-serif !important;
    }
  `,
  viewAllButtonPlaceholder: css`
    height: 56px;
    animation-delay: -0.3s;
  `,
  placeholderText: css`
    width: 100%;
    height: 24px;
    margin-bottom: 8px;
    animation-delay: -0.3s;
  `,
  courseNoteSection: css`
    position: relative;

    /* accounting for some margin set in the product card */

    top: -34px;
  `,
  courseNoteText: css`
    color: #666;
    font-size: 14px;
  `,
  transition: css`
    transition: cubic-bezier(0.23, 1, 0.32, 1);
  `,
  productCardWrapper: css`
    height: 100%;
  `,
};

const gridConfig = { md: 3 as const, sm: 6 as const, xs: 12 as const };

type PropsForPlaceholder = {
  id?: string;
  innerRef?: (element: HTMLElement | null) => void;
};

type Props = {
  title?: string;
  description?: React.ReactNode;
  customSkillset?: boolean;
  collectionOrder?: number;
  collection: CollectionType;
  onProductCardClick?: (product: Product, productType: ProductType) => void;
  enterpriseProductConfigurations?: EnterpriseProductMetadataConfiguration;
  itemIdMappingFunction?: (id: string) => string;
  invertS12nSelection?: boolean;
  renderTitle?: () => React.ReactNode;
  expandButtonUrl?: string;
  expandedExternalLinkButtonUrl?: string;
  expandedExternalLinkButtonText?: string;
  productCardTrackingName?: string;
};

export type CollectionProduct = {
  id: string;
  isCourse: boolean;
  isClip?: boolean;
  product: Product;
};

export function EnterpriseProductCardCollection({
  title,
  description,
  customSkillset,
  collectionOrder,
  collection,
  onProductCardClick,
  enterpriseProductConfigurations: productMetadata = [],
  itemIdMappingFunction,
  renderTitle,
  expandButtonUrl,
  expandedExternalLinkButtonUrl,
  expandedExternalLinkButtonText,
  productCardTrackingName,
}: Props) {
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const { trackId: collectionId } = collection;
  const clipItems = (collection.items ?? [])
    .filter((item) => item.clipId)
    .map(({ clipId }) => ({ itemId: clipId?.itemId, courseId: clipId?.courseId })) as Types.Clip_CourseItemId[];
  const track = useRetracked();
  const {
    data: clipsData,
    loading: loadingClips,
    error: clipsError,
  } = useQuery<ClipsQueryQuery, ClipsQueryQueryVariables>(clipsQuery, {
    skip: clipItems.length === 0,
    variables: {
      ids: clipItems,
    },
    context: {
      clientName: 'gatewayGql',
    },
  });

  const allProducts: Array<CollectionProduct> = getAllProducts(
    {
      ...collection,
      // @ts-expect-error URL: string
      clips: collection?.clips ?? clipsData?.Clip?.queryClipItemsByIds,
    },
    itemIdMappingFunction
  );

  if (allProducts.length === 0 && !loadingClips) {
    return <h2>{_t('There are no courses or specializations in this collection.')}</h2>;
  }

  const collectionType = getCollectionType(collectionId);
  const eventingV3CarouselData = {
    id: collectionId ?? '',
    name: collection.title,
    model: collectionId ?? '',
    type: collectionType,
    section: collectionOrder,
  };

  return (
    <ShowMoreGridSection<CollectionProduct>
      className="rc-EnterpriseProductCardCollection"
      css={undefined}
      title={title || ''}
      renderTitle={renderTitle}
      renderSubSection={() => description}
      items={allProducts}
      itemsGridConfig={gridConfig}
      defaultRows={isMobile ? 2 : 1}
      spacing={{ sm: 24, xs: 16 }}
      renderItem={({ item, itemIndex, itemRef }) => {
        const { product, isCourse, id, isClip } = item;

        const productId = getProductId(id, isCourse, product.productType);
        const { label } = getProductCardDisplayProps(product, !isCourse);
        const shouldShowNewBadge = isProjectOrSelfPacedProject(product.courseTypeMetadata);

        const trackingData = {
          customSkillset,
          collectionOrder,
          productCardOrder: itemIndex + 1,
          collectionId,
          productId,
        };
        const trackingName = productCardTrackingName ?? 'collection_product_card';
        const courseMetadata = getProductMetadata(isCourse, product.id, productMetadata);

        // Avoid TrackedDiv or similar because it introduces an extra VO-focusable <div> that adds screen reader
        // noise. Our role="text" workaround is banned by Lighthouse.
        const onProductCardClickWithTracking = (product0: Product, productType: ProductCardType) => {
          track({ trackingName, trackingData, action: 'click' });
          onProductCardClick?.(product0, productType);
        };

        const productType = getProductTypeFromProduct(product?.productType, isCourse);
        const showClipStyleIfClip = isClip && productType === 'clip';

        const eventingV3ProductData = {
          id: extractEventId(id, productType),
          name: product.name,
          ...(productType && {
            type: productType,
          }),
          slug: product.slug || '',
        };

        return (
          <div css={styles.productCardWrapper}>
            <ProductCardCds
              product={product}
              productType={productType}
              variant={isMobile ? 'list' : 'grid'}
              enterpriseProductMetadata={courseMetadata}
              showClipStyleIfClip={showClipStyleIfClip}
              onClick={onProductCardClickWithTracking}
              htmlAttributes={{ 'data-e2e': isCourse ? 'CourseProductCard' : 'S12nProductCard', ref: itemRef }}
              label={label}
              shouldShowNewBadge={shouldShowNewBadge}
              customLinkProps={{
                trackingName: 'collection_product_card',
                withVisibilityTracking: true,
                requireFullyVisible: true,
                data: { itemIndex, ...trackingData },
                refAlt: itemRef,
              }}
              eventingV3Data={{
                productCard: {
                  index: itemIndex ?? 0,
                },
                product: eventingV3ProductData,
                carousel: eventingV3CarouselData,
              }}
            />
          </div>
        );
      }}
      renderExpandButton={({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }) => {
        return (
          <TrackedCdsButton
            trackingName="show_more"
            trackingData={{
              title,
              isExpanded,
              showMoreCount,
            }}
            size="small"
            variant="secondary"
            onClick={onClick}
            {...rest}
            aria-label={`${defaultLabel} ${title}`}
          >
            {defaultLabel}
          </TrackedCdsButton>
        );
      }}
      renderSecondaryCta={() => {
        if (!expandButtonUrl && !expandedExternalLinkButtonUrl) return null;
        return (
          <TrackedLinkButton
            trackingName="secondary_CTA"
            trackingData={{ title }}
            size="small"
            variant="ghost"
            component="a"
            href={expandButtonUrl || expandedExternalLinkButtonUrl}
          >
            {expandedExternalLinkButtonText}
          </TrackedLinkButton>
        );
      }}
    />
  );
}

export function EnterpriseProductCardCollectionPlaceholder() {
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  return (
    <ShowMoreGridSectionPlaceholder
      className="rc-EnterpriseProductCardCollectionPlaceholder"
      itemsGridConfig={gridConfig}
      defaultRows={isMobile ? 2 : 1}
      renderPlaceholderItem={() => (
        <div className="rc-CollectionItemWithCarousel-Placeholder" css={styles.cardWrapper}>
          <CdsProductCard variant={isMobile ? 'list' : 'grid'} productType="course" loading={true} />
        </div>
      )}
    />
  );
}

export function EnterpriseProductCardCollectionsPlaceholder({ id, innerRef }: PropsForPlaceholder) {
  return (
    <div className="rc-EnterpriseProductCardCollectionsPlaceholder" key={id} ref={innerRef} css={styles.transition}>
      <EnterpriseProductCardCollectionPlaceholder />
    </div>
  );
}

export default EnterpriseProductCardCollection;
