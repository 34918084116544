import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Button, Typography, Typography2 } from '@coursera/cds-core';
import { ArrowPreviousIcon } from '@coursera/cds-icons';
import { Divider } from '@coursera/coursera-ui';

import { CONTENT_TYPES } from 'bundles/asset-admin/constants/AssetUpload';
import type { Asset } from 'bundles/asset-admin/types/assets';
import PartnerHelpLink from 'bundles/authoring/common/components/PartnerHelpLink';

import _t from 'i18n!nls/asset-admin';

type DefaultAssetSelectorContentsProps = {
  assetLibraryButton?: React.ReactNode;
  uploader: React.ReactNode;
};

export const DefaultAssetSelectorContents: React.SFC<DefaultAssetSelectorContentsProps> = (props) => {
  const { assetLibraryButton, uploader } = props;

  return (
    <div className="rc-DefaultAssetSelectorContents">
      <div className="dashboard-wrapper">{uploader}</div>
      {assetLibraryButton ? (
        <div className="asset-library-section">
          <Divider>
            <span className="text-muted">{_t('or')}</span>
          </Divider>
          {assetLibraryButton}
        </div>
      ) : null}
    </div>
  );
};

type AssetAdminManagerHeaderPropTypes = {
  onBack: () => void;
  title?: string;
  description?: string;
  currentContent: CONTENT_TYPES;
  allowMultiple: boolean;
  assetType?: string;
  helperLinkId?: string;
};

export const AssetAdminManagerHeader: React.SFC<AssetAdminManagerHeaderPropTypes> = (props) => {
  const { onBack, title, currentContent, allowMultiple, assetType, helperLinkId, description } = props;
  const asset = assetType || 'default';
  let descriptionComponent;

  const defaultDescriptions = {
    video: _t(
      'You can add {fileTypes, plural, =0 {a video} other {videos}} to your library by importing {fileTypes, plural, =0 {a file} other {one or more files}} here. After importing, you’ll be able to edit the asset name in the Asset Library.'
    ),
    image: _t(
      'You can add {fileTypes, plural, =0 {an image} other {images}} to your library by importing {fileTypes, plural, =0 {a file} other {one or more files}} here. After importing, you’ll be able to edit the asset names and accessibility descriptions in the Asset Library.'
    ),
    default: _t(
      'You can add {fileTypes, plural, =0 {an asset} other {assets}} to your library by importing {fileTypes, plural, =0 {a file} other {one or more files}} here. After importing, you’ll be able to edit the asset name in the Asset Library.'
    ),
  };

  if (currentContent === CONTENT_TYPES.DEFAULT) {
    descriptionComponent = (
      <Typography2 component="p" variant="bodyPrimary" className="asset-description">
        <FormattedMessage
          message={description || defaultDescriptions[asset as keyof typeof defaultDescriptions]}
          fileTypes={allowMultiple ? 1 : 0}
        />
        &nbsp;
        {helperLinkId && <PartnerHelpLink articleId={helperLinkId} />}
      </Typography2>
    );
  }

  if (currentContent === CONTENT_TYPES.ASSET_LIBRARY) {
    const assetDescriptions = {
      video: _t('Choose {fileTypes, plural, =0 {a video} other {videos}} from your Asset Library to use.'),
      image: _t('Choose {fileTypes, plural, =0 {an image} other {images}} from your Asset Library to use.'),
      default: _t('Choose {fileTypes, plural, =0 {an asset} other {assets}} from your Asset Library to use.'),
    };

    descriptionComponent = (
      <Typography2 component="p" variant="bodyPrimary" className="asset-description">
        <FormattedMessage
          message={assetDescriptions[asset as keyof typeof assetDescriptions]}
          fileTypes={allowMultiple ? 1 : 0}
        />
      </Typography2>
    );
  }

  return (
    <header className="rc-AssetAdminManagerHeader">
      {title && (
        <Typography variant="h1semibold" component="h2">
          {title}
        </Typography>
      )}
      {currentContent === CONTENT_TYPES.ASSET_LIBRARY && (
        <Button
          onClick={onBack}
          icon={<ArrowPreviousIcon size="small" />}
          iconPosition="before"
          size="small"
          variant="ghost"
        >
          {_t('Back')}
        </Button>
      )}
      {descriptionComponent}
    </header>
  );
};

type FooterButtonsProps = {
  onCancel: () => void;
  onContinue: () => void;
  selectedAssets: Set<Asset>;
  uploadedAssets: Array<Asset>;
  showCancel?: boolean;
  confirmLabel?: string;
};

export const FooterButtons: React.SFC<FooterButtonsProps> = ({
  onCancel,
  onContinue,
  selectedAssets,
  uploadedAssets,
  showCancel = true,
  confirmLabel = _t('Continue'),
}) => {
  const enableContinue = selectedAssets?.size > 0 || uploadedAssets?.length > 0;

  return (
    <footer className="rc-AssetAdminManager-FooterButtons">
      <>
        <Button
          size="medium"
          variant="primary"
          onClick={onContinue}
          disabled={!enableContinue}
          data-testid="continue-button"
        >
          {confirmLabel}
        </Button>
        {showCancel && (
          <Button size="medium" variant="secondary" onClick={onCancel} data-testid="cancel-button">
            {_t('Cancel')}
          </Button>
        )}
      </>
    </footer>
  );
};
