import type { Node, NodeEntry } from 'slate';
import { Editor, Element, Text, Transforms } from 'slate';

import { LIST_TYPES_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const LIST_REGEX = /^(?:([-*+] )|([1-9]\d*\. ))/im;

export const normalizeListMarkdown = (editor: Editor, tools: Set<ToolsKeys>, nodeEntry: NodeEntry<Node>) => {
  if (!Text.isText(nodeEntry[0]) || hasAncestorOfType(editor, BLOCK_TYPES.LIST_ITEM, nodeEntry[1])) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<Text>;
  const { text } = node;

  const match = text.match(LIST_REGEX);
  if (!match) {
    return false;
  }

  const listType = match[1] ? BLOCK_TYPES.BULLET_LIST : BLOCK_TYPES.NUMBER_LIST;
  const tool = LIST_TYPES_TO_TOOLS[listType];
  if (!tools.has(tool as ToolsKeys)) {
    return false;
  }

  const newline = text.indexOf('\n');
  if (newline >= 0) {
    Transforms.delete(editor, { at: { path, offset: newline }, distance: 1, unit: 'character' });
    Transforms.splitNodes(editor, { at: { path, offset: newline } });
    return true;
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.delete(editor, {
      at: { path, offset: 0 },
      distance: match[1]?.length || match[2]?.length,
      unit: 'character',
    });

    Transforms.wrapNodes(
      editor,
      {
        type: BLOCK_TYPES.LIST_ITEM,
        children: [],
      },
      {
        at: path,
        mode: 'highest',
        match: (el) => Element.isElement(el) && el.type === BLOCK_TYPES.TEXT,
      }
    );

    Transforms.wrapNodes(
      editor,
      {
        type: listType,
        children: [],
      },
      {
        at: path,
        mode: 'highest',
        match: (el) => Element.isElement(el) && el.type === BLOCK_TYPES.LIST_ITEM,
      }
    );
  });

  return true;
};
