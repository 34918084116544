import { times } from 'lodash';
import { Editor, Transforms } from 'slate';
import type { Path } from 'slate';
import { ReactEditor } from 'slate-react';

import { getAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TableCellElement, TableElement, TableRowElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const DEFAULT_NUM_COLUMNS = 3;
const DEFAULT_NUM_ROWS = 3;

export const getRowPath = (editor: Editor): Path | undefined => {
  const rowEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE_ROW);
  if (!rowEntry) {
    return undefined;
  }

  return rowEntry[1];
};

export const getRowIndex = (editor: Editor): number => {
  const path = getRowPath(editor);
  return path?.[path.length - 1] ?? -1;
};

export const getColumnPath = (editor: Editor): Path | undefined => {
  const cellEntry = getAncestorOfType(editor, BLOCK_TYPES.TABLE_CELL);
  if (!cellEntry) {
    return undefined;
  }

  return cellEntry[1];
};

export const getColumnIndex = (editor: Editor): number => {
  const path = getColumnPath(editor);
  return path?.[path.length - 1] ?? -1;
};

export const getNumRows = (table: TableElement) => {
  return table.children.length;
};

export const getNumColumns = (table: TableElement) => {
  return table.children.reduce((numColumns: number, row: TableRowElement) => {
    return Math.max(numColumns, row.children.length);
  }, 0);
};

type TableData = {
  path: Path | undefined;
  columnIndex: number;
  rowIndex: number;
  numRows: number;
  numColumns: number;
};

export const getTableData = (editor: Editor, table: TableElement): TableData => {
  return {
    path: ReactEditor.findPath(editor, table),
    columnIndex: getColumnIndex(editor),
    rowIndex: getRowIndex(editor),
    numRows: getNumRows(table),
    numColumns: getNumColumns(table),
  };
};

export const createTableCell = (text = '', header?: boolean): TableCellElement => {
  return {
    type: BLOCK_TYPES.TABLE_CELL,
    header,
    children: [
      {
        type: BLOCK_TYPES.TEXT,
        children: [{ text }],
      },
    ],
  };
};

export const createTableRow = (
  columns: number,
  header?: boolean,
  createCellText: (column: number) => string = () => ''
): TableRowElement => {
  return {
    type: BLOCK_TYPES.TABLE_ROW,
    children: times(columns, (column: number) => {
      const text = createCellText(column);
      return createTableCell(text, header);
    }),
  };
};

export const createTable = (rows = DEFAULT_NUM_ROWS, columns = DEFAULT_NUM_COLUMNS): TableElement => {
  return {
    type: BLOCK_TYPES.TABLE,
    headless: false,
    children: times(rows, (row: number) => {
      const header = row === 0;
      return createTableRow(columns, header, (column: number) => {
        return header ? _t('Header #{column}', { column: column + 1 }) : '';
      });
    }),
  };
};

export const toggleHeader = (editor: Editor, element: TableElement) => {
  const path = ReactEditor.findPath(editor, element);
  const headless = !element.headless;
  const { numColumns } = getTableData(editor, element);

  Editor.withoutNormalizing(editor, () => {
    Transforms.setNodes(editor, { headless }, { at: path });
    times(numColumns, (col: number) => {
      if (headless) {
        Transforms.unsetNodes(editor, 'header', { at: [...path, 0, col] });
      } else {
        Transforms.setNodes(editor, { header: true }, { at: [...path, 0, col] });
      }
    });
  });
};

export const focusTableCell = (
  editor: Editor,
  tablePath: Path,
  rowIndex: number,
  columnIndex: number,
  last = false
) => {
  const path = [...tablePath, Math.max(0, rowIndex), Math.max(0, columnIndex)];

  const positions = Array.from(Editor.positions(editor, { at: path }));
  const position = last ? positions[positions.length - 1] : positions[0];
  Transforms.select(editor, position);
};

export const insertTable = (editor: Editor) => {
  const table = createTable();
  insertBlockNodes(editor, table);

  const index = editor.children.findIndex((el) => el === table);
  if (index >= 0) {
    focusTableCell(editor, [index], 0, 0);
  }
};
