/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import ProductBadgeEnrollment from 'bundles/course-cards/components/course-card/enterprise/ProductBadgeEnrollment';
import ProductEnrolledCard, {
  ProductEnrolledCardWithSaveSkillSetBanner,
} from 'bundles/course-cards/components/course-card/enterprise/ProductEnrolledCard';
import type { EnterpriseBadge } from 'bundles/page-config-common/providers/enterprise/EnterpriseBadgeCollectionsProvider';
import type { PropsFromSaveSkillSetToggle } from 'bundles/program-home/components/ProgramHomeApp';
import type { CourseStub, ProductStub } from 'bundles/program-home/types/Products';

type PropsForProductList = {
  products: Array<ProductStub>;
  programId: string;
  userId: number;
  showSaveSkillSetBanner?: boolean;
  productCount?: number;
  targetSkillProfileId?: string;
  programSlug: string;
  thirdPartyOrganizationId: string;
  recommendBadgeCourseId?: CourseStub;
  recommendedProductIds: Set<string>;
  badgeProductMapping: { [key: string]: EnterpriseBadge };
  selectedBadge?: EnterpriseBadge;
  handleEnrollInCredential: (badge: EnterpriseBadge) => void;
} & PropsFromSaveSkillSetToggle;

const ProductList = ({
  products,
  programId,
  userId,
  showSaveSkillSetBanner,
  productCount,
  targetSkillProfileId,
  programSlug,
  thirdPartyOrganizationId,
  recommendBadgeCourseId,
  recommendedProductIds,
  badgeProductMapping,
  selectedBadge,
  onSkillSetSaveToggle,
  handleEnrollInCredential,
}: PropsForProductList) => {
  return (
    <React.Fragment>
      {products.map((product, idx) =>
        showSaveSkillSetBanner ? (
          <ProductEnrolledCardWithSaveSkillSetBanner
            key={product.id}
            programId={programId}
            product={product}
            userId={userId}
            productCount={productCount}
            targetSkillProfileId={targetSkillProfileId}
            programSlug={programSlug}
            showSaveSkillSetBanner={idx === 0}
            onSkillSetSaveToggle={onSkillSetSaveToggle}
            thirdPartyOrganizationId={thirdPartyOrganizationId}
          />
        ) : (
          <React.Fragment key={product.id}>
            {product.isCourse && recommendBadgeCourseId?.courseId === product.courseId && (
              <ProductBadgeEnrollment
                product={product}
                recommendedProductIds={recommendedProductIds}
                badgeProductMapping={badgeProductMapping}
                selectedBadge={selectedBadge}
                onEnrollInCredential={handleEnrollInCredential}
              />
            )}
            <ProductEnrolledCard
              key={product.id}
              userId={userId}
              programId={programId}
              product={product}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
            />
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default ProductList;
