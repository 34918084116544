import * as React from 'react';

import CourseReenrollModal from 'bundles/campus-basic-contract-renewal/components/learner/CourseReenrollModal';
import LearnerReenrollPopupModal from 'bundles/campus-basic-contract-renewal/components/learner/LearnerReenrollPopupModal';
import type { PropsFromWithFreemiumCourseReenrollData } from 'bundles/campus-basic-contract-renewal/components/learner/withFreemiumCourseReenrollData';
import LoadingIcon from 'bundles/courseraLoadingIcon/LoadingIcon';

export type PropsFromCaller = {
  userName: string;
  setShowFreemiumCourseReenrollModal: (val: boolean) => void;
} & PropsFromWithFreemiumCourseReenrollData;

const CourseReenrollModals = ({
  userName,
  freemiumEnrollmentFilteredCourses,
  setShowFreemiumCourseReenrollModal,
}: PropsFromCaller) => {
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [showEnrolledModal, setShowEnrolledModal] = React.useState<boolean>(false);

  const isOneCourse = freemiumEnrollmentFilteredCourses.length === 1;

  const incrementStep = React.useCallback(() => {
    if (freemiumEnrollmentFilteredCourses.length === currentStep + 1) {
      setShowFreemiumCourseReenrollModal(false);
    } else {
      setCurrentStep((prevState) => prevState + 1);
    }
  }, [currentStep, setShowFreemiumCourseReenrollModal, freemiumEnrollmentFilteredCourses.length]);

  const onBrowseBtnClick = React.useCallback(() => {
    incrementStep();
  }, [incrementStep]);

  const onReenrollClick = React.useCallback(() => {
    if (isOneCourse) {
      setShowEnrolledModal(true);
    } else {
      incrementStep();
    }
  }, [isOneCourse, incrementStep]);

  const onCloseReenrolledModal = React.useCallback(() => {
    setShowEnrolledModal(false);
    setShowFreemiumCourseReenrollModal(false);
  }, [setShowFreemiumCourseReenrollModal]);

  const courseToShow = freemiumEnrollmentFilteredCourses[currentStep];

  return (
    <React.Fragment>
      {courseToShow ? (
        <React.Fragment>
          <CourseReenrollModal
            userName={userName}
            filteredCourse={courseToShow}
            onBrowseBtnClick={onBrowseBtnClick}
            onReenrollClick={onReenrollClick}
          />
          {showEnrolledModal && (
            <LearnerReenrollPopupModal
              onCloseModal={onCloseReenrolledModal}
              courseId={freemiumEnrollmentFilteredCourses[0].productItemId}
              data-test-id="learner-reenroll-popup-modal"
            />
          )}
        </React.Fragment>
      ) : (
        <div>
          <LoadingIcon />
        </div>
      )}
    </React.Fragment>
  );
};

export default CourseReenrollModals;
