import { graphql } from 'react-apollo';

import type {
  QueryAllSkillProgressQuery,
  QueryAllSkillProgressQueryVariables,
} from './__generated__/queryAllSkillProgress';
import queryAllSkillProgress from './queryAllSkillProgress.graphql';

type LEVELSET_STATUS = 'UNAVAILABLE' | 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED_ONCE' | 'COMPLETED_ALL';

export type SkillProgress = {
  skillId: string;
  skillName: string;
  domains: { id: string; name: string }[];
  isEligibleForScoring: boolean;
  score: number;
  scoredAtTime: number;
  levelsetStatus: LEVELSET_STATUS;
};

export type DataFromSkills = {
  loadingSkills: boolean;
  skillProgress: SkillProgress[];
};

function getLevelsetStatus(levelSetStatus?: { attemptsLeft: number; progressStatus: string } | null): LEVELSET_STATUS {
  if (!levelSetStatus) return 'UNAVAILABLE';
  if (levelSetStatus.progressStatus === 'NOT_STARTED') return 'NOT_STARTED';
  if (levelSetStatus.progressStatus === 'IN_PROGRESS') return 'IN_PROGRESS';
  if (levelSetStatus.attemptsLeft === 1) return 'COMPLETED_ONCE';
  if (levelSetStatus.attemptsLeft === 0) return 'COMPLETED_ALL';
  return 'UNAVAILABLE';
}

const withSkillsData = graphql<
  {
    programSlug: string;
    programId?: string;
    skillLimit?: number;
  },
  QueryAllSkillProgressQuery,
  QueryAllSkillProgressQueryVariables,
  DataFromSkills
>(queryAllSkillProgress, {
  options: ({ skillLimit, programId }) => ({
    variables: {
      first: skillLimit ?? 1000,
      enterpriseProgramId: programId,
    },
    context: { clientName: 'gatewayGql' },
  }),
  props: ({ data }) => {
    const loadingSkills = data?.loading ?? true;

    const skillProgress =
      data?.SkillsProgress?.queryAllSkillProgress.edges?.map(({ node }) => ({
        skillId: node.skill.id.split('~')[1],
        skillName: node.skill.skillName,
        domains: node.skill.domains.map((domain) => ({
          id: domain.id,
          name: domain.domainName,
        })),
        score: node.skillScore?.score ?? (node.skill.isEligibleForScoring ? 0 : -1), // -1 so that non-scorable skills will always be sorted last by Score
        isEligibleForScoring: node.skill.isEligibleForScoring,
        scoredAtTime: node.skillScore?.score ? parseFloat(node.skillScore?.scoredAtTime.seconds ?? '0') : 0, // If a skill doesn't have a score, it should be sorted last
        levelsetStatus: getLevelsetStatus(node.levelSetStatus),
      })) ?? [];

    return {
      loadingSkills,
      skillProgress,
    };
  },
});

export { withSkillsData };
