/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { isHotkey } from 'is-hotkey';

import { Button, Tab, TabContext, TabPanel, Tabs, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';
import { ChevronPreviousIcon } from '@coursera/cds-icons';

import AdvancedEditor from 'bundles/cml/shared/components/widget/dialog/AdvancedEditor';
import SimpleConfigEditor from 'bundles/cml/shared/components/widget/dialog/SimpleEditor';
import { hasSimpleConfigEditor } from 'bundles/cml/shared/components/widget/dialog/utils/configUtils';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';
import type {
  WidgetSession,
  WidgetSessionDraftConfig,
  WidgetSessionPreview,
} from 'bundles/cml/shared/types/widgetManager';
import { FloatingTooltip } from 'bundles/common/components/Tooltip';
import { CONFIG_ERROR_MESSAGES } from 'bundles/widget/components/config/ExternalUrlEditor';

import _t from 'i18n!nls/cml';

const isEscapeKey = isHotkey('escape');

const TABS = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
} as const;

const styles = {
  dialog: css`
    padding: var(--cds-spacing-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--cds-spacing-300);
    width: 578px;
  `,
  container: css`
    width: 100%;

    /* Labels currently have an extra bottom margin of 8px, that we don't need. */
    .cds-formLabel-root {
      margin: 0;
    }
  `,
  label: css`
    margin-bottom: var(--cds-spacing-100);
  `,
};

export type Props = {
  readonly?: boolean;
  onClose: () => void;
  widgetSession: WidgetSession;
  widgetPreview: WidgetSessionPreview;
  widgetElement: WidgetElement;
  onUpdate?: (widgetSession: WidgetSession, iframeTitle: string, id: string, config: WidgetSessionDraftConfig) => void;
  onDone: () => void;
};

export type EditorProps = {
  readonly?: boolean;
  config: WidgetSessionDraftConfig;
  onConfigChange: (config: WidgetSessionDraftConfig) => void;
  setSaveEnabled: (value: boolean) => void;
  isExternalUrl: boolean;
  hasSimpleEditorOption: boolean;
};

const WidgetConfigDialog: React.FC<Props> = ({
  onClose,
  widgetSession,
  widgetPreview,
  widgetElement,
  onUpdate,
  readonly,
  onDone,
}) => {
  const externalUrl = widgetSession.draft.configuration?.url;
  const hasSimpleEditorOption = hasSimpleConfigEditor(externalUrl, widgetSession.draft?.configuration?.videoId);
  const [tab, setTab] = useState<string>(hasSimpleEditorOption ? TABS.SIMPLE : TABS.ADVANCED);
  const [saveEnabled, setSaveEnabled] = useState(true);

  const handleChange = useCallback(
    (config: WidgetSessionDraftConfig) =>
      onUpdate?.(widgetSession, widgetSession.draft.iframeTitle, widgetSession.id, config),
    [widgetSession, onUpdate]
  );

  const handleKeyDown = useCallback(
    ({ nativeEvent: event }: React.KeyboardEvent) => {
      if (isEscapeKey(event)) {
        onClose();
      }
    },
    [onClose]
  );

  const editorProps = useMemo(
    () => ({
      config:
        widgetElement.id !== widgetSession?.publishedId
          ? widgetPreview.configuration
          : widgetSession.draft.configuration,
      onConfigChange: handleChange,
      setSaveEnabled: (value: boolean) => setSaveEnabled(value),
      hasSimpleEditorOption,
      isExternalUrl: !!externalUrl,
      readonly,
    }),
    [handleChange, widgetSession, widgetPreview, widgetElement, hasSimpleEditorOption, externalUrl, readonly]
  );

  const getTooltipError = useCallback((option: string, jsonOnly: boolean) => {
    if (option === TABS.SIMPLE) {
      return CONFIG_ERROR_MESSAGES.simple();
    }
    if (jsonOnly) {
      return CONFIG_ERROR_MESSAGES.json();
    }
    return CONFIG_ERROR_MESSAGES.advanced();
  }, []);

  return (
    <div
      role="dialog"
      aria-label={_t('Widget configuration dialog')}
      css={styles.dialog}
      onKeyDownCapture={handleKeyDown}
      data-pendo="cml-widget-config-dialog"
    >
      <Button
        variant="ghost"
        size="small"
        icon={<ChevronPreviousIcon />}
        iconPosition="before"
        onClick={onClose}
        data-test="back-btn"
        edgeAlign="start"
      >
        {_t('Back')}
      </Button>
      {hasSimpleEditorOption ? (
        <TabContext value={tab}>
          <Tabs
            aria-label={_t('Configuration Options')}
            onChange={(value) => {
              setTab(value);
              setSaveEnabled(true);
            }}
            css={styles.container}
            data-pendo="cml-widget-config-tabs"
          >
            <Tab label={_t('Simple configuration')} value={TABS.SIMPLE} />
            <Tab label={_t('Advanced configuration')} value={TABS.ADVANCED} />
          </Tabs>
          <TabPanel value={TABS.SIMPLE} css={styles.container}>
            <SimpleConfigEditor {...editorProps} isExternalUrl={!!externalUrl} />
          </TabPanel>

          <TabPanel value={TABS.ADVANCED} css={styles.container}>
            <AdvancedEditor {...editorProps} widgetSession={widgetSession} />
          </TabPanel>
        </TabContext>
      ) : (
        <div css={styles.container}>
          <Typography2 component="h3" variant="subtitleMedium" css={styles.label}>
            {_t('Edit configuration')}
          </Typography2>
          <AdvancedEditor {...editorProps} widgetSession={widgetSession} />
        </div>
      )}
      <FloatingTooltip show={!saveEnabled} message={getTooltipError(tab, !hasSimpleEditorOption)}>
        <div>
          <Button size="small" onClick={onDone} data-test="done-btn" disabled={!saveEnabled}>
            {_t('Done')}
          </Button>
        </div>
      </FloatingTooltip>
    </div>
  );
};

export default WidgetConfigDialog;
