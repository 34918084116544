/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, Typography2, useTheme } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

const bannerItemContainer = css({
  display: 'flex',
});

const titleStyle = (theme: Theme) =>
  css({
    marginBottom: theme.spacing(8),
  });

export type PropsFromCaller = {
  title: string;
  description: string;
  icon: JSX.Element;
};

const LearnerProgramHomeBannerItem = ({ title, description, icon }: PropsFromCaller) => {
  const theme = useTheme();
  return (
    <div css={bannerItemContainer}>
      <div css={{ marginRight: theme.spacing(16) }}>{icon}</div>
      <div>
        <Typography variant="h2semibold" css={titleStyle(theme)} component="h3">
          {title}
        </Typography>
        <Typography2 component="p">{description}</Typography2>
      </div>
    </div>
  );
};

export default LearnerProgramHomeBannerItem;
