/* @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';

import logger from 'js/app/loggerSingleton';
import requestCountry from 'js/lib/requestCountry';

import { useTheme } from '@coursera/cds-core';
import type { CSSProperties } from '@coursera/coursera-ui';

import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import NonRecurringChoiceScreenReaderDescription from 'bundles/s12n-enroll/components/non-recurring/NonRecurringChoiceScreenReaderDescription';

import _t from 'i18n!nls/s12n-enroll';

export type Props = {
  choice: string;
  currentChoice: string;
  productPrice: ProductPrices | undefined;
  numOfMonths: number;
  numOfHours: number;
  percentageOff: number;
  onClick: () => void;
  title: JSX.Element | string;
  duration?: JSX.Element | string;
  showRecommendedPaceBadge?: boolean;
  isFullDiscount?: boolean;
};

const useStyles = (): Record<string, CSSProperties> => {
  const theme = useTheme();

  return {
    nonRecurringChoice: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
      padding: `${theme.spacing(32)} 0 ${theme.spacing(32)} ${theme.spacing(24)}`,
      cursor: 'pointer',
      position: 'relative',
    },
    radioInput: {
      opacity: 0,
      ':checked~span.cif-stack': {
        '.cif-circle': {
          color: '#2571ed',
        },
      },
      ':focus-visible~span.cif-stack': {
        outline: '2px solid #2571ed',
        borderRadius: '50%',
      },
    },
    choiceButton: {
      position: 'absolute !important',
      left: '-10px',
    },
    circleThin: {
      color: 'rgba(0,0,0,0.12)',
    },
    circle: {
      color: 'white',
    },
    choiceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    choiceTitle: {
      fontSize: '16px',
      lineHeight: '30px',
      fontFamily: 'OpenSans',
      marginBottom: 0,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'baseline',
    },
    priceDetails: {
      alignItems: 'flex-end',
    },
    price: {
      fontSize: '16px',
      lineHeight: '30px',
      fontFamily: 'OpenSans',
      fontWeight: 700,
      marginBottom: 0,
    },
    priceExtras: {
      fontWeight: 400,
      marginBottom: '6px',
    },
    badge: {
      width: 'auto',
      display: 'inline-flex',
      borderRadius: '4px',
      textTransform: 'uppercase',
      padding: '0 4px',
      marginBottom: 0,
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '18px',
    },
    transparentBadge: {
      background: 'transparent !important',
      padding: 0,
    },
    durationBadge: {
      background: '#F0F0F0',
      color: '#888888',
    },
    discountBadge: {
      background: '#FFE057',
      color: '#000000',
    },
    recommendedPaceBadge: {
      background: '#ADF9DA',
      marginTop: '8px',
    },
  };
};

const FormattedPrice: React.FC<{
  totalPrice: number;
  currencyCode: string;
  numOfMonths: number;
  percentageOff: number;
}> = ({ totalPrice, currencyCode, numOfMonths, percentageOff }) => {
  const styles = useStyles();

  if (numOfMonths && requestCountry.get() === 'IN') {
    return (
      <>
        <div>
          <span css={styles.price}>
            <ReactPriceDisplay value={Math.round(totalPrice / numOfMonths)} currency={currencyCode} />
          </span>
          <span css={styles.priceExtras}>/{_t('month')}</span>
        </div>

        <div css={styles.priceExtras}>
          <span>{_t('Total')}&nbsp;</span>
          <ReactPriceDisplay value={totalPrice} currency={currencyCode} />
        </div>
      </>
    );
  }

  return (
    <>
      <div css={styles.price}>
        <ReactPriceDisplay value={totalPrice} currency={currencyCode} />
      </div>

      {percentageOff > 0 && (
        <div css={[styles.badge, styles.discountBadge]}>{_t('#{percentageOff}% off', { percentageOff })}</div>
      )}
    </>
  );
};

const NonRecurringChoice: React.FunctionComponent<Props> = ({
  choice,
  currentChoice,
  productPrice,
  numOfMonths,
  numOfHours,
  percentageOff,
  onClick,
  title,
  duration,
  showRecommendedPaceBadge = false,
  isFullDiscount = false,
}) => {
  const styles = useStyles();

  const descriptionId = `non-recurring-choice-description-${choice}`;
  const inputId = `non-recurring-choice-${choice}`;

  if (!productPrice) {
    logger.error('Missing price for payment pass option');
    return null;
  }

  return (
    <label data-testid="rc-NonRecurringChoice" htmlFor={inputId} css={styles.nonRecurringChoice}>
      <input
        type="radio"
        id={inputId}
        value={choice}
        checked={choice === currentChoice}
        onClick={onClick}
        aria-describedby={descriptionId}
        css={styles.radioInput}
      />

      <NonRecurringChoiceScreenReaderDescription
        numOfMonths={numOfMonths}
        numOfHours={numOfHours}
        productPrice={productPrice}
        percentageOff={percentageOff}
        isRecommended={showRecommendedPaceBadge}
      />

      <span aria-hidden="true" className="cif-stack" css={styles.choiceButton}>
        <i className="cif-circle-thin cif-stack-2x" css={styles.circleThin} />
        <i className="cif-circle cif-stack-1x" css={styles.circle} />
      </span>

      <div aria-hidden="true" css={styles.choiceContainer}>
        <div className="context-details" css={[styles.details, styles.contextDetails]}>
          <h4 css={styles.choiceTitle} id={descriptionId}>
            {title}
          </h4>

          <div css={[styles.badge, styles.durationBadge, showRecommendedPaceBadge && styles.transparentBadge]}>
            {duration}
          </div>

          {showRecommendedPaceBadge && (
            <div css={[styles.badge, styles.recommendedPaceBadge]}>{_t('Recommended Pace')}</div>
          )}
        </div>

        <div className="price-details" css={[styles.details, styles.priceDetails]}>
          {isFullDiscount ? (
            _t('Free')
          ) : (
            <FormattedPrice
              totalPrice={productPrice.amount}
              currencyCode={productPrice.currencyCode}
              numOfMonths={numOfMonths}
              percentageOff={percentageOff}
            />
          )}
        </div>
      </div>
    </label>
  );
};

export default NonRecurringChoice;
