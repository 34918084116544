/* common emotion styles used inside the CML editor */
import { css } from '@emotion/react';

// styles taken directly from https://www.figma.com/file/199O68clHPnYpeqcARrd1F/CML-updates%3A-Google-Q2-2021
export const hoverMenu = () => css`
  background: white;

  /* stylelint-disable length-zero-no-unit,alpha-value-notation */
  box-shadow: 0px 9px 27px rgb(0 0 0 / 0.07), 0px 3.76px 11.28px rgb(0 0 0 / 0.05), 0px 2px 6px rgb(0 0 0 / 0.04),
    0px 1.1px 3.4px rgb(0 0 0 / 0.035), 0px 0.6px 1.8px rgb(0 0 0 / 0.03), 0px 0.25px 0.75px rgb(0 0 0 / 0.02);
  /* stylelint-enable length-zero-no-unit,alpha-value-notation */

  border-radius: 8px;
`;
